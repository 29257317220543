import { Component } from 'react';
import LocalizedStrings from 'react-localization';

class Localization extends Component {
    static strings = new LocalizedStrings({
        en:{
          jobs: "Jobs",
          assetManagement: "Asset Management",
          items: "Items",
          dashboard: "Dashboard",
          productInclusionGuide: "Inclusion Guide",
          colourScheme: "Colour Scheme",
          categories: "Categories",
          attributes: "Attributes",
          report: "Report",
          reportTemplates: "Report Templates",
          placeholders: "Placeholders",
          users: 'Users',
          preferences: 'Company Settings',
          termsOfService: 'Terms of Service',
          privacyPolicy: 'Privacy Policy',
          getStartedMainTextFirstPart: 'Perform Your Selection',
          smartly: 'smartly',
          online: 'online',
          getStartedSecondText: 'Perform selection, create colour schemes, generate and sign the job reports, markup the floor plans using your PC, tablet or phone.',
          forgotPasswordMain: 'Forgot Password?',
          createCompany: 'Create Company',
          forgotPasswordSecond: 'Enter your Email address and we will send you link with instructions, how to recover your password:',
          forgotPasswordSent: 'Recovery Link Sent!',
          forgotPassworHintText: 'If you don’t see the Email, check your spam folder.',
          forgotPasswordSentAddition: 'We Sent a password reset link and instructions to <span class="text-bold">{0}</span>.',
          loginText: 'Welcome Back!',
          resendCode: 'Click here to resend',
          emailVerificationMain: 'Email Verification',
          emailVerificationSecond: 'Enter the 6-digits verification code we sent to <span class="text-bold to-lowercase">{0}</span>',
          backToPrevious: 'BACK TO PREVIOUS STEP',
          resetPassword: 'Reset Password?',
          userBannedMain: 'Request Banned.',
          userBannedSecond: 'Unfortunately, your request to create <span class="text-bold">{0}</span> company was banned by company admin.',
          userJoinBannedSecond: 'Unfortunately, your request to join <span class="text-bold">{0}</span> company was banned by company admin.',
          requestSent: 'Request Sent!',
          weSentYourRequest: 'We sent your request to join <span class="text-bold">{0}</span> company.',
          nowWait: ' Now you need to wait until company admin accepts it.',
          companyCreated: 'Company Created!',
          companyCreatedRequest: 'Your company <span class="text-bold">{0}</span> is successfully created and is under review. You will get status notification email.',
          yourRequestIsWasRejected: 'Unfortunately, your request to create <span class="text-bold">{0}</span> company was rejected by admin.',
          editAndResubmitYourRequest: 'You can edit and resubmit your request.',
          editYourPreviousCompanyRequest: 'Edit your previous company request',
          requestCompanyMain: 'Almost Done!',
          requestCompanySecond: 'Looks like your colleagues are already using <span class="text-bold">{0}</span>',
          requestCompanyThird: 'Are you a part of a company named <span class="text-bold">{0}</span>?',
          buttonYesRequestToJoin: 'Yes, Request to Join',
          companyDeactivated: 'Sorry, {0} Your Company {0} Is Deactivated!',
          companyExpiredMain: 'Your Company {0} Trial Period Expired.',
          companyExpiredAdmin: 'Please, contact the sales manager for more details.',
          companyExpiredUser: 'Please, ask your company admin to contact the sales manager for more details.',
          companyExpiredButton: 'Contact Sale Manager',
          requestRejectedMain: 'Request Rejected.',
          requestRejectedSecond: 'Unfortunately, your request to join <span class="text-bold">{0}</span> company was rejected by company admin.',
          requestRejectedThird: 'You can retry request to join this company.',
          companyCreatedResubmitted: 'Company Resubmitted.',
          companyCreatedRequestResubmitted: 'Your company  <span class="text-bold">{0}</span> is successfully resubmitted and under review now. You will get status notification email.',
          manageJoinApprove: 'The user will be able to log into the system after approval',
          manageJoinReject: 'The user will not be able to log into the system but will be able to resend the join request',
          manageJoinBan: 'The user will not be able to log into the system and resend the join request',
          manageJoinUnban: 'You will see the unbanned request in the new ones',
          activateCompany: 'Company users will have the access to the system again',
          deactivateCompany: 'Company users will not have the access to the system',
          manageCreateApprove: 'The user will be able to log into the company after approval',
          manageCreateReject: 'You will need to provide the reason of rejection. The user will not be able to log into the company but will be able to resubmit the request',
          manageCreateBan: 'You will need to provide the reason of ban. The user will not be able to log into the system and resend the request',
          manageCreateEdit: 'The user will get notification about edited data',
          manageCreateUnban: 'You will see the unbanned request in the new ones',
          haventReceivedTheEmail: 'Have not received the Email?',
          backToLoginPage: 'Back to Login Page',
          backToWebsite: 'Back to Website',
          requestToJoinText: 'The name entered <span class="text-bold">{0}</span>  already exists.',
          chooseDifferentNameButton: 'Enter New Name',
          requestToJoinButton: 'Request to Join',
          createCompanyFileSizeLimitError: 'Image file size is too big. Please provide image with size less than 2MB.',
          createReportTemplateFileSizeLimitError: '<div class="large_img"><div class="message">Selected image is not proper enough!</div><div class="size">Image should be square (1*1 ratio), larger than 300*300px and less than 1200*1200 and file size less than 1mb in jpg/jpeg/png file format.</div> <div class="suggestion">Suggestion: choose an image with transparent/white background.</div></div>',
          createCompanyFileDimensionsError: 'Image file dimensions are invalid.',
          enterEmail: 'Enter your email',
          enterPassword: 'Enter your password',
          enterFirstName: 'Enter first name',
          enterLastName: 'Enter last name',
          requiredField: 'This field is required.',
          confirmPassword: 'Enter a password to confirm',
          companyName: 'Enter company name',
          companyPosition: 'Enter company position',
          companyAddress: 'Enter company address',
          matchPassword: 'Those passwords didn\'t match',
          invalidEmail: 'Invalid email address',
          invalidDomain: 'Invalid domain',
          invalidName: 'Invalid name',
          invalidCompanyPosition: 'Invalid company position',
          invalidCompanyName: 'Invalid company name',
          invalidPIGName: 'Invalid Inclusion Guide name',
          invalidAttributeName: 'Invalid attribute name',
          invalidCategoryName: 'Invalid category name',
          invalidColourSchemeName: 'Invalid colour scheme name',
          invalidFirstName: 'Invalid first name',
          invalidFilterReportName: 'Invalid filter report name',
          invalidFilterReportValue: 'Invalid attribute value',
          invalidLastName: 'Invalid last name',
          codeResent: 'New code and link have been sent to your email',
          incorrectLoginOrPassword: 'Incorrect login or password',
          alreadyHaveAnAccountYet: 'Already {0} have an account?',
          dontHaveAnAccountYet: 'Don\'t have {0} an account yet?',
          alreadyHaveAnAccountYetDesktop: 'Already have an account?',
          dontHaveAnAccountYetDesktop: 'Don\'t have an account yet?',
          editRequest: 'Are you sure you want to edit this create company request?',
          rejectRequest: 'Are you sure you want to reject this create company request?',
          banRequest: 'Are you sure you want to ban this create company request?',
          unbanRequest: 'Are you sure you want to unban this create company request?',
          deleteRequest: 'Are you sure you want to delete this {0}?',
          deleteReport: 'Delete Template <span class="text-bold">{0}</span>, are you sure?',
          deletePIG: 'Delete the Inclusion Guide <span class="text-bold">{0}</span>, are you sure?',
          deleteHeader: 'Delete Header <span class="text-bold">{0}</span>, are you sure?',
          deleteSubHeader: 'Delete Sub-Header <span class="text-bold">{0}</span>, are you sure?',
          deleteSelectionArea: 'Delete Selection Area <span class="text-bold">{0}</span>, are you sure?',
          deletePlaceholder: 'Delete Placeholder <span class="text-bold">{0}</span>, are you sure?',
          deleteSelectionType: 'Delete Selection Type <span class="text-bold">{0}</span>, are you sure?',
          deleteHeaderSecondText: 'This Header will be removed from all the existing job reports including the sub-headers, placeholders and selected items.',
          deleteHeaderSecondTextSuperAdmin: 'Also all subheader will be deleted permanently.',
          deleteSubHeaderSecondText: 'This Sub-Header will be removed from all the existing job reports including the selection types and selected items.',
          deleteSelectionAreaSecondText: 'This Selection Area will be removed from all the existing job reports including the selection types and selected items.',
          deleteSubHeaderSecondTextSuperAdmin: 'Sub-Header will be deleted permanently.',
          deleteColourScheme: 'Delete the colour scheme <span class="text-bold">{0}</span>, are you sure?',
          deleteColourSchemeSecond: 'The colour scheme will be permanently deleted!',
          deletePlaceholderSecondText: 'This Category Placeholder will be removed from all the existing job reports including the selected items.',
          deleteSelectionTypeSecondText: 'This Selection Type will be removed from all the existing job reports including the selected items.',
          deleteSecondText: 'The {0} will be permanently deleted!',
          deleteCompanyItem: 'Delete company item <span class="text-bold">{0}</span>, are you sure?',
          deleteCompanyItemSecond: 'The item will be permanently deleted!',
          deleteAsset: 'Delete asset <span class="text-bold">{0}</span>, are you sure?',
          discontinueAsset: 'Discontinue asset <span class="text-bold">{0}</span>, are you sure?',
          discontinueItem: 'Discontinue item <span class="text-bold">{0}</span>, are you sure?',
          discontinueItemCP: 'Deactivate item <span class="text-bold">{0}</span>, are you sure?',
          activateAsset: 'Activate asset <span class="text-bold">{0}</span>, are you sure?',
          activateItem: 'Activate item <span class="text-bold">{0}</span>, are you sure?',
          deleteAssetSecond: 'The asset will be permanently deleted!',
          discontinueAssetSecond: 'All derived Company Items will be discontinued!',
          discontinueItemSecond: 'Company Item will not be available for any action!',
          activateAssetSecond: 'Assets will become available to companies!',
          activateItemSecond: 'Company item will become available for all actions!',
          approveRequest: 'Approve request to create comapny <span class="text-bold">{0}</span>, are you sure?',
          requestModeButton: 'Yes, {0} it.',
          updatePackage: 'Do you want to update all current selections?',
          createJob: 'Create New Job',
          editJob: 'Edit Job',
          toActivateCompany: 'Are you sure you want to activate this company?',
          toDeActivateCompany: 'Are you sure you want to deactivate this company?',
          areYouSureExitWithoutSaving: 'Are you sure you want to exit without saving?',
          youAreNotAHuman: 'Access not allowed. Suspicious activity detected',
          youAreNotAllowed: 'You are not allowed to perform this action',
          invalidDateFormat: 'Invalid Date Format',
          approveRequestFromUser: '{0} request from <span class="text-bold">{1}</span> to join <span class="text-bold">{2}</span>, are you sure?',
          requestUsersModeButton: 'Yes, {0}',
          unBunUser: 'The user will be able to log into the system and resend the join request',
          settings: 'Settings',
          reportTemplateColourSettings: 'Report Template Colour Settings',
          setHeaderDefaultColour: 'Set Header Default Colour:',
          setHeaderDefaultTextColour: 'Set Header Default Text Colour:',
          setSubHeaderDefaultColour: 'Set Sub-Header Default Colour:',
          setSubHeaderDefaultTextColour: 'Set Sub-Header Default Text Colour:',
          headerColour: 'Header Background',
          headerTextColour: 'Header Text',
          subHeaderColour: 'Sub-Header Background',
          subHeaderTextColour: 'Sub-Header Text',
          selectionAreaColour: 'Selection Area Background',
          selectionAreaTextColour: 'Selection Area Text',
          editPIG: 'Edit Inclusion Guide',
          createPIG: 'Create Inclusion Guide',
          editReportTemplate: 'Edit Template',
          createReportTemplate: 'Add New Template',
          applyChanges: 'Apply Changes',
          createReport: 'Create Report',
          addTemplate: 'Add Template',
          copyReport: 'Copy Report',
          duplicate: 'Duplicate',
          specifyTheAttributes: 'Specify the Attributes Value(s)',
          specifyThePlaceholders: 'Specify the Placeholders',
          areYouDeleteNote: 'Are you sure you want to delete this note?',
          youWantLeaveEmpty: 'Are you sure you want to leave empty fields?',
          existingJobSelections: 'Do you want to overwrite all the existing job selections?',
          colorSchemeLeaveWithoutChanges: 'Do you want to leave without changes? All changes will be lost.',
          allPlaceholdersRelated: 'All placeholders related to the category <span class="text-bold">{0}</span> have been filled.',
          noPlaceholderDefined: 'No placeholder defined for this item category found in the company',
          noPlaceholderDefinedReport: 'No placeholder defined for this item category found in the report.',
          noItemsOfTheSelected: 'No items of the selected category found in the company.',
          deleteItemFromJob: 'Delete the Item <span class="text-bold">{0}</span> from <span class="text-bold">{1}</span>, are you sure?',
          selectedItemPermanentlyDeleted: 'Selected {0} will be permanently deleted!',
          deleteJobFor: 'Delete the job <span class="text-bold">{0}</span> for <span class="text-bold">{1}</span>, are you sure?',
          jobWillBePermanently: 'The Job will be permanently deleted!',
          changesWillBeLost: 'All changes will be lost!',
          exitWithoutSavingAreSure: '<span class="text-bold">Exit without saving,</span> are you sure?',
          pleasePayAttention: 'Please pay attention, This action cannot be reversed!',
          overwriteAllTheExisting: 'Overwrite all the existing job selections in <span class="text-bold">{0}</span>, are you sure?',
          companiesWillHaveAccess: 'Companies will have access to it.',
          companiesWillNotHaveAccess: 'Companies will no longer have access to it.',
          cancel: 'Cancel',
          yesDelete: 'Yes, Delete',
          yesPublish: 'Yes, Publish',
          yesChange: 'Yes, Change',
          yesImport: 'Yes, Import',
          publishTemplate: 'Publish Template <span class="text-bold">{0}</span>, are you sure?',
          changeTemplateToDraft: 'Change to draft <span class="text-bold">{0}</span>, are you sure?',
          youCanCustomizeReportTemplate: 'You can customize this template by changing headers and sub-hedaers in Template',
          youCanCustomizeReportTemplateLibrary: 'You can customize these templates by changing headers and sub-hedaers in Template',
          importReportTemplate: 'Import Template <span class="text-bold">{0}</span>, are you sure?',
          importReportTemplateArray: 'Import <span class="text-bold">{0}</span> Templates, are you sure?',
          importReportTemplateArrayOne: 'Import <span class="text-bold">{0}</span> Template, are you sure?',
          createColorScheme: 'Create Colour Scheme',
          editColorScheme: 'Edit Colour Scheme',
          createdSuccessfully: '{0} <span class="text-bold">{1}</span> created successfully.',
          createProductInclusionGuide: 'Create Inclusion Guide',
          applyChangesTo: 'Apply changes to <span class="text-bold">{0}</span>?',
          areYouSureYouWantToDeleteAllTheItemsIn: 'Are you sure you want to delete all the items in <span class="text-bold">{0}</span>?',
          doYouWantToCreateNewVersionOf: 'Do you want to create new version of <span class="text-bold">{0}</span>?',
          areYouSureToApplyChanges: 'Apply changes to <span class="text-bold">{0}</span>, are you sure?',
          newVersionCreatedFor: 'New version <span class="text-bold">{0}</span> created for <span class="text-bold">{1}</span>.',
          newVersionCreatedName: 'Do you want to create new version of <span class="text-bold">{0}</span>?',
          requestToCreateNewAsset: 'Request to Create New Asset',
          resubmitRequestCreateAsset: 'Resubmit Request to Create New Asset',
          adminWillNoLongerSeeRequest: 'The admin will no longer see this request.',
          cancelRequestToCreateNewAsset: 'Cancel request to create a new asset <span class="text-bold">{0}</span>, are you sure?',
          yourRequestToCreateNewAsset: 'Your request to <span class="text-bold">Create a New Asset</span> submitted successfully.',
          yourReRequestToCreateNewAsset: 'Your request to <span class="text-bold">Create a New Asset</span> re-submitted successfully.',
          approveAssetAreYouSure: 'Approve asset <span class="text-bold">{0}</span>, are you sure?',
          thereNoWayUndoAction: 'There is no way to undo the action.',
          exitWithoutSaving: '<span style="font-weight: bold">Exit without saving</span>, are you sure?',
          doNotHavePerform: 'You do not have right to perform this action',
          reportTemplateImported: 'Report Template successfully imported!',
          reportTemplatesImported: 'Report Templates successfully imported!',
          comparisonCompanyItem: 'Comparison of "Company Item" and "Vendor Asset"',
          discontinueNotAllowed: 'Company Item cannot be activated because corresponding asset is Discontinued',
          olderVersionsEditable: 'Older versions are not editable!',
          noValueForCustomisation: 'There is no value for customisation',
          makeSureAllAttributesFilled: 'Make sure all Attributes are filled!',
          deactivateRemoveCompanyItemsBelow: '<span class="text-bold">{0}</span> Company Items below, are you sure?',
          sendNotificationToOtherCompanyAdm: 'Send Notification to other Company Admins and Managers',
          confirmToDelete: 'Confirm to Delete',
          deactivatingSelectedItemNotPossible: 'Deactivating selected Company Item is not possible! it’s used in the list below:',
          designRange: 'Design Range',
          textBoldAre: '<span>{0}</span> <span class="text-bold">{1}</span>, are you sure?',
          designRangeUsed: 'Design Range <span class="text-bold">{0}</span> is currently used in the following Jobs:',
          deactivatedDesignRangesAvailable: '<span class="text-bold">Deactivated Design Ranges</span> will not be available for New Jobs.\n' +
              'Deactivation process does not affect  any InProgress, Completed or Archived Jobs.',
          canNotDeleteUsedDesignRanges: 'You can not Delete in-used Design Ranges. But you can <span class="text-bold">Archieve</span> this whilst it’s in use.<br>' +
              '<span class="text-bold">Archived Design Range</span> will not appear during creating a new Job or in edit Job modals. It is Archived only because it has already been used in a Completed/Archived Job.',
          editDesignRange: 'Edit Design Range <span class="text-bold" style="max-width: 170px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{0}</span>',
          duplicateDesignRange: 'Duplicate Design Range <span class="text-bold">{0}</span>',
          addNewDesignRange: 'Add New Design Range',
          addDesignRange: 'Add Design Range',
          limitedAccessDelete: 'Limited Access to Delete. This Design Range is currently used in <span>{0}</span> Jobs!',
          moreDetails: 'More Details',
          doYouWantCreateDuplicate: 'Do you want to create a <span class="text-bold">Duplicate</span> version? <br>' +
              '<span style="font-size: 11px">You will have full access to edit and adjust all items.</span>',
          addSelectionReport: 'Add Selection Report',
          addNewSelectionReport: 'Add New Selection Report'
        },
       })
}

export default Localization;
