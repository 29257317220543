import * as actionTypes from '../Actions/actionTypes';
import {Job} from 'Models/Job';
import {AssignJob} from "Models/AssignJob";

const initialState = {
    error: false,
    loading: false,
    jobVersions: new Array(0),
    markUps: new Array(0),
    companyJobs: new Array(0),
    job: null,
    notes: new Array(0),
    initialJob: new Job(),
    assignJob: new AssignJob()
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ASSIGN_JOB_TO_PARENT_COMPANY_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ASSIGN_JOB_TO_PARENT_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ASSIGN_JOB_TO_PARENT_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.RETURN_JOB_TO_CHILD_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.RETURN_JOB_TO_CHILD_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.RETURN_JOB_TO_CHILD_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_JOB_VERSIONS_START:
            return {
                ...state,
                loading: state.jobVersions.some(v => v.reportTemplateId)
            };
        case actionTypes.SET_JOB_VERSIONS:
            return {
                ...state,
                loading: false,
                jobVersions: action.jobVersions,
                error: false
            };
        case actionTypes.GET_JOB_VERSIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_NOTE_TO_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_NOTE_TO_JOB_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_NOTE_TO_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_NOTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_NOTE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_NOTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPLOAD_NOTE_IMAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPLOAD_NOTE_IMAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.UPLOAD_NOTE_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPLOAD_PLACEHOLDER_NOTE_IMAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPLOAD_PLACEHOLDER_NOTE_IMAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.UPLOAD_PLACEHOLDER_NOTE_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_PLACEHOLDER_NOTE_IMAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_PLACEHOLDER_NOTE_IMAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_PLACEHOLDER_NOTE_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_MARKUP_FILE_TO_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_MARKUP_FILE_TO_JOB_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_MARKUP_FILE_TO_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_MARKUP_FILE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_MARKUP_FILE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_MARKUP_FILE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_MARKUP_OF_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_MARKUP_OF_JOB:
            return {
                ...state,
                loading: false,
                error: false,
                markUps: action.markUps
            };
        case actionTypes.GET_MARKUP_OF_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_MARK_UP_VERSION_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_MARK_UP_VERSION_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_MARK_UP_VERSION_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_JOBS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_COMPANY_JOBS:
            return {
                ...state,
                loading: false,
                companyJobs: action.companyJobs
            };
        case actionTypes.GET_COMPANY_JOBS_FAILD:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_JOB:
            return {
                ...state,
                loading: false,
                job: action.job
            };
        case actionTypes.GET_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REMOVE_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_JOB_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.REMOVE_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DOWNLOAD_JOB_REPORT_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DOWNLOAD_JOB_REPORT_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.DOWNLOAD_JOB_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_JOB_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.EDIT_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ASSIGN_CONSULTANT_TO_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ASSIGN_CONSULTANT_TO_JOB_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.ASSIGN_CONSULTANT_TO_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_JOB_REPORT_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_JOB_REPORT_END:
            return {
                ...state,
                loading: false
                };
        case actionTypes.GET_JOB_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_JOB_NOTES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_JOB_NOTES:
            return {
                ...state,
                loading: false,
                notes: action.notes
                };
        case actionTypes.GET_JOB_NOTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_NOTE_FROM_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_NOTE_FROM_JOB_END:
            return {
                ...state,
                loading: false
                };
        case actionTypes.DELETE_NOTE_FROM_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ON_SET_JOB_FOR_EDIT:
            return {
                ...state,
                initialJob: action.initialJob
            }
        case actionTypes.SET_ASSIGN_JOB_INFO:
            return {
                ...state,
                assignJob: action.assignJob
            }
        default:
            return state;
    }
};

export default reducer;
