import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';

export const addUserToCompanyStart = () => {
    return {
        type: actionTypes.ADD_USER_TO_COMPANY_START
    }
}

export const addUserToCompanyEnd = () => {
    return {
        type: actionTypes.ADD_USER_TO_COMPANY_END
    }
}

export const addUserToCompanyFailed = () => {
    return {
        type: actionTypes.ADD_USER_TO_COMPANY_FAILED
    }
}

export const addUserToCompany = (companyId, user, callback, errorCallback) => {
    return dispatch => {
        dispatch(addUserToCompanyStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/users`, user)
        .then(response => {
            dispatch(addUserToCompanyEnd())
            callback(response)
        }).catch(error => {
            dispatch(addUserToCompanyFailed())
            errorCallback(error)
        })
    }

}

export const removeUserFromCompanyStart = () => {
    return {
        type: actionTypes.REMOVE_USER_FROM_COMPANY_START
    }
}

export const removeUserFromCompanyEnd = () => {
    return {
        type: actionTypes.REMOVE_USER_FROM_COMPANY_END
    }
}

export const removeUserFromCompanyFailed = () => {
    return {
        type: actionTypes.REMOVE_USER_FROM_COMPANY_FAILED
    }
}

export const removeUserFromCompany = (companyId, userId, callback) => {
    return dispatch => {
        dispatch(removeUserFromCompanyStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/users/${userId}`)
        .then(response => {
            dispatch(removeUserFromCompanyEnd())
            callback(response)
        }).catch(error => {
            dispatch(removeUserFromCompanyFailed())
        })
    }

}

export const getCompanyUsersStart = () => {
    return {
        type: actionTypes.GET_COMPANY_USERS_START
    }
}

export const setCompanyUsers = (companyUsers) => {
    return {
        type: actionTypes.SET_COMPANY_USERS,
        companyUsers
    }
}

export const getCompanyUsersFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_USERS_FAILED
    }
}

export const getCompanyUsers = (companyId, searchWord, callback, limit, offset, sort) => {
    return dispatch => {
        dispatch(getCompanyUsersStart())

        let sortItems = ''

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/users${sortItems}`, {
            params: {
              ...(limit ? {limit} : {limit: '1000'}),
              ...(offset ? {offset} : {offset: 0}),
              ...(searchWord ? {q: searchWord} : {}),
            }
        })
        .then(response => {
            dispatch(setCompanyUsers(response.data.rows))
            if (callback) {
                callback(response)
            }
        }).catch(error => {
            console.log(error)
            dispatch(getCompanyUsersFailed())
        })
    }

}

export const getMeStart = () => {
    return {
        type:actionTypes.GET_ME_START
    }
}

export const getMeFailed = () => {
    return {
        type:actionTypes.GET_ME_FAILED
    }
}

export const getMe = (callback, errorCallback) => {
    return dispatch => {
        dispatch(getMeStart())
        axios.get(`${Constants.SERVER_URL}/users/me`)
        .then(response => {
            if(callback) {
                callback(response)
            }
        }).catch(error => {
            console.log(error)
            dispatch(getMeFailed())
            if(errorCallback) {
                errorCallback(error)
            }
        })
    }

}

export const setCheckUserData = checkUserData => {
    return {
        type: actionTypes.SET_CHECK_USER_DATA,
        checkUserData
    }
}

export const setModalText = (modalText, callback) => {
    return {
        type: actionTypes.SET_MODAL_TEXT,
        modalText,
        callback
    }
}

export const setModalTextInside = (modalTextInside, callbackFunctionInside) => {
    return {
        type: actionTypes.SET_MODAL_TEXT_INSIDE,
        modalTextInside,
        callbackFunctionInside
    }
}

export const setCurrentUser = currentUser => {
    return {
        type: actionTypes.SET_CURRENT_USER,
        currentUser
    }
}


export const updateUserInfoStart = () => {
    return {
        type: actionTypes.UPDATE_USER_INFO_START
    }
}

export const updateUserInfoFinished = () => {
    return {
        type: actionTypes.UPDATE_USER_INFO_FINISHED
    }
}

export const updateUserInfoFailed = () => {
    return {
        type: actionTypes.UPDATE_USER_INFO_FAILED
    }
}

export const updateUserInfo = (userInfo, callback, errorCallback) => {
    return dispatch => {
        dispatch(updateUserInfoStart());
        axios.patch(`${Constants.SERVER_URL}/users/me`, userInfo)
            .then(response => {
                if(callback) {
                    callback(response.data)
                }
                dispatch(updateUserInfoFinished())
            })
            .catch(error => {
                console.log(error)
                dispatch(updateUserInfoFailed());
                errorCallback(error.response.data.error);
            })
    }
}

export const contactUsStart = () => {
    return {
        type: actionTypes.CONTACT_US_START
    }
}

export const contactUsFinished = () => {
    return {
        type: actionTypes.CONTACT_US_FINISHED
    }
}

export const contactUsFailed = () => {
    return {
        type: actionTypes.CONTACT_US_FAILED
    }
}

export const contactUs = (contactUsForm, callback, errorCallback) => {
    return dispatch => {
        dispatch(contactUsStart());
        let url = '';
        if (localStorage.getItem('accessToken')) {
            url = `${Constants.SERVER_URL}/contact`;
        } else {
            url = `${Constants.SERVER_URL}/contact/support`;
        }
        axios.post(url, contactUsForm)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(contactUsFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error)
                dispatch(contactUsFailed())
            })
    }
}

export const toggleContactUsModal = (status) => {
    return dispatch => {
        dispatch({
            type: actionTypes.TOGGLE_CONTACT_US_MODAL,
            openContactUsModal: status
        })
    }
}

export const editUserStart = () => {
    return {
        type: actionTypes.EDIT_USER_START
    }
}

export const editUserFinished = () => {
    return {
        type: actionTypes.EDIT_USER_FINISHED
    }
}

export const editUserFailed = () => {
    return {
        type: actionTypes.EDIT_USER_FAILED
    }
}

export const editUser = (userId, user, callback, errorCallback) => {
    return dispatch => {
        dispatch(editUserStart());
        axios.patch(`${Constants.SERVER_URL}/users/${userId}`, user)
            .then(response => {
                if (callback) {
                    callback();
                }
                dispatch(editUserFinished())
            })
            .catch(error => {
                errorCallback(error.response ? error.response.data.error : error);
                dispatch(editUserFailed());
            })
    }
}

export const changeUserPasswordBySuperAdminStart = () => {
    return {
        type: actionTypes.CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_START
    }
}

export const changeUserPasswordBySuperAdminFinished = () => {
    return {
        type: actionTypes.CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FINISHED
    }
}

export const changeUserPasswordBySuperAdminFailed = () => {
    return {
        type: actionTypes.CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FAILED
    }
}

export const changeUserPasswordBySuperAdmin = (userId, password, callback, errorCallback) => {
    return dispatch => {
        dispatch(changeUserPasswordBySuperAdminStart());
        axios.patch(`${Constants.SERVER_URL}/users/${userId}/password`, password)
    }
}

export const changeUserRoleBySuperAdminStart = () => {
    return {
        type: actionTypes.CHANGE_USER_ROLE_BY_SUPER_ADMIN_START
    }
}

export const changeUserRoleBySuperAdminFinished = () => {
    return {
        type: actionTypes.CHANGE_USER_ROLE_BY_SUPER_ADMIN_FINISHED
    }
}

export const changeUserRoleBySuperAdminFailed = () => {
    return {
        type: actionTypes.CHANGE_USER_ROLE_BY_SUPER_ADMIN_FAILED
    }
}

export const changeUserRoleBySuperAdmin = (companyId, userId, role, callback, errorCallback) => {
    return dispatch => {
        dispatch(changeUserRoleBySuperAdminStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/users/${userId}/role`, role)
    }
}

export const onSetUserForEdit = (user) => {
    return {
        type: actionTypes.ON_SET_USER_FOR_EDIT,
        user
    }
}

export const setContactUsIsOpen = contactUsModalIsOpen => {
    return {
        type: actionTypes.SET_CONTACT_US_IS_OPEN,
        contactUsModalIsOpen
    }
}

export const getUserNotificationsStart = () => {
    return {
        type: actionTypes.GET_USER_NOTIFICATIONS_START
    }
}

export const setUserNotifications = userNotifications => {
    return {
        type: actionTypes.SET_USER_NOTIFICATIONS,
        userNotifications
    }
}

export const getUserNotificationsFailed = () => {
    return {
        type: actionTypes.GET_USER_NOTIFICATIONS_FAILED
    }
}

export const getUserNotifications = (limit, offset, status, searchWord, callback, errorCallback) => {
    return dispatch => {
        dispatch(getUserNotificationsStart());
        axios.get(`${Constants.SERVER_URL}/users/me/notifications`, {
            params: {
                ...(limit ? {limit} : {limit: '1000'}),
                ...(offset ? {offset} : {offset: 0}),
                ...(searchWord ? {q: searchWord} : {}),
                ...(status ? {read: status} : {})
            }
        })
            .then(response => {
                if (callback) {
                    callback(response);
                }
                dispatch(setUserNotifications(response.data))
            })
            .catch(error => {
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData);
                }
                console.log(errorData)
                dispatch(getUserNotificationsFailed())
            })
    }
}

export const requestUserInformation = (idItems, callback, errorCallback) => {
     return dispatch => {
        axios.post(`${Constants.SERVER_URL}/users/me/notifications/read`, idItems)
            .then(response => {
                if (callback) {
                    callback(response);
                }
            })
            .catch(error => {
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData);
                }
                console.log(errorData)
            })
    }
}

export const setWarningModalRedux = (showWarningModalRedux, callback) => {
    return {
        type: actionTypes.SHOW_WARNING_MODAL_REDUX,
        showWarningModalRedux,
        callback
    }
}

export const setChangeComponent = (changeComponent) => {
    return {
        type: actionTypes.CHANGE_COMPONENT,
        changeComponent
    }
}

export const setGlobalSpinner = globalSpinner => {
    return {
        type: actionTypes.SET_GLOBAL_SPINNER,
        globalSpinner
    }
}