import React, { Component, Suspense } from 'react';
import './App.scss';
import './inspinia.css';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import Modal from "react-modal";
import UserUtils from "./Utils/UserUtils";
import LoadingIndicator from "./Utils/LoadingIndicator";
import routes from "./loginRoutes";
import {setCurrentUser} from "./Store/Actions";
import MainWrapper from "./UI/MainWrapper";

class App extends Component {
    constructor() {
        super();
        this.state = {
            spinner: true,
            loginStatus: true,
            currentRoute: ''
        };
        this.events = [
            "load",
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];

        this.warn = this.warn.bind(this);
        this.logout = this.logout.bind(this);
        this.resetTimeout = this.resetTimeout.bind(this);


        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
    }

    componentDidMount() {
        // window.location.reload();
        Modal.setAppElement('body');
        if (UserUtils.isLoggedIn()) {
            UserUtils.authorize();
        }
        this.setState({ spinner: false });
    }

    clearTimeout() {
        // if (this.warnTimeout) clearTimeout(this.warnTimeout);

        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }

    setTimeout() {
        // this.warnTimeout = setTimeout(this.warn, 16 * 1000);
        this.logoutTimeout = setTimeout(this.logout, 3600000);
    }

    resetTimeout() {
        this.clearTimeout();
        this.setTimeout();
    }

    warn() {
        alert("You will be logged out automatically in 1 minute.");
    }

    logoutCurrentUser = (val) => this.props.store.dispatch(setCurrentUser(val))

    logout() {
        this.setState({ loginStatus: false });
        UserUtils.logoutUser(this.logoutCurrentUser);
        this.props.history.push('/login')
    }

    destroy() {
        this.clearTimeout();
        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
    }

    renderApplication() {
        return (
            <Suspense fallback={<div style={{display: 'flex', height: 600}}><LoadingIndicator/></div>}>
                <Switch>
                    {routes.map(route => {
                        return route.component && (
                            <Route
                                key={route.name}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} />
                                )}/>
                        );
                    })}
                </Switch>
            </Suspense>
        )
    }

    render() {
        const {pathname} = this.props.location;

        return (
            <MainWrapper location={this.props.location}>
                <div className="container" style={{height: (UserUtils.isLayoutRoutes(pathname) || pathname === '/') ? 'auto' : '100%'}}>
                    <div className="inner-container">
                        {this.renderApplication()}
                    </div>
                </div>
            </MainWrapper>
        );
    }
}

export default withRouter(App);