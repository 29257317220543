import React, { Component } from 'react';
import './LoadingIndicator.css';

class LoadingIndicator extends Component {
    constructor(props) {
        super(props);
        if (props.whiteStyle === undefined) {
            this.state = {
                style: "spinner"
            }
        } else {
            this.state = {
                style: "spinner white"
            }
        }
    }

    render() {
        const {width, height, color, margin} = this.props;
        const stylesSkCube = {backgroundColor: color ? color : '#00BAE5'};
        return (
            <div className="sk-cube-grid" style={{
                width: width ? width : 26,
                height: height ? height : 26,
                margin: margin ? margin : 'auto'
            }}>
                <div className="sk-cube sk-cube1" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube2" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube3" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube4" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube5" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube6" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube7" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube8" style={stylesSkCube}></div>
                <div className="sk-cube sk-cube9" style={stylesSkCube}></div>
            </div>
        )
    }
}

export default LoadingIndicator;