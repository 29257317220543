const layoutRoutes = [
    "/",
    "/jobs",
    "/job",
    "/items" ,
    "/companies",
    "/company-settings",
    "/users",
    "/account-settings",
    "/analytical-report",
    '/add-item-to-reseller-roaster',
    '/add-item-to-reseller-settings',
    '/add-item-to-child-company-roaster',
    "/company-users",
    "/categories",
    // "/report-templates",
    "/headers",
    "/company-items",
    "/company-attributes",
    //"/company-placeholders",
    "/package",
    "/package-page",
    "/add-item-to-package",
    //'/colour-schemes',
    //'/colour-scheme-page',
    //'/add-item-to-colour-scheme',
    '/add-item-to-company',
    "/assets",
    "/vendors",
    "/attributes",
    "/reseller",
    "/reseller-settings",
    "/reseller-child-settings",
    "/settings",
    "/design-ranges",
    "/range",
]

export default layoutRoutes;
