import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';

export const editCompanyStart = () => {
    return {
        type: actionTypes.EDIT_COMPANY_START
    }
};

export const editCompanyFinished = () => {
    return {
        type: actionTypes.EDIT_COMPANY_FINISHED
    }
};

export const editCompanyFailed = () => {
    return {
        type: actionTypes.EDIT_COMPANY_FAILED
    }
};

export const editCompany = (companyId, company, callback, errorCallback) => {
    return dispatch => {
        dispatch(editCompanyStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}`, company)
            .then(response => {
                dispatch(editCompanyFinished());
                if (callback) {
                    callback(response);
                }
            })
            .catch(error => {
                const showError = error.response ? error.response.data.error : error;
                console.log(showError);
                errorCallback(showError);
                dispatch(editCompanyFailed());
            })
    }
};

export const deleteCompanyStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_START
    }
};

export const deleteCompanyFinished = () => {
    return {
        type: actionTypes.DELETE_COMPANY_FINISHED
    }
};

export const deleteCompanyFailed = () => {
    return {
        type: actionTypes.DELETE_COMPANY_FAILED
    }
};

export const deleteCompany = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCompanyStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}`)
            .then(response => {
                dispatch(deleteCompanyFinished());
                callback()
            })
            .catch(error => {
                dispatch(deleteCompanyFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const getCompaniesForResellerCreationStart = () => {
    return {
        type: actionTypes.GET_COMPANIES_FOR_RESELLER_CREATION_START
    }
};

export const getCompaniesForResellerCreationFailed = () => {
    return {
        type: actionTypes.GET_COMPANIES_FOR_RESELLER_CREATION_FAILED
    }
};

export const setCompaniesForResellerCreation = companiesResellerCreation => {
    return {
        type: actionTypes.SET_COMPANIES_FOR_RESELLER_CREATION,
        companiesResellerCreation
    }
};

export const getCompaniesForResellerCreation = (callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompaniesForResellerCreationStart());
        axios.get(`${Constants.SERVER_URL}/companies/availableForReseller`)
            .then(response => {
                dispatch(setCompaniesForResellerCreation(response.data.rows));
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getCompaniesForResellerCreationFailed())
            })
    }
};

export const getCompanyCategoriesStart = () => {
    return {
        type: actionTypes.GET_COMPANY_CATEGORIES_START
    }
};

export const setCompanyCategories = (categories) => {
    return {
        type: actionTypes.SET_COMPANY_CATEGORIES,
        categories: categories
    }
};

export const getCompanyCategoriesFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_CATEGORIES_FAILED,
    }
};

export const getCompanyCategories = (companyId, vendorId, searchWord, attributeQuery, itemsSearchWord, callback, errorCallback, limit) => {
    return dispatch => {
        dispatch(getCompanyCategoriesStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/categories/get`, attributeQuery.length > 0 ?
            {
                attrs: attributeQuery
            } : null,
            {
                params: {
                  ...(limit ? {limit} : {limit: '1000'}),
                  ...(vendorId ? {vendorId} : {}),
                  ...(searchWord ? {q: searchWord} : {}),
                  ...(itemsSearchWord ? {itemsQ: itemsSearchWord} : {}),
                }
            })
            .then(response => {
                response.data.rows.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                response.data.rows.unshift({
                    name: 'All Categories',
                    id: null
                });
                if (callback) {
                    callback(response.data)
                }
                dispatch(setCompanyCategories(response.data.rows))
            })
            .catch(error => {
                console.log(error);
                dispatch(getCompanyCategoriesFailed())
            })
    }
};

export const getSystemCategoriesStart = () => {
    return {
        type: actionTypes.GET_SYSTEM_CATEGORIES_START
    }
};

export const setSystemCategories = (categories) => {
    return {
        type: actionTypes.SET_SYSTEM_CATEGORIES,
        categories: categories
    }
};

export const getSystemCategoriesFailed = () => {
    return {
        type: actionTypes.GET_SYSTEM_CATEGORIES_FAILED
    }
};

export const getSystemCategories = (vendorId, searchWord, attributeQuery, itemsSearchWord, callback, limit) => {
    return dispatch => {
        dispatch(getSystemCategoriesStart());
        axios.post(`${Constants.SERVER_URL}/categories/system/get`, attributeQuery.length > 0 ?
            {
                attrs: attributeQuery
            } : null, {
                params: {
                  ...(limit ? {limit} : {limit: '1000'}),
                  ...(vendorId ? {vendorId} : {}),
                  ...(searchWord ? {q: searchWord} : {}),
                  ...(itemsSearchWord ? {assetsQ: itemsSearchWord} : {}),
                }
            })
            .then(response => {
                response.data.rows.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                    response.data.rows.unshift({
                        name: 'All Categories',
                        id: null
                    });
            if (callback) {
                callback(response.data)
            }
            dispatch(setSystemCategories(response.data.rows))
        }).catch( error => {
            dispatch(getSystemCategoriesFailed())
        });
    }
};

export const getVendorsStart = () => {
    return {
        type: actionTypes.GET_VENDORS_START
    }
};

export const setVendors = (vendors) => {
    return {
        type: actionTypes.SET_VENDORS,
        vendors: vendors
    }
};

export const getVendorsFailed = () => {
    return {
        type: actionTypes.GET_VENDORS_FAILED
    }
};

export const getVendors = (categoryId, searchWord, attributeQuery, itemsSearchWord, callback, limit) => {
    return dispatch => {
        dispatch(getVendorsStart())
        axios.post(`${Constants.SERVER_URL}/vendors/get`, attributeQuery.length > 0 ?
            {
                attrs: attributeQuery
            } : null, {
                params: {
                  ...(limit ? {limit} : {limit: '1000'}),
                  ...(categoryId ? {categoryId} : {}),
                  ...(searchWord ? {q: searchWord} : {}),
                  ...(itemsSearchWord ? {assetsQ: itemsSearchWord} : {}),
                }
            })
            .then(response => {
                let vendors = response.data.rows ? response.data.rows : response.data;
                vendors.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                        vendors.unshift({
                            name: 'All Vendors',
                            id: null
                        });
            dispatch(setVendors(vendors))

            if (callback) {
                callback(response.data);
            }
        })
            .catch(error => {
                console.log(error)
                dispatch(getVendorsFailed())
        });
    }
};

export const getCompanyItemsVendorsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEMS_VENDORS_START
    }
}

export const setCompanyItemsVendors = (vendors) => {
    return {
        type: actionTypes.SET_COMPANY_ITEMS_VENDORS,
        vendors: vendors
    }
}

export const getCompanyItemsVendorsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEMS_VENDORS_FAILED
    }
}

export const getCompanyItemsVendors = (companyId, categoryId, categories, attributeQuery, searchWord, itemsSearchWord, callback, limit) => {
    return dispatch => {
        dispatch(getCompanyItemsVendorsStart());
        let q = '';
        if (categoryId) {
            q = '&categoryId=' + categoryId
        }
        if (categories && categories.length > 0) {
            for (const category of categories) {
                q += '&categoryIds[]=' + category.id
            }
        }
        if (searchWord) {
            q += ('&q=' + searchWord)
        }
        if (itemsSearchWord) {
            q += ('&itemsQ=' + itemsSearchWord)
        }
        axios.post(Constants.SERVER_URL + '/companies/' + companyId + '/items/vendors/get?limit=1000' + q, attributeQuery.length > 0 ?
            {
                attrs: attributeQuery
            } : null)
            .then(response => {
                response.data.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                    response.data.unshift({
                        name: 'All Vendors',
                        id: null
                    });
                if (callback) {
                    callback(response.data)
                }
                dispatch(setCompanyItemsVendors(response.data))
            })
            .catch(error => {
                console.log(error)
                dispatch(getCompanyItemsVendorsFailed())
            })
    }
}

export const getCompaniesStart = () => {
    return {
        type: actionTypes.GET_COMPANIES_START
    }
}

export const setCompanies = (companies) => {
    return {
        type: actionTypes.SET_COMPANIES,
        companies: companies
    }
}

export const setFilteredCompanies = (filteredCompanies) => {
    return {
        type: actionTypes.SET_COMPANIES,
        filteredCompanies: filteredCompanies
    }
}

export const getCompaniesFailed = () => {
    return {
        type: actionTypes.GET_COMPANIES_FAILED
    }
}

export const getCompanies = (page, size, q, transform, callback, sort) => {
    return dispatch => {
        dispatch(getCompaniesStart())
        let sortItems = ''

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }

        axios.get(`${Constants.SERVER_URL}/companies${sortItems}`, {
            params: {
              ...({limit: size}),
              ...({offset: (page -1) * size}),
              ...(q ? {q} : {}),
            }
        })
        .then(response => {
            if (transform) {
                let activated = response.data.rows.filter(company => company.isActivated === true);
                let unActive = response.data.rows.filter(company => company.isActivated !== true);
                dispatch(setFilteredCompanies({
                    activated: activated,
                    unActivated: unActive,
                }))
            }else {
                dispatch(setCompanies(response.data.rows))
            }
            callback(response)
        })
        .catch(error => {
            console.log(error)
            dispatch(getCompaniesFailed())
        })
    }

}

export const getCompanyStart = () => {
    return {
        type: actionTypes.GET_COMPANY_START
    }
}

export const setCompany = (company) => {
    return {
        type: actionTypes.SET_COMPANY,
        company: company
    }
}

export const getCompanyFailed = () => {
    return {
        type: actionTypes.GET_COMPANIES_FAILED
    }
}

export const getCompany = (companyId, callback) => {
    return dispatch => {
        dispatch(getCompanyStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}`)
        .then(response => {
            callback(response)
            dispatch(setCompany(response.data))
        })
        .catch(error => {
            console.log(error)
            dispatch(getCompanyFailed())
        });
    }

}

export const removeCompanyStart = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_START
    }
}

export const removeCompanyEnd = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_END
    }
}

export const removeCompanyFailed = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_FAILED
    }
}

export const removeCompany = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeCompanyStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}`)
        .then(response => {
            callback(response)
            dispatch(removeCompanyEnd())
        })
        .catch(error => {
            errorCallback(error);
            dispatch(removeCompanyFailed())
        });
    }

}

export const createCompanyStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_START
    }
}

export const createCompanyEnd = () => {
    return {
        type: actionTypes.CREATE_COMPANY_END
    }
}

export const createCompanyFailed = () => {
    return {
        type: actionTypes.CREATE_COMPANY_FAILED
    }
}

export const createCompany = (company, callback, errorCallback) => {
    return dispatch => {
        dispatch(createCompanyStart())
        axios.post(`${Constants.SERVER_URL}/companies`, company)
        .then(response => {
            callback(response)
            dispatch(createCompanyEnd())
        })
        .catch(error => {
            errorCallback(error.response.data.error)
            dispatch(createCompanyFailed())
        });
    }
}

export const getResellerChildCompanyItemsWithStart = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANY_ITEM_WITH_START
    }
}

export const getResellerChildCompanyItemsWithEnd = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANY_ITEM_WITH_END
    }
}

export const getResellerChildCompanyItemsWithFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANY_ITEM_WITH_FAILED
    }
}

export const getResellerChildCompanyItemsWith = (page, size, searchWord, filter, vendor, category, companyId, childCompanyId, callback) => {
    return dispatch => {
        dispatch(getResellerChildCompanyItemsWithStart())
        axios.get(`${Constants.SERVER_URL}/resellers/${companyId}/companies/${childCompanyId}/items`, {
            params: {
                ...(
                    searchWord !== ''
                    ? filter === 'Name'
                    : {q: searchWord}
                    ? filter === 'Category'
                    : {category: searchWord}
                    ? filter === 'SKU'
                    : {sku: searchWord}
                    ),
                ...({limit: size}),
                ...({offset: page*size}),
                ...(vendor !== '' ? {vendorId: vendor}: {}),
                ...(category !== '' ? {categoryId: category}: {}),
            }
        })
        .then(response => {
            callback(response)
            dispatch(getResellerChildCompanyItemsWithEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(getResellerChildCompanyItemsWithFailed())
        });
    }
}

export const getCompanyItemByIdStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEM_BY_ID_START
    }
}

export const getCompanyItemByIdEnd = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEM_BY_ID_END
    }
}

export const getCompanyItemByIdFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEM_BY_ID_FAILED
    }
}

export const getCompanyItemById = (companyId, itemId, callback) => {
    return dispatch => {
        dispatch(getCompanyItemByIdStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/items/${itemId}`)
        .then(response => {
            callback(response)
            dispatch(getCompanyItemByIdEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(getCompanyItemByIdFailed())
        });
    }

}

export const addCompanyItemStart = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEM_START
    }
}

export const addCompanyItemEnd = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEM_END
    }
}

export const addCompanyItemFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEM_FAILED
    }
}

export const addCompanyItem = (material, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyItemStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/items/`, material)
        .then(response => {
            callback(response)
            dispatch(addCompanyItemEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(addCompanyItemFailed())
            errorCallback(error)
        });
    }

}

export const addCompanyItemsToCompanyJobStart = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEMS_TO_COMPANY_JOB_START
    }
}

export const addCompanyItemsToCompanyJobEnd = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEMS_TO_COMPANY_JOB_END
    }
}

export const addCompanyItemsToCompanyJobFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEMS_TO_COMPANY_JOB_FAILED
    }
}

export const addCompanyItemsToCompanyJob = (companyId, jobId, companyItemId, selectionTypes, customAttributeValues, callback, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyItemsToCompanyJobStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/items/${companyItemId}`, {
            selectionTypes: selectionTypes,
            customAttributesValues: customAttributeValues
        })
        .then(response => {
            callback(response)
            dispatch(addCompanyItemsToCompanyJobEnd())
        })
        .catch(error => {
            dispatch(addCompanyItemsToCompanyJobFailed())
            errorCallback(error)
        });
    }
}

export const editCompanyItemsInCompanyJob = (companyId, jobId, companyItemId, selectionTypes, customAttributeValues, callback, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyItemsToCompanyJobStart())
        axios.put(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/items/${companyItemId}`, {
            selectionTypes: selectionTypes,
            customAttributesValues: customAttributeValues
        })
            .then(response => {
                callback(response)
                dispatch(addCompanyItemsToCompanyJobEnd())
            })
            .catch(error => {
                dispatch(addCompanyItemsToCompanyJobFailed())
                errorCallback(error.response.data.error)
            });
    }
}

export const removeCompanyItemFromCompanyJobStart = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_START
    }
}

export const removeCompanyItemFromCompanyJobEnd = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_END
    }
}

export const removeCompanyItemFromCompanyJobFailed = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_FAILED
    }
}

export const removeCompanyItemFromCompanyJob = (companyId, jobId, companyItemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeCompanyItemFromCompanyJobStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/items/${companyItemId}`)
        .then(response => {
            callback(response)
            dispatch(removeCompanyItemFromCompanyJobEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(removeCompanyItemFromCompanyJobFailed())
        });

    }
}

export const updateCompanyItemStart = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_ITEM_START
    }
}

export const updateCompanyItemEnd = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_ITEM_END
    }
}

export const updateCompanyItemFailed = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_ITEM_FAILED
    }
}

export const updateCompanyItem = (material, companyId, companyItemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(updateCompanyItemStart())
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/items/${companyItemId}`, material)
        .then(response => {
            callback(response)
            dispatch(updateCompanyItemEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(updateCompanyItemFailed())
        });
    }
}

export const removeCompanyItemStart = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_START
    }
}

export const removeCompanyItemEnd = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_END
    }
}

export const removeCompanyItemFailed = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_FAILED
    }
}

export const removeCompanyItem = (companyId, companyItemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeCompanyItemStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/items/${companyItemId}`)
        .then(response => {
            callback(response)
            dispatch(removeCompanyItemEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(removeCompanyItemFailed())
            errorCallback(error)
        });
    }

}

export const discontinueCompanyItemStart = () => {
    return {
        type: actionTypes.DISCONTINUE_COMPANY_ITEM_START
    }
}

export const discontinueCompanyItemEnd = () => {
    return {
        type: actionTypes.DISCONTINUE_COMPANY_ITEM_END
    }
}

export const discontinueCompanyItemFailed = () => {
    return {
        type: actionTypes.DISCONTINUE_COMPANY_ITEM_FAILED
    }
}

export const discontinueCompanyItem = (companyId, companyItemId, value, callback, errorCallback, isNotification) => {
    return dispatch => {
        dispatch(discontinueCompanyItemStart())
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/items/${companyItemId}/supported`,
            {isSupported: value, isNotification}
            )
            .then(response => {
                callback(response)
                dispatch(discontinueCompanyItemEnd())
            })
            .catch(error => {
                console.log(error)
                dispatch(discontinueCompanyItemFailed())
                errorCallback(error.response.data.error)
            });
    }

}

export const uploadCompanyItemImageStart = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_ITEM_IMAGE_START
    }
}

export const uploadCompanyItemImageEnd = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_ITEM_IMAGE_END
    }
}

export const uploadCompanyItemImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_ITEM_IMAGE_FAILED
    }
}

export const uploadCompanyItemImage = (companyId, companyItemId, data, callback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch(uploadCompanyItemImageStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/items/${companyItemId}/image`, formData, config)
        .then(response => {
            callback(response)
            dispatch(uploadCompanyItemImageEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(uploadCompanyItemImageFailed())
        });
    }

}

export const createCompanyJobStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_JOB_START
    }
}

export const createCompanyJobEnd = () => {
    return {
        type: actionTypes.CREATE_COMPANY_JOB_END
    }
}

export const createCompanyJobFailed = () => {
    return {
        type: actionTypes.CREATE_COMPANY_JOB_FAILED
    }
}

export const createCompanyJob = (companyId, job, callback, errorCallback) => {
    return dispatch => {
        dispatch(createCompanyJobStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs`, job)
        .then(response => {
            callback(response)
            dispatch(createCompanyJobEnd())
        })
        .catch(error => {
            const errorData = error.response ? error.response.data.error : error;
            console.error(errorData)
            dispatch(createCompanyJobFailed())
            if(errorCallback) {
                errorCallback(errorData)
            }
        });

    }
}

export const createCompanyCategoryStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_CATEGORY_START
    }
}

export const createCompanyCategoryEnd = () => {
    return {
        type: actionTypes.CREATE_COMPANY_CATEGORY_END
    }
}

export const createCompanyCategoryFailed = () => {
    return {
        type: actionTypes.CREATE_COMPANY_CATEGORY_FAILED
    }
}

export const createCompanyCategory = (companyId, category, callback, errorCallback) => {
    return dispatch => {
        dispatch(createCompanyCategoryStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/categories`, category)
        .then(response => {
            callback(response)
            dispatch(createCompanyCategoryEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(createCompanyCategoryFailed())
            errorCallback(error)
        });
    }

}

export const editCompanyCategoryStart = () => {
    return {
        type: actionTypes.EDIT_COMPANY_CATEGORY_START
    }
}

export const editCompanyCategoryEnd = () => {
    return {
        type: actionTypes.EDIT_COMPANY_CATEGORY_END
    }
}

export const editCompanyCategoryFailed = () => {
    return {
        type: actionTypes.EDIT_COMPANY_CATEGORY_FAILED
    }
}

export const editCompanyCategory = (companyId, categoryId, category, callback, errorCallback) => {
    return dispatch => {
        dispatch(editCompanyCategoryStart())
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/categories/${categoryId}`, category)
        .then(response => {
            callback(response)
            dispatch(editCompanyCategoryEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(editCompanyCategoryFailed())
            errorCallback(error)
        });
    }

}

export const uploadCompanyImageStart = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_IMAGE_START
    }
}

export const uploadCompanyImageEnd = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_IMAGE_END
    }
}

export const uploadCompanyImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_IMAGE_FAILED
    }
}

export const uploadCompanyImage = (companyId, data, callback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch(uploadCompanyImageStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/image`, formData, config)
        .then(response => {
            if (callback) {
                callback(response)
            }
            dispatch(uploadCompanyImageEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(uploadCompanyImageFailed())
        });
    }
 }

export const removeCompanyCategoryStart = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_CATEGORY_START
    }
}

export const removeCompanyCategoryEnd = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_CATEGORY_END
    }
}

export const removeCompanyCategoryFailed = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_CATEGORY_FAILED
    }
}

export const removeCompanyCategory = (companyId, categoryId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeCompanyCategoryStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/categories/${categoryId}`)
        .then(response => {
            callback(response)
            dispatch(removeCompanyCategoryEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(removeCompanyCategoryFailed())
            errorCallback(error)
        });
    }
}

export const createNewCompanyStart = () => {
    return {
        type: actionTypes.CREATE_NEW_COMPANY_START
    }
}

export const createNewCompanyEnd = () => {
    return {
        type: actionTypes.CREATE_NEW_COMPANY_END
    }
}

export const createNewCompanyFailed = () => {
    return {
        type: actionTypes.CREATE_NEW_COMPANY_FAILED
    }
}

export const createNewCompany = (name, address, domain, position, comment, callback, errorCallback) => {
    return dispatch => {
        dispatch(createNewCompanyStart())
        axios.post(`${Constants.SERVER_URL}/companiesCreateRequests`, {
            name,
            address,
            domain,
            position,
            comment
        })
        .then(response => {
            callback(response)
            dispatch(createNewCompanyEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(createNewCompanyFailed())
            errorCallback(error)
        });
    }
}

export const activateCompanyStart = () => {
    return {
        type: actionTypes.ACTIVATE_COMPANY_START
    }
}

export const activateCompanyFinished = () => {
    return {
        type: actionTypes.ACTIVATE_COMPANY_FINISHED
    }
}

export const activateCompanyFailed = () => {
    return {
        type: actionTypes.ACTIVATE_COMPANY_FAILED
    }
}

export const activateCompany = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(activateCompanyStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/activate`)
            .then(response => {
                dispatch(activateCompanyFinished())
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(activateCompanyFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const deactivateCompanyStart = () => {
    return {
        type: actionTypes.DEACTIVATE_COMPANY_START
    }
}

export const deactivateCompanyFinished = () => {
    return {
        type: actionTypes.DEACTIVATE_COMPANY_FINISHED
    }
}

export const deactivateCompanyFailed = () => {
    return {
        type: actionTypes.DEACTIVATE_COMPANY_FAILED
    }
}

export const deactivateCompany = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deactivateCompanyStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/deactivate`)
            .then(response => {
                dispatch(deactivateCompanyFinished())
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(deactivateCompanyFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const requestCreateNewCompanyStart = () => {
    return {
        type: actionTypes.REQUEST_CREATE_NEW_COMPANY_START
    }
}

export const requestCreateNewCompanyEnd = () => {
    return {
        type: actionTypes.REQUEST_CREATE_NEW_COMPANY_END
    }
}

export const requestCreateNewCompanyFailed = () => {
    return {
        type: actionTypes.REQUEST_CREATE_NEW_COMPANY_FAILED
    }
}

export const requestCreateNewCompany = (id, name, address, domain, position, comment, callback, errorCallback) => {
    return dispatch => {
        dispatch(requestCreateNewCompanyStart())
        axios.post(`${Constants.SERVER_URL}/companiesCreateRequests/${id}/resubmit`, {
            name,
            address,
            domain,
            position,
            comment
        })
        .then(response => {
            callback(response)
            dispatch(requestCreateNewCompanyEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(requestCreateNewCompanyFailed())
            errorCallback(error)
        });
    }
}

export const getCompanyJoinRequestsStart = () => {
    return {
        type: actionTypes.GET_USERS_JOIN_REQUESTS_START
    }
}

export const setCompanyJoinRequests = (response) => {
    return {
        type: actionTypes.SET_USERS_JOIN_REQUESTS,
        companyJoinRequests: response.rows,
        companyJoinRequestsTotal: response.count
    }
}

export const getCompanyJoinRequestsFailed = () => {
    return {
        type: actionTypes.GET_USERS_JOIN_REQUESTS_FAILED
    }
}

export const getCompanyJoinRequests = (page, size, q, status, companyId, callback, errorCallback, sort) => {
    return dispatch => {
        dispatch(getCompanyJoinRequestsStart());
        let sortItems = ''

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/joinRequests${sortItems}`, {
            params: {
                ...({limit: size}),
                ...({offset: (page -1) * size}),
                ...(q ? {q} : {}),
                ...(status ? {status: status} : {}),
            }
        })
            .then(response => {
                dispatch(setCompanyJoinRequests(response.data))
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getCompanyJoinRequestsFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const approveCompanyJoinRequestsStart = () => {
    return {
        type: actionTypes.APPROVE_USER_JOIN_REQUEST_START
    }
}

export const approveCompanyJoinRequestsFinished = () => {
    return {
        type: actionTypes.APPROVE_USER_JOIN_REQUEST_FINISHED
    }
}

export const approveCompanyJoinRequestsFailed = () => {
    return {
        type: actionTypes.APPROVE_USER_JOIN_REQUEST_FAILED
    }
}

export const approveCompanyJoinRequests = (companyId, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(approveCompanyJoinRequestsStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/joinRequests/${requestId}/approve`)
            .then(response => {
                dispatch(approveCompanyJoinRequestsFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(approveCompanyJoinRequestsFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const rejectCompanyJoinRequestsStart = () => {
    return {
        type: actionTypes.REJECT_USER_JOIN_REQUEST_START
    }
}

export const rejectCompanyJoinRequestsFinished = () => {
    return {
        type: actionTypes.REJECT_USER_JOIN_REQUEST_FINISHED
    }
}

export const rejectCompanyJoinRequestsFailed = () => {
    return {
        type: actionTypes.REJECT_USER_JOIN_REQUEST_FAILED
    }
}

export const rejectCompanyJoinRequests = (companyId, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(rejectCompanyJoinRequestsStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/joinRequests/${requestId}/reject`)
            .then(response => {
                dispatch(rejectCompanyJoinRequestsFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(rejectCompanyJoinRequestsFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const banCompanyJoinRequestsStart = () => {
    return {
        type: actionTypes.BAN_USER_JOIN_REQUEST_FAILED_START
    }
}

export const banCompanyJoinRequestsFinished = () => {
    return {
        type: actionTypes.BAN_USER_JOIN_REQUEST_FAILED_END
    }
}

export const banCompanyJoinRequestsFailed = () => {
    return {
        type: actionTypes.BAN_USER_JOIN_REQUEST_FAILED_FAILED
    }
}

export const banCompanyJoinRequests = (companyId, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(banCompanyJoinRequestsStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/joinRequests/${requestId}/ban`)
            .then(response => {
                dispatch(banCompanyJoinRequestsFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(banCompanyJoinRequestsFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const unbanCompanyJoinRequestsStart = () => {
    return {
        type: actionTypes.UNBAN_COMPANY_JOIN_REQUEST_START
    }
}

export const unbanCompanyJoinRequestsFinished = () => {
    return {
        type: actionTypes.UNBAN_COMPANY_JOIN_REQUEST_FINISHED
    }
}

export const unbanCompanyJoinRequestsFailed = () => {
    return {
        type: actionTypes.UNBAN_COMPANY_JOIN_REQUEST_FAILED
    }
}

export const unbanCompanyJoinRequests = (companyId, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(unbanCompanyJoinRequestsStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/joinRequests/${requestId}/unban`)
            .then(response => {
                dispatch(unbanCompanyJoinRequestsFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(unbanCompanyJoinRequestsFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const unbanCompanyCreateRequestsStart = () => {
    return {
        type: actionTypes.UNBAN_COMPANY_CREATE_REQUEST_START
    }
}

export const unbanCompanyCreateRequestsFinished = () => {
    return {
        type: actionTypes.UNBAN_COMPANY_CREATE_REQUEST_FINISHED
    }
}

export const unbanCompanyCreateRequestsFailed = () => {
    return {
        type: actionTypes.UNBAN_COMPANY_CREATE_REQUEST_FAILED
    }
}

export const unbanCompanyCreateRequests = (requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(unbanCompanyCreateRequestsStart());
        axios.post(`${Constants.SERVER_URL}/companiesCreateRequests/${requestId}/unban`)
            .then(response => {
                dispatch(unbanCompanyCreateRequestsFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(unbanCompanyCreateRequestsFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const getCompanyCreateRequestsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_CREATE_REQUESTS_START
    }
}

export const setCompanyCreateRequests = (response) => {
    return {
        type: actionTypes.SET_COMPANY_CREATE_REQUESTS,
        companyCreateRequests: response.rows,
        total: response.count
    }
}

export const getCompanyCreateRequestsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_CREATE_REQUESTS_FAILED
    }
}

export const getCompanyCreateRequests = (queryParams, callback, errorCallback, sort) => {
    return dispatch => {
        dispatch(getCompanyCreateRequestsStart())
        let sortItems = ''

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/companiesCreateRequests${sortItems}`, {
            params: {
                ...(queryParams.limit ? {limit: queryParams.limit} : {}),
                ...(queryParams.offset ? {offset: queryParams.offset} : {}),
                ...(queryParams.searchWord ? {q: queryParams.searchWord} : {}),
                ...(queryParams.status ? {status: queryParams.status} : {}),
            }
        })
            .then(response => {
                dispatch(setCompanyCreateRequests(response.data))
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(getCompanyCreateRequestsFailed());
                errorCallback(error.response.data.error);
            })
    }
}

export const approveCompanyCreateRequestsStart = () => {
    return {
        type: actionTypes.APPROVE_CREATE_COMPANY_REQUEST_START
    }
}

export const approveCompanyCreateRequestsFinished = () => {
    return {
        type: actionTypes.APPROVE_CREATE_COMPANY_REQUEST_FINISHED,
    }
}

export const approveCompanyCreateRequestsFailed = () => {
    return {
        type: actionTypes.APPROVE_CREATE_COMPANY_REQUEST_FAILED
    }
}

export const approveCompanyCreateRequests = (requestId, licenseIdentifier, callback, errorCallback) => {
    return dispatch => {
        dispatch(approveCompanyCreateRequestsStart())
        axios.post(`${Constants.SERVER_URL}/companiesCreateRequests/${requestId}/approve`, licenseIdentifier)
            .then(response => {
                dispatch(approveCompanyCreateRequestsFinished())
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(approveCompanyCreateRequestsFailed())
                const errorData = error.response ? error.response.data.error : error
                console.log(errorData)
                errorCallback(errorData)
            })
    }
}

export const rejectCompanyCreateRequestsStart = () => {
    return {
        type: actionTypes.REJECT_CREATE_COMPANY_REQUEST_START
    }
}

export const rejectCompanyCreateRequestsFinished = () => {
    return {
        type: actionTypes.REJECT_CREATE_COMPANY_REQUEST_FINISHED,
    }
}

export const rejectCompanyCreateRequestsFailed = () => {
    return {
        type: actionTypes.REJECT_CREATE_COMPANY_REQUEST_FAILED
    }
}

export const rejectCompanyCreateRequests = (requestId, rejectReason, callback, errorCallback) => {
    return dispatch => {
        dispatch(rejectCompanyCreateRequestsStart());
        axios.post(`${Constants.SERVER_URL}/companiesCreateRequests/${requestId}/reject`, rejectReason)
            .then(response => {
                dispatch(rejectCompanyCreateRequestsFinished())
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error)
                dispatch(rejectCompanyCreateRequestsFailed())
            })
    }
}

export const activateCompanyJoinRequestStart = () => {
    return  {
       type: actionTypes.ACTIVATE_COMPANY_JOIN_REQUEST_START
    }
}

export const activateCompanyJoinRequestEnd = () => {
    return  {
        type: actionTypes.ACTIVATE_COMPANY_JOIN_REQUEST_END
     }
}

export const activateCompanyJoinRequestFailed = () => {
    return  {
        type: actionTypes.ACTIVATE_COMPANY_JOIN_REQUEST_FALSE
     }
}

export const activateCompanyJoinRequest = (companyId, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(activateCompanyJoinRequestStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/joinRequests/${requestId}/activate`)
            .then(response => {
                dispatch(activateCompanyJoinRequestEnd())
                if (callback) {
                    callback()
                }
            })
                .catch(error => {
                    dispatch(activateCompanyJoinRequestFailed())
                    console.log(error)
                    errorCallback(error)
                })
        }
}

export const editCompanyCreateRequestsStart = () => {
    return {
        type: actionTypes.EDIT_COMPANY_REQUEST_START
    }
}

export const editCompanyCreateRequestsFinished = () => {
    return {
        type: actionTypes.EDIT_COMPANY_REQUEST_FINISHED,
    }
}

export const editCompanyCreateRequestsFailed = () => {
    return {
        type: actionTypes.EDIT_COMPANY_REQUEST_FAILED
    }
}

export const editCompanyCreateRequests = (requestId, request, callback, errorCallback) => {
    return dispatch => {
        dispatch(editCompanyCreateRequestsStart());
        axios.patch(`${Constants.SERVER_URL}/companiesCreateRequests/${requestId}`, request)
            .then(response => {
                dispatch(editCompanyCreateRequestsFinished())
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(editCompanyCreateRequestsFailed());
                if (errorCallback) {
                    errorCallback(error.response.data.error)
                }
            })
    }
}

export const setCompanyRequestForEdit = (request) => {
    return {
        type: actionTypes.SET_COMPANY_REQUEST_FOR_EDIT,
        requestForEdit: request
    }
}

export const banCompanyRequestStart = () => {
    return {
        type: actionTypes.BAN_COMPANY_CREATE_REQUEST_START
    }
}

export const banCompanyRequestFinished = () => {
    return {
        type: actionTypes.BAN_COMPANY_CREATE_REQUEST_FINISHED
    }
}

export const banCompanyRequestFailed = () => {
    return {
        type: actionTypes.BAN_COMPANY_CREATE_REQUEST_FAILED
    }
}

export const banCompanyRequest = (requestId, banReason, callback, errorCallback) => {
    return dispatch => {
        dispatch(banCompanyRequestStart());
        axios.post(`${Constants.SERVER_URL}/companiesCreateRequests/${requestId}/ban`, banReason)
            .then(response => {
                dispatch(banCompanyRequestFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(banCompanyRequestFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const uploadCompanyRequestImageStart = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_REQUEST_IMAGE_START
    }
}

export const uploadCompanyRequestImageEnd = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_REQUEST_IMAGE_END
    }
}

export const uploadCompanyRequestImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_COMPANY_REQUEST_IMAGE_FAILED
    }
}

export const uploadCompanyRequestImage = (requestId, data, callback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch(uploadCompanyRequestImageStart())
        axios.post(`${Constants.SERVER_URL}/companiesCreateRequests/${requestId}/image`, formData, config)
        .then(response => {
            callback(response)
            dispatch(uploadCompanyRequestImageEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(uploadCompanyRequestImageFailed())
        });
    }
}

export const setRequestCreateCompanyName = (createRequestCompanyName) => {
    return {
        type: actionTypes.SET_COMPANY_CREATE_REQUEST_NAME,
        createRequestCompanyName
    }
}

export const deleteCompanyImageStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_IMAGE_START
    }
}

export const deleteCompanyImageEnd = () => {
    return {
        type: actionTypes.DELETE_COMPANY_IMAGE_END
    }
}

export const deleteCompanyImageFailed = () => {
    return {
        type: actionTypes.DELETE_COMPANY_IMAGE_FAILED
    }
}

export const deleteCompanyImage = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCompanyImageStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/image`)
        .then(response => {
            if (callback) {
                callback(response)
            }
            dispatch(deleteCompanyImageEnd())
        })
        .catch(error => {
            console.log(error)
            errorCallback(error.response ? error.response.data.error : error);
            dispatch(deleteCompanyImageFailed())
        });
    }
}

export const getCompanyStatusStart = () => {
    return {
        type: actionTypes.GET_COMPANY_STATUS_START
    }
}

export const getCompanyStatusEnd = () => {
    return {
        type: actionTypes.GET_COMPANY_STATUS_END
    }
}

export const getCompanyStatusFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_STATUS_FAILED
    }
}

export const getCompanyStatus = (name, callback) => {
    return dispatch => {
        dispatch(getCompanyStatusStart())
        axios.get(`${Constants.SERVER_URL}/companies/status/name`, {
            params: {
                ...{name}
            }
        })
            .then(response => {
                callback(response)
                dispatch(getCompanyStatusEnd())
            })
            .catch(error => {
                console.log(error)
                dispatch(getCompanyStatusFailed())
            });
    }
}

export const createCompanyJoinRequestStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_JOIN_REQUEST_START
    }
}

export const createCompanyJoinRequestEnd = () => {
    return {
        type: actionTypes.CREATE_COMPANY_JOIN_REQUEST_END
    }
}

export const createCompanyJoinRequestFailed = () => {
    return {
        type: actionTypes.CREATE_COMPANY_JOIN_REQUEST_FAILED
    }
}


export const createCompanyJoinRequest = (id, callback, errorCallback) => {
    return dispatch => {
        dispatch(createCompanyJoinRequestStart())
        axios.post(`${Constants.SERVER_URL}/companies/${id}/joinRequests`)
            .then(response => {
                callback(response)
                dispatch(createCompanyJoinRequestEnd())
            })
            .catch(error => {
                console.log(error)
                if(errorCallback) {
                    errorCallback(error)
                }
                dispatch(createCompanyJoinRequestFailed())
            });
    }
}

export const deleteCompaniesCreateRequestsImageStart = () => {
  return {
      type: actionTypes.DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_START
  }
}

export const deleteCompaniesCreateRequestsImageEnd = () => {
  return {
      type: actionTypes.DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_END
  }
}

export const deleteCompaniesCreateRequestsImageFailed = () => {
  return {
      type: actionTypes.DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_FAILED
  }
}

export const deleteCompaniesCreateRequestsImage = (requestId, callback) => {
    return dispatch => {
        dispatch(deleteCompaniesCreateRequestsImageStart())
        axios.delete(`${Constants.SERVER_URL}/companiesCreateRequests/${requestId}/image`)
            .then(response => {
                if(callback) {
                    callback(response)
                }
                dispatch(deleteCompaniesCreateRequestsImageEnd())
            })
            .catch(error => {
                console.log(error)
                dispatch(deleteCompaniesCreateRequestsImageFailed())
            });
    }
}

export const setCompanyForEdit = (company) => {
    return {
        type: actionTypes.ON_SET_COMPANY_FOR_EDIT,
        company
    }
}

export const setCreateEditItem = createEditTitle => {
    return {
        type: actionTypes.SET_CREATE_EDIT_ITEM,
        createEditTitle
    }
}

export const setLicenseToCompanyStart = () => {
    return {
        type: actionTypes.SET_LICENSE_TO_COMPANY_START,
    }
}

export const setLicenseToCompanyEnd = () => {
    return {
        type: actionTypes.SET_LICENSE_TO_COMPANY_END,
    }
}

export const setLicenseToCompanyFailed = () => {
    return {
        type: actionTypes.SET_LICENSE_TO_COMPANY_FAILED,
    }
}

export const setLicenseToCompany = (companyId, licenseIdentifier, callback) => {
    return dispatch => {
        dispatch(setLicenseToCompanyStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/licenses`, licenseIdentifier)
            .then(response => {
                if(callback) {
                    callback(response)
                }
                dispatch(setLicenseToCompanyEnd())
            })
            .catch(error => {
                console.log(error)
                dispatch(setLicenseToCompanyFailed())
            });
    }
}

export const getGeneralAnalyticsCompanyStart = () => {
    return {
        type: actionTypes.GET_GENERAL_ANALYTICS_COMPANY_START,
    }
}

export const setGeneralAnalyticsCompany = generalAnalyticsCompany => {
    return {
        type: actionTypes.SET_GENERAL_ANALYTICS_COMPANY,
        generalAnalyticsCompany
    }
}

export const getGeneralAnalyticsCompanyFailed = () => {
    return {
        type: actionTypes.GET_GENERAL_ANALYTICS_COMPANY_FAILED,
    }
}

export const getGeneralAnalyticsCompany = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getGeneralAnalyticsCompanyStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/analytics/general`)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setGeneralAnalyticsCompany(response.data))
            })
            .catch(error => {
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData);
                }
                console.log(errorData)
                dispatch(getGeneralAnalyticsCompanyFailed())
            });
    }
}

export const uploadCompanyReportImage = (companyId, data, callback, errorCallback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch(uploadCompanyRequestImageStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/report/image`, formData, config)
            .then(response => {
                callback(response)
                dispatch(uploadCompanyRequestImageEnd())
            })
            .catch(error => {
                console.log(error)
                errorCallback(error.response.data.error)
                dispatch(uploadCompanyRequestImageFailed())
            });
    }
}

export const deleteCompaniesReportImage = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCompaniesCreateRequestsImageStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/report/image`)
            .then(response => {
                if(callback) callback(response)
                dispatch(deleteCompaniesCreateRequestsImageEnd())
            })
            .catch(error => {
                errorCallback(error.response.data.error)
                dispatch(deleteCompaniesCreateRequestsImageFailed())
            });
    }
}

export const getCompanyReportImage = (companyId, data, callback, errorCallback) => {
    return dispatch => {
        dispatch(uploadCompanyRequestImageStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/report/image/url`, data)
            .then(response => {
                callback(response)
                dispatch(uploadCompanyRequestImageEnd())
            })
            .catch(error => {
                console.log(error)
                dispatch(uploadCompanyRequestImageFailed())
            });
    }
}

export const getWhereUsedDesignRange = (companyId, designRangeId, callback, errorCallback) => {
    return dispatch => {
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/status/jobs`)
          .then(response => {
              callback(response)
          })
          .catch(error => {
              errorCallback(error)
          })
    }
}
