import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    error: false,
    loading: false,
    companyPackages: [],
    companyPackage: null,
    packageItems: [],
    packageFilters: [],
    packageVersions: [],
    packageItemsStatusByFilter: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_COMPANY_PACKAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_COMPANY_PACKAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_COMPANY_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_COMPANY_PACKAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_COMPANY_PACKAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_COMPANY_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_COMPANY_PACKAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_COMPANY_PACKAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_COMPANY_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_PACKAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_COMPANY_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_COMPANY_PACKAGE:
            return {
                ...state,
                loading: false,
                error: false,
                companyPackage: action.companyPackage
            };
        case actionTypes.GET_COMPANY_PACKAGES_OF_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_COMPANY_PACKAGES_OF_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_COMPANY_PACKAGES_OF_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                companyPackages: action.companyPackages
            };
        case actionTypes.EDIT_JOB_PACKAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_JOB_PACKAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_JOB_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_COMPANY_ITEM_TO_PACKAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_COMPANY_ITEM_TO_PACKAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_COMPANY_ITEM_TO_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_COMPANY_ITEM_FROM_PACKAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_COMPANY_ITEM_FROM_PACKAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_COMPANY_ITEM_FROM_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_ITEMS_OF_THE_PACKAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_ITEMS_OF_THE_PACKAGE:
            return {
                ...state,
                loading: false,
                error: false,
                packageItems: action.packageItems
            };
        case actionTypes.GET_ITEMS_OF_THE_PACKAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_FILTER_FOR_ADD_COMPANY_ITEM_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_FILTER_FOR_ADD_COMPANY_ITEM:
            return {
                ...state,
                loading: false,
                error: false,
                packageFilters: action.packageFilters
            };
        case actionTypes.GET_FILTER_FOR_ADD_COMPANY_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_COMPANY_PACKAGE_VERSION_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_COMPANY_PACKAGE_VERSION_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_COMPANY_PACKAGE_VERSION_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_PACKAGE_VERSIONS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_PACKAGE_VERSIONS:
            return {
                ...state,
                loading: false,
                error: false,
                packageVersions: action.packageVersions
            };
        case actionTypes.GET_PACKAGE_VERSIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_ITEMS_TO_PACKAGE_ALL_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_ITEMS_TO_PACKAGE_ALL_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_ITEMS_TO_PACKAGE_ALL_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_PACKAGE_FROM_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_PACKAGE_FROM_JOB_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_PACKAGE_FROM_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_PACKAGE_ITEMS_STATUS_BY_FILTER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_PACKAGE_ITEMS_STATUS_BY_FILTER:
            return {
                ...state,
                loading: false,
                packageItemsStatusByFilter: action.packageItemsStatus,
                error: false,
            };
        case actionTypes.GET_PACKAGE_ITEMS_STATUS_BY_FILTER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default reducer;
