import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';

export const getResellersStart = () => {
    return {
        type: actionTypes.GET_RESELLERS_START
    }
};

export const getResellersFailed = () => {
    return {
        type: actionTypes.GET_RESELLERS_FAILED
    }
};

export const setResellers = (resellers, total) => {
    return {
        type: actionTypes.SET_RESELLERS,
        resellers: resellers,
        total: total
    }
};

export const getResellers = (page, size, searchRequest, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellersStart());
        axios.get(`${Constants.SERVER_URL}/resellers`, {
            params: {
              ...({limit: size}),
              ...({offset: page*size}),
              ...(searchRequest ? {q: searchRequest} : {})
            }
        })
            .then(response => {
                dispatch(setResellers(response.data.rows, response.data.count));
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getResellersFailed())
            })
    }
};

export const addResellerStart = () => {
    return {
        type: actionTypes.ADD_RESELLER_START
    }
};

export const addResellerFinished = () => {
    return {
        type: actionTypes.ADD_RESELLER_FINISHED
    }
};

export const addResellerFailed = () => {
    return {
        type: actionTypes.ADD_RESELLER_FAILED,
    }
};

export const addReseller = (reseller, callback, errorCallback) => {
    return dispatch => {
        dispatch(addResellerStart());
        axios.post(`${Constants.SERVER_URL}/resellers`, reseller)
            .then(response => {
                dispatch(addResellerFinished());
                callback(response.data)
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addResellerFailed());
            })
    }
};

export const getResellerByIdStart = () => {
    return {
        type: actionTypes.GET_RESELLER_BY_ID_START
    }
};


export const getResellerByIdFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_BY_ID_FAILED,
    }
};

export const setReseller = (reseller) => {
    return{
        type: actionTypes.SET_RESELLER,
        reseller: reseller
    }
};

export const getResellerById = (resellerId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerByIdStart());
        axios.get(`${Constants.SERVER_URL}/resellers/` + resellerId)
            .then(response => {
                dispatch(setReseller(response.data));
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getResellerByIdFailed());
            })
    }
};

export const deleteResellerStart = () => {
    return {
        type: actionTypes.DELETE_RESELLER_START
    }
};

export const deleteResellerFinished = () => {
    return {
        type: actionTypes.DELETE_RESELLER_FINISHED
    }
};

export const deleteResellerFailed = () => {
    return {
        type: actionTypes.DELETE_RESELLER_FAILED,
    }
};

export const deleteReseller = (resellerId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteResellerStart());
        axios.delete(`${Constants.SERVER_URL}/resellers` + resellerId)
            .then(response => {
                dispatch(deleteResellerFinished());
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteResellerFailed());
            })
    }
};

export const uploadResellerImageStart = () => {
    return {
        type: actionTypes.UPLOAD_RESELLER_IMAGE_START
    }
};

export const uploadResellerImageFinished = () => {
    return {
        type: actionTypes.UPLOAD_RESELLER_IMAGE_FINISHED
    }
};

export const uploadResellerImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_RESELLER_IMAGE_FAILED
    }
};

export const uploadResellerImage = (resellerId, image, callback, errorCallback) => {
    return dispatch => {
        dispatch(uploadResellerImageStart());
        const formData = new FormData();
        let extension = image.name.split('.')[1];
        formData.append('image', image);
        const config = {
            headers: {
                'ss-image-extension': extension,
            }
        };
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/image`, formData, config)
            .then(response => {
                callback();
                dispatch(uploadResellerImageFinished());
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(uploadResellerImageFailed())
            })
    }
};

export const editResellerStart = () => {
    return {
        type: actionTypes.EDIT_RESELLER_START
    }
};

export const editResellerFinished = () => {
    return {
        type: actionTypes.EDIT_RESELLER_FINISHED
    }
};

export const editResellerFailed = () => {
    return {
        type: actionTypes.EDIT_RESELLER_FAILED
    }
};

export const editReseller = (resellerId, reseller, callback, errorCallback) => {
    return dispatch => {
        dispatch(editResellerStart());
        axios.patch(`${Constants.SERVER_URL}/resellers/${resellerId}`, reseller)
            .then(response => {
                dispatch(editResellerFinished());
                callback();
            })
            .catch(error => {
                errorCallback();
                dispatch(editResellerFailed())
            })
    }
};

export const getResellerUsersStart = () => {
    return {
        type: actionTypes.GET_RESELLER_USERS_START
    }
};

export const getResellerUsersFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_USERS_FAILED
    }
};

export const setResellerUsers = (users, total) => {
    return {
        type: actionTypes.SET_RESELLER_USERS,
        resellerUsers: users,
        total: total
    }
};

export const getResellerUsers = (page, size, resellerId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerUsersStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/users`, + {
            params: {
              ...({limit: size}),
              ...({offset: page*size})
            }
        })
            .then(response => {
                dispatch(setResellerUsers(response.data.rows, response.data.count));
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(getResellerUsersFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getResellerUsersResellerPortalStart = () => {
    return {
        type: actionTypes.GET_RESELLER_USERS_RESELLER_PORTAL_START
    }
};

export const getResellerUsersResellerPortalFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_USERS_RESELLER_PORTAL_FAILED
    }
};

export const setResellerUsersResellerPortal = (users, total) => {
    return {
        type: actionTypes.SET_RESELLER_USERS_RESELLER_PORTAL,
        resellerPortalResellerUsers: users,
        total: total
    }
};

export const getResellerUsersResellerPortal = (page, size, searchWord, resellerId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerUsersResellerPortalStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companiesUsers`, + {
            params: {
              ...({limit: size}),
              ...({offset: page*size}),
              ...(searchWord ? {q: searchWord} : {}),
            }
        })
            .then(response => {
                dispatch(setResellerUsersResellerPortal(response.data.rows, response.data.count));
                if (callback) {
                    callback(response.data.rows);
                }
            })
            .catch(error => {
                dispatch(getResellerUsersResellerPortalFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const addUserToResellerStart = () => {
    return {
        type: actionTypes.ADD_USER_TO_RESELLER_START
    }
};

export const addUserToResellerFinished = () => {
    return {
        type: actionTypes.ADD_USER_TO_RESELLER_FINISHED
    }
};

export const addUserToResellerFailed = () => {
    return {
        type: actionTypes.ADD_USER_TO_RESELLER_FAILED
    }
};

export const addUserToReseller = (resellerId, user, callback, errorCallback) => {
    return dispatch => {
        dispatch(addUserToResellerStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/users`, user)
            .then(response => {
                dispatch(addUserToResellerFinished());
                if (callback) {
                    callback ();
                }
            })
            .catch(error => {
                dispatch(addUserToResellerFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const deleteResellersUserStart = () => {
    return {
        type: actionTypes.DELETE_USER_FROM_RESELLER_START
    }
};

export const deleteResellersUserFinished = () => {
    return {
        type: actionTypes.DELETE_USER_FROM_RESELLER_FINISHED
    }
};

export const deleteResellersUserFailed = () => {
    return {
        type: actionTypes.DELETE_USER_FROM_RESELLER_FAILED
    }
};


export const deleteResellersUser = (resellerId, userId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteResellersUserStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${resellerId}/users/${userId}`)
            .then(response => {
                dispatch(deleteResellersUserFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(deleteResellersUserFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const createResellerChildCompanyStart = () => {
    return {
        type: actionTypes.ADD_RESELLER_CHILD_COMPANY_START
    }
};

export const createResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.ADD_RESELLER_CHILD_COMPANY_FINISHED
    }
};

export const createResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.ADD_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const createResellerChildCompany = (resellerId, company, callback, errorCallback) => {
    return dispatch => {
        dispatch(createResellerChildCompanyStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/companies`, company)
            .then(response => {
                callback(response);
                dispatch(createResellerChildCompanyFinished())
            })
            .catch(error => {
                dispatch(createResellerChildCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getResellerChildCompaniesStart = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANIES_START
    }
};

export const setResellerChildCompanies = (companies, total) => {
    return {
        type: actionTypes.SET_RESELLER_CHILD_COMPANIES,
        resellerChildCompanies: companies,
        total: total
    }
};

export const getResellerChildCompaniesFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANIES_FAILED
    }
};

export const getResellerChildCompanies = (page, size, searchRequest, resellerId, parentCompany, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerChildCompaniesStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies`, + {
            params: {
              ...({limit: size}),
              ...({offset: page*size}),
              ...(searchRequest ? {q: searchRequest} : {}),
            }
        })
            .then(response => {
                response.data.rows.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                let companies = response.data.rows;
                if (parentCompany) {
                    companies.unshift(parentCompany);
                }
                dispatch(setResellerChildCompanies(companies, response.data.count));
                if (callback) {
                    callback(response.data.rows)
                }
            })
            .catch(error => {
                if (errorCallback) {
                    errorCallback(error.response.data.error)
                }
                dispatch(getResellerChildCompaniesFailed());
            })
    }
};

export const getResellerChildCompanyByIdStart = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANY_BY_ID_START,
    }
};


export const getResellerChildCompanyByIdFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANY_BY_ID_FAILED,
    }
};

export const setResellerChildCompany = (resellerChildCompany) => {
    return{
        type: actionTypes.SET_RESELLER_CHILD_COMPANY_BY_ID,
        resellerChildCompany: resellerChildCompany
    }
};

export const getResellerChildCompanyById = (resellerId, childCompanyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerChildCompanyByIdStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${childCompanyId}`)
            .then(response => {
                dispatch(setResellerChildCompany(response.data));
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getResellerChildCompanyByIdFailed());
            })
    }
};

export const deleteItemFromResellerRoasterStart = () => {
    return {
        type: actionTypes.DELETE_ITEM_FROM_RESELLER_ROASTER_START
    }
};

export const deleteItemFromResellerRoasterFinished = () => {
    return {
        type: actionTypes.DELETE_ITEM_FROM_RESELLER_ROASTER_FINISHED
    }
};

export const deleteItemFromResellerRoasterFailed = () => {
    return {
        type: actionTypes.DELETE_ITEM_FROM_RESELLER_ROASTER_FAILED
    }
};

export const deleteItemFromResellerRoaster = (resellerId, companyItemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteItemFromResellerRoasterStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${resellerId}/items/${companyItemId}`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deleteItemFromResellerRoasterFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteItemFromResellerRoasterFailed())
            })
    }
};

export const deleteItemFromResellerChildCompanyRoasterStart = () => {
    return {
        type: actionTypes.DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_START
    }
};

export const deleteItemFromResellerChildCompanyRoasterFinished = () => {
    return {
        type: actionTypes.DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FINISHED
    }
};

export const deleteItemFromResellerChildCompanyRoasterFailed = () => {
    return {
        type: actionTypes.DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FAILED
    }
};

export const deleteItemFromResellerChildCompanyRoaster = (resellerId, childCompanyId, companyItemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteItemFromResellerChildCompanyRoasterStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${childCompanyId}/items/${companyItemId}`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deleteItemFromResellerChildCompanyRoasterFinished())
            })
            .catch(error => {
                if(errorCallback) {
                    errorCallback(error.response.data.error);
                }
                dispatch(deleteItemFromResellerChildCompanyRoasterFailed())
            })
    }
};

export const addUserToResellerChildCompanyStart = () => {
    return {
        type: actionTypes.ADD_USER_TO_RESELLER_CHILD_COMPANY_START
    }
};

export const addUserToResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.ADD_USER_TO_RESELLER_CHILD_COMPANY_FINISHED
    }
};

export const addUserToResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.ADD_USER_TO_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const addUserToResellerChildCompany = (resellerId, childCompanyId, user, callback, errorCallback) => {
    return dispatch => {
        dispatch(addUserToResellerChildCompanyStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${childCompanyId}/users`, user)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addUserToResellerChildCompanyFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addUserToResellerChildCompanyFailed())
            })
    }
};

export const deleteUserOfResellerChildCompanyStart = () => {
    return {
        type: actionTypes.DELETE_USER_TO_RESELLER_CHILD_COMPANY_START
    }
};

export const deleteUserOfResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.DELETE_USER_TO_RESELLER_CHILD_COMPANY_FINISHED
    }
};

export const deleteUserOfResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.DELETE_USER_TO_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const deleteUserOfResellerChildCompany = (resellerId, childCompanyId, userId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteUserOfResellerChildCompanyStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${childCompanyId}/users/${userId}`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deleteUserOfResellerChildCompanyFinished());
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteUserOfResellerChildCompanyFailed);
            })
    }
};

export const getUsersOfResellerChildCompanyStart = () => {
    return {
        type: actionTypes.GET_USERS_OF_RESELLER_CHILD_COMPANY_START
    }
};

export const getUsersOfResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.GET_USERS_OF_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const setUsersOfResellerChildCompany = (users, total) => {
    return {
        type: actionTypes.SET_USERS_OF_RESELLER_CHILD_COMPANY,
        resellerChildCompanyUsers: users,
        total: total
    }
};

export const getUsersOfResellerChildCompany = (page, size, searchWord, resellerId, childCompanyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getUsersOfResellerChildCompanyStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${childCompanyId}/users`, + {
            params: {
              ...({limit: size}),
              ...({offset: page*size}),
              ...(searchWord ? {q: searchWord} : {}),
            }
        })
            .then(response => {
                dispatch(setUsersOfResellerChildCompany(response.data.rows, response.data.total));
                if (callback) {
                    callback(response);
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getUsersOfResellerChildCompanyFailed());
            })
    }
};

export const getResellerChildCompanyJobsStart = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANY_JOBS_START
    }
};

export const getResellerChildCompanyJobsFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_CHILD_COMPANY_JOBS_FAILED
    }
};

export const setResellerChildCompanyJobs = (jobs, total) => {
    return {
        type: actionTypes.SET_RESELLER_CHILD_COMPANY_JOBS,
        resellerChildCompanyJobs: jobs,
        total: total
    }
};

export const getResellerChildCompanyJobs = (page, size, searchWord, status, resellerId, childCompanyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerChildCompanyJobsStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${childCompanyId}/jobs`, + {
            params: {
              ...({limit: size}),
              ...({offset: page*size}),
              ...(searchWord ? {q: searchWord} : {}),
              ...(status !== 'ALL' ? {status} : {}),
            }
        })
            .then(response => {
                dispatch(setResellerChildCompanyJobs(response.data.rows, response.data.count))
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                if (errorCallback) {
                    errorCallback(error.response.data.error)
                }
                dispatch(getResellerChildCompanyJobsFailed())
            })
    }
};

export const deleteResellerChildCompanyStart = () => {
    return {
        type: actionTypes.DELETE_RESELLER_CHILD_COMPANY_START
    }
};

export const deleteResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.DELETE_RESELLER_CHILD_COMPANY_FINISHED
    }
};

export const deleteResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.DELETE_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const deleteResellerChildCompany = (resellerId, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteResellerChildCompanyStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}`)
            .then(response => {
                dispatch(deleteResellerChildCompanyFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                if (errorCallback) {
                    errorCallback(error.response.data.error)
                }
                dispatch(deleteResellerChildCompanyFailed())
            })
    }
};

export const addAssetToResellerRoasterStart = () => {
    return {
        type: actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_START
    }
};

export const addAssetToResellerRoasterFinished = () => {
    return {
        type: actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_FINISHED
    }
};

export const addAssetToResellerRoasterFailed = () => {
    return {
        type: actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_FAILED
    }
};

export const addAssetToResellerRoaster = (resellerId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(addAssetToResellerRoasterStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/items`, items)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addAssetToResellerRoasterFinished());
            })
            .catch(error => {
                dispatch(addAssetToResellerRoasterFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const addAssetToResellerRoasterAllStart = () => {
    return {
        type: actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_ALL_START
    }
};

export const addAssetToResellerRoasterAllFinished = () => {
    return {
        type: actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_ALL_FINISHED
    }
};

export const addAssetToResellerRoasterAllFailed = () => {
    return {
        type: actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_ALL_FAILED
    }
};

export const addAssetToResellerRoasterAll = (resellerId, filters, callback, errorCallback) => {
    return dispatch => {
        dispatch(addAssetToResellerRoasterAllStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/items/filtered`, filters)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addAssetToResellerRoasterAllFinished());
            })
            .catch(error => {
                dispatch(addAssetToResellerRoasterAllFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getReportTemplatesOfResellerCompanyStart = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_START
    }
};

export const setReportTemplatesOfResellerCompany = (reportTemplates) => {
    return {
        type: actionTypes.SET_REPORT_TEMPLATES_OF_RESELLER_COMPANY,
        reportTemplates: reportTemplates
    }
};

export const getReportTemplatesOfResellerCompanyFailed = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_FAILED
    }
};

export const getReportTemplatesOfResellerCompany = (resellerId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getReportTemplatesOfResellerCompanyStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/reportTemplates`)
            .then(response => {
                let reportTemplates = response.data.map(reportTemplate => reportTemplate.template);
                dispatch(setReportTemplatesOfResellerCompany(reportTemplates));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getReportTemplatesOfResellerCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const addReportTemplateToResellerChildCompanyStart = () => {
    return {
        type: actionTypes.ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_START
    }
};

export const addReportTemplateToResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FINISHED,
    }
};

export const addReportTemplateToResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const addReportTemplateToResellerChildCompany = (resellerId, companyId, reportTemplate, callback, errorCallback) => {
    return dispatch => {
        dispatch(addReportTemplateToResellerChildCompanyStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/reportTemplates`, reportTemplate)
            .then(response => {
                dispatch(addReportTemplateToResellerChildCompanyFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(addReportTemplateToResellerChildCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const deleteReportTemplateOfResellerChildCompanyStart = () => {
    return {
        type: actionTypes.DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_START
    }
};

export const deleteReportTemplateOfResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FINISHED,
    }
};

export const deleteReportTemplateOfResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const deleteReportTemplateOfResellerChildCompany = (resellerId, companyId, reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteReportTemplateOfResellerChildCompanyStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/reportTemplates/${reportTemplateId}`)
            .then(response => {
                dispatch(deleteReportTemplateOfResellerChildCompanyFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(deleteReportTemplateOfResellerChildCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getReportTemplateOfResellerChildCompanyStart = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_START
    }
};

export const setReportTemplateOfResellerChildCompany = (childCompanyReportTemplates) => {
    return {
        type: actionTypes.SET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY,
        childCompanyReportTemplates: childCompanyReportTemplates
    }
};

export const getReportTemplateOfResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const getReportTemplateOfResellerChildCompany = (resellerId, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getReportTemplateOfResellerChildCompanyStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/reportTemplates`)
            .then(response => {
                dispatch(setReportTemplateOfResellerChildCompany(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getReportTemplateOfResellerChildCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getPackagesOfResellerCompanyStart = () => {
    return {
        type: actionTypes.GET_PACKAGES_OF_RESELLER_COMPANY_START
    }
};

export const setPackagesOfResellerCompany = (packages) => {
    return {
        type: actionTypes.SET_PACKAGES_OF_RESELLER_COMPANY,
        packages: packages
    }
};

export const getPackagesOfResellerCompanyFailed = () => {
    return {
        type: actionTypes.GET_PACKAGES_OF_RESELLER_COMPANY_FAILED
    }
};

export const getPackagesOfResellerCompany = (resellerId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getPackagesOfResellerCompanyStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/packages`)
            .then(response => {
                dispatch(setPackagesOfResellerCompany(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getPackagesOfResellerCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const addPackageToResellerChildCompanyStart = () => {
    return {
        type: actionTypes.ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_START
    }
};

export const addPackageToResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FINISHED,
    }
};

export const addPackageToResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const addPackageToResellerChildCompany = (resellerId, companyId, companyPackage, callback, errorCallback) => {
    return dispatch => {
        dispatch(addPackageToResellerChildCompanyStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/packages`, companyPackage)
            .then(response => {
                dispatch(addPackageToResellerChildCompanyFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(addPackageToResellerChildCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const deletePackageOfResellerChildCompanyStart = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_START
    }
};

export const deletePackageOfResellerChildCompanyFinished = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FINISHED,
    }
};

export const deletePackageOfResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const deletePackageOfResellerChildCompany = (resellerId, companyId, packageId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deletePackageOfResellerChildCompanyStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/packages/${packageId}`)
            .then(response => {
                dispatch(deletePackageOfResellerChildCompanyFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(deletePackageOfResellerChildCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getPackageOfResellerChildCompanyStart = () => {
    return {
        type: actionTypes.GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_START
    }
};

export const setPackagesOfResellerChildCompany = (childCompanyPackages) => {
    return {
        type: actionTypes.SET_PACKAGES_OF_RESELLER_CHILD_COMPANY,
        childCompanyPackages: childCompanyPackages
    }
};

export const getPackageOfResellerChildCompanyFailed = () => {
    return {
        type: actionTypes.GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_FAILED
    }
};

export const getPackagesOfResellerChildCompany = (resellerId, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getPackageOfResellerChildCompanyStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/packages`)
            .then(response => {
                dispatch(setPackagesOfResellerChildCompany(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getPackageOfResellerChildCompanyFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getPackageItemsOfResellerCompanyStart = () => {
    return {
        type: actionTypes.GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_START
    }
};

export const setPackageItemsOfResellerCompany = (packageItems) => {
    return {
        type: actionTypes.SET_PACKAGE_ITEMS_OF_RESELLER_COMPANY,
        packageItems: packageItems
    }
};

export const getPackageItemsOfResellerCompanyFailed = () => {
    return {
        type: actionTypes.GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_FAILED
    }
};

export const getPackageItemsOfResellerCompany = (resellerId, companyId, packageId, searchWord, vendorId, callback, errorCallback, offset, limit) => {
    return dispatch => {
        dispatch(getPackageItemsOfResellerCompanyStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/packages/${packageId}/items`, + {
            params: {
              ...(offset ? {offset} : {offset: '0'}),
              ...(limit ? {limit} : {limit: '1000'}),
              ...(searchWord ? {q: searchWord} : {}),
              ...(vendorId ? {vendorId} : {}),
            }
        })
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setPackageItemsOfResellerCompany(response.data.rows));
            })
            .catch(error => {
                if (errorCallback) {
                    errorCallback(error.response.data.error)
                }
                dispatch(getPackageItemsOfResellerCompanyFailed())
            })
    }
};

export const getResellerPackageVersionsStart = () => {
    return {
        type: actionTypes.GET_RESELLER_PACKAGE_VERSIONS_START
    }
};

export const setResellerPackageVersions = (versions) => {
    return {
        type: actionTypes.SET_RESELLER_PACKAGE_VERSIONS,
        packageVersions: versions
    }
};

export const getResellerPackageVersionsFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_PACKAGE_VERSIONS_FAILED
    }
};

export const getResellerPackageVersions = (resellerId, companyId, packageId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerPackageVersionsStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/companies/${companyId}/packages/${packageId}/versions`)
            .then(response => {
            let versions = response.data;
                if (callback) {
                    callback(response.data)
                }
                if (versions.length === 0) {
                    versions = [{
                        version: 1,
                        id: null
                    }]
                }
                dispatch(setResellerPackageVersions(versions));
            })
            .catch(error => {
                if (errorCallback) {
                    errorCallback(error.response.data.error)
                }
                dispatch(getResellerPackageVersionsFailed())
            })
    }
};
