import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';

export const addCustomAttributeValueToAssetStart = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_START
    }
};

export const addCustomAttributeValueToAssetFailed = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_FAILED
    }
};

export const addCustomAttributeValueToAssetFinished = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_FINISHED
    }
};

export const addCustomAttributeValueToAsset = (assetId, customAttributes, callback) => {
    return dispatch => {
        dispatch(addCustomAttributeValueToAssetStart());
        axios.post(`${Constants.SERVER_URL}/assets/${assetId}/customAttributes`, customAttributes)
            .then(response => {
                dispatch(addCustomAttributeValueToAssetFinished());
                callback();
            })
            .catch(error => {
                dispatch(addCustomAttributeValueToAssetFailed())
            })
    }
};

export const deleteCustomAttributeStart = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_START
    }
};

export const deleteCustomAttributeFinished = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FINISHED
    }
};

export const deleteCustomAttributeFailed = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FAILED
    }
};

export const deleteCustomAttribute = (assetId, customAttributeId, value, callback) => {
    return dispatch => {
        dispatch(deleteCustomAttributeStart());
        axios.delete(`${Constants.SERVER_URL}/assets/${assetId}/customAttributes/${customAttributeId}`, {
            params: {
              ...(value ? {value} : {})
            }
        })
            // .then(response => {
            //     dispatch(deleteCustomAttributeFinished());
            //     callback();
            // })
            // .catch(error => {
            //     dispatch(deleteCustomAttributeFailed())
            // })
    }
};

export const getAssetCustomAttributesStart = () => {
    return {
        type: actionTypes.GET_ASSET_CUSTOM_ATTRIBUTES_START
    }
};

export const setAssetCustomAttributes = (attributes) => {
    return {
        assetCustomAttributes: attributes,
        type: actionTypes.SET_ASSET_CUSTOM_ATTRIBUTES
    }
};

export const getAssetCustomAttributesFailed = () => {
    return {
        type: actionTypes.GET_ASSET_CUSTOM_ATTRIBUTES_FAILED
    }
};

export const getAssetCustomAttributes = (vendorId, categoryId, searchWord, attributeQuery, packageFilters, callback) => {
    return dispatch => {
        dispatch(getAssetCustomAttributesStart());
        axios.post(`${Constants.SERVER_URL}/assetsCustomAttributes/get`, {
            attrs: attributeQuery
        }, {
            params: {
              ...(vendorId ? {vendorId} : {}),
              ...(categoryId ? {categoryId} : {}),
              ...(searchWord ? {q: searchWord} : {})
            }
        })
            .then(response => {
                response.data.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
                dispatch(setAssetCustomAttributes(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getAssetCustomAttributesFailed());
            })
    }
};

export const getAssetsStart = () => {
    return {
        type: actionTypes.GET_ASSETS_START
    }
}

export const setAssets = (assets) => {
    return {
        type: actionTypes.SET_ASSETS,
        pagedItems: assets.rows,
        totalItems: assets.count,
    }
}

export const getAssetsFailed = () => {
    return {
        type: actionTypes.GET_ASSETS_FAILED
    }
}


export const setAssetsPagedItemsEmpty = () => {
    return {
        type: actionTypes.SET_ASSETS_PAGE_ITEMS_EMPTY
    }
}

export const getAssets = (
    companyId,
    page,
    size,
    searchWord,
    vendor,
    categoryId,
    attributeFilters,
    callback,
    errorCallback,
    sort,
    assetId,
    isSupported
    ) => {
    return dispatch => {
        dispatch(getAssetsStart());
        let sortItems = ''

        if (sort && sort.length > 0) {
            sortItems = '?';
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }

        axios.post(`${Constants.SERVER_URL}/assets/get${sortItems}`, attributeFilters.length !== 0 ? {
            attrs: attributeFilters
        } : null, {
            params: {
              ...({limit: size}),
              ...({offset: (page - 1) * size}),
              ...(searchWord !== '' ? {q: searchWord} : {}),
              ...(vendor !== '' ? {vendorId: vendor} : {}),
              ...(categoryId !== '' ? {categoryId} : {}),
              ...(companyId ? {companyId} : {}),
              ...(assetId ? {id: assetId} : {}),
              ...(isSupported ? {isSupported: isSupported === true} : {}),
            }
        })
            .then(response => {
                dispatch(setAssets(response.data))
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getAssetsFailed())
                if(errorCallback) {
                    errorCallback(error.response ? error.response.data.error : error)
                }
            })
    }
}

export const removeAssetStart = () => {
    return {
        type: actionTypes.REMOVE_ASSET_START
    }
};

export const removeAssetEnd = () => {
    return {
        type: actionTypes.REMOVE_ASSET_END
    }
};

export const removeAssetFailed = () => {
    return {
        type: actionTypes.REMOVE_ASSET_FAILED
    }
};

export const removeAsset = (assetId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeAssetStart());
        axios.delete(`${Constants.SERVER_URL}/assets/${assetId}`)
        .then(() => {
            callback()
            dispatch(removeAssetEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(removeAssetFailed())
            errorCallback(error.response.data.error)
        });
    }
}

export const discontinueAssetStart = () => {
    return {
        type: actionTypes.DISCONTINUE_ASSET_START
    }
};

export const discontinueAssetEnd = () => {
    return {
        type: actionTypes.DISCONTINUE_ASSET_END
    }
};

export const discontinueAssetFailed = () => {
    return {
        type: actionTypes.DISCONTINUE_ASSET_FAILED
    }
};

export const discontinueAsset = (assetId, value, callback, errorCallback) => {
    return dispatch => {
        dispatch(discontinueAssetStart());
        axios.patch(`${Constants.SERVER_URL}/assets/${assetId}/supported`, {isSupported: value})
            .then(() => {
                callback()
                dispatch(discontinueAssetEnd())
            })
            .catch(error => {
                dispatch(discontinueAssetFailed())
                errorCallback(error.response.data.error)
            });
    }
}

export const updateAssetStart = () => {
    return {
        type: actionTypes.UPDATE_ASSET_START
    }
}

export const updateAssetEnd = () => {
    return {
        type: actionTypes.UPDATE_ASSET_END
    }
}

export const updateAssetFailed = () => {
    return {
        type: actionTypes.UPDATE_ASSET_FAILED
    }
}

export const updateAsset = (asset, callback, errorCallback) => {
    return dispatch => {
        dispatch(updateAssetStart())
        axios.patch(`${Constants.SERVER_URL}/assets/${asset.id}`, asset)
        .then(() => {
            callback();
            dispatch(updateAssetEnd());
        })
        .catch(error => {
            console.log(error)
            dispatch(updateAssetFailed())
            errorCallback(error.response.data.error)
        });
    }
}

export const createAssetStart = () => {
    return {
        type: actionTypes.CREATE_ASSET_START
    }
}

export const createAssetEnd = () => {
    return {
        type: actionTypes.CREATE_ASSET_END
    }
}

export const createAssetFailed = () => {
    return {
        type: actionTypes.CREATE_ASSET_FAILED
    }
}

export const createAsset = (asset, callback, errorCallback) => {
    if (asset.sku === '') {
        asset.sku = null
    }
    return dispatch => {
        dispatch(createAssetStart())
        axios.post(`${Constants.SERVER_URL}/assets`, asset)
        .then((response) => {
            callback(response.data)
            dispatch(createAssetEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(createAssetFailed())
            errorCallback(error)
        });
    }
}

export const uploadAssetImageStart = () => {
   return {
       type: actionTypes.UPLOAD_ASSET_IMAGE_START
   }
}

export const uploadAssetImageEnd = () => {
    return {
        type: actionTypes.UPLOAD_ASSET_IMAGE_END
    }
 }

 export const uploadAssetImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_ASSET_IMAGE_FAILED
    }
 }

export const uploadAssetImage = (assetId, data, callback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch(uploadAssetImageStart())
        axios.post(`${Constants.SERVER_URL}/assets/${assetId}/image`, formData, config)
        .then(() => {
            callback()
            dispatch(uploadAssetImageEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(uploadAssetImageFailed())
        });
    }
}

export const removeImgFromAsset = (assetId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeAssetStart());
        axios.delete(`${Constants.SERVER_URL}/assets/${assetId}/image`)
            .then((res) => {
                callback(res.data)
                dispatch(removeAssetEnd())
            })
            .catch(error => {
                console.log(error)
                dispatch(removeAssetFailed())
                errorCallback(error.response.data.error)
            });
    }
}

export const setChosenAssetId = chosenAssetId => {
    return {
        type: actionTypes.SET_CHOSEN_ASSET_ID,
        chosenAssetId
    }
}

export const getAssetStart = () => {
    return {
        type: actionTypes.GET_ASSET_START
    }
}

export const setAsset = asset => {
    return {
        type: actionTypes.SET_ASSET,
        asset
    }
}

export const getAssetFailed = () => {
    return {
        type: actionTypes.GET_ASSET_FAILED
    }
}

export const getAsset = assetId => {
    return dispatch => {
        dispatch(getAssetStart())
        axios.get(`${Constants.SERVER_URL}/assets/${assetId}`)
            .then(response => {
                dispatch(setAsset(response.data))
            }).catch(error => {
            console.log(error)
            dispatch(getAssetFailed())
        })
    }
}