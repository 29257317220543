import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';
import generalUtils from '../../Utils/GeneralUtils';
import {deleteCompanyImageEnd, deleteCompanyImageFailed, deleteCompanyImageStart} from "./company";

export const addReportTemplateStart = () => {
    return {
        type: actionTypes.ADD_REPORT_TEMPLATE_START
    }
};

export const addReportTemplateFailed = () => {
    return {
        type: actionTypes.ADD_REPORT_TEMPLATE_FAILED,
    }
};

export const addReportTemplate = (template, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch (addReportTemplateStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates`, template)
            .then(response => {
                callback(response.data);
            }).catch(error => {
                dispatch(addReportTemplateFailed());
                errorCallback(error.response.data.error)
        })
    }
};

export const editReportTemplateStart = () => {
    return {
        type: actionTypes.EDIT_REPORT_TEMPLATE_START
    }
};

export const editReportTemplateFinished = () => {
    return {
        type: actionTypes.EDIT_REPORT_TEMPLATE_FINISHED
    }
};

export const editReportTemplate = (template, companyId, reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch (editReportTemplateStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}`, template)
            .then(response => {
                dispatch(editReportTemplateFinished());
                callback(response.data)
            })
            .catch (error => {
                dispatch(editReportTemplateFinished());
                errorCallback(error.response.data.error);
            })
    }
};

export const deleteReportTemplateStart = () => {
    return {
        type: actionTypes.REMOVE_REPORT_TEMPLATE_START
    }
};

export const deleteReportTemplateFinished = () => {
    return {
        type: actionTypes.REMOVE_REPORT_TEMPLATE_FINISHED
    }
};

export const deleteReportTemplate = (companyId, reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteReportTemplateStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}`)
            .then(response => {
                dispatch(deleteReportTemplateFinished());
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteReportTemplateFinished())
            })
    }
};

export const importReportTemplate = (companyId, dto, callback, errorCallback) => {
    return dispatch => {
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/import`, dto)
            .then(response => {
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error);
            })
    }
};

export const getReportTemplatesStart = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_START
    }
};

export const setReportTemplates = (reportTemplates) => {
    return {
        type: actionTypes.SET_REPORT_TEMPLATES,
        reportTemplates: reportTemplates
    }
};

export const getReportTemplateFailed = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_FAILED
    }
};

export const getReportTemplates = (companyId, callback) => {
    return dispatch => {
        dispatch(getReportTemplatesStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates`)
            .then( response => {
                dispatch(setReportTemplates(response.data));
                if (callback) {
                    callback(response.data)
                }
        })
            .catch( error => {
                dispatch(getReportTemplateFailed())
            })
    }
};

export const getReportTemplatesAll = (companyId, callback, page, size, searchWord, sort, isActive, errorCallback) => {
    return dispatch => {
        dispatch(getReportTemplatesStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesAll${sortItems}`, {
            params: {
                ...(searchWord ? {q: searchWord} : {}),
                ...(isActive ? {isActive} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        }).then( response => {
                dispatch(setReportTemplates(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch( error => {
                dispatch(getReportTemplateFailed())
                if(errorCallback) {
                    errorCallback(error)
                }
            })
    }
};

export const getReportTemplateByIdStart = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATE_BY_ID_START
    }
};

export const setReportTemplateById = (reportTemplate) => {
    return {
        type: actionTypes.SET_REPORT_TEMPLATE_BY_ID,
        reportTemplate: reportTemplate
    }
};

export const getReportTemplateByIdFailed = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATE_BY_ID_FAILED
    }
};

export const getReportTemplateById = (companyId, reportTemplateId, callback) => {
    return dispatch => {
        dispatch (getReportTemplateByIdStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}`)
            .then(response => {
                callback(response.data.headers);
                dispatch(setReportTemplateById(response.data));
            })
            .catch(error => {
                getReportTemplateByIdFailed()
            })
    }
};

export const getReportTemplateByIdAfterDraggingStart = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_START
    }
};

export const setReportTemplateByIdAfterDragging = (reportTemplate) => {
    return {
        type: actionTypes.SET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING,
        reportTemplate: reportTemplate
    }
};

export const getReportTemplateByIdFailedAfterDragging = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_FAILED
    }
};

export const getReportTemplateByIdAfterDragging = (companyId, reportTemplateId, callback) => {
    return dispatch => {
        dispatch (getReportTemplateByIdAfterDraggingStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}`)
            .then(response => {
                callback(response.data.headers);
                dispatch(setReportTemplateById(response.data));
                dispatch(setReportTemplateByIdAfterDragging(response.data));
            })
            .catch(error => {
                console.log(error)
                getReportTemplateByIdFailedAfterDragging()
            })
    }
};

export const addReportTemplateHeaderStart = () => {
    return {
        type: actionTypes.ADD_REPORT_TEMPLATE_HEADER_START
    }
};

export const addReportTemplateHeaderFinished = () => {
    return {
        type: actionTypes.ADD_REPORT_TEMPLATE_HEADER_FINISHED
    }
};

export const addReportTemplateHeader = (companyId, reportTemplateId, header, callback, errorCallback) => {
    return dispatch => {
        dispatch (addReportTemplateHeaderStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/headers`, header)
            .then(response => {
                dispatch(addReportTemplateHeaderFinished());
                callback()
            })
            .catch(error => {
                dispatch(addReportTemplateHeaderFinished())
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData)
                }
                console.error(error)
            })
    }
};

export const editReportTemplateHeaderStart = () => {
    return {
        type: actionTypes.EDIT_REPORT_TEMPLATE_HEADER_START
    }
};

export const editReportTemplateHeaderFinished = () => {
    return {
        type: actionTypes.EDIT_REPORT_TEMPLATE_HEADER_FINISHED
    }
};

export const editReportTemplateHeader = (companyId, headerId, header, callback, errorCallback) => {
    return dispatch => {
        dispatch (editReportTemplateHeaderStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/headers/${headerId}`, header)
            .then(response => {
                callback();
            })
            .catch(error => {
                dispatch(editReportTemplateHeaderFinished())
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData)
                }
                console.error(error)
            })
    }
};

export const removeReportTemplateHeaderStart = () => {
    return {
        type: actionTypes.REMOVE_REPORT_TEMPLATE_HEADER_START
    }
};

export const removeReportTemplateHeaderFinished = () => {
    return {
        type: actionTypes.REMOVE_REPORT_TEMPLATE_HEADER_FINISHED
    }
};

export const removeReportTemplateHeader = (companyId, reportTemplateId, headerId, callback) => {
    return dispatch => {
        dispatch(removeReportTemplateHeaderStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/headers/${headerId}`)
            .then(response => {
                callback();
            })
            .catch(error => {
                dispatch(removeReportTemplateHeaderFinished())
            })
    }
};

export const dragAndDropReportTemplateStart = () => {
    return {
        type: actionTypes.DRAG_AND_DROP_REPORT_TEMPLATE_START
    }
};

export const dragAndDropReportTemplateSuccess = () => {
    return {
        type: actionTypes.DRAG_AND_DROP_REPORT_TEMPLATE_SUCCESS
    }
};

export const dragAndDropReportTemplateFailed = () => {
    return {
        type: actionTypes.DRAG_AND_DROP_REPORT_TEMPLATE_FAILED
    }
};

export const dragAndDropReportTemplate = (companyId, reportTemplateId, dragAndDropObject, callback) => {
    return dispatch => {
        dispatch(dragAndDropReportTemplateStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/headers/order`, dragAndDropObject)
            .then(response => {
                callback();
                dispatch(dragAndDropReportTemplateSuccess())
            })
            .catch(error => {
                dispatch(dragAndDropReportTemplateFailed())
            })
    }
};

export const createVariationReportStart = () => {
    return {
        type: actionTypes.CREATE_VARIATION_REPORT_START
    }
};

export const createVariationReportFailed = () => {
    return {
        type: actionTypes.CREATE_VARIATION_REPORT_FAILED
    }
};

export const createVariationReportFinished = () => {
    return {
        type: actionTypes.CREATE_VARIATION_REPORT_FINISHED
    }
};

export const createVariationReport = (companyId, variationReport, callback, errorCallback) => {
    return dispatch => {
        dispatch(createVariationReportStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesVariations`, variationReport)
            .then(response => {
                dispatch(createVariationReportFinished());
                if (callback) {
                    callback(response.data.id);
                }
            })
            .catch(error => {
                dispatch(createVariationReportFailed());
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData)
                }
                console.error(error)
            })
    }
};

export const addVariationReportRuleStart = () => {
    return {
        type: actionTypes.ADD_VARIATION_REPORT_RULE_START
    }
};

export const addVariationReportRuleFinished = () => {
    return {
        type: actionTypes.ADD_VARIATION_REPORT_RULE_FINISHED
    }
};

export const addVariationReportRuleFailed = () => {
    return {
        type: actionTypes.ADD_VARIATION_REPORT_RULE_FAILED
    }
};

export const addVariationReportRule = (companyId, variationId, rules, callback, errorCallback) => {
    return dispatch => {
        dispatch(addVariationReportRuleStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesVariations/${variationId}/rules`, rules)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addVariationReportRuleFinished());
            })
            .catch(error => {
                errorCallback(error);
                dispatch(addVariationReportRuleFailed());
            })
    }
};

export const getVariationReportsStart = () => {
    return {
        type: actionTypes.GET_VARIATION_REPORTS_START
    }
};

export const getVariationReportsFailed = () => {
    return {
        type: actionTypes.GET_VARIATION_REPORTS_FAILED
    }
};

export const setVariationReports = (variationReports) => {
    return {
        type: actionTypes.SET_VARIATION_REPORTS,
        variationReports: variationReports
    }
};

export const getVariationReports = (companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getVariationReportsStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesVariations`)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setVariationReports(response.data));

            })
            .catch(error => {
                console.log(error)
                if(errorCallback) {
                    errorCallback(error.response ? error.response.data.error : error);
                }
                dispatch(getVariationReportsFailed())
            })
    }
};

export const editVariationReportStart = () => {
    return {
        type: actionTypes.EDIT_VARIATION_REPORT_START
    }
};

export const editVariationReportFinished = () => {
    return {
        type: actionTypes.EDIT_VARIATION_REPORT_FINISHED
    }
};

export const editVariationReportFailed = () => {
    return {
        type: actionTypes.EDIT_VARIATION_REPORT_FAILED
    }
};

export const editVariationReport = (companyId, variationReportItd, variationReport, callback, errorCallback) => {
    return dispatch => {
        dispatch(editVariationReportStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesVariations/${variationReportItd}`, variationReport)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(editVariationReportFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(editVariationReportFailed())
            })
    }
};

export const editVariationReportRuleStart = () => {
    return {
        type: actionTypes.EDIT_VARIATION_REPORT_RULE_START
    }
};

export const editVariationReportRuleFinished = () => {
    return {
        type: actionTypes.EDIT_VARIATION_REPORT_RULE_FINISHED
    }
};

export const editVariationReportRuleFailed = () => {
    return {
        type: actionTypes.EDIT_VARIATION_REPORT_RULE_FAILED
    }
};

export const editVariationReportRule = (companyId, variationReportId, rulesId, rules, callback, errorCallback) => {
    return dispatch => {
        dispatch(editVariationReportRuleStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesVariations/${variationReportId}/rules/${rulesId}`, rules)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(editVariationReportRuleFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(editVariationReportRuleFailed())
            })
    }
};

export const deleteVariationReportStart = () => {
    return {
        type: actionTypes.DELETE_VARIATION_REPORT_START
    }
};

export const deleteVariationReportFinished = () => {
    return {
        type: actionTypes.DELETE_VARIATION_REPORT_FINISHED
    }
};

export const deleteVariationReportFailed = () => {
    return {
        type: actionTypes.DELETE_VARIATION_REPORT_FAILED
    }
};

export const deleteVariationReport = (companyId, variationReportId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteVariationReportStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesVariations/${variationReportId}`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deleteVariationReportFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteVariationReportFailed())
            })
    }
};

export const getVariationReportHeadersStart = () => {
     return {
         type: actionTypes.GET_VARIATION_REPORT_HEADERS_START
     }
};

export const setVariationReportHeaders = (variationReports) => {
    return {
        type: actionTypes.SET_VARIATION_REPORT_HEADERS,
        variationReports: variationReports
    }
};

export const getVariationReportHeadersFailed = () => {
    return {
        type: actionTypes.GET_VARIATION_REPORT_HEADERS_FAILED,
    }
};

export const getVariationReportHeaders = (companyId, jobId, reportTemplateVariationId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getVariationReportHeadersStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/report/headers`, {
            params: {
              ...({reportTemplateVariationId})
            }
        })
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setVariationReportHeaders(response.data))
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getVariationReportHeadersFailed())
            })
    }
};

export const getReportTemplateAttributesStart = () => {
    return {
        type: actionTypes.GET_TEMPLATE_ATTRIBUTES_START
    }
};

export const getReportTemplateAttributesFailed = () => {
    return {
        type: actionTypes.GET_TEMPLATE_ATTRIBUTES_FAILED
    }
};

export const setReportTemplateAttributes = (attributes) => {
    return {
        type: actionTypes.SET_TEMPLATE_ATTRIBUTES,
        reportTemplateAttributes: attributes
    }
};

export const getReportTemplateAttributes = (companyId, reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getReportTemplateAttributesStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplatesAttributes`, + {
            params: {
              ...(reportTemplateId ? {reportTemplateId} : {})
            }
        })
            .then(response => {
                dispatch(setReportTemplateAttributes(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                if (errorCallback) {
                    errorCallback(error.response.data.error);
                }
                dispatch(getReportTemplateAttributesFailed())
            })
    }
};

export const getResellerAnalyticalReportStart = () => {
    return {
        type: actionTypes.GET_RESELLER_ANALYTICAL_REPORT_START
    }
};

export const setResellerAnalyticalReport = (report) => {
    return {
        type: actionTypes.SET_RESELLER_ANALYTICAL_REPORT,
        analyticalReport: report
    }
};

export const getResellerAnalyticalReportFailed = () => {
    return {
        type: actionTypes.GET_RESELLER_ANALYTICAL_REPORT_FAILED,
    }
};

export const getResellerAnalyticalReport = (resellerId, status, company, reportTemplate, vendor, from, to, callback, errorCallback) => {
    return dispatch => {
        dispatch(getResellerAnalyticalReportStart());
        axios.get(`${Constants.SERVER_URL}/resellers/${resellerId}/analytics`, + {
            params: {
              ...(status ? {status: status.status} : {}),
              ...(company ? {companyId: company.id} : {}),
              ...(reportTemplate ? {reportTemplateId: reportTemplate.id} : {}),
              ...(vendor ? {vendorId: vendor.id} : {}),
              ...(from ? {from} : {}),
              ...(to ? {to} : {}),
            }
        })
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setResellerAnalyticalReport(response.data))
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getResellerAnalyticalReportFailed())
            })
    }
};

export const setReportTemplateForEdit = reportTemplateForEdit => {
    return {
        type: actionTypes.ON_SET_REPORT_TEMPLATE_FOR_EDIT,
        reportTemplateForEdit
    }
}

export const setReportTemplateForCopy = reportTemplateForCopy => {
    return {
        type: actionTypes.ON_SET_REPORT_TEMPLATE_FOR_COPY,
        reportTemplateForCopy
    }
}

export const setReportTemplateCreateFilterData = reportTemplateFormValues => {
    return {
        type: actionTypes.SET_REPORT_TEMPLATES_CR_FIL_DATA,
        reportTemplateFormValues
    }
}

export const getReportTemplatesAllForSuperAdmin = (callback, page, size, searchWord, sort, status, errorCallback) => {
    return dispatch => {
        dispatch(getReportTemplatesStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/reportTemplates${sortItems}`, {
            params: {
                ...(searchWord ? {q: searchWord} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
                ...(status ? {status: status} : {}),
            }
        }).then( response => {
            dispatch(setReportTemplates(response.data));
            if (callback) {
                callback(response.data)
            }
        })
            .catch( error => {
                dispatch(getReportTemplateFailed())
                if(errorCallback) {
                    errorCallback(error)
                }
            })
    }
};

export const editReportTemplatesSuperAdmin = (callback, errorCallback, params) => {
    return dispatch => {
        dispatch(editReportTemplateStart());

        axios.patch(`${Constants.SERVER_URL}/reportTemplates/${params.id}`, params)
            .then( response => {
                dispatch(editReportTemplateFinished(response.data));
                callback();
            })
            .catch( error => {
                dispatch(editReportTemplateFinished());
                errorCallback(error.response.data.error);
            })
    }
};

export const deleteReportTemplateSuperAdmin = (reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteReportTemplateStart());
        axios.delete(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}`)
            .then(response => {
                dispatch(deleteReportTemplateFinished());
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteReportTemplateFinished())
            })
    }
};

export const addReportTemplateSuperAdmin = (template, callback, errorCallback) => {
    return dispatch => {
        dispatch (addReportTemplateStart());
        axios.post(`${Constants.SERVER_URL}/reportTemplates`, template)
            .then(response => {
                callback(response);
            }).catch(error => {
            dispatch(addReportTemplateFailed());
            errorCallback(error.response.data.error)
        })
    }
};

export const getReportTemplateByIdSuperAdmin = (reportTemplateId, callback) => {
    return dispatch => {
        dispatch (getReportTemplateByIdStart());
        axios.get(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}`)
            .then(response => {
                callback(response.data.headers);
                dispatch(setReportTemplateById(response.data));
            })
            .catch(error => {
                getReportTemplateByIdFailed()
            })
    }
};

export const addReportTemplateHeaderSuperAdmin = (reportTemplateId, header, callback) => {
    return dispatch => {
        dispatch (addReportTemplateHeaderStart());
        axios.post(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}/headers`, header)
            .then(response => {
                dispatch(addReportTemplateHeaderFinished());
                callback()
            })
            .catch(error => {
                dispatch(addReportTemplateHeaderFinished())
            })
    }
};

export const editReportTemplateHeaderSuperAdmin = (templateId, headerId, header, callback) => {
    return dispatch => {
        dispatch (editReportTemplateHeaderStart());
        axios.patch(`${Constants.SERVER_URL}/reportTemplates/${templateId}/headers/${headerId}`, header)
            .then(response => {
                callback();
            })
            .catch(error => {
                dispatch(editReportTemplateHeaderFinished())
            })
    }
};

export const removeReportTemplateHeaderSuperAdmin = (reportTemplateId, headerId, callback) => {
    return dispatch => {
        dispatch(removeReportTemplateHeaderStart());
        axios.delete(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}/headers/${headerId}`)
            .then(response => {
                callback();
            })
            .catch(error => {
                dispatch(removeReportTemplateHeaderFinished())
            })
    }
};

export const dragAndDropReportTemplateSuperAdmin = (reportTemplateId, dragAndDropObject, callback) => {
    return dispatch => {
        dispatch(dragAndDropReportTemplateStart());
        axios.post(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}/headers/order`, dragAndDropObject)
            .then(response => {
                callback();
            })
            .catch(error => {
                dispatch(dragAndDropReportTemplateFailed())
            })
    }
};

export const getReportTemplateByIdAfterDraggingSuperAdmin = (reportTemplateId, callback) => {
    return dispatch => {
        dispatch (getReportTemplateByIdAfterDraggingStart());
        axios.get(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}`)
            .then(response => {
                callback(response.data.headers);
                dispatch(setReportTemplateByIdAfterDragging(response.data));
            })
            .catch(error => {
                getReportTemplateByIdFailedAfterDragging()
            })
    }
};

export const importReportTemplateLibrary = (companyId, dto, callback, errorCallback) => {
    return dispatch => {
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/import`, dto)
            .then(response => {
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error);
            })
    }
};

export const copyReportTemplateSuperAdmin = (template, callback, errorCallback) => {
    return dispatch => {
        dispatch (addReportTemplateStart());
        axios.post(`${Constants.SERVER_URL}/reportTemplates/copy`, template)
            .then(response => {
                callback();
            }).catch(error => {
            dispatch(addReportTemplateFailed());
            errorCallback(error.response.data.error)
        })
    }
};

export const uploadReportTemplateImage = (data, companyId, reportTemplateId, callback, errorCallback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch (editReportTemplateStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/image`, formData, config)
            .then(response => {
                dispatch(editReportTemplateFinished());
                callback()
            })
            .catch (error => {
                dispatch(editReportTemplateFinished());
                errorCallback(error.response.data.error);
            })
    }
};

export const deleteReportTemplateImage = (companyId, reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch(editReportTemplateStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/image`)
            .then(response => {
                if (callback) callback(response)
                dispatch(editReportTemplateFinished())
            })
            .catch(error => {
                errorCallback(error.response ? error.response.data.error : error);
                dispatch(editReportTemplateFinished())
            });
    }
}
