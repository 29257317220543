import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';
import {setReportTemplateAttributes} from "./reportTemplates";

export const getAttributesStart = () => {
    return {
        type: actionTypes.GET_ATTRIBUTES_START
    }
}

export const setAttributes = ({rows, count}) => {
    return {
        type: actionTypes.SET_ATTRIBUTES,
        attributeItems: rows,
        attributeItemsTotalItems: count
    }
}

export const getAttributesFailed = () => {
    return {
        type: actionTypes.GET_ATTRIBUTES_FAILED
    }
}

export const getAttributes = (searchWord, callback, page, size, sort) => {
    return dispatch => {
        dispatch(getAttributesStart())
        let sortItems = '';
        if (sort && sort.length > 0) {
            sortItems = '?'
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/customAttributes${sortItems}`, {
            params: {
                ...(searchWord ? {q: searchWord} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        })
            .then(response => {
                dispatch(setAttributes(response.data))
                callback(response)
            })
            .catch(error => {
                console.log(error)
                dispatch(getAttributesFailed())
        });
    }
}

export const getCategoryAttributesStart = () => {
    return {
        type: actionTypes.GET_CATEGORY_ATTRIBUTES_START
    }
}

export const setCategoryAttributes = (attributes) => {
    return {
        type: actionTypes.SET_CATEGORY_ATTRIBUTES,
        attributes: attributes
    }
}

export const getCategoryAttributesFailed = () => {
    return {
        type: actionTypes.GET_CATEGORY_ATTRIBUTES_FAILED
    }
}

export const getCategoryAttributes = (companyId, categoryId, callback) => {
    return dispatch => {
        dispatch(getCategoryAttributesStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/categories/${categoryId}/customAttributes`)
            .then(response => {
                callback(response)
                dispatch(setCategoryAttributes(response.data))
            })
            .catch(error => {
                console.log(error)
                dispatch(getCategoryAttributesFailed())
        });
    }
}

export const removeAttributeStart = () => {
    return {
        type: actionTypes.REMOVE_ATTRIBUTE_START
    }
}

export const removeAttributeEnd = () => {
    return {
        type: actionTypes.REMOVE_ATTRIBUTE_END
    }
}

export const removeAttributeFailed = () => {
    return {
        type: actionTypes.REMOVE_ATTRIBUTE_FAILED
    }
}

export const removeAttribute = (attributeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeAttributeStart())
        axios.delete(`${Constants.SERVER_URL}/customAttributes/${attributeId}`)
            .then(() => {
                callback()
                dispatch(removeAttributeEnd())
            })
            .catch(error => {
                console.log(error)
                if (errorCallback) {
                    errorCallback(error)
                }
                dispatch(removeAttributeFailed())
            });
    }
}

export const editAttributeStart = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_START
    }
}

export const editAttributeEnd = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_END
    }
}

export const editAttributeFailed = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_FAILED
    }
}

export const editAttribute = (attributeId, name, callback, callbackError) => {
    return dispatch => {
        dispatch(editAttributeStart())
        axios.patch(`${Constants.SERVER_URL}/customAttributes/${attributeId}`, {name})
            .then(() => {
                callback()
                dispatch(editAttributeEnd())
            })
            .catch(error => {
                console.error(error)
                callbackError(error)
                dispatch(editAttributeFailed())
            });
    }
}

export const createAttributeStart = () => {
    return {
        type: actionTypes.CREATE_ATTRIBUTE_START
    }
}

export const createAttributeEnd = () => {
    return {
        type: actionTypes.CREATE_ATTRIBUTE_END
    }
}

export const createAttributeFailed = () => {
    return {
        type: actionTypes.CREATE_ATTRIBUTE_FAILED
    }
}

export const createAttribute = (name, callback, callbackError) => {
    return dispatch => {
        dispatch(createAttributeStart())
        axios.post(`${Constants.SERVER_URL}/customAttributes`, {name})
            .then(() => {
                callback()
                dispatch(createAttributeEnd())
            })
            .catch(error => {
                console.error(error)
                callbackError(error)
                dispatch(createAttributeFailed())
            });
    }
}

export const getCompanyAttributesStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ATTRIBUTE_START
    }
}

export const setCompanyAttributes = (rows, count) => {
    return {
        type: actionTypes.SET_COMPANY_ATTRIBUTE,
        attributes: rows,
        attributeItemsTotalItems: count
    }
}

export const getCompanyAttributesFailed = () => {
    return {
        type: actionTypes.GET_CATEGORY_ATTRIBUTES_FAILED
    }
}

export const getCompanyAttributes = (companyId, searchWord, callback, page, size, sort) => {
    return dispatch => {
        dispatch(getCompanyAttributesStart())
        let sortItems = '';
        if (sort && sort.length > 0) {
            sortItems = '?';
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/customAttributes${sortItems}`, {
            params: {
              ...(searchWord ? {q: searchWord} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        })
            .then((response) => {
                const {rows, count} = response.data;
                dispatch(setCompanyAttributes(rows, count));
                //TODO remove or handle in different way
                dispatch(setReportTemplateAttributes(rows));
                callback(response)
            })
            .catch(error => {
                console.log(error)
                dispatch(getCompanyAttributesFailed())
            });
    }
}

export const removeCompanyAttributeStart = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ATTRIBUTES_START
    }
}

export const removeCompanyAttributeEnd = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ATTRIBUTES_END
    }
}

export const removeCompanyAttributeFailed = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ATTRIBUTES_FAILED
    }
}

export const removeCompanyAttribute = (companyId, attributeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeCompanyAttributeStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/customAttributes/${attributeId}`)
        .then((response) => {
            callback(response)
            dispatch(removeCompanyAttributeEnd())
        })
        .catch(error => {
            console.log(error)
            errorCallback(error)
            dispatch(removeCompanyAttributeFailed())
        });
    }
}

export const editCompanyAttributeStart = () => {
    return {
        type: actionTypes.EDIT_COMPANY_ATTRIBUTE_START
    }
}

export const editCompanyAttributeEnd = () => {
    return {
        type: actionTypes.EDIT_COMPANY_ATTRIBUTE_END
    }
}

export const editCompanyAttributeFailed = () => {
    return {
        type: actionTypes.EDIT_COMPANY_ATTRIBUTE_FAILED
    }
}

export const editCompanyAttribute = (companyId, attributeId, name, callback, callbackError) => {
    return dispatch => {
        dispatch(editCompanyAttributeStart())
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/customAttributes/${attributeId}`, {name})
        .then(response => {
            callback(response)
            dispatch(editCompanyAttributeEnd())
        })
        .catch(error => {
            console.error(error)
            callbackError(error)
            dispatch(editCompanyAttributeFailed())
        });
    }
}

export const createCompanyAttributeStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_ATTRIBUTE_START
    }
}

export const createCompanyAttributeEnd = () => {
    return {
        type: actionTypes.CREATE_COMPANY_ATTRIBUTE_END
    }
}

export const createCompanyAttributeFailed = () => {
    return {
        type: actionTypes.CREATE_COMPANY_ATTRIBUTE_FAILED
    }
}

export const createCompanyAttribute = (companyId, name, callback, callbackError) => {
    return dispatch => {
        dispatch(createCompanyAttributeStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/customAttributes`, {name})
        .then(response => {
            callback(response)
            dispatch(createCompanyAttributeEnd())
        })
        .catch(error => {
            console.error(error)
            callbackError(error)
            dispatch(createCompanyAttributeFailed())
        });
    }
}

export const editAttributeOfAssetStart = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_OF_ASSET_START
    }
}

export const editAttributeOfAssetEnd = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_OF_ASSET_END
    }
}

export const editAttributeOfAssetFailed = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_OF_ASSET_FAILED
    }
}

export const editAttributeOfAsset = (assetId, attributeId, value, callback, errorCallback) => {
    return dispatch => {
        dispatch(editAttributeOfAssetStart())
        axios.post(`${Constants.SERVER_URL}/assets/${assetId}/customAttributes/${attributeId}`, {
            value
        })
        .then(response => {
            callback(response)
            dispatch(editAttributeOfAssetEnd())
        })
        .catch(error => {
            console.log(error)
            errorCallback(error)
            dispatch(editAttributeOfAssetFailed())
        });
    }
}

export const editAttributeOfCompanyItemStart = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_OF_COMPANY_ITEM_START
    }
}

export const editAttributeOfCompanyItemEnd = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_OF_COMPANY_ITEM_END
    }
}

export const editAttributeOfCompanyItemFailed = () => {
    return {
        type: actionTypes.EDIT_ATTRIBUTE_OF_COMPANY_ITEM_FAILED
    }
}

export const  editAttributeOfCompanyItem = (companyId, itemId, attributeId, value, callback, errorCallback) => {
    return dispatch => {
        dispatch(editAttributeOfCompanyItemStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/items/${itemId}/customAttributes/${attributeId}`, {
            value
        })
        .then(response => {
            callback(response)
            dispatch(editAttributeOfCompanyItemEnd())
        })
        .catch(error => {
            console.log(error)
            errorCallback(error)
            dispatch(editAttributeOfCompanyItemFailed())
        });
    }
}
