import React, {useEffect, useState} from 'react';
import {MAIN_BACKGROUNDS} from 'Utils/Constants';
import UserUtils from "../Utils/UserUtils";

const MainWrapper = (props) => {
    const location = props.location;
    const [background, setBackground] = useState(MAIN_BACKGROUNDS[0])
    const [tableImg, setTableImg] = useState(MAIN_BACKGROUNDS[0])
    const [imgClassesAddition, setImgClassesAddition] = useState('')

    useEffect(() => {
        if (MAIN_BACKGROUNDS.find(item => item.path === location.pathname)) {
            let background = MAIN_BACKGROUNDS.find(item => item.path === location.pathname)
            setBackground(background.background)
            setTableImg(background.img)
            setImgClassesAddition(background.imgClasses ? background.imgClasses : '')
        }
    }, [location]);

    const isBackgroundValueString = typeof background === 'string';

    return (
        isBackgroundValueString
            ? <div className="wrapper-main-container">
                <div className="main-container" style={{
                    backgroundColor: 'none',
                    backgroundImage: `url(${background})`,
                }}>
                    <div className="wrapper-content-main">
                        {props.children}
                        <div className="mobile-img-wrapper">
                            <img src={tableImg} className={imgClassesAddition}/>
                        </div>
                    </div>
                </div>
            </div>
            : <>
                {props.children}
                {
                    !(UserUtils.isLayoutRoutes(location.pathname) || location.pathname === '/')
                        ? <div className="mobile-img-wrapper">
                            <img src={tableImg} className={imgClassesAddition}/>
                        </div>
                        : null
                }
            </>
    )
}

export default MainWrapper;