import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    placeholders: [],
    placeholdersCount: 0,
    error: false,
    loading: false,
    errorText: '',
    categoryAttributes: [],
    freePlaceholders: [],
    createEditPlaceholder: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PLACEHOLDERS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_PLACEHOLDERS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            };
        case actionTypes.SET_PLACEHOLDERS:
            return {
                ...state,
                placeholders: action.placeholders.rows,
                placeholdersCount: action.placeholders.count,
                error: false,
                loading: false
            };
        case actionTypes.ADD_PLACEHOLDER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_PLACEHOLDER_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                errorText: action.errorText
            };
        case actionTypes.ADD_PLACEHOLDER_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.EDIT_PLACEHOLDER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_PLACEHOLDER_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.REMOVE_PLACEHOLDER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_PLACEHOLDER_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_START:
            return {
                ...state,
                loading: true,
                error: false
            };
        case actionTypes.ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.GET_CATEGORY_CUSTOM_ATTRIBUTE_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.SET_CATEGORY_CUSTOM_ATTRIBUTE:
            return  {
                ...state,
                loading: false,
                error: false,
                categoryAttributes: action.categoryAttributes
            };
        case actionTypes.GET_CATEGORY_CUSTOM_ATTRIBUTE_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_PLACEHOLDER_CATEGORY_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_PLACEHOLDER_CATEGORY_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_PLACEHOLDER_CATEGORY_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_CATEGORIES_TO_PLACEHOLDER_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.ADD_CATEGORIES_TO_PLACEHOLDER_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_CATEGORIES_TO_PLACEHOLDER_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_CATEGORY_PLACEHOLDER_POST_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.SET_CATEGORY_PLACEHOLDER_POST:
            return  {
                ...state,
                loading: false,
                error: false,
                freePlaceholders: action.freePlaceholders
            };
        case actionTypes.GET_CATEGORY_PLACEHOLDER_POST_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REMOVE_COMPANY_ITEM_PLACEHOLDER_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_COMPANY_ITEM_PLACEHOLDER_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REMOVE_COMPANY_ITEM_PLACEHOLDER_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_JOB_PLACEHOLDER_NOTE_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.ADD_JOB_PLACEHOLDER_NOTE_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_JOB_PLACEHOLDER_NOTE_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_JOB_PLACEHOLDER_NOTE_START:
            return  {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_JOB_PLACEHOLDER_NOTE_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.EDIT_JOB_PLACEHOLDER_NOTE_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_CREATE_EDIT_PLACEHOLDERS:
            return  {
                ...state,
                createEditPlaceholder: action.createEditPlaceholder
            };
        default:
            return state;
    }
};

export default reducer;
