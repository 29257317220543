import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';
import generalUtils from "../../Utils/GeneralUtils";

export const addCustomAttributeToCompanyItemStart = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_START
    }
};

export const addCustomAttributeToCompanyItemFinished = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FINISHED
    }
};

export const addCustomAttributeToCompanyItemFailed = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FAILED
    }
};

export const addCustomAttributeToCompanyItem = (companyId, companyItemId, customAttributes, callback) => {
    return dispatch => {
        dispatch(addCustomAttributeToCompanyItemStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/items/${companyItemId}/customAttributes`, customAttributes)
            .then(response => {
                dispatch(addCustomAttributeToCompanyItemFinished());
                callback()
            })
            .catch(error => {
                dispatch(addCustomAttributeToCompanyItemFailed())
            })
    }
};

export const deleteCustomAttributeFromCompanyItemStart = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_START
    }
};

export const deleteCustomAttributeFromCompanyItemFinished = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FINISHED
    }
};

export const deleteCustomAttributeFromCompanyItemFailed = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FAILED
    }
};

export const deleteCustomAttributeFromCompanyItem = (companyId, companyItemId, customAttributeId, value, callback) => {
    return dispatch => {
        dispatch(deleteCustomAttributeFromCompanyItemStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/items/${companyItemId}/customAttributes/${customAttributeId}`, {
            params: {
              ...(value ? {value: value} : {}),
            }
        })
            // .then(respone => {
            //     callback();
            //     dispatch(deleteCustomAttributeFromCompanyItemFinished());
            // })
            // .catch(error => {
            //     dispatch(deleteCustomAttributeFromCompanyItemFailed())
            // })
    }
};

export const addCustomAttributeValueToResellerItemStart = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_START
    }
};

export const addCustomAttributeValueToResellerItemFailed = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FAILED
    }
};

export const addCustomAttributeValueToResellerItemFinished = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FAILED
    }
};

export const addCustomAttributeValueToResellerItem = (resellerId, companyItemId, customAttributes, callback) => {
    return dispatch => {
        dispatch(addCustomAttributeValueToResellerItemStart());
        axios.post(`${Constants.SERVER_URL}/resellers/${resellerId}/items/${companyItemId}/customAttributes`, customAttributes)
            .then(response => {
                dispatch(addCustomAttributeValueToResellerItemFinished());
                callback();
            })
            .catch(error => {
                dispatch(addCustomAttributeValueToResellerItemFailed())
            })
    }
};

export const editResellerCompanyItemStart = () => {
    return {
        type: actionTypes.EDIT_RESELLER_COMPANY_ITEM_START
    }
};

export const editResellerCompanyItemFinished = () => {
    return {
        type: actionTypes.EDIT_RESELLER_COMPANY_ITEM_FINISHED
    }
};

export const editResellerCompanyItemFailed = () => {
    return {
        type: actionTypes.EDIT_RESELLER_COMPANY_ITEM_FAILED
    }
};

export const editResellerCompanyItem = (resellerId, companyItemId, item, callback, errorCallback) => {
    return dispatch => {
        dispatch(editResellerCompanyItemStart());
        axios.patch(`${Constants.SERVER_URL}/resellers/${resellerId}/items/${companyItemId}`, item)
            .then(response => {
                dispatch(editResellerCompanyItemFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                console.log(error);
                if (errorCallback) {
                    errorCallback()
                }
                dispatch(editResellerCompanyItemFailed())
            })
    }
};

export const deleteCustomAttributeFromResellerItemStart = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_START
    }
};

export const deleteCustomAttributeFromResellerItemFinished = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FINISHED
    }
};

export const deleteCustomAttributeFromResellerItemFailed = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FAILED
    }
};

export const deleteCustomAttributeFromResellerItem = (companyId, companyItemId, customAttributeId, value, callback) => {
    return dispatch => {
        dispatch(deleteCustomAttributeFromResellerItemStart());
        axios.delete(`${Constants.SERVER_URL}/resellers/${companyId}/items/${companyItemId}/customAttributes/${customAttributeId}`, {
            params: {
              ...(value ? {value: value} : {}),
            }
        })
            // .then(respone => {
            //     callback();
            //     dispatch(deleteCustomAttributeFromResellerItemFinished());
            // })
            // .catch(error => {
            //     dispatch(deleteCustomAttributeFromResellerItemFailed())
            // })
    }
};

export const addItemsToCompanyRoasterStart = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_START
    }
};

export const addItemsToCompanyRoasterFinished = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_FINISHED
    }
};

export const addItemsToCompanyRoasterFailed = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_FAILED
    }
};

export const addItemsToCompanyRoaster = (companyId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(addItemsToCompanyRoasterStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/items`, items)
            .then(() => {
                if (callback) {
                    callback()
                }
                dispatch(addItemsToCompanyRoasterFinished());
            })
            .catch(error => {
                dispatch(addItemsToCompanyRoasterFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const addItemsToCompanyRoasterAllStart = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_ALL_START
    }
};

export const addItemsToCompanyRoasterAllFinished = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FINISHED
    }
};

export const addItemsToCompanyRoasterAllFailed = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FAILED
    }
};

export const addItemsToCompanyRoasterAll = (companyId, filter, callback, errorCallback) => {
    return dispatch => {
        dispatch(addItemsToCompanyRoasterAllStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/items/filtered`, filter)
            .then(() => {
                if (callback) {
                    callback()
                }
                dispatch(addItemsToCompanyRoasterAllFinished());
            })
            .catch(error => {
                dispatch(addItemsToCompanyRoasterAllFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const deleteCompanyItemImageStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ITEM_IMAGE_START
    }
};

export const deleteCompanyItemImageFinished = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ITEM_IMAGE_FINISHED
    }
};

export const deleteCompanyItemImageFailed = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ITEM_IMAGE_FAILED
    }
};

export const deleteCompanyItemImage = (companyId, itemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCompanyItemImageStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/items/${itemId}/image`)
            .then(() => {
                if (callback) {
                    callback()
                }
                dispatch(deleteCompanyItemImageFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteCompanyItemImageFailed())
            })
    }
}

export const exportItemsToExcelStart = () => {
    return {
        type: actionTypes.EXPORT_ITEMS_TO_EXCEL_START
    }
}

export const setExportItemsUrl = (itemsUrl) => {
    return {
        type: actionTypes.SET_EXPORT_ITEMS_URL,
        exportItemsUrl: itemsUrl
    }
}

export const exportItemsToExcelFailed = () => {
    return {
        type: actionTypes.EXPORT_ITEMS_TO_EXCEL_FAILED
    }
}

export const exportItemsToExcel = (companyId, vendorId, searchWord, callback, errorCallback) => {
    return dispatch => {
        dispatch(exportItemsToExcelStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/export/items`, {
            params: {
              ...(vendorId ? {vendorId} : {}),
              ...(searchWord ? {q: searchWord} : {}),
            }
        })
            .then(response => {
                if (callback) {
                    callback(response.data.url)
                }
                dispatch(setExportItemsUrl(response.data.url))
            })
            .catch(error => {
                console.error(error)
                if (errorCallback) {
                    errorCallback(error.response.data.error);
                }
                dispatch(exportItemsToExcelFailed())
            })
    }
}

export const getCompanyItemsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEMS_START
    }
}

export const setCompanyItems = (companyItems) => {
    return {
        type: actionTypes.SET_COMPANY_ITEMS,
        companyItems: companyItems
    }
}

export const getCompanyItemsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEMS_FAILED
    }
}

export const getCompanyItems = (
    limit,
    offset,
    searchWord,
    packageId,
    packageVersionId,
    vendor,
    companyId,
    isForReseller,
    category,
    categories,
    attributeFilters,
    childCompanyId,
    callback,
    sort,
    isSupported,
    itemIdToShowFirst
) => {
    return dispatch => {
        dispatch(getCompanyItemsStart());
        let sortItems = ''

        if (sort && sort.length > 0) {

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }

        let query = '?limit=' + limit +'&offset=' + offset;
        if (searchWord !== '') {
            query += '&q=' + searchWord;
        }
        let v = '';
        if (vendor !== '') {
            v += '&vendorId=' + vendor
        }
        if (category) {
            v+= ('&categoryId=' + category)
        }
        let child = '';
        if (childCompanyId) {
            child += '&companyId=' + childCompanyId
        }
        if (packageId) {
            query +='&packageToExclude=' + packageId
        }
        if (packageVersionId) {
            query +='&packageToExcludeVersion=' + packageVersionId
        }
        if (categories && categories.length > 0) {
            for (const category of categories) {
                query += '&categoryIds[]=' + category.id;
            }
        }
        if (isSupported !== undefined) {
            query +='&isSupported=' + isSupported
        }
        if (itemIdToShowFirst) {
            query +='&itemIdToShowFirst=' + itemIdToShowFirst
        }
        if (isForReseller) {
            axios.post(Constants.SERVER_URL + '/resellers/' + companyId + '/items/get' + query + v + child + sortItems,
            attributeFilters.length > 0 ?
                {
                    attrs: attributeFilters
                } : null)
                .then(response => {
                    if (callback) {
                        callback(response.data)
                    }
                    dispatch(setCompanyItems(response.data))
                })
                .catch(error => {
                    dispatch(getCompanyItemsFailed())
                })
        } else {
            axios.post(Constants.SERVER_URL + '/companies/' + companyId + '/items/get' + query + v + sortItems,
                attributeFilters.length > 0 ?
                {
                    attrs: attributeFilters
                } : null)
                .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setCompanyItems(response.data.rows))
            })
                .catch(error => {
                    dispatch(getCompanyItemsFailed())
                })
        }
    }
}

export const getCompanyItemsStatusByFilterStart = () => {
    return {
        type: actionTypes.GET_ITEMS_STATUS_BY_FILTER_START
    }
}

export const setCompanyItemsStatusByFilter = (status) => {
    return {
        companyItemsStatus: status.total === status.added,
        type: actionTypes.SET_ITEMS_STATUS_BY_FILTER
    }
}

export const getCompanyItemsStatusByFilterFailed = () => {
    return {
        type: actionTypes.GET_ITEMS_STATUS_BY_FILTER_START
    }
}

export const getCompanyItemsStatusByFilter = (companyId, filter, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyItemsStatusByFilterStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/items/filtered/status/get/`, {
            filter: filter
        })
            .then(response => {
                dispatch(setCompanyItemsStatusByFilter(response.data))
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getCompanyItemsStatusByFilterFailed())
                errorCallback(error.response.data.error)
            })
    }
}

export const getCompanyItemsExportStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEMS_EXPORT_START
    }
}

export const setCompanyItemsExport = (companyItemsExport) => {
    return {
        type: actionTypes.SET_COMPANY_ITEMS_EXPORT,
        companyItemsExport: companyItemsExport
    }
}

export const getCompanyItemsExportFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_ITEMS_EXPORT_FAILED
    }
}

export const getCompanyItemsExport = (size, page, searchWord, filter, vendor, companyId, packageId, callback) => {

    return dispatch => {
        dispatch(getCompanyItemsExportStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/items/`, {
            params: {
              ...({limit: size}),
              ...(size > 1000 ? {offset: page*size} : {offset: '0'}),
              ...(searchWord !== '' ? {q: searchWord} : {}),
              ...(vendor !== '' ? {vendorId: vendor} : {}),
              ...(packageId !== '' ? {packageToExclude: packageId} : {}),
            }
        })
        .then(response => {
            dispatch(setCompanyItemsExport(response.data.rows))
            callback(response)
        })
        .catch(error => {
            console.log(error)
            dispatch(getCompanyItemsExportFailed())
        })
    }

}

export const getCompanyJobsItemsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_JOBS_ITEMS_START
    }
}

export const setCompanyJobsItems = ({rows, count}) => {
    return {
        type: actionTypes.SET_COMPANY_JOBS_ITEMS,
        companyJobsItems: rows,
        companyJobsItemsCount: count
    }
}

export const getCompanyJobsItemsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_JOBS_ITEMS_FAILED
    }
}

export const getCompanyJobsItems = (companyId, searchWord, limit, offset, status, callback, errorCallback, sort) => {
    return dispatch => {
        dispatch(getCompanyJobsItemsStart())
        let sortItems = '';

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs${sortItems}`, {
            params: {
              ...(searchWord ? {q: searchWord} : {}),
                ...(limit ? {limit: limit} : {}),
                ...(offset ? {offset: offset} : {}),
                ...(status ? {status: status} : {})
            }
        })
        .then(response => {
            dispatch(setCompanyJobsItems(response.data))
            if(callback) {
                callback(response)
            }
        })
        .catch(error => {
            const errorData = error.response ? error.response.data.error : error;
            console.log(errorData)
            errorCallback(errorData)
            dispatch(getCompanyJobsItemsFailed())
        })
    }

}

export const getCompanyCategoriesItemsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_CATEGORIES_ITEMS_START
    }
}

export const setCompanyCategoriesItems = ({rows, count}) => {
    return {
        type: actionTypes.SET_COMPANY_CATEGORIES_ITEMS,
        categories: rows,
        categoriesTotalItems: count
    }
}

export const getCompanyCategoriesItemsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_CATEGORIES_ITEMS_FAILED
    }
}

export const getCompanyCategoriesItems = (companyId, vendorId, searchWord, attributeQuery, callback, errorCallback, page, size, sort) => {
    return dispatch => {
        dispatch(getCompanyCategoriesItemsStart())
        let sortItems = '';
        if (sort && sort.length > 0) {
            sortItems = '?';
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/categories${sortItems}`, {
            params: {
              ...(vendorId ? {vendorId} : {}),
              ...(searchWord ? {q: searchWord} : {}),
              ...(attributeQuery ? attributeQuery : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        })
        .then(response => {
            callback(response)
            dispatch(setCompanyCategoriesItems(response.data))
        })
        .catch(error => {
            console.log(error)
            dispatch(getCompanyCategoriesItemsFailed())
            errorCallback(error)
        })

    }
}

export const getCategoriesItemsSuperAdmin = (vendorId, searchWord, attributeQuery, callback, errorCallback, page, size, sort) => {
    return dispatch => {
        dispatch(getCompanyCategoriesItemsStart())
        let sortItems = '';
        if (sort && sort.length > 0) {
            sortItems = '?';
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/categories${sortItems}`, {
            params: {
                ...(vendorId ? {vendorId} : {}),
                ...(searchWord ? {q: searchWord} : {}),
                ...(attributeQuery ? attributeQuery : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        })
            .then(response => {
                callback(response)
                dispatch(setCompanyCategoriesItems(response.data))
            })
            .catch(error => {
                console.log(error)
                dispatch(getCompanyCategoriesItemsFailed())
                errorCallback(error)
            })

    }
}
export const getAnalyticsCompanyItems = (companyId, errorCallback) => {
    return dispatch => {
        dispatch(getAnalyticsCompanyItemsStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/analytics/items`)
            .then(response => {
                dispatch(setAnalyticsCompanyItems(response.data))
            })
            .catch(error => {
                const errorText = error.response ? error.response.data.error : error;
                console.log(errorText)
                dispatch(getAnalyticsCompanyItemsFailed())
                errorCallback && errorCallback(errorText)
            })

    }
}

export const getAnalyticsCompanyItemsStart = () => {
    return {
        type: actionTypes.GET_ANALYTICS_COMPANY_ITEMS_START
    }
}

export const setAnalyticsCompanyItems = analyticsCompanyData => {
    return {
        type: actionTypes.GET_ANALYTICS_COMPANY_ITEMS_END,
        analyticsCompanyData
    }
}

export const getAnalyticsCompanyItemsFailed = () => {
    return {
        type: actionTypes.GET_ANALYTICS_COMPANY_ITEMS_FAILED
    }
}

export const getCompanyAssetsCreateRequestsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ASSETS_CREATE_REQUESTS_START
    }
}

export const setCompanyAssetsCreateRequests = (data) => {
    return {
        type: actionTypes.GET_COMPANY_ASSETS_CREATE_REQUESTS_END,
        companyAssetsCreateRequestsItems: data.rows,
        companyAssetsCreateRequestsItemsCount: data.count
    }
}

export const getCompanyAssetsCreateRequestsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_ASSETS_CREATE_REQUESTS_FAILED
    }
}

export const getCompanyAssetsCreateRequestsItems = (page, size, searchWord, status, sort, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyAssetsCreateRequestsStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/assetCreateRequests${sortItems}`, {
            params: {
                ...(searchWord ? {q: searchWord} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
                ...(status ? {status: status} : {})
            }
        })
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setCompanyAssetsCreateRequests(response.data))
            })
            .catch(error => {
                const getError = error.response ? error.response.data.error : error;
                console.log(error)
                if(errorCallback) {
                    errorCallback(getError)
                }
                dispatch(getCompanyAssetsCreateRequestsFailed())
            })
    }
}

export const createNewAssetRequestStart = () => {
    return {
        type: actionTypes.CREATE_NEW_ASSET_REQUEST_START
    }
}

export const createNewAssetRequestEnd = () => {
    return {
        type: actionTypes.CREATE_NEW_ASSET_REQUEST_END
    }
}

export const createNewAssetRequestFailed = () => {
    return {
        type: actionTypes.CREATE_NEW_ASSET_REQUEST_FAILED
    }
}

export const createNewAssetRequest = (companyId, asset, callback, errorCallback) => {
    return dispatch => {
        dispatch(createNewAssetRequestStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/assetCreateRequests`, asset)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(createNewAssetRequestEnd())
            })
            .catch(error => {
                const getError = error.response ? (error.response.data.messageStyled ? error.response.data.messageStyled : error.response.data.error) : error;
                if(errorCallback) {
                    errorCallback(getError)
                }
                dispatch(createNewAssetRequestFailed())
            })
    }
}

export const uploadNewAssetRequestImageStart = () => {
    return {
        type: actionTypes.UPLOAD_NEW_ASSET_REQUEST_IMAGE_START
    }
}

export const uploadNewAssetRequestImageEnd = () => {
    return {
        type: actionTypes.UPLOAD_NEW_ASSET_REQUEST_IMAGE_END
    }
}

export const uploadNewAssetRequestImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_NEW_ASSET_REQUEST_IMAGE_FAILED
    }
}

export const uploadNewAssetRequestImage = (companyId, requestId, data, callback, errorCallback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch(uploadNewAssetRequestImageStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/assetCreateRequests/${requestId}/image`, formData, config)
            .then(response => {
                if(callback) {
                    callback(response)
                }
                dispatch(uploadNewAssetRequestImageEnd())
            })
            .catch(error => {
                const getError = error.response ? error.response.data.error : error;
                console.log(getError)
                if(errorCallback) {
                    errorCallback(getError)
                }
                dispatch(uploadNewAssetRequestImageFailed())
            });
    }
}

export const updateAssetRequestStart = () => {
    return {
        type: actionTypes.UPDATE_NEW_ASSET_REQUEST_IMAGE_START
    }
}

export const updateAssetRequestEnd = () => {
    return {
        type: actionTypes.UPDATE_NEW_ASSET_REQUEST_IMAGE_END
    }
}

export const updateAssetRequestFailed = () => {
    return {
        type: actionTypes.UPDATE_NEW_ASSET_REQUEST_IMAGE_FAILED
    }
}

export const updateAssetRequest = (companyId, asset, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(updateAssetRequestStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/assetCreateRequests/${requestId}`, asset)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(updateAssetRequestEnd())
            })
            .catch(error => {
                const getError = error.response ? error.response.data.error : error;
                console.log(error)
                if(errorCallback) {
                    errorCallback(getError)
                }
                dispatch(updateAssetRequestFailed())
            })
    }
}

export const deleteCompanyAssetsCreateRequestStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ASSETS_CREATE_REQUEST_START
    }
}

export const deleteCompanyAssetsCreateRequestEnd = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ASSETS_CREATE_REQUEST_END
    }
}

export const deleteCompanyAssetsCreateRequestFailed = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ASSETS_CREATE_REQUEST_FAILED
    }
}

export const cancelCompanyAssetsCreateRequest = (companyId, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCompanyAssetsCreateRequestStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/assetCreateRequests/${requestId}/cancel`)
            .then(() => {
                if (callback) {
                    callback()
                }
                dispatch(deleteCompanyAssetsCreateRequestEnd())
            })
            .catch(error => {
                const getError = error.response ? error.response.data.error : error;
                if(errorCallback) {
                    errorCallback(getError)
                }
                dispatch(deleteCompanyAssetsCreateRequestFailed())
            })
    }
}

export const getAssetsCreateRequestsSAStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_START
    }
}

export const setAssetsCreateRequestsSARequests = (data) => {
    return {
        type: actionTypes.GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_END,
        companyAssetsCreateRequestsSAItems: data.rows,
        companyAssetsCreateRequestsSAItemsCount: data.count
    }
}

export const getAssetsCreateRequestsSAFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_FAILED
    }
}

export const getAssetsCreateRequestsSA = (page, size, searchWord, status, sort, callback, errorCallback) => {
    return dispatch => {
        dispatch(getAssetsCreateRequestsSAStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/assetCreateRequests${sortItems}`, {
            params: {
                ...(searchWord ? {q: searchWord} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
                ...(status ? {status: status} : {})
            }
        })
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setAssetsCreateRequestsSARequests(response.data))
            })
            .catch(error => {
                const getError = error.response ? error.response.data.error : error;
                console.log(error)
                if (errorCallback) {
                    errorCallback(getError)
                }
                dispatch(getAssetsCreateRequestsSAFailed())
            })
    }
}

export const approveAssetCreateRequestStart = () => {
    return {
        type: actionTypes.APPROVE_COMPANY_ASSETS_CREATE_REQUEST_START
    }
}

export const approveAssetCreateRequestEnd = () => {
    return {
        type: actionTypes.APPROVE_COMPANY_ASSETS_CREATE_REQUEST_END
    }
}

export const approveAssetCreateRequestFailed = () => {
    return {
        type: actionTypes.APPROVE_COMPANY_ASSETS_CREATE_REQUEST_FAILED
    }
}

export const approveAssetCreateRequest = (companyId, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(approveAssetCreateRequestStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/assetCreateRequests/${requestId}/approve`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(approveAssetCreateRequestEnd())
            })
            .catch(error => {
                const getError = error.response
                    ? (error.response.data.messageStyled ? error.response.data.messageStyled : error.response.data.error)
                    : error;
                if (errorCallback) {
                    errorCallback(getError)
                }
                dispatch(approveAssetCreateRequestFailed())
            })
    }
}

export const rejectAssetCreateRequestStart = () => {
    return {
        type: actionTypes.REJECT_ASSET_CREATE_REQUEST_START
    }
}

export const rejectAssetCreateRequestEnd = () => {
    return {
        type: actionTypes.REJECT_ASSET_CREATE_REQUEST_END
    }
}

export const rejectAssetCreateRequestFailed = () => {
    return {
        type: actionTypes.REJECT_ASSET_CREATE_REQUEST_FAILED
    }
}

export const rejectAssetCreateRequest = (companyId, reason, requestId, callback, errorCallback) => {
    return dispatch => {
        dispatch(rejectAssetCreateRequestStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/assetCreateRequests/${requestId}/reject`, reason)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(rejectAssetCreateRequestEnd())
            })
            .catch(error => {
                const getError = error.response ? error.response.data.error : error;
                console.log(error)
                if (errorCallback) {
                    errorCallback(getError)
                }
                dispatch(rejectAssetCreateRequestFailed())
            })
    }
}

export const getInfoWhereUseItemStart = () => {
    return {
        type: actionTypes.GET_INFO_WHERE_USE_ITEM_START
    }
}

export const getInfoWhereUseItemEnd = () => {
    return {
        type: actionTypes.GET_INFO_WHERE_USE_ITEM_END
    }
}

export const getInfoWhereUseItemFailed = () => {
    return {
        type: actionTypes.GET_INFO_WHERE_USE_ITEM_FAILED
    }
}

export const getInfoWhereUseItem = (companyId, itemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getInfoWhereUseItemStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/items/${itemId}/use`)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(getInfoWhereUseItemEnd())
            })
            .catch(error => {
                const getError = error.response ? error.response.data.error : error;
                console.log(error)
                if (errorCallback) {
                    errorCallback(getError)
                }
                dispatch(getInfoWhereUseItemFailed())
            })
    }
}