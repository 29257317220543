import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    loading: false,
    error: false,
    assetCustomAttributes: [],
    selectedAsset: null,
    pagedItems: new Array(0),
    totalItems: 0,
    chosenAssetId: null,
    asset: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_FINISHED:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FINISHED:
            return  {
                ...state,
                loading: false,
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_ASSET_CUSTOM_ATTRIBUTES_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_ASSET_CUSTOM_ATTRIBUTES:
            return  {
                ...state,
                assetCustomAttributes: action.assetCustomAttributes,
                loading: false,
                error: false
            };
        case actionTypes.GET_ASSET_CUSTOM_ATTRIBUTES_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REMOVE_ASSET_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_ASSET_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.REMOVE_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPDATE_ASSET_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPDATE_ASSET_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.UPDATE_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            }; 
        case actionTypes.CREATE_ASSET_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CREATE_ASSET_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.CREATE_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPLOAD_ASSET_IMAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPLOAD_ASSET_IMAGE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.UPLOAD_ASSET_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_ASSETS:
            return {
                ...state,
                pagedItems: action.pagedItems,
                totalItems: action.totalItems
            };                                 
        case actionTypes.SET_ASSETS_PAGE_ITEMS_EMPTY:
            return {
                ...state,
                pagedItems: new Array(0)
            }
        case actionTypes.CREATE_NEW_ASSET_REQUEST_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CREATE_NEW_ASSET_REQUEST_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.CREATE_NEW_ASSET_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPLOAD_NEW_ASSET_REQUEST_IMAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPLOAD_NEW_ASSET_REQUEST_IMAGE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.UPLOAD_NEW_ASSET_REQUEST_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPDATE_NEW_ASSET_REQUEST_IMAGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPDATE_NEW_ASSET_REQUEST_IMAGE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.UPDATE_NEW_ASSET_REQUEST_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_CHOSEN_ASSET_ID:
            return {
                ...state,
                chosenAssetId: action.chosenAssetId
            };
        case actionTypes.GET_ASSET_START:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.SET_ASSET:
            return {
                ...state,
                asset: action.asset,
                loading: false,
            }
        case actionTypes.GET_ASSET_FAILED:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
};

export default reducer
