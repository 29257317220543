import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    reportTemplates: [],
    variationReports: [],
    currentReportTemplate: null,
    error: false,
    loading: false,
    loadingAfterDragging: false,
    reportTemplateAttributes: [],
    analyticalReport: null,
    reportTemplateForEdit: null,
    reportTemplateFormValues: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_REPORT_TEMPLATE_START:
            return {
                ...state,
                loading: true,
                error: false
            };
        case actionTypes.ADD_REPORT_TEMPLATE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_REPORT_TEMPLATE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_REPORT_TEMPLATE_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.GET_REPORT_TEMPLATES_START:
            return {
                ...state,
                reportTemplates: [],
                loading: true
            };
        case actionTypes.SET_REPORT_TEMPLATES:
            return {
                ...state,
                reportTemplates: action.reportTemplates,
                error: false,
                loading: false
            };
        case actionTypes.GET_REPORT_TEMPLATES_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            };
        case actionTypes.GET_REPORT_TEMPLATE_BY_ID_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_REPORT_TEMPLATE_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_REPORT_TEMPLATE_BY_ID:
            return {
                ...state,
                loading: false,
                error: false,
                currentReportTemplate: action.reportTemplate
            };
        case actionTypes.GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_START:
            return {
                ...state,
                loadingAfterDragging: true
            };
        case actionTypes.GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_FAILED:
            return {
                ...state,
                loadingAfterDragging: false,
                error: true
            };
        case actionTypes.SET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING:
            return {
                ...state,
                loadingAfterDragging: false,
                error: false,
                currentReportTemplate: action.reportTemplate
            };
        case actionTypes.REMOVE_REPORT_TEMPLATE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_REPORT_TEMPLATE_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.ADD_REPORT_TEMPLATE_HEADER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_REPORT_TEMPLATE_HEADER_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.EDIT_REPORT_TEMPLATE_HEADER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_REPORT_TEMPLATE_HEADER_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.REMOVE_REPORT_TEMPLATE_HEADER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_REPORT_TEMPLATE_HEADER_FINISHED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.DRAG_AND_DROP_REPORT_TEMPLATE_START:
            return {
                ...state,
                loadingAfterDragging: true
            };
        case actionTypes.DRAG_AND_DROP_REPORT_TEMPLATE_SUCCESS:
            return {
                ...state,
                loadingAfterDragging: false
            };
        case actionTypes.DRAG_AND_DROP_REPORT_TEMPLATE_FAILED:
            return {
                ...state,
                loadingAfterDragging: false,
                error: true
            };
        case actionTypes.CREATE_VARIATION_REPORT_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CREATE_VARIATION_REPORT_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.CREATE_VARIATION_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_VARIATION_REPORT_RULE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_VARIATION_REPORT_RULE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_VARIATION_REPORT_RULE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_VARIATION_REPORTS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_VARIATION_REPORTS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.SET_VARIATION_REPORTS:
            return {
                ...state,
                loading: false,
                variationReports: action.variationReports
            };
        case actionTypes.EDIT_VARIATION_REPORT_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_VARIATION_REPORT_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_VARIATION_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_VARIATION_REPORT_RULE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_VARIATION_REPORT_RULE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_VARIATION_REPORT_RULE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_VARIATION_REPORT_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_VARIATION_REPORT_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_VARIATION_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_VARIATION_REPORT_HEADERS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_VARIATION_REPORT_HEADERS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.SET_VARIATION_REPORT_HEADERS:
            return {
                ...state,
                loading: false,
                variationReports: action.variationReports
            };
        case actionTypes.GET_TEMPLATE_ATTRIBUTES_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_TEMPLATE_ATTRIBUTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.SET_TEMPLATE_ATTRIBUTES:
            return {
                ...state,
                loading: false,
                reportTemplateAttributes: action.reportTemplateAttributes
            };
        case actionTypes.GET_RESELLER_ANALYTICAL_REPORT_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_RESELLER_ANALYTICAL_REPORT_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.SET_RESELLER_ANALYTICAL_REPORT:
            return {
                ...state,
                loading: false,
                analyticalReport: action.analyticalReport
            };
        case actionTypes.ON_SET_REPORT_TEMPLATE_FOR_EDIT:
            return {
                ...state,
                reportTemplateForEdit: action.reportTemplateForEdit
            };
        case actionTypes.ON_SET_REPORT_TEMPLATE_FOR_COPY:
            return {
                ...state,
                reportTemplateForCopy: action.reportTemplateForCopy
            };
        case actionTypes.SET_REPORT_TEMPLATES_CR_FIL_DATA:
            return {
                ...state,
                reportTemplateFormValues: action.reportTemplateFormValues
            };
        default:
            return state;
    }
};

export default reducer;
