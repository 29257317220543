import * as Constants from './Constants';

export default class GeneralUtils {

    static buildSortUrlParams(sort) {
        let sortItems = '';
        if (sort && sort.length > 0) {
            sortItems = '?';
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        return sortItems;
    }

    static getLicenseByIdentifier = identifier => {
        return Constants.CompanyLicenseItems.find(l => {return l.identifier === identifier});
    }

    static splitAttributeValues = newValue => {
        let splitValues = newValue.split(',');
        // old logic
        // format values properly - Value
        // splitValues = splitValues.map( v => {
        //     return buildAttributeWord(v);
        // });

        // function buildAttributeWord(w) {
        //     let original = w.trim().toLowerCase();
        //     let parts = original.split(" ");
        //     let res = '';
        //     for (let i = 0; i < parts.length; i++) {
        //         let lc = parts[i];
        //         res += lc.length === 0 ? lc : (lc[0].toUpperCase() + (lc.length > 1 ? lc.substring(1) : '')) + " "
        //     }
        //     return res.trim();
        // }

        return splitValues;
    }

}
