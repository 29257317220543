import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    loading: false,
    error: false,
    attributes: new Array(0),
    companyAttributes: Array(0),
    attributeItems: Array(0),
    attributeItemsTotalItems: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ATTRIBUTES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_ATTRIBUTES:
            return {
                ...state,
                loading: false,
                attributeItems: action.attributeItems,
                attributeItemsTotalItems: action.attributeItemsTotalItems
            };
        case actionTypes.GET_ATTRIBUTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_CATEGORY_ATTRIBUTES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_CATEGORY_ATTRIBUTES:
            return {
                ...state,
                loading: false,
                companyAttributes: action.attributes
            };
        case actionTypes.GET_CATEGORY_ATTRIBUTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REMOVE_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_ATTRIBUTE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.REMOVE_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_ATTRIBUTE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.EDIT_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.CREATE_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CREATE_ATTRIBUTE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.CREATE_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_COMPANY_ATTRIBUTE:
            return {
                ...state,
                loading: false,
                attributes: action.attributes,
                attributeItemsTotalItems: action.attributeItemsTotalItems
            };
        case actionTypes.GET_COMPANY_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REMOVE_COMPANY_ATTRIBUTES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_COMPANY_ATTRIBUTES_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.REMOVE_COMPANY_ATTRIBUTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_COMPANY_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_COMPANY_ATTRIBUTE_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.EDIT_COMPANY_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.CREATE_COMPANY_ATTRIBUTE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CREATE_COMPANY_ATTRIBUTE_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.CREATE_COMPANY_ATTRIBUTE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_ATTRIBUTE_OF_ASSET_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_ATTRIBUTE_OF_ASSET_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.EDIT_ATTRIBUTE_OF_ASSET_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_ATTRIBUTE_OF_COMPANY_ITEM_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_ATTRIBUTE_OF_COMPANY_ITEM_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.EDIT_ATTRIBUTE_OF_COMPANY_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };      
        default:
            return state;
    }
};

export default reducer