import * as actionTypes from "./actionTypes";
import axios from "axios";
import * as Constants from "../../Utils/Constants";
import {setCategoryAttributes} from "./attributes";

export const getCompanyItemsSelectionTypeStart = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_START
    }
};

export const getCompanyItemsSelectionTypeCategoriesStart = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_ITEMS_CATEGORIES_START
    }
};

export const getCompanyItemsSelectionTypeVendorsStart = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_ITEMS_VENDORS_START
    }
};

export const getCompanyItemsSelectionTypeAttributesStart = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_ITEMS_ATTRIBUTES_START
    }
};


export const setCompanyItemsSelectionType = (companyItems) => {
    return {
        type: actionTypes.SET_SELECTION_TYPE_ITEMS,
        companyItemsSelectionType: companyItems
    }
};

export const setCompanyItemsSelectionTypeCategories = (categories) => {
    return {
        type: actionTypes.SET_SELECTION_TYPE_ITEMS_CATEGORIES,
        selectionTypeCategories: categories
    }
};

export const setCompanyItemsSelectionTypeVendors = (vendors) => {
    return {
        type: actionTypes.SET_SELECTION_TYPE_ITEMS_VENDORS,
        selectionTypeVendors: vendors
    }
};


export const getCompanyItemsFailedSelectionType = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_FAILED
    }
};

export const getCompanyItemsSelectionTypeCategoriesFailed = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_ITEMS_CATEGORIES_FAILED,
    }
};

export const getCompanyItemsSelectionTypeVendorsFailed = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_ITEMS_VENDORS_FAILED,
    }
};

export const getCompanyItemsSelectionTypeAttributesFailed = () => {
    return {
        type: actionTypes.GET_SELECTION_TYPE_ITEMS_ATTRIBUTES_FAILED,
    }
};

export const removeSelectionTypeFromReportTemplateStart = () => {
    return {
        type: actionTypes.REMOVE_SELECTION_TYPE_FROM_REPORT_TEMPLATE_START
    }
};

export const removeSelectionTypeFromReportTemplateFinished = () => {
    return {
        type: actionTypes.REMOVE_SELECTION_TYPE_FROM_REPORT_TEMPLATE_FINISHED
    }
};

export const onCreateSelectionType = (companyId, data, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes`, data)
            .then(response => {
                if (callback) callback(response);
            })
            .catch(error => errorCallback(error.response.data.error))
    }
};

export const onEditSelectionType = (companyId, selectionTypeId, data, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}`, data)
            .then(response => {
                if (callback) callback(response)
            })
            .catch(error => {
                if (errorCallback) errorCallback(error.response.data.error)
            })
    }
};

export const getCompanyItemsSelectionType = (
    limit,
    offset,
    searchWord,
    vendor,
    companyId,
    selectionTypeId,
    category,
    categories,
    attributeFilters,
    callback,
    packageIdToExclude,
    packageToExcludeVersion,
    itemIdToShowFirst
) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeStart());

        let query = '?limit=' + limit +'&offset=' + offset;
        if (searchWord !== '') {
            query += '&q=' + searchWord;
        }
        let v = '';
        if (vendor !== '') {
            v += '&vendorId=' + vendor
        }
        if (category) {
            v+= ('&categoryId=' + category)
        }
        if (packageIdToExclude) {
            v+= ('&packageIdToExclude=' + packageIdToExclude)
            v+= ('&packageToExcludeVersion=' + packageToExcludeVersion)

        }
        if (categories && categories.length > 0) {
            for (const category of categories) {
                query += '&categoryIds[]=' + category.id;
            }
        }
        if (itemIdToShowFirst) {
            query +='&itemIdToShowFirst=' + itemIdToShowFirst
        }

        axios.post(Constants.SERVER_URL + '/companies/' + companyId + '/selectionTypes/' + selectionTypeId + '/items/get' + query + v,
            attributeFilters.length > 0 ?
                {
                    attrs: attributeFilters
                } : null)
            .then(response => {
                if (callback) callback(response.data);
                dispatch(setCompanyItemsSelectionType(response.data.rows))
            })
            .catch(error => dispatch(getCompanyItemsFailedSelectionType()))
    }
}

export const addCompanyItemToSelectionTypes = (companyId, selectionTypeId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items`, {items})
            .then(response => {
                if (callback) callback(response);
            })
            .catch(error => errorCallback(error.response.data.error))
    }
};

export const onEditSelectedItemsToSelectionType = (companyId, selectionTypeId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items`, {items})
            .then(response => {
                if (callback) callback(response);
            })
            .catch(error => errorCallback(error.response.data.error))
    }
};

export const onRemoveItemsFromSelectionType = (companyId, selectionTypeId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items`, {data: {items}})
            .then(response => {
                if (callback) callback(response);
            })
            .catch(error => errorCallback(error.response.data.error))
    }
};

export const getSelectionTypeCategories = (companyId, selectionTypeId, vendorId, searchWord, attributeQuery, itemsSearchWord, callback, errorCallback, limit) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeCategoriesStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items/categories/get`, attributeQuery.length > 0 ?
            {
                attrs: attributeQuery
            } : null,
            {
                params: {
                    ...(limit ? {limit} : {limit: '1000'}),
                    ...(vendorId ? {vendorId} : {}),
                    ...(searchWord ? {q: searchWord} : {}),
                    ...(itemsSearchWord ? {itemsQ: itemsSearchWord} : {}),
                }
            })
            .then(response => {
                response.data.rows.unshift({
                    name: 'All Categories',
                    id: null
                });
                if (callback) {
                    callback(response.data)
                }
                dispatch(setCompanyItemsSelectionTypeCategories(response.data.rows))
            })
            .catch(error => {
                dispatch(getCompanyItemsSelectionTypeCategoriesFailed())
            })
    }
};

export const getSelectionTypeVendors = (companyId, selectionTypeId, categoryId, categories, searchWord, attributeQuery, itemsSearchWord, callback, errorCallback, limit) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeVendorsStart());
        let q = '';
        if (categoryId) {
            q = '&categoryId=' + categoryId
        }
        if (categories && categories.length > 0) {
            for (const category of categories) {
                q += '&categoryIds[]=' + category.id
            }
        }
        if (searchWord) {
            q += ('&q=' + searchWord)
        }
        if (itemsSearchWord) {
            q += ('&itemsQ=' + itemsSearchWord)
        }
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items/vendors/get?limit=1000${q}`, attributeQuery.length > 0 ?
            {
                attrs: attributeQuery
            } : null)
            .then(response => {
                response.data.rows.unshift({
                    name: 'All Vendors',
                    id: null
                });
                if (callback) {
                    callback(response.data)
                }
                dispatch(setCompanyItemsSelectionTypeVendors(response.data.rows))
            })
            .catch(error => {
                dispatch(getCompanyItemsSelectionTypeVendorsFailed())
            })
    }
};

export const getSelectionTypeAttributes = (companyId, selectionTypeId, vendorId, categoryId, searchWord, attributeQuery, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyItemsSelectionTypeAttributesStart());
        let q = '';
        if (vendorId) {
            q += '&vendorId=' + vendorId
        }
        if (categoryId) {
            q += '&categoryId=' + categoryId
        }
        if (searchWord) {
            q+= '&q=' + searchWord
        }
        if (q) {
            q = q.replace('&', '?')
        }
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items/customAttributes/get${q}`, attributeQuery && attributeQuery.length !== 0 ? {
            attrs: attributeQuery
        } : null)
            .then(response => {
                if (callback) {
                    dispatch(setCategoryAttributes(response.data.rows));
                    callback(response.data.rows);
                }
            })
            .catch(error => {
                dispatch(getCompanyItemsSelectionTypeAttributesFailed())
            })
    }
};

export const removeSelectionTypeFromReportTemplate = (companyId, reportTemplateId, headerId, selectionTypeId, callback) => {
    return dispatch => {
        dispatch(removeSelectionTypeFromReportTemplateStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/headers/${headerId}/selectionTypes/${selectionTypeId}`)
            .then(response => {
                callback();
                dispatch(removeSelectionTypeFromReportTemplateFinished())
            })
            .catch(error => {
                dispatch(removeSelectionTypeFromReportTemplateFinished())
            })
    }
};

export const getFiltersVendorsAttributes = (
  companyId,
  selectionTypeId,
  categoryId,
  vendorId,
  attrs,
  q,
  callback
  ) => {
    return dispatch => {
        // todo simplify to one action
        dispatch(getCompanyItemsSelectionTypeCategoriesStart());
        dispatch(getCompanyItemsSelectionTypeVendorsStart());
        dispatch(getCompanyItemsSelectionTypeAttributesStart());
        const data = {
            categoryId,
            vendorId,
            attrs,
            q,
        }
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items/filters/get`, data)
          .then(response => {
              const {categories, vendors, attributes} = response.data
              categories.rows.unshift({
                  name: 'All Categories',
                  id: null
              });
              vendors.rows.unshift({
                  name: 'All Vendors',
                  id: null
              });

              // todo simplify to one action
              dispatch(setCompanyItemsSelectionTypeCategories(categories.rows))
              dispatch(setCompanyItemsSelectionTypeVendors(vendors.rows))
              dispatch(setCategoryAttributes(attributes.rows))
              callback(response.data)
          })
          .catch(e => {
              // todo simplify to one action
              dispatch(getCompanyItemsSelectionTypeCategoriesFailed())
              dispatch(getCompanyItemsSelectionTypeVendorsFailed())
              dispatch(getCompanyItemsSelectionTypeAttributesFailed())
          })
    }
}
