import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    loading: false,
    error: false,
    colorSchemes: [],
    colorScheme: null,
    colorSchemeItems: [],
    virtualBoardItems: [],
    colorSchemesTotalItems: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_COLOR_SCHEME_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_COLOR_SCHEME_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_COLOR_SCHEME_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_COLOR_SCHEME_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_COLOR_SCHEME_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_COLOR_SCHEME_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_COLOR_SCHEME_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_COLOR_SCHEME_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_COLOR_SCHEME_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COLOR_SCHEMES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_COLOR_SCHEMES:
            return {
                ...state,
                loading: false,
                error: false,
                colorSchemes: action.colorSchemes
            };
        case actionTypes.SET_COLOR_SCHEMES_ALL:
            return {
                ...state,
                loading: false,
                error: false,
                colorSchemes: action.colorSchemes,
                colorSchemesTotalItems: action.colorSchemesTotalItems
            };
        case actionTypes.GET_COLOR_SCHEMES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COLOR_SCHEME_BY_ID_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_COLOR_SCHEME_BY_ID:
            return {
                ...state,
                loading: false,
                error: false,
                colorScheme: action.colorScheme
            };
        case actionTypes.GET_COLOR_SCHEME_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_ITEMS_TO_COLOR_SCHEME_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_ITEMS_TO_COLOR_SCHEME_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_ITEMS_TO_COLOR_SCHEME_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_ITEMS_FROM_COLOR_SCHEME_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_ITEMS_FROM_COLOR_SCHEME_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_ITEMS_FROM_COLOR_SCHEME_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COLOR_SCHEME_ITEMS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_COLOR_SCHEME_ITEMS:
            return {
                ...state,
                loading: false,
                error: false,
                colorSchemeItems: action.colorSchemeItems
            };
        case actionTypes.GET_COLOR_SCHEME_ITEMS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_COLOR_SCHEME_TO_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_COLOR_SCHEME_TO_JOB_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_COLOR_SCHEME_TO_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_ITEM_TO_VIRTUAL_BOARD_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_ITEM_TO_VIRTUAL_BOARD_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_ITEM_TO_VIRTUAL_BOARD_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_VIRTUAL_BOARD_ITEM_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_VIRTUAL_BOARD_ITEM_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_VIRTUAL_BOARD_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_VIRTUAL_BOARD_ITEMS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_VIRTUAL_BOARD_ITEMS:
            return {
                ...state,
                loading: false,
                error: false,
                virtualBoardItems: action.virtualBoardItems
            };
        case actionTypes.GET_VIRTUAL_BOARD_ITEMS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
                virtualBoardItems: action.virtualBoardItems
            };
        case actionTypes.ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_COLOR_SCHEME_FROM_JOB_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_COLOR_SCHEME_FROM_JOB_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
                virtualBoardItems: action.virtualBoardItems
            };
        case actionTypes.DELETE_COLOR_SCHEME_FROM_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default reducer
