import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    error: false,
    loading: false,
    assetVendors: new Array(0),
    companyVendors: new Array(0),
    assetVendorsTotalItems: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSET_VENDORS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_ASSET_VENDORS:
            return  {
                ...state,
                loading: false,
                error: false,
                assetVendors: action.assetVendors,
                assetVendorsTotalItems: action.assetVendorsTotalItems
            };
        case actionTypes.GET_ASSET_VENDORS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_VENDORS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_VENDORS:
            return  {
                ...state,
                loading: false,
                error: false,
                companyVendors: action.companyVendors
            };
        case actionTypes.GET_COMPANY_VENDORS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.CREATE_VENDOR_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.CREATE_VENDOR_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CREATE_VENDOR_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_VENDOR_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_VENDOR_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_VENDOR_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
                };
        case actionTypes.EDIT_VENDOR_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_VENDOR_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.EDIT_VENDOR_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
                };                        
        default:
            return state;
    }
};

export default reducer;
