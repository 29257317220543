import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    loading: false,
    error: false,
    allCategories: new Array(0),
    totalItemsAllCategories: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CATEGORIES_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SET_CATEGORIES:
            return {
                ...state,
                loading: false,
                allCategories: action.allCategories,
                totalItemsAllCategories: action.totalItemsAllCategories
            };
        case actionTypes.GET_CATEGORIES_FAILED:
            return {
                ...state,
                error: true
            };
        case actionTypes.REMOVE_CATEGORY_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REMOVE_CATEGORY_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.REMOVE_CATEGORY_FAILED:
            return {
                ...state,
                error: true
            }; 
        case actionTypes.EDIT_CATEGORY_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_CATEGORY_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.EDIT_CATEGORY_FAILED:
            return {
                ...state,
                error: true
            }; 
        case actionTypes.CREATE_CATEGORY_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CREATE_CATEGORY_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.CREATE_CATEGORY_FAILED:
            return {
                ...state,
                error: true
            };                  
        default:
            return state;
    }
};

export default reducer