import * as actionTypes from '../Actions/actionTypes';
import {User} from 'Models/User'

const initialState = {
    error: false,
    loading: false,
    companyUsers: new Array(0),
    userData: null,
    checkUserData: true,
    currentUser: null,
    modalText: null,
    openContactUsModal: false,
    callbackFunction: null,
    initialUser: new User(),
    modalTextInside: null,
    callbackFunctionInside: null,
    contactUsModalIsOpen: false,
    userNotifications: [],
    changeComponent: null,
    showWarningModalRedux: false,
    globalSpinner: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_USER_TO_COMPANY_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_USER_TO_COMPANY_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_USER_TO_COMPANY_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REMOVE_USER_FROM_COMPANY_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.REMOVE_USER_FROM_COMPANY_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REMOVE_USER_FROM_COMPANY_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
            case actionTypes.GET_COMPANY_USERS_START:
                return  {
                    ...state,
                    loading: true,
                };
            case actionTypes.SET_COMPANY_USERS:
                return  {
                    ...state,
                    loading: false,
                    error: false,
                    companyUsers: action.companyUsers
                };
            case actionTypes.GET_COMPANY_USERS_FAILED:
                return  {
                    ...state,
                    loading: false,
                    error: true
                };
            case actionTypes.GET_ME_START:
                return  {
                    ...state,
                    loading: true,
                };
            case actionTypes.GET_ME_END:
                return  {
                    ...state,
                    loading: false,
                    error: false,
                    userData: action.userData
                };
            case actionTypes.GET_ME_FAILED:
                return  {
                    ...state,
                    loading: false,
                    error: true
                };
            case actionTypes.SET_CHECK_USER_DATA:
                return  {
                    ...state,
                    checkUserData: action.checkUserData,
                };
            case actionTypes.SET_MODAL_TEXT:
                return  {
                    ...state,
                    modalText: action.modalText,
                    callbackFunction: action.callback
                };
            case actionTypes.SET_MODAL_TEXT_INSIDE:
                return  {
                    ...state,
                    modalTextInside: action.modalTextInside,
                    callbackFunctionInside: action.callbackFunctionInside
                };
            case actionTypes.SET_CURRENT_USER:
                return  {
                    ...state,
                    loading: false,
                    currentUser: action.currentUser,
                };
            case actionTypes.UPDATE_USER_INFO_START:
                return  {
                    ...state,
                    loading: true,
                };
            case actionTypes.UPDATE_USER_INFO_FINISHED:
                return  {
                    ...state,
                    loading: false,
                    error: false,
                };
            case actionTypes.UPDATE_USER_INFO_FAILED:
                return  {
                    ...state,
                    loading: false,
                    error: true
                };
        case actionTypes.TOGGLE_CONTACT_US_MODAL:
            return  {
                ...state,
                openContactUsModal: action.openContactUsModal
            };
        case actionTypes.CHECK_USER_STATUS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.CHECK_USER_STATUS_END:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.CHECK_USER_STATUS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.LOGIN_USER_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.LOGIN_USER_END:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.LOGIN_USER_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.USER_REGISTRATION_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.USER_REGISTRATION_END:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.USER_REGISTRATION_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_USER_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_USER_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_USER_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.CHANGE_USER_ROLE_BY_SUPER_ADMIN_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.CHANGE_USER_ROLE_BY_SUPER_ADMIN_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.CHANGE_USER_ROLE_BY_SUPER_ADMIN_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ON_SET_USER_FOR_EDIT:
            return  {
                ...state,
                initialUser: action.user
            };
        case actionTypes.SET_CONTACT_US_IS_OPEN:
            return {
                ...state,
                contactUsModalIsOpen: action.contactUsModalIsOpen
            };
        case actionTypes.GET_USER_NOTIFICATIONS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_USER_NOTIFICATIONS:
            return  {
                ...state,
                loading: false,
                error: false,
                userNotifications: action.userNotifications
            };
        case actionTypes.GET_USER_NOTIFICATIONS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SHOW_WARNING_MODAL_REDUX:
            return  {
                ...state,
                showWarningModalRedux: action.showWarningModalRedux,
                callbackFunction: action.callback
            };
        case actionTypes.CHANGE_COMPONENT:
            return  {
                ...state,
                changeComponent: action.changeComponent
            };
        case actionTypes.SET_GLOBAL_SPINNER:
            return  {
                ...state,
                globalSpinner: action.globalSpinner
            };
        default:
            return state;
        }
};
export default reducer;