import axios from 'axios';
import layoutRoutes from './../layoutRoutes';
import * as Constants from 'Utils/Constants';
import Localization from 'Utils/Localization';
import React from "react";
import {Consultant} from "./Constants";

class UserUtils {
    static currentChildCompany = null;

    static ROLES = [
        {
            name: 'Admin',
            role: 'COMPANY_ADMIN'
        },
        {
            name: 'Consultant',
            role: 'COMPANY_CONSULTANT'
        },
        {
            name: 'Manager',
            role: 'COMPANY_MASTER_USER'
        }
    ];

    static getRoleTitle(role) {
        if (role === Constants.ROLE_COMPANY_MASTER_USER) {
            return 'Manager'
        }
        if (role === Constants.ROLE_COMPANY_ADMIN) {
            return 'Admin'
        }
        if (role === Constants.ROLE_COMPANY_USER) {
            return 'Consultant'
        }
    }

    static isLoggedIn() {
        let token = localStorage.getItem(Constants.ACCESS_TOKEN);
        return token != null;
    }

    static getToken() {
        return localStorage.getItem(Constants.ACCESS_TOKEN);
    }

    static authorize() {
        let token = localStorage.getItem(Constants.ACCESS_TOKEN);
        if (token !== null) {
            axios.defaults.headers.common['Authorization'] = token;
        }
    }

    static saveAccessToken(token) {
        localStorage.setItem(Constants.ACCESS_TOKEN, token);
    }

    static logoutUser(setCurrentUser) {
        localStorage.removeItem(Constants.ACCESS_TOKEN);
        UserUtils.removeLocalStorageItem('dataCreateCompany');
        UserUtils.removeLocalStorageItem('userMail');
        UserUtils.removeLocalStorageItem('userId');
        setCurrentUser(null);
    }

    static isSuperAdmin(currentUser) {
        return currentUser && currentUser.role === Constants.ROLE_SUPER_USER
    }

    static isCompanyAdmin(currentUser) {
        return currentUser
            && currentUser.companies
            && currentUser.companies.length > 0
            && currentUser.companies[0].role === Constants.ROLE_COMPANY_ADMIN;
    }

    static isCompanyManager(currentUser) {
        return currentUser
            && currentUser.companies
            && currentUser.companies.length > 0
            && currentUser.companies[0].role === Constants.ROLE_COMPANY_MASTER_USER;
    }

    static isCompanyUser(currentUser) {
        return currentUser
            && currentUser.companies
            && currentUser.companies.length > 0
            && currentUser.companies[0].role === Constants.ROLE_COMPANY_USER;
    }

    static isLayoutRoutes(pathname) {
        return layoutRoutes.includes(pathname);
    }

    static getCurrentUser(currentUser ,setCurrentUser, getMe, getCompany, route, callbackError, callBack) {
        const {pathname} = route.location;
        getMe((response) => {
            if(callBack) {
                callBack()
            }

            const { data } = response;
            const { createCompanyRequest, companies, joinCompanyRequest } = data;

            if (!UserUtils.isSuperAdmin(data)) {
                if (createCompanyRequest) {
                    setCurrentUser(data);
                    const { status } = createCompanyRequest;
                    if (status === 'active') {
                        route.push('/waiting-admin-review');
                    }
                    if (status === 'rejected') {
                        route.push('/rejected-create-company');
                    }
                    if (status === 'banned') {
                        route.push('/user-banned');
                    }
                } else if (companies.length === 0 && !joinCompanyRequest) {
                    setCurrentUser(data);
                    route.push('/create-company');
                } else if (companies.length > 0) {
                    if (companies[0].isExpired) {
                        setCurrentUser(data);
                        if (UserUtils.isCompanyAdmin(data)) {
                            route.push('/company-expired-admin');
                        } else {
                            route.push('/company-expired-user');
                        }
                    } else if (companies.length > 0 && companies[0].isActivated) {
                        getCompany(data.companies[0].id, (company) => {
                            const updatedData = {...data, currentCompany: company.data, myCompanies: companies};
                            setCurrentUser(updatedData)
                            if(UserUtils.isLayoutRoutes(pathname)) {
                                route.push(pathname);
                                return
                            }
                            route.push('/')
                        });
                    } else if (companies.length > 0 && !companies[0].isActivated) {
                        setCurrentUser(data);
                        route.push('/company-deactivated');
                    }
                } else if (joinCompanyRequest) {
                    setCurrentUser(data);
                    if (joinCompanyRequest.status === 'notActivated') {
                        route.push('/request-company');
                    } else if (joinCompanyRequest.status === 'active') {
                        route.push('/successed-request');
                    } else if (joinCompanyRequest.status === 'rejected') {
                        route.push('/reject-join-request');
                    } else if (joinCompanyRequest.status === 'banned') {
                        route.push('/user-join-banned');
                    }
                } else {
                    data.myCompanies = companies;
                    getCompany(data.companies[0].id, (company) => {
                        const updatedData = {...data, currentCompany: company.data};
                        setCurrentUser(updatedData)
                        route.push('/')
                    });
                }
            } else {
                setCurrentUser(data);
                if(UserUtils.isLayoutRoutes(pathname)) {
                    route.push(pathname);
                    return
                }
                route.push('/')
            }
        }, callbackError);
    }

    static setLocalStorageItem = (key, value) => localStorage.setItem(key, value);

    static getLocalStorageItem = key => {
       return localStorage.getItem(key);
    }

    static removeLocalStorageItem = (key) => {
        localStorage.removeItem(key);
    }

    static toVerifyUser = ({userId, accessToken}, email, history, setCheckUserData) => {
        UserUtils.setLocalStorageItem('userId', userId);
        UserUtils.setLocalStorageItem('userMail', email);
        UserUtils.saveAccessToken(accessToken);
        axios.defaults.headers.common['Authorization'] = accessToken;
        setCheckUserData(false);
        history.push('/verification');
    }

    static toHandleRecaptchaToken = (e, recaptchaRef, checkRecaptcha, handleSubmit, setModalText) => {
        e.preventDefault()
        recaptchaRef.current.executeAsync().then(token => {
            recaptchaRef.current.reset();

            checkRecaptcha(token, response => {
                if(response.data.isValid) {
                    handleSubmit();
                } else if (!response.data.isValid) {
                    if(setModalText) {
                        setModalText(Localization.strings.youAreNotAHuman)
                    } else {
                        alert(Localization.strings.youAreNotAHuman)
                    }
                }
            }, () => {
                if(setModalText) {
                    setModalText(Localization.strings.youAreNotAHuman)
                } else {
                    alert(Localization.strings.youAreNotAHuman)
                }
            })
        }, () => {
            if(setModalText) {
                setModalText(Localization.strings.youAreNotAHuman)
            } else {
                alert(Localization.strings.youAreNotAHuman)
            }
        });
    }

    static setIsWinClass(window) {
        let operatingSystem = '';
        if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'os_win'; }
        return operatingSystem;
    }

    static onGetRequestStatus = (status) => {
        const describeStatus = Constants.JOBS_STATUSES.find(s => s.value === status );
        let spanElement;
        switch (describeStatus.value) {
            case 'JOB_STATUS_IN_PROGRESS':
                spanElement = <span className="status_rejected">InProgress</span>;
                break;
            case 'JOB_STATUS_COMPLETED':
                spanElement = <span className="status_activated">Completed</span>;
                break;
            case 'JOB_STATUS_NEW':
                spanElement = <span className="status_active">New</span>;
                break;
            case 'JOB_STATUS_ARCHIVE':
                spanElement = <span className="status_archive">Archived</span>;
                break;
            case 'JOB_STATUS_DONE':
                spanElement = <span className="status_done">Done</span>;
                break;
            default:
                spanElement = <span/>;
        }
        return spanElement;
    }

    static returnFullName = (person) => {
        if (person === null) {
            return null
        } else {
            return person.firstName + ' ' + person.lastName;
        }
    }

    static showForbiddenMessageForUser = (currentUser, role, message, showMessageModal) => {
        let result = false;

        switch(role) {
            case Consultant:
                if (UserUtils.isCompanyUser(currentUser)) {
                    showMessageModal(message)
                    result = true
                }
                break;
            default: result = false;
        }

        return result;
    }
}

export default UserUtils;
