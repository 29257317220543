import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';

export const getCategoriesStart = () => {
    return {
        type: actionTypes.GET_CATEGORIES_START
    }
}

export const setCategories = ({rows, count}) => {
    return {
        type: actionTypes.SET_CATEGORIES,
        allCategories: rows,
        totalItemsAllCategories: count
    }
}

export const getCategoriesFailed = () => {
    return {
        type: actionTypes.GET_CATEGORIES_FAILED
    }
}

export const getCategories = (vendorId, searchWord, attributeQuery, callback, page, size, sort) => {
    return dispatch => {
        dispatch(getCategoriesStart())
        let sortItems = ''

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/categories/system${sortItems}`, {
            params: {
                ...(vendorId ? {vendorId} : {}),
                ...(searchWord ? {q: searchWord} : {}),
                ...(attributeQuery ? attributeQuery : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        })
        .then(response => {
            dispatch(setCategories(response.data))
            if(callback) {
                callback(response)
            }
        })
        .catch(error => {
            console.log(error)
            dispatch(getCategoriesFailed())
        });
    }
}

export const removeCategoryStart = () => {
    return {
        type: actionTypes.REMOVE_CATEGORY_START
    }
}

export const removeCategoryEnd = () => {
    return {
        type: actionTypes.REMOVE_CATEGORY_END
    }
}

export const removeCategoryFailed = () => {
    return {
        type: actionTypes.REMOVE_CATEGORY_FAILED
    }
}

export const removeCategory = (categoryId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeCategoryStart())
        axios.delete(`${Constants.SERVER_URL}/categories/${categoryId}`)
        .then(response => {
            callback(response)
            dispatch(removeCategoryEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(removeCategoryFailed())
            errorCallback(error)
        });
    }
}

export const editCategoryStart = () => {
    return {
        type: actionTypes.EDIT_CATEGORY_START
    }
}

export const editCategoryEnd = () => {
    return {
        type: actionTypes.EDIT_CATEGORY_END
    }
}

export const editCategoryFailed = () => {
    return {
        type: actionTypes.EDIT_CATEGORY_FAILED
    }
}

export const editCategory = (categoryId, name, callback, callbackError) => {
    return dispatch => {
        dispatch(editCategoryStart())
        axios.patch(`${Constants.SERVER_URL}/categories/${categoryId}`, {name})
        .then(response => {
            callback(response)
            dispatch(editCategoryEnd())
        })
        .catch(error => {
            console.error(error)
            callbackError(error)
            dispatch(editCategoryFailed())
        });
    }   
}

export const createCategoryStart = () => {
    return {
        type: actionTypes.CREATE_CATEGORY_START
    }
}

export const createCategoryEnd = () => {
    return {
        type: actionTypes.CREATE_CATEGORY_END
    }
}

export const createCategoryFailed = () => {
    return {
        type: actionTypes.CREATE_CATEGORY_FAILED
    }
}

export const createCategory = (name, callback, callbackError) => {
    return dispatch => {
        dispatch(createCategoryStart())
        axios.post(`${Constants.SERVER_URL}/categories`, {name})
        .then(response => {
            callback(response)
            dispatch(createCategoryEnd())
        })
        .catch(error => {
            console.error(error)
            callbackError(error)
            dispatch(createCategoryFailed())
        });
    }
}
