import * as actionTypes from '../Actions/actionTypes';
import {CompanyCreateRequest} from "Models/CompanyCreateRequest";
import {Company} from "Models/Company";

const initialState = {
    loading: false,
    error: false,
    companies: new Array(0),
    vendors: new Array(0),
    categories: new Array(0),
    companyItems: new Array(0),
    company: null,
    joinRequests: new Array(0),
    createRequests: new Array(0),
    requestForEdit: new CompanyCreateRequest(),
    createRequestCompanyName: null,
    createRequestsTotal: 0,
    joinRequestsTotal: 0,
    companiesResellerCreation: new Array(0),
    initialCompany: new Company(),
    createEditTitle: null,
    generalAnalyticsCompany: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EDIT_COMPANY_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANIES_FOR_RESELLER_CREATION_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_COMPANIES_FOR_RESELLER_CREATION_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_COMPANIES_FOR_RESELLER_CREATION:
            return {
                ...state,
                loading: false,
                error: false,
                companiesResellerCreation: action.companiesResellerCreation
            };
        case actionTypes.GET_COMPANY_CATEGORIES_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_CATEGORIES:
            return {
                ...state,
                loading: false,
                error: false,
                categories: action.categories
            };
        case actionTypes.GET_COMPANY_CATEGORIES_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_SYSTEM_CATEGORIES_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_SYSTEM_CATEGORIES:
            return {
                ...state,
                loading: false,
                error: false,
                categories: action.categories
            };
        case actionTypes.GET_SYSTEM_CATEGORIES_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_VENDORS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_VENDORS:
            return {
                ...state,
                loading: false,
                error: false,
                vendors: action.vendors
            };
        case actionTypes.GET_VENDORS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_COMPANY_ITEMS_VENDORS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_ITEMS_VENDORS:
            return {
                ...state,
                loading: false,
                error: false,
                vendors: action.vendors
            };
        case actionTypes.GET_COMPANY_ITEMS_VENDORS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_COMPANIES_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANIES:
            return {
                ...state,
                loading: false,
                error: false,
                companies: action.companies,
                filteredCompanies: action.filteredCompanies
            };
        case actionTypes.GET_COMPANIES_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                company: action.company
            };
        case actionTypes.GET_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.REMOVE_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REMOVE_COMPANY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REMOVE_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.CREATE_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.CREATE_COMPANY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CREATE_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANY_ITEM_WITH_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANY_ITEM_WITH_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANY_ITEM_WITH_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_COMPANY_ITEM_BY_ID_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_COMPANY_ITEM_BY_ID_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.GET_COMPANY_ITEM_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.ADD_COMPANY_ITEM_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_COMPANY_ITEM_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_COMPANY_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.ADD_COMPANY_ITEMS_TO_COMPANY_JOB_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_COMPANY_ITEMS_TO_COMPANY_JOB_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.ADD_COMPANY_ITEMS_TO_COMPANY_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.UPDATE_COMPANY_ITEM_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.UPDATE_COMPANY_ITEM_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.UPDATE_COMPANY_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.REMOVE_COMPANY_ITEM_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REMOVE_COMPANY_ITEM_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REMOVE_COMPANY_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.UPLOAD_COMPANY_ITEM_IMAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.UPLOAD_COMPANY_ITEM_IMAGE_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.UPLOAD_COMPANY_ITEM_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.CREATE_COMPANY_JOB_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.CREATE_COMPANY_JOB_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CREATE_COMPANY_JOB_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.CREATE_COMPANY_CATEGORY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.CREATE_COMPANY_CATEGORY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CREATE_COMPANY_CATEGORY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.EDIT_COMPANY_CATEGORY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_COMPANY_CATEGORY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.EDIT_COMPANY_CATEGORY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.UPLOAD_COMPANY_IMAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.UPLOAD_COMPANY_IMAGE_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.UPLOAD_COMPANY_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.REMOVE_COMPANY_CATEGORY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REMOVE_COMPANY_CATEGORY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REMOVE_COMPANY_CATEGORY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.REQUEST_CREATE_NEW_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REQUEST_CREATE_NEW_COMPANY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REQUEST_CREATE_NEW_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.CREATE_NEW_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.CREATE_NEW_COMPANY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CREATE_NEW_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_USERS_JOIN_REQUESTS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_USERS_JOIN_REQUESTS:
            return {
                ...state,
                loading: false,
                error: false,
                joinRequests: action.companyJoinRequests,
                joinRequestsTotal: action.companyJoinRequestsTotal
            };
        case actionTypes.GET_USERS_JOIN_REQUESTS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.APPROVE_USER_JOIN_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.APPROVE_USER_JOIN_REQUEST_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.APPROVE_USER_JOIN_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.REJECT_USER_JOIN_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REJECT_USER_JOIN_REQUEST_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REJECT_USER_JOIN_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_COMPANY_CREATE_REQUESTS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_CREATE_REQUESTS:
            return {
                ...state,
                loading: false,
                error: false,
                createRequests: action.companyCreateRequests,
                createRequestsTotal: action.total
            };
        case actionTypes.GET_COMPANY_CREATE_REQUESTS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.APPROVE_CREATE_COMPANY_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.APPROVE_CREATE_COMPANY_REQUEST_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.APPROVE_CREATE_COMPANY_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.REJECT_CREATE_COMPANY_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REJECT_CREATE_COMPANY_REQUEST_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.REJECT_CREATE_COMPANY_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.EDIT_COMPANY_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_COMPANY_REQUEST_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.EDIT_COMPANY_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.SET_COMPANY_REQUEST_FOR_EDIT:
            return {
                ...state,
                requestForEdit: action.requestForEdit
            };
        case actionTypes.SET_COMPANY_CREATE_REQUEST_NAME:
            return {
                ...state,
                createRequestCompanyName: action.createRequestCompanyName
            };
        case actionTypes.BAN_USER_JOIN_REQUEST_FAILED_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.BAN_USER_JOIN_REQUEST_FAILED_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.BAN_USER_JOIN_REQUEST_FAILED_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.UNBAN_COMPANY_CREATE_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.UNBAN_COMPANY_CREATE_REQUEST_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.UNBAN_COMPANY_CREATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.UNBAN_COMPANY_JOIN_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.UNBAN_COMPANY_JOIN_REQUEST_FINISHED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.UNBAN_COMPANY_JOIN_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.DELETE_COMPANY_IMAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_COMPANY_IMAGE_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_COMPANY_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_COMPANY_STATUS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_COMPANY_STATUS_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.GET_COMPANY_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.CREATE_COMPANY_JOIN_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.CREATE_COMPANY_JOIN_REQUEST_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CREATE_COMPANY_JOIN_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.ON_SET_COMPANY_FOR_EDIT:
            return {
                ...state,
                initialCompany: action.company,
            };
        case actionTypes.SET_CREATE_EDIT_ITEM:
            return {
                ...state,
                createEditTitle: action.createEditTitle,
            };
        case actionTypes.SET_LICENSE_TO_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_LICENSE_TO_COMPANY_END:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.SET_LICENSE_TO_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case actionTypes.GET_GENERAL_ANALYTICS_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_GENERAL_ANALYTICS_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                generalAnalyticsCompany: action.generalAnalyticsCompany
            };
        case actionTypes.GET_GENERAL_ANALYTICS_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default reducer