import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    error: false,
    loading: false,
    designRanges: [],
    currentDesignRange: null,
    designRangesReportTemplates: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_COMPANY_DESIGN_RANGE_START:
            return {
                ...state,
                loading: true,
                error: false
            };
        case actionTypes.ADD_COMPANY_DESIGN_RANGE_END:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_COMPANY_DESIGN_RANGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_COMPANY_REPORT_TEMPLATES_START:
            return {
                ...state,
                loading: true,
                error: false
            };
        case actionTypes.ADD_COMPANY_REPORT_TEMPLATES_END:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_COMPANY_REPORT_TEMPLATES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_COMPANY_DESIGN_RANGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_COMPANY_DESIGN_RANGE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.EDIT_COMPANY_DESIGN_RANGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_DESIGN_RANGE_COMPANY_START:
            return {
                ...state,
                designRanges: [],
                loading: true
            };
        case actionTypes.SET_DESIGN_RANGE_COMPANY_END:
            return {
                ...state,
                designRanges: action.designRanges,
                error: false,
                loading: false
            };
        case actionTypes.GET_DESIGN_RANGE_COMPANY_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            };
        case actionTypes.DELETE_COMPANY_DESIGN_RANGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.DELETE_COMPANY_DESIGN_RANGE_END:
            return {
                ...state,
                loading: false
            };
        case actionTypes.DELETE_COMPANY_DESIGN_RANGE_FAILED:
            return {
                ...state,
                loading: false
            };
        case actionTypes.GET_DESIGN_RANGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_DESIGN_RANGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_DESIGN_RANGE_END:
            return {
                ...state,
                loading: false,
                error: false,
                currentDesignRange: action.currentDesignRange
            };
        case actionTypes.GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_START:
            return {
                ...state,
                designRangesReportTemplates: [],
                loading: true
            };
        case actionTypes.SET_REPORT_TEMPLATES_OF_DESIGN_RANGE_END:
            return {
                ...state,
                designRangesReportTemplates: action.designRangesReportTemplates,
                error: false,
                loading: false
            };
        case actionTypes.GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;