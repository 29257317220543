import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    loading: false,
    error: false,
    userId: null,
    userPassword: null,
    enterCodeMode: false
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REQUEST_PASSWORD_CHANGE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.REQUEST_PASSWORD_CHANGE_END:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.REQUEST_PASSWORD_CHANGE_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            }; 
        case actionTypes.CHANGE_PASSWORD_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.CHANGE_PASSWORD_END:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.CHANGE_PASSWORD_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPDATE_RECOVERED_PASSWORD_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPDATE_RECOVERED_PASSWORD_END:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.UPDATE_RECOVERED_PASSWORD_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.LOGIN_USER_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.LOGIN_USER_END:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.LOGIN_USER_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.LOGIN_USER_TO_RESELLER_PORTAL_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.LOGIN_USER_TO_RESELLER_PORTAL_END:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.LOGIN_USER_TO_RESELLER_PORTAL_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            }; 
        case actionTypes.USER_REGISTRATION_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.USER_REGISTRATION_END:
            return  {
                ...state,
                loading: false,
                userId: action.userId,
                userMail: action.email,
                userPassword: action.password
            };
        case actionTypes.USER_REGISTRATION_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            }; 
        case actionTypes.USER_VERIFICATION_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.USER_VERIFICATION_END:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.USER_VERIFICATION_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            }; 
        case actionTypes.SET_ENTER_CODE_MODE:
            return  {
                ...state,
                enterCodeMode: action.enterCodeMode
            };
        case actionTypes.RESEND_VERIFICATION_CODE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.RESEND_VERIFICATION_CODE_END:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.RESEND_VERIFICATION_CODE_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            }; 
        case actionTypes.CHECK_USER_STATUS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.CHECK_USER_STATUS_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CHECK_USER_STATUS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };   
        case actionTypes.SET_USER_EMAIL:
            return  {
                ...state,
                userMail: action.userMail
            }; 
        case actionTypes.SET_USER_ID:
            return  {
                ...state,
                userId: action.userId
            };
        case actionTypes.CHECK_RECAPTCHA_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.CHECK_RECAPTCHA_END:
            return  {
                ...state,
                loading: false,
                error: false,
            };
        case actionTypes.CHECK_RECAPTCHA_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default reducer
