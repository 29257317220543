import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as Constants from "../../Utils/Constants";
import generalUtils from "../../Utils/GeneralUtils";
import {deletePackageItemCustomAttributeFailed, deletePackageItemCustomAttributeFinished} from "./packages";
import {editReportTemplateFinished, editReportTemplateStart} from "./reportTemplates";

export const addDesignRangeStart = () => {
    return {
        type: actionTypes.ADD_COMPANY_DESIGN_RANGE_START
    }
}

export const addDesignRangeEnd = () => {
    return {
        type: actionTypes.ADD_COMPANY_DESIGN_RANGE_END
    }
};

export const addDesignRangeFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_DESIGN_RANGE_FAILED,
    }
};

export const addDesignRange = (designRange, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(addDesignRangeStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/designRanges`, designRange)
            .then(response => {
                dispatch(addDesignRangeEnd())
                callback(response.data);
            }).catch(error => {
            dispatch(addDesignRangeFailed());
            errorCallback(error.response.data.error)
        })
    }
};

export const deleteDesignRangeStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_DESIGN_RANGE_START
    }
};

export const deleteDesignRangeFinished = () => {
    return {
        type: actionTypes.DELETE_COMPANY_DESIGN_RANGE_END
    }
};

export const deleteDesignRangeFailed = () => {
    return {
        type: actionTypes.DELETE_COMPANY_DESIGN_RANGE_FAILED
    }
};

export const deleteDesignRange = (companyId, designRangeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteDesignRangeStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}`)
            .then(response => {
                dispatch(deleteDesignRangeFinished());
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteDesignRangeFailed())
            })
    }
};

export const deleteDesignRangeReportTemplate = (companyId, designRangeId, reportTemplates, callback, errorCallback = () => {}) => {
    return () => {
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/reportTemplates`, {
            data: {
                reportTemplates
            }
        })
          .then(response => {
            if (callback) {
                callback()
            }
        })
          .catch(error => {
              errorCallback(error.response.data.error);
          })
    }
}

export const getDesignRangesStart = () => {
    return {
        type: actionTypes.GET_DESIGN_RANGE_COMPANY_START
    }
};

export const setDesignRanges = (designRanges) => {
    return {
        type: actionTypes.SET_DESIGN_RANGE_COMPANY_END,
        designRanges: designRanges
    }
};

export const getDesignRangesFailed = () => {
    return {
        type: actionTypes.GET_DESIGN_RANGE_COMPANY_FAILED
    }
};

export const getDesignRangesAll = (page, size, companyId, searchWord, sort, status, callback, errorCallback) => {
    return dispatch => {
        dispatch(getDesignRangesStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/designRanges${sortItems}`, {
            params: {
                ...(searchWord ? {q: searchWord} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
                ...(searchWord ? {q: searchWord} : {}),
                ...(status !== undefined ? {status} : {})
            }
        }).then(response => {
            dispatch(setDesignRanges(response.data.rows));
            if (callback) {
                callback(response.data)
            }
        })
            .catch(error => {
                dispatch(getDesignRangesFailed())
                if (errorCallback) {
                    errorCallback(error)
                }
            })
    }
};

export const editDesignRangesStart = () => {
    return {
        type: actionTypes.EDIT_COMPANY_DESIGN_RANGE_START
    }
};

export const editDesignRangesFinished = () => {
    return {
        type: actionTypes.EDIT_COMPANY_DESIGN_RANGE_END
    }
};

export const editDesignRanges = (designRange, companyId, designRangeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(editDesignRangesStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}`, designRange)
            .then(response => {
                dispatch(editDesignRangesFinished());
                callback(response.data)
            })
            .catch(error => {
                dispatch(editDesignRangesFinished());
                errorCallback(error.response.data.error);
            })
    }
};

export const uploadDesignRangeImage = (data, companyId, designRangeId, callback, errorCallback) => {
    const formData = new FormData();
    let extension = data.name.split('.')[1];
    formData.append('image', data);
    const config = {
        headers: {
            'ss-image-extension': extension,
        }
    };

    return dispatch => {
        dispatch(editDesignRangesStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/image`, formData, config)
            .then(response => {
                dispatch(editDesignRangesFinished());
                callback()
            })
            .catch(error => {
                dispatch(editDesignRangesFinished());
                errorCallback(error.response.data.error);
            })
    }
};

export const deleteDesignRangeImage = (companyId, designRangeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(editDesignRangesStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/image`)
            .then(response => {
                if (callback) callback(response)
                dispatch(editDesignRangesFinished())
            })
            .catch(error => {
                errorCallback(error.response ? error.response.data.error : error);
                dispatch(editDesignRangesFinished())
            });
    }
}

export const changeStatusDesignRange = (callback, errorCallback, companyId, params) => {
    return dispatch => {
        dispatch(editDesignRangesStart());

        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${params.id}`, params)
            .then( response => {
                dispatch(editDesignRangesFinished(response.data));
                callback();
            })
            .catch( error => {
                dispatch(editDesignRangesFinished());
                errorCallback(error.response.data.error);
            })
    }
};

export const getDesignRangeByIdStart = () => {
    return {
        type: actionTypes.GET_DESIGN_RANGE_START
    }
};

export const setDesignRangeById = (currentDesignRange) => {
    return {
        type: actionTypes.SET_DESIGN_RANGE_END,
        reportTemplate: currentDesignRange
    }
};

export const getDesignRangeByIdFailed = () => {
    return {
        type: actionTypes.GET_DESIGN_RANGE_FAILED
    }
};

export const getDesignRangeById = (companyId, designRangeId, callback, callbackError) => {
    return dispatch => {
        dispatch(getDesignRangeByIdStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}`)
            .then(response => {
                callback(response.data);
                dispatch(setDesignRangeById(response.data));
            })
            .catch(error => {
                console.log(error)
                if(callbackError) {
                    callbackError(error)
                }
                getDesignRangeByIdFailed()
            })
    }
};

export const addCompanyReportTemplatesStart = () => {
    return {
        type: actionTypes.ADD_COMPANY_REPORT_TEMPLATES_START
    }
};

export const addCompanyReportTemplatesEnd = () => {
    return {
        type: actionTypes.ADD_COMPANY_REPORT_TEMPLATES_END,
    }
};

export const addCompanyReportTemplatesFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_REPORT_TEMPLATES_FAILED
    }
};

export const addCompanyReportTemplates = (companyId, designRangeId, callback, reportTemplate, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyReportTemplatesStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/reportTemplates`, reportTemplate)
            .then(response => {
                dispatch(addCompanyReportTemplatesEnd())
                callback();
            }).catch(error => {
            dispatch(addCompanyReportTemplatesFailed());
            if(errorCallback) {
                errorCallback(error)
            }
        })
    }
};

export const addCompanyVariationEnd = () => {
    return {
        type: actionTypes.ADD_COMPANY_REPORT_TEMPLATES_END,
    }
};

export const addCompanyVariationFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_REPORT_TEMPLATES_FAILED
    }
};


export const addCompanyVariation = (companyId, designRangeId, variation, callback, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyReportTemplatesStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/variations`, variation)
            .then(response => {
                dispatch(addCompanyVariationEnd())
                callback(response.data.id);
            }).catch(error => {
            dispatch(addCompanyVariationFailed());
            if(errorCallback) {
                errorCallback(error)
            }
        })
    }
};

export const deleteCompanyVariation = (companyId, designRangeId, variations, callback, errorCallback) => {
    return () => {
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/variations`, {
            data: {
                variations
            }
        })
            .then(response => {
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
            })
    }
}

export const getReportTemplatesDesignRangeStart = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_START
    }
};

export const setReportTemplatesDesignRange = (designRangesReportTemplates) => {
    return {
        type: actionTypes.SET_REPORT_TEMPLATES_OF_DESIGN_RANGE_END,
        designRangesReportTemplates
    }
};

export const getReportTemplatesDesignRangeFailed = () => {
    return {
        type: actionTypes.GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_FAILED
    }
};
export const getReportTemplatesDesignRange = (companyId, designRangeId, withHeaders, callback, errorCallBack) => {
    return dispatch => {
        dispatch(getReportTemplatesDesignRangeStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/reportTemplates`, {
            params: {
                ...(withHeaders ? {withHeaders: withHeaders} : {}),
            }})
            .then(response => {
                callback(response.data);
                dispatch(setReportTemplatesDesignRange(response.data));
            })
            .catch(error => {
                if (errorCallBack) {
                    errorCallBack(error)
                }
                getReportTemplatesDesignRangeFailed()
            })
    }
};

export const getVariationsDesignRange = (companyId, designRangeId, callback, errorCallBack) => {
    return dispatch => {
        dispatch(getReportTemplatesDesignRangeStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/variations`)
          .then(response => {
              callback(response.data);
              dispatch(setReportTemplatesDesignRange(response.data));
          })
          .catch(error => {
              if (errorCallBack) {
                  errorCallBack(error)
              }
              getReportTemplatesDesignRangeFailed()
          })
    }
};

export const addFilteredItemsToSelectionType = (companyId, selectionTypeId, filter, excludedItems, editedItems, callback, errorCallback) => {
    return dispatch => {
        const data = {filter, excludedItems, editedItems}
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/selectionTypes/${selectionTypeId}/items/filtered`, data)
          .then(response => {
              callback(response.data);
          })
          .catch(error => {
              if (errorCallback) {
                  errorCallback(error)
              }
          })
    }
};

export const editReportTemplateInDesignRange = (template, companyId, designRangeId, reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch (editReportTemplateStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/designRanges/${designRangeId}/reportTemplates/${reportTemplateId}`, template)
            .then(response => {
                dispatch(editReportTemplateFinished());
                callback(response.data)
            })
            .catch (error => {
                dispatch(editReportTemplateFinished());
                errorCallback(error.response.data.error);
            })
    }
};
