import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';
import JsFileDownloader from 'js-file-downloader';



export const assignJobToParentCompanyStart = () => {
    return {
        type: actionTypes.ASSIGN_JOB_TO_PARENT_COMPANY_START
    }
};

export const assignJobToParentCompanyFinished = () => {
    return {
        type: actionTypes.ASSIGN_JOB_TO_PARENT_COMPANY_FINISHED
    }
};

export const assignJobToParentCompanyFailed = () => {
    return {
        type: actionTypes.ASSIGN_JOB_TO_PARENT_COMPANY_FAILED
    }
};

export const assignJobToParentCompany = (companyId, jobId, assignJobInfo, callback, errorCallback) => {
    return dispatch => {
        dispatch(assignJobToParentCompanyStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/assignment/reseller`, assignJobInfo)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(assignJobToParentCompanyFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(assignJobToParentCompanyFailed())
            })
    }
};

export const returnJobToChildCompanyStart = () => {
    return {
        type: actionTypes.RETURN_JOB_TO_CHILD_START
    }
};

export const returnJobToChildCompanyFinished = () => {
    return {
        type: actionTypes.RETURN_JOB_TO_CHILD_FINISHED
    }
};

export const returnJobToChildCompanyFailed = () => {
    return {
        type: actionTypes.RETURN_JOB_TO_CHILD_FAILED
    }
};

export const returnJobToChildCompany = (companyId, jobId, callback, errorCallback) => {
    return dispatch => {
        dispatch(returnJobToChildCompanyStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/assignment/reseller`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(returnJobToChildCompanyFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(returnJobToChildCompanyFailed())
            })
    }
};

export const getJobVersionsStart = () => {
    return {
        type: actionTypes.GET_JOB_VERSIONS_START
    }
};

export const setJobVersions = (jobVersions) => {
    return {
        type: actionTypes.SET_JOB_VERSIONS,
        jobVersions: jobVersions
    }
};

export const getJobVersionsFailed = () => {
    return {
        type: actionTypes.GET_JOB_VERSIONS_FAILED
    }
};

export const getJobVersions = (companyId, jobId, currentReportTemplate, reportTemplateId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getJobVersionsStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/versions${reportTemplateId}`)
            .then(response => {
                response.data.unshift(currentReportTemplate);
                dispatch(setJobVersions(response.data));
                if(callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getJobVersionsFailed())
            })
    }
};

export const addNoteToJobStart = () => {
    return {
        type: actionTypes.ADD_NOTE_TO_JOB_START
    }
};

export const addNoteToJobFinished = () => {
    return {
        type: actionTypes.ADD_NOTE_TO_JOB_FINISHED
    }
};

export const addNoteToJobFailed = () => {
    return {
        type: actionTypes.ADD_NOTE_TO_JOB_FAILED
    }
};

export const addNoteToJob = (companyId, jobId, note, callback, errorCallback) => {
    return dispatch => {
        dispatch(addNoteToJobStart());
         axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/notes`, note)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(addNoteToJobFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addNoteToJobFailed());
            })
    }
};

export const editNoteStart = () => {
    return {
        type: actionTypes.EDIT_NOTE_START
    }
};

export const editNoteFinished = () => {
    return {
        type: actionTypes.EDIT_NOTE_FINISHED
    }
};

export const editNoteFailed = () => {
    return {
        type: actionTypes.EDIT_NOTE_FAILED
    }
};

export const editNote = (companyId, jobId, noteId, note, callback, errorCallback) => {
    return dispatch => {
        dispatch(editNoteStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/notes/${noteId}`, note)
            .then(response => {
                dispatch(editNoteFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch(editNoteFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const uploadPlaceholderNoteImageStart = () => {
    return {
        type: actionTypes.UPLOAD_PLACEHOLDER_NOTE_IMAGE_START
    }
};

export const uploadPlaceholderNoteImageFinished = () => {
    return {
        type: actionTypes.UPLOAD_PLACEHOLDER_NOTE_IMAGE_FINISHED
    }
};

export const uploadPlaceholderNoteImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_PLACEHOLDER_NOTE_IMAGE_FAILED
    }
};

export const uploadSelectionTypeNoteImage = (companyId, jobId, selectionTypeId, noteId, image, callback, errorCallback) => {
    return dispatch => {
        dispatch(uploadPlaceholderNoteImageStart());
        const formData = new FormData();
        let extension = image.name.split('.')[1];
        formData.append('image', image);
        const config = {
            headers: {
                'ss-image-extension': extension,
            }
        };
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/selectionTypes/${selectionTypeId}/notes/${noteId}/image`, formData, config)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(uploadPlaceholderNoteImageFinished());
            })
            .catch(error => {
                errorCallback(error.response ? error.response.data.error : error);
                dispatch(uploadPlaceholderNoteImageFailed())
            })
    }
};

export const deletePlaceholderNoteImageStart = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_NOTE_IMAGE_START
    }
};

export const deletePlaceholderNoteImageFinished = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_NOTE_IMAGE_FINISHED
    }
};

export const deletePlaceholderNoteImageFailed = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_NOTE_IMAGE_FAILED
    }
};

export const deleteSelectionTypeNoteImage = (companyId, jobId, selectionTypeId, noteId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deletePlaceholderNoteImageStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/selectionTypes/${selectionTypeId}/notes/${noteId}/image`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deletePlaceholderNoteImageFinished());
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deletePlaceholderNoteImageFailed())
            })
    }
};

export const uploadNoteImageStart = () => {
    return {
        type: actionTypes.UPLOAD_NOTE_IMAGE_START
    }
};

export const uploadNoteImageFinished = () => {
    return {
        type: actionTypes.UPLOAD_NOTE_IMAGE_FINISHED
    }
};

export const uploadNoteImageFailed = () => {
    return {
        type: actionTypes.UPLOAD_NOTE_IMAGE_FAILED
    }
};

export const uploadNoteImage = (companyId, jobId, noteId, image, callback, errorCallback) => {
    return dispatch => {
        dispatch(uploadNoteImageStart());
        const formData = new FormData();
        let extension = image.name.split('.')[1];
        formData.append('image', image);
        const config = {
            headers: {
                'ss-image-extension': extension,
            }
        };
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/notes/${noteId}/image`, formData, config)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(uploadNoteImageFinished());
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(uploadNoteImageFailed())
            })
    }
};

export const addMarkUpFileToJobStart = () => {
    return {
        type: actionTypes.ADD_MARKUP_FILE_TO_JOB_START
    }
};

export const addMarkUpFileToJobFinished = () => {
    return {
        type: actionTypes.ADD_MARKUP_FILE_TO_JOB_FINISHED
    }
};

export const addMarkUpFileToJobFailed = () => {
    return {
        type: actionTypes.ADD_MARKUP_FILE_TO_JOB_FAILED
    }
};

export const addMarkUpFileToJob = (companyId, jobId, markUp, callback, errorCallback) => {
    return dispatch => {
        dispatch(addMarkUpFileToJobStart());
        const formData = new FormData();
        formData.append('file', markUp);
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/markup`, formData,{
            params: {
                name: markUp.name
            }
          })
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addMarkUpFileToJobFinished());
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addMarkUpFileToJobFailed())
            })
    }
};

export const deleteMarkUpFileStart = () => {
    return {
        type: actionTypes.DELETE_MARKUP_FILE_START
    }
};

export const deleteMarkUpFileFinished = () => {
    return {
        type: actionTypes.DELETE_MARKUP_FILE_FINISHED
    }
};

export const deleteMarkUpFileFailed = () => {
    return {
        type: actionTypes.DELETE_MARKUP_FILE_FAILED
    }
};

export const deleteMarkUpFile = (companyId, jobId, markUpId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteMarkUpFileStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/markup/${markUpId}`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deleteMarkUpFileFinished());
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteMarkUpFileFailed())
            })
    }
};

export const getMarkUpsOfJobStart = () => {
    return {
        type: actionTypes.GET_MARKUP_OF_JOB_START
    }
};

export const setMarkUpsOfJob = (markUps) => {
    return {
        type: actionTypes.SET_MARKUP_OF_JOB,
        markUps: markUps
    }
};

export const getMarkUpsOfJobFailed = () => {
    return {
        type: actionTypes.GET_MARKUP_OF_JOB_FAILED
    }
};

export const getMarkUpsOfJob = (companyId, jobId, callback, errorCallback, sort) => {
    return dispatch => {
        dispatch(getMarkUpsOfJobStart());
        let sortItems = ''

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/markup${sortItems}`)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setMarkUpsOfJob(response.data));
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getMarkUpsOfJobFailed())
            })
    }
};

export const addMarkUPVersionStart = () => {
    return {
        type: actionTypes.ADD_MARK_UP_VERSION_START
    }
};

export const addMarkUPVersionFinished = () => {
    return {
        type: actionTypes.ADD_MARK_UP_VERSION_FINISHED
    }
};

export const addMarkUPVersionFailed = () => {
    return {
        type: actionTypes.ADD_MARK_UP_VERSION_FAILED
    }
};

export const addMarkUPVersion = (companyId, jobId, markUpId, newMarkup, isSigned,  callback, errorCallback) => {
    return dispatch => {
        dispatch(addMarkUPVersionStart());
        const formData = new FormData();
        formData.append('file', newMarkup);

        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/markup/${markUpId}/versions`, formData, {
            params: {
              ...(isSigned ? {isSigned: 'true'} : {}),
            }
          })

            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addMarkUPVersionFinished());
            })
            .catch(error => {
                console.log(error);
                errorCallback(error.response.data.error);
                dispatch(addMarkUPVersionFailed())
            })
    }
};

export const deleteConsultantFromJobStart = () => {
    return {
        type: actionTypes.DELETE_CONSULTANT_FROM_JOB_START
    }
};

export const deleteConsultantFromJobFinished = () => {
    return {
        type: actionTypes.DELETE_CONSULTANT_FROM_JOB_FINISHED
    }
};

export const deleteConsultantFromJobFailed = () => {
    return {
        type: actionTypes.DELETE_CONSULTANT_FROM_JOB_FAILED
    }
};

export const deleteConsultantFromJob = (companyId, jobId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteConsultantFromJobStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/consultant`)
            .then(() => {
                if(callback) {
                    callback()
                }
            }).catch(error => {
            const errorData = error.response ? error.response.data.error : error;
            if (errorCallback) {
                errorCallback(errorData);
            }
            console.error(errorData)
        })
    }
};

export const addCustomAttributesToPlaceholderNoteStart = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_START
    }
};

export const addCustomAttributesToPlaceholderNoteFinished = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FINISHED
    }
};

export const addCustomAttributesToPlaceholderNoteFailed = () => {
    return {
        type: actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FAILED
    }
};

export const addCustomAttributesToPlaceholderNote = (companyId, jobId, placeholderId, noteId, attributes, callback, errorCallback) => {
    return dispatch =>{
        dispatch(addCustomAttributesToPlaceholderNoteStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/categoryPlaceholders/${placeholderId}/notes/${noteId}/customAttributes`, attributes)
            .then(() => {
                if(callback) {
                    callback()
                }
            }).catch(error => {
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData)
                }
                console.error(error)
            })
    }
};

export const deleteCustomAttributeFromPlaceholderNoteStart = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_START
    }
};

export const deleteCustomAttributeFromPlaceholderNoteFinished = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FINISHED
    }
};

export const deleteCustomAttributeFromPlaceholderNoteFailed = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FAILED
    }
};

export const deleteCustomAttributeFromPlaceholderNote = (companyId, jobId, placeholderId, noteId, attributeId, value, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCustomAttributeFromPlaceholderNoteStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/categoryPlaceholders/${placeholderId}/notes/${noteId}/customAttributes/${attributeId}`, {
            params: {
              ...(value ? {value} : {}),
            }
        }).then(() => {
                if(callback) {
                    callback()
                }
            }).catch(error => {
            const errorData = error.response ? error.response.data.error : error;
            if (errorCallback) {
                errorCallback(errorData)
            }
            console.error(error)
        })
    }
};

export const deleteJobNoteImageStart = () => {
    return {
        type: actionTypes.DELETE_JOB_NOTE_IMAGE_START
    }
};

export const deleteJobNoteImageFinished = () => {
    return {
        type: actionTypes.DELETE_JOB_NOTE_IMAGE_FINISHED
    }
};

export const deleteJobNoteImageFailed = () => {
    return {
        type: actionTypes.DELETE_JOB_NOTE_IMAGE_FAILED
    }
};

export const deleteJobNoteImage = (companyId, jobId, noteId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteJobNoteImageStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/notes/${noteId}/image`)
            .then(response => {
                if (callback) {
                    callback ()
                }
                dispatch(deleteJobNoteImageFinished())
            }).catch(error => {
            errorCallback(error.response.data.error);
            dispatch(deleteJobNoteImageFailed())
        })
    }
};

export const getCompanyJobsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_JOBS_START
    }
}

export const setCompanyJobs = (companyJobs) => {
    return {
        type: actionTypes.SET_COMPANY_JOBS,
        companyJobs
    }
}

export const getCompanyJobsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_JOBS_FAILD
    }
}

export const getCompanyJobs = (companyId, searchWord, status, callback, errorCallback, limit, offset, sort) => {
    return dispatch => {
        dispatch(getCompanyJobsStart())
            let sortItems = '';
            if (sort && sort.length > 0) {
                sortItems = '?';
                for (const sortItem of sort) {
                    sortItems += '&sort[]=' + sortItem
                }
            }
            axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs${sortItems}`, {
                params: {
                  ...(searchWord ? {q: searchWord} : null),
                  ...(status ? {status: status} : null),
                  ...(limit ? {limit: limit} : {limit: '1000'}),
                  ...(offset ? {offset: offset} : {offset: '0'})
                }
              })
            .then(response => {
                dispatch(setCompanyJobs(response.data.rows))
                callback(response)
            }).catch(error => {
                console.log(error)
                errorCallback(error)
                dispatch(getCompanyJobsFailed())
            })
    }
}

export const  getJobStart = () => {
    return {
        type: actionTypes.GET_JOB_START
    }
}

export const  setJob = (job) => {
    return {
        type: actionTypes.SET_JOB,
        job
    }
}

export const  getJobFailed = () => {
    return {
        type: actionTypes.GET_JOB_FAILED
    }
}

export const getJob = (companyId, jobId, callback) => {
    return dispatch => {
        dispatch(getJobStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}`)
        .then(response => {
            dispatch(setJob(response.data))
            callback(response)
        }).catch(error => {
            console.log(error)
            dispatch(getJobFailed())
        })
    }
}

export const removeJobStart = () => {
    return {
        type: actionTypes.REMOVE_ASSET_START
    }
}

export const removeJobEnd = () => {
    return {
        type: actionTypes.REMOVE_ASSET_END
    }
}

export const removeJobFailed = () => {
    return {
        type: actionTypes.REMOVE_ASSET_FAILED
    }
}

export const removeJob = (jobId, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removeJobStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}`)
        .then(response => {
            callback(response)
            dispatch(removeJobEnd())
        }).catch(error => {
            console.log(error)
            dispatch(removeJobFailed())
            errorCallback(error)
        })

    }
}

export const downloadJobReportStart = () => {
    return {
        type: actionTypes.DOWNLOAD_JOB_REPORT_START
    }
}

export const downloadJobReportEnd = () => {
    return {
        type: actionTypes.DOWNLOAD_JOB_REPORT_END
    }
}

export const downloadJobReportFailed = () => {
    return {
        type: actionTypes.DOWNLOAD_JOB_REPORT_FAILED
    }
}
//todo old

// export const downloadJobReport = (companyId, jobId, reportTemplateId, isVariation, callback, errorCallback) => {
//     return dispatch => {
//         dispatch(downloadJobReportStart())
//         axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/report/download/lambda/pdf`, {
//             params: {
//                 ...(isVariation ? {reportTemplateVariationId: reportTemplateId} : {reportTemplateId}),
//             },
//             responseType: 'blob'
//         })
//             .then(response => {
//                 callback(response)
//                 dispatch(downloadJobReportEnd())
//             }).catch(error => {
//             console.log(error)
//             dispatch(downloadJobReportFailed())
//             errorCallback(error)
//         })
//     }
//
// }

//TODO slow (12 + 13 s)

// export const downloadJobReport = (companyId, jobId, reportTemplateId, isVariation, callback, errorCallback) => {
//     return dispatch => {
//         dispatch(downloadJobReportStart())
//         axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/report/download/lambda/pdf`, {
//             params: {
//                 ...(isVariation ? {reportTemplateVariationId: reportTemplateId} : {reportTemplateId}),
//             },
//             responseType: 'json'
//         })
//             .then(response => {
//                 fetch(response.data.body)
//                     .then(resp => resp.blob())
//                     .then(blobobject => {
//                         const blob = window.URL.createObjectURL(blobobject);
//                         const anchor = document.createElement('a');
//                         anchor.style.display = 'none';
//                         anchor.href = blob;
//                         anchor.download = "File.pdf";
//                         document.body.appendChild(anchor);
//                         anchor.click();
//                         window.URL.revokeObjectURL(blob);
//
//                         callback()
//                         dispatch(downloadJobReportEnd())
//
//                     })
//                     .catch(() => console.log('An error in downloadin gthe file sorry'));
//
//             })
//
//
//     }
//
// }

//TODO fast

// export const downloadJobReport = (companyId, jobId, reportTemplateId, isVariation, callback, errorCallback) => {
//     return dispatch => {
//         dispatch(downloadJobReportStart())
//         axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/report/download/lambda/pdf`, {
//             params: {
//               ...(isVariation ? {reportTemplateVariationId: reportTemplateId} : {reportTemplateId}),
//             },
//             responseType: 'json',
//
//         })
//         .then(response => {
//             window.open(response.data.body)
//             callback()
//             dispatch(downloadJobReportEnd())
//         })
//             .catch(error => {
//             console.log(error)
//             dispatch(downloadJobReportFailed())
//             errorCallback(error)
//         })
//     }
//
// }

//TODO test (9 + 16 s) check pre loader

export const downloadJobReport = (companyId, jobId, reportTemplateId, isVariation, callback, errorCallback) => {

    return dispatch => {
        dispatch(downloadJobReportStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/report/download/lambda/pdf`, {
            params: {
              ...(isVariation ? {reportTemplateVariationId: reportTemplateId} : {reportTemplateId}),
            },
            responseType: 'json',

        })
        .then(response => {
            let uri = response.data.body;
            let name = '123';
            new JsFileDownloader({
                url: uri,
                forceDesktopMode: true,
                withCredentials: false,
                timeout:400000,
            })
                .then(function () {
                    // Called when download ended
                    callback()
                    dispatch(downloadJobReportEnd())
                })
                .catch(function (error) {
                    console.log(error)
                });



        })
            .catch(error => {
            console.log(error)
            dispatch(downloadJobReportFailed())
            errorCallback(error)
        })
    }

}


export const editJobStart = () => {
    return {
        type: actionTypes.EDIT_JOB_START
    }
}

export const editJobEnd = () => {
    return {
        type: actionTypes.EDIT_JOB_END
    }
}

export const editJobFailed = () => {
    return {
        type: actionTypes.EDIT_JOB_FAILED
    }
}

export const editJob = (job, jobId, companyId, callback, errorCallback) => {
    return dispatch => {
        dispatch(editJobStart())
        delete job.consultantId;
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}`, job)
        .then(response => {
            callback(response)
            dispatch(editJobEnd())
        }).catch(error => {
            dispatch(editJobFailed())
            const errorData = error.response ? error.response.data.error : error;
            if (errorCallback) {
                errorCallback(errorData)
            }
            console.error(error)
        })
    }

}

export const assignConsultantToJobStart = () => {
    return {
        type: actionTypes.ASSIGN_CONSULTANT_TO_JOB_START
    }
}

export const assignConsultantToJobEnd = () => {
    return {
        type: actionTypes.ASSIGN_CONSULTANT_TO_JOB_END
    }
}

export const assignConsultantToJobFailed = () => {
    return {
        type: actionTypes.ASSIGN_CONSULTANT_TO_JOB_FAILED
    }
}

export const assignConsultantToJob = (companyId, jobId, consultantId, callback, errorCallback) => {
    return dispatch => {
        dispatch(assignConsultantToJobStart())
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/consultant`, {consultantId: consultantId})
            .then(() => {
                if(callback) {
                    callback()
                }
        })
            .catch(error => {
                const errorData = error.response ? error.response.data.error : error;
                if (errorCallback) {
                    errorCallback(errorData);
                }
                console.error(errorData)
            })
    }
}

export const getJobReportStart = () => {
    return {
        type: actionTypes.GET_JOB_REPORT_START
    }
}

export const getJobReportEnd = () => {
    return {
        type: actionTypes.GET_JOB_REPORT_END
    }
}

export const getJobReportFailed = () => {
    return {
        type: actionTypes.GET_JOB_REPORT_FAILED
    }
}

export const getJobReport = (companyId, jobId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getJobReportStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/report`)
        .then(response => {
            callback(response)
            dispatch(getJobReportEnd())
        }).catch(error => {
            console.log(error)
            dispatch(getJobReportFailed())
            errorCallback(error)
        })
    }

}

export const getJobNotesStart = () => {
    return {
        type: actionTypes.GET_JOB_NOTES_START
    }
}

export const setJobNotes = (notes) => {
    return {
        type: actionTypes.SET_JOB_NOTES,
        notes: notes
    }
}

export const getJobNotesFailed = () => {
    return {
        type: actionTypes.GET_JOB_NOTES_FAILED
    }
}

export const getJobNotes = (companyId, jobId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getJobNotesStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/notes`)
        .then(response => {
            callback(response)
            dispatch(setJobNotes(response.data))
        }).catch(error => {
            console.log(error)
            dispatch(getJobNotesFailed())
            errorCallback(error)
        })
    }

}

export const deleteNoteFromJobStart = () => {
    return {
        type: actionTypes.DELETE_NOTE_FROM_JOB_START
    }
}

export const deleteNoteFromJobEdd = () => {
    return {
        type: actionTypes.DELETE_NOTE_FROM_JOB_END
    }
}

export const deleteNoteFromJobFailed = () => {
    return {
        type: actionTypes.DELETE_NOTE_FROM_JOB_FAILED
    }
}

export const deleteNoteFromJob = (companyId, jobId, noteId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteNoteFromJobStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/notes/${noteId}`)
        .then(response => {
            callback(response)
            dispatch(deleteNoteFromJobEdd())
        }).catch(error => {
            console.log(error)
            dispatch(deleteNoteFromJobFailed())
            errorCallback(error)
        })
    }
}

export const setJobForEdit = initialJob => {
    return {
        type: actionTypes.ON_SET_JOB_FOR_EDIT,
        initialJob
    }
}

export const setAssignJobInfo = assignJob => {
    return {
        type: actionTypes.SET_ASSIGN_JOB_INFO,
        assignJob
    }
}
