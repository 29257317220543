import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';

export const requestPasswordChangeStart = () => {
    return {
        type: actionTypes.REQUEST_PASSWORD_CHANGE_START
    }
}

export const requestPasswordChangeEnd = () => {
    return {
        type: actionTypes.REQUEST_PASSWORD_CHANGE_END
    }
}

export const requestPasswordChangeFailed = () => {
    return {
        type: actionTypes.REQUEST_PASSWORD_CHANGE_FAILED
    }
}

export const requestPasswordChange = (email, callback, errorCallback) => {
    return dispatch => {
        dispatch(requestPasswordChangeStart())
        axios.get(Constants.SERVER_URL + '/users/recovery?email=' + email)
        .then(response => {
            callback(response)
            dispatch(requestPasswordChangeEnd())
        })
        .catch(error => {
            dispatch(requestPasswordChangeFailed())
            errorCallback(error)
        });

    }
}

export const changePasswordStart = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_START
    }
}

export const changePasswordEnd = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_END
    }
}

export const changePasswordFailed = () => {
    return {
        type: actionTypes.CHANGE_PASSWORD_FAILED
    }
}

export const changePassword = (oldPassword, newPassword, callback, errorCallback) => {
    return dispatch => {
        dispatch(changePasswordStart())
        axios.patch(Constants.SERVER_URL + '/users/me/password', {
            password: oldPassword,
            newPassword: newPassword
        })
        .then(response => {
            if(callback) {
                callback(response)
            }
            dispatch(changePasswordEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(changePasswordFailed())
            errorCallback(error)
        });
    }

}

export const updateRecoveredPasswordStart = () => {
    return {
        type: actionTypes.UPDATE_RECOVERED_PASSWORD_START
    }
}

export const updateRecoveredPasswordEnd = () => {
    return {
        type: actionTypes.UPDATE_RECOVERED_PASSWORD_END
    }
}

export const updateRecoveredPasswordFailed = () => {
    return {
        type: actionTypes.UPDATE_RECOVERED_PASSWORD_FAILED
    }
}

export const updateRecoveredPassword = (recoveryId, newPassword, callback, errorCallback) => {
    return dispatch => {
        dispatch(updateRecoveredPasswordStart())
        axios.post(Constants.SERVER_URL + '/users/recovery', {
            password: newPassword,
            recoveryId: recoveryId
        })
        .then(response => {
            callback(response)
            dispatch(updateRecoveredPasswordEnd())
        })
        .catch(error => {
            console.log(error)
            dispatch(updateRecoveredPasswordFailed())
            errorCallback(error)
        });
    }

}

export const loginUserStart = () => {
    return {
        type: actionTypes.LOGIN_USER_START
    }
}

export const loginUserEnd = () => {
    return {
        type: actionTypes.LOGIN_USER_END
    }
}

export const loginUserFailed = () => {
    return {
        type: actionTypes.LOGIN_USER_FAILED
    }
}

export const loginUser = (login, password, callback, callbackError) => {
    return dispatch => {
        dispatch(loginUserStart())
        axios.post(Constants.SERVER_URL + '/auth', {
            email: login,
            password: password
        })
        .then((response) => {
            callback(response)
            dispatch(loginUserEnd())
        }).catch((error) => {
            console.log(error)
            dispatch(loginUserFailed())
            callbackError(error);
        });
    }
   
}

export const loginUserToResellerPortalStart = () => {
    return {
        type: actionTypes.LOGIN_USER_TO_RESELLER_PORTAL_START
    }
}

export const loginUserToResellerPortalEnd = () => {
    return {
        type: actionTypes.LOGIN_USER_TO_RESELLER_PORTAL_END
    }
}

export const loginUserToResellerPortalFailed = () => {
    return {
        type: actionTypes.LOGIN_USER_TO_RESELLER_PORTAL_FAILED
    }
}

export const loginUserToResellerPortal = (login, password, callback, callbackError) => {
    return dispatch => {
        dispatch(loginUserToResellerPortalStart())
        axios.post(Constants.SERVER_URL + '/auth', {
            email: login,
            password: password
        })
        .then((response) => {
            callback(response)
            dispatch(loginUserToResellerPortalEnd())
        }).catch((error) => {
            console.log(error)
            dispatch(loginUserToResellerPortalFailed())
            callbackError(error);
        });
    }
}

export const checkUserStatusStart = () => {
    return {
        type: actionTypes.CHECK_USER_STATUS_START
    }
}

export const checkUserStatusEnd = () => {
    return {
        type: actionTypes.CHECK_USER_STATUS_END,
    }
}

export const checkUserStatusFailed = () => {
    return {
        type: actionTypes.CHECK_USER_STATUS_FAILED
    }
}

export const checkUserStatus = (email, callback, errorCallback) => {
    return dispatch => {
        dispatch(checkUserStatusStart());
        axios.get(`${Constants.SERVER_URL}/users/status/email`, {
            params: {
              ...{email}
            }
          })
        .then(response => {
            dispatch(checkUserStatusEnd())
            callback(response)
        }).catch(error => {
            console.error(error)
            dispatch(checkUserStatusFailed())
            errorCallback(error)
        })
    }
}

export const userRegistrationStart = () => {
    return {
        type: actionTypes.USER_REGISTRATION_START
    }
}

export const userRegistrationEnd = (payload) => {
    return {
        type: actionTypes.USER_REGISTRATION_END,
        userId: payload.userId,
        email: payload.email,
        password: payload.password
    }
}

export const userRegistrationFailed = () => {
    return {
        type: actionTypes.USER_REGISTRATION_FAILED
    }
}

export const userRegistration = (email, firstName, lastName, password, callback, errorCallback) => {
    return dispatch => {
        dispatch(userRegistrationStart());
        axios.post(`${Constants.SERVER_URL}/users`, {
            email,
            password,
            firstName,
            lastName
        })
        .then(response => {
            dispatch(userRegistrationEnd({userId: response.data.id, email, password}))
            dispatch(setEnterCodeMode(true));
            callback(response)
        }).catch(error => {
            console.error(error)
            dispatch(userRegistrationFailed())
            errorCallback(error)
        })
    }
}

export const userVerificationStart = () => {
    return {
        type: actionTypes.USER_VERIFICATION_START
    }
}

export const userVerificationEnd = () => {
    return {
        type: actionTypes.USER_VERIFICATION_END
    }
}

export const userVerificationFailed = () => {
    return {
        type: actionTypes.USER_VERIFICATION_FAILED
    }
}

export const userVerification = (userId, verificationCode, verificationDigits, callback, errorCallback) => {
    return dispatch => {
        dispatch(userVerificationStart());
        axios.post(`${Constants.SERVER_URL}/users/verification`, {
            verificationCode,
            verificationDigits,
            userId
        })
        .then(response => {
            dispatch(userVerificationEnd())
            dispatch(setEnterCodeMode(false))
            callback(response)
        }).catch(error => {
            console.error(error)
            dispatch(userVerificationFailed())
            errorCallback(error)
        })
    }
}

export const setEnterCodeMode = (enterCodeMode) => {
    return {
        type: actionTypes.SET_ENTER_CODE_MODE,
        enterCodeMode
    }
}

export const reSendVerificationCodeStart = () => {
    return {
        type: actionTypes.RESEND_VERIFICATION_CODE_START
    }
}

export const reSendVerificationCodeEnd = () => {
    return {
        type: actionTypes.RESEND_VERIFICATION_CODE_END
    }
}

export const reSendVerificationCodeFailed = () => {
    return {
        type: actionTypes.RESEND_VERIFICATION_CODE_FAILED
    }
}

export const reSendVerificationCode = (userId, callback, errorCallback) => {
    return dispatch => {
        dispatch(reSendVerificationCodeStart())
        axios.post(`${Constants.SERVER_URL}/users/verification/resend`, {
            userId
        })
        .then(response => {
            dispatch(reSendVerificationCodeEnd())
            callback(response)
        }).catch(error => {
            console.error(error)
            dispatch(reSendVerificationCodeFailed())
            errorCallback(error)
        })
    }
}

export const setUserEmail = userMail => {
    return {
        type: actionTypes.SET_USER_EMAIL,
        userMail
    }
}

export const setUserId = userId => {
    return {
        type: actionTypes.SET_USER_ID,
        userId
    }
}

export const checkRecaptchaStart = () => {
    return {
        type: actionTypes.CHECK_RECAPTCHA_START
    }
}

export const checkRecaptchaEnd = () => {
    return {
        type: actionTypes.CHECK_RECAPTCHA_END
    }
}

export const checkRecaptchaFailed = () => {
    return {
        type: actionTypes.CHECK_RECAPTCHA_FAILED
    }
}

export const checkRecaptcha = (token, callback, errorCallback) => {
    return dispatch => {
        dispatch(checkRecaptchaStart())
        axios.post(`${Constants.SERVER_URL}/recaptcha`, {
            token
        })
            .then(response => {
                dispatch(checkRecaptchaEnd())
                callback(response)
            }).catch(error => {
            console.error(error)
            dispatch(checkRecaptchaFailed())
            errorCallback(error)
        })
    }
}