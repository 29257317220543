import loginBackground from 'Assets/img/loginBackground.svg';
import forgotPasswordBackground from 'Assets/img/passwordRecovery.svg';
import createCompanyBackground from 'Assets/img/createCompany.svg';
import verificationBackground from 'Assets/img/verificationBackground.svg';
import notVerified from 'Assets/img/notVerified.svg';
import requestSent from 'Assets/img/requestSent.svg';
import almostDoneM from 'Assets/img/almostDoneM.svg';
import getStartedM from 'Assets/img/getStartedM.svg';
import passwordM from 'Assets/img/passwordM.svg';
import requestM from 'Assets/img/requestM.svg';
import requestSentM from 'Assets/img/requestSentM.svg';
import verificationM from 'Assets/img/verificationM.svg';
import getStartedMobile from 'Assets/img/getStartedMobile.svg';

import assets from 'Assets/img/icons/dashboardMain/assets.svg';
import attributes from 'Assets/img/icons/dashboardMain/attributes.svg';
import categories from 'Assets/img/icons/dashboardMain/categories.svg';
import colourSchema from 'Assets/img/icons/dashboardMain/colourSchema.svg';
import companies from 'Assets/img/icons/dashboardMain/companies.svg';
import jobs from 'Assets/img/icons/dashboardMain/jobs.svg';
import productIncusionGuide from 'Assets/img/icons/dashboardMain/productIncusionGuide.svg';
import reportPlaceholders from 'Assets/img/icons/dashboardMain/reportPlaceholders.svg';
import reportTemplate from 'Assets/img/icons/dashboardMain/reportTemplate.svg';
import resellers from 'Assets/img/icons/dashboardMain/resellers.svg';
import setting from 'Assets/img/icons/dashboardMain/setting.svg';
import users from 'Assets/img/icons/dashboardMain/users.svg';
import vendors from 'Assets/img/icons/dashboardMain/vendors.svg';
import React from "react";

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const IS_ON_RESELLER_PORTAL = JSON.parse(process.env.REACT_APP_IS_ON_RESELLER);
export const PUBLIC_RECAPTCHA_SITE_KEY = '6Ld1FeIZAAAAAKck-zqP-pHQ4YX-B4hDADovlA4m';

export const defaultColour = '#225FDD'

export const BOOKING_TYPES = ['4 Hours', '5 Hours', 'Custom'];
export const SELECTION_TYPES = ['Internal and External', 'Internal only', 'External only'];
export const RELATIONS = [
        {name:'is', relation: 'EQUALS'},
        {name:'isn\'t', relation: 'DOES_NOT_EQUAL'},
        {name:'does not contain', relation: 'DOES_NOT_CONTAIN'},
        {name:'contains', relation: 'CONTAINS'}
    ];
export const CURRENT_VERSION = 'Version';
export const NOTE_TYPE = ['Standard', 'Inclusion', 'Upgrade'];
export const ANALYTICAL_REPORT_RANGES = ['Month to Date', 'Last 30 days', 'Last 90 days'];

export const COMPANY_JOIN_REQUEST_STATUSES = {
    rejected: 'Rejected',
    approved: 'Approved',
    active: 'Active',
    banned: 'Banned'
}

export const SELECTION_TYPES_SELECT = [
    {
        name: 'Internal and External',
        value: 'External_and_Internal'
    },
    {
        name: 'Internal only',
        value: 'External_only'
    },
    {
        name: 'External only',
        value: 'Internal_only'
    }
];

export const BOOKING_TYPES_SELECT = [
    {
        name: '4 Hours',
        value: '4_hours'
    },
    {
        name: '5 Hours',
        value: '5_hours'
    },
    {
        name: 'Custom',
        value: 'custom'
    }
    ];

export const POSITIONS = [
    {
        name: 'CEO',
        value: 'CEO',
    },
    {
        name: 'Principal',
        value: 'Principal',
    },
    {
        name: 'Project Manager',
        value: 'Project Manager',
    },
    {
        name: 'Design Director',
        value: 'Design Director',
    },
    {
        name: 'Sales Dep.',
        value: 'Sales Dep.',
    },
    {
        name: 'Marketing Dep.',
        value: 'Marketing Dep.',
    },
    {
        name: 'Other',
        value: 'Other',
    }
]

export const ROUTES = {
    'company-users': 'Users',
    'companies': 'Companies',
    'job': 'Job',
    'company-items': 'Items',
    'package': 'Inclusion Guide',
    'colour-schemes': 'Colour Scheme',
    'categories': 'Categories',
    'company-attributes': 'Attributes',
    'report-templates': 'Report Templates',
    'design-ranges': 'Design Range',
    'company-placeholders': 'Placeholders',
    'jobs': 'Jobs'
}

export const GENERIC_EMAILS = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'icloud.com',
    'outlook.com',
    'aol.com',
    'mail.com',
    'live.com',
    'yandex.ru',
    'rambler.ru'
]

export const CREATE_COMPANY_REQUEST_STATUSES = [
    {
        name: 'All',
        status: ''
    },
    {
        name: 'New',
        status: 'active'
    },
    {
        name: 'Rejected',
        status: 'rejected'
    },
    {
        name: 'Banned',
        status: 'banned'
    }
]

export const JOIN_COMPANY_REQUEST_STATUSES = [
    {
        name: 'All',
        status: ''
    },
    {
        name: 'New',
        status: 'active'
    },
    {
        name: 'Rejected',
        status: 'rejected'
    },
    {
        name: 'Banned',
        status: 'banned'
    }
]

export const JOBS_STATUSES = [
    {
        name: 'All',
        value: ''
    },
    {
        name: 'New',
        value: 'JOB_STATUS_NEW'
    },
    {
        name: 'In Progress',
        value: 'JOB_STATUS_IN_PROGRESS'
    },
    {
        name: 'Completed',
        value: 'JOB_STATUS_COMPLETED'
    },
    {
        name: 'Archived',
        value: 'JOB_STATUS_ARCHIVE'
    }
]

export const JOBS_STATUSES_EDITING = [
    {
        name: 'In Progress',
        value: 'JOB_STATUS_IN_PROGRESS'
    },
    {
        name: 'Completed',
        value: 'JOB_STATUS_COMPLETED'
    },
    {
        name: 'Archived',
        value: 'JOB_STATUS_ARCHIVE'
    }
]

export const NOTIFICATIONS_STATUS_ENHANCING = [
    {
        name: 'All Messages',
        value: ''
    },
    {
        name: 'Read Items',
        value: 'true'
    },
    {
        name: 'Unread Items',
        value: 'false'
    },
]

export const JOBS_STATUSES_ENHANCING = [
    {
        name: 'All Jobs',
        value: ''
    },
    {
        name: 'New Jobs',
        value: 'JOB_STATUS_NEW'
    },
    {
        name: 'InProgress Jobs',
        value: 'JOB_STATUS_IN_PROGRESS'
    },
    {
        name: 'Completed Jobs',
        value: 'JOB_STATUS_COMPLETED'
    },
    {
        name: 'Archived Job',
        value: 'JOB_STATUS_ARCHIVE'
    }
]

export const ACCESS_TOKEN = "accessToken";
export const ROLE_COMPANY_ADMIN = "COMPANY_ADMIN";
export const ROLE_COMPANY_USER = "COMPANY_CONSULTANT";
export const ROLE_SUPER_USER = "SUPER_ADMIN";
export const ROLE_COMPANY_MASTER_USER = 'COMPANY_MASTER_USER';
export const Consultant = 'Consultant';

export const ROLES = [
    {
        name: 'Admin',
        value: 'COMPANY_ADMIN'
    },
    {
        name: Consultant,
        value: 'COMPANY_CONSULTANT'
    },
    {
        name: 'Manager',
        value: 'COMPANY_MASTER_USER'
    }
];

export const MAIN_BACKGROUNDS = [
    {
        path: '/get-started',
        background: loginBackground,
        mobileImg: getStartedMobile,
        img: getStartedM
    },
    {
        path: '/login',
        background: loginBackground,
        img: getStartedM
    },
    {
        path: '/registration',
        background: loginBackground,
        img: getStartedM,
        imgClasses: 'image-d-n'
    },
    {
        path: '/forgot-password',
        background: forgotPasswordBackground,
        img: passwordM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/recovery',
        background: forgotPasswordBackground,
        img: passwordM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/create-company',
        background: createCompanyBackground,
        img: almostDoneM,
        imgClasses: 'image-d-n'
    },
    {
        path: '/verification',
        background: verificationBackground,
        img: verificationM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/request-company',
        background: createCompanyBackground,
        img: almostDoneM
    },
    {
        path: '/waiting-admin-review',
        background: createCompanyBackground,
        img: almostDoneM
    },
    {
        path: '/reject-join-request',
        background: notVerified,
        img: requestM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/request-company',
        background: requestSent,
        img: almostDoneM
    },
    {
        path: '/successed-request',
        background: requestSent,
        img: requestSentM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/rejected-create-company',
        background: notVerified,
        img: requestM
    },
    {
        path: '/company-deactivated',
        background: notVerified,
        img: requestM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/user-banned',
        background: notVerified,
        img: requestM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/user-join-banned',
        background: notVerified,
        img: requestM
    },
    {
        path: '/company-expired-user',
        background: notVerified,
        img: requestM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/company-expired-admin',
        background: notVerified,
        img: requestM,
        imgClasses: 'narrower-image'
    },
    {
        path: '/contact-support-team',
        background: loginBackground,
        img: getStartedM
    },
]

export const CompaniesTableItems = [
    {title: 'No.', value: null},
    {title: 'Names', value: 'name'},
    {title: 'Address', value: 'address'},
    {title: 'Domain', value: 'domain'},
    {title: 'Expiry Date', value: 'expiration'},
    {title: 'Reseller', value: null},
    {title: 'Licence', value: 'license'},
    {title: 'Status', value: 'activated'},
    {title: 'Activate', value: null},
    {title: 'Actions', value: null},
]

export const JobsTableItems = [
    {title: 'No.', value: null},
    {title: 'Job Number', value: 'jobId'},
    {title: 'Client Full Name', value: 'name'},
    {title: 'Job Address', value: 'address'},
    {title: 'Consultant', value: 'consultant'},
    {title: 'Created Date', value: 'created'},
    {title: 'Completed Date', value: 'completed'},
    {title: 'Latest Activity', value: 'latestActivity'},
    {title: 'Status', value: 'status'},
    {title: 'Actions', value: null}
]

export const JobsTableItemsCompanyPortal = [
    {title: 'No.', value: null},
    {title: 'Job Number', value: 'jobId'},
    {title: 'Client Full Name', value: 'name'},
    {title: 'Consultant', value: 'consultant'},
    {title: 'Design Range', value: 'designRange'},
    {title: 'Inclusion Guide', value: 'package'},
    {title: 'Status', value: 'status'},
    {title: 'Actions', value: null},
]

export const CompaniesTableItemsRequests = [
    {title: 'No.', value: null},
    {title: 'Company Name', value: 'name'},
    {title: 'Company Domain', value: 'domain'},
    {title: 'Company Address', value: 'address'},
    {title: 'Request Position', value: 'position'},
    {title: 'Comment', value: null},
    {title: 'Logo', value: null},
    {title: 'Status', value: 'status'},
    {title: 'Actions', value: null}
]

export const CompanyUsersTableItems = [
    {title: "No.", value: null},
    {title: "Name", value: 'name'},
    {title: "Email Address", value: 'email'},
    {title: "Role", value: 'role'},
    {title: "Actions", value: null}
]

export const CompanyUsersTableItemsRequests = [
    {title: "No.", value: null},
    {title: "Name", value: 'name'},
    {title: "Email", value: 'email'},
    {title: "Status", value: 'status'},
    {title: "Actions", value: null}
]

export const ExportsCompanyTableItems = [
    {title: 'No.', value: null},
    {title: 'Name', value: null},
    {title: 'Manufacturer', value: null},
    {title: 'Product Code', value: null},
    {title: 'Category', value: null},
    {title: 'Photo', value: null},
]

export const CatAttVenTableItems = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Actions', value: null}
]
export const CatTableItems = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Items', value: 'items'},
    {title: 'Actions', value: null}
]

export const PigItems = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Version', value: 'version'},
    {title: 'Actions', value: null}
]

export const SchemeItems = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Items', value: 'items'},
    {title: 'Actions', value: null}
]

export const ReportsItems = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Design Range', value: 'designRange'},
    {title: 'Active', value: 'active'},
    {title: 'Actions', value: null}
]

export const ReportsItemsLibrary = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    //{title: '', value: null},
    //{title: '', value: null},
    {title: 'Note', value: 'note'},
    {title: 'Actions', value: null},
    {title: '', value: null},

]

export const ReportsItemsLibraryIpad = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: '', value: null},
    {title: 'Range', value: 'range'},
    {title: 'Actions', value: null},
    {title: '', value: null},

]

export const ReportsItemsSuperAdmin = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Type', value: null},
    {title: 'Range', value: 'range'},
    {title: 'Note', value: 'note'},
    {title: 'Status', value: 'status'},
    {title: 'Publish', value: null},
    {title: 'Actions', value: null}
]

export const ReportsItemsSuperAdminIpad = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Type', value: 'type'},
    {title: 'Range', value: 'range'},
    {title: 'Publish', value: null},
    {title: 'Actions', value: null}
]

export const PlaceholdersItems = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Category', value: null},
    {title: 'Actions', value: null}
]

export const CPDashboardMainFirst = [
    {title: 'Items', img: assets, path: 'company-items'},
    {title: 'Inclusion Guide', img: productIncusionGuide, path: 'package'},
    {title: 'Colour Scheme', img: colourSchema, path: 'colour-schemes'},
    {title: 'Categories', img: categories, path: 'categories'},
    {title: 'Attributes', img: attributes, path: 'company-attributes'},
]

export const CPDashboardMainSecond = [
    {title: 'Jobs', img: jobs, path: 'jobs'},
    {title: 'Design Range', img: reportTemplate, path: 'design-ranges'},
    {title: 'Report - Placeholders', img: reportPlaceholders, path: 'company-placeholders'},
    {title: 'Users', img: users, path: 'company-users'},
    {title: 'Settings', img: setting, path: 'settings'},
]

export const CPDashboardConsultantMainSecond = [
    {title: 'Jobs', img: jobs, path: 'jobs'},
    {title: 'Design Range', img: reportTemplate, path: 'design-ranges'},
    {title: 'Report - Placeholders', img: reportPlaceholders, path: 'company-placeholders'},
    {title: 'Settings', img: setting, path: 'settings'},
    {title: null, img: null, path: null},
]

export const SADashboardMainFirst = [
    {title: 'Assets', img: assets, path: 'assets'},
    {title: 'Categories', img: categories, path: 'categories'},
    {title: 'Vendors', img: vendors, path: 'vendors'},
    {title: 'Attributes', img: attributes, path: 'attributes'},
    {title: null, img: null, path: null},
]

export const SADashboardMainSecond = [
    {title: 'Jobs', img: jobs, path: 'jobs'},
    {title: 'Companies', img: companies, path: 'companies'},
    {title: 'Users', img: users, path: 'company-users'},
    {title: 'Resellers', img: resellers, path: 'reseller'},
    {title: 'Settings', img: setting, path: 'settings'},
]

export const enhancingDashboardItems = [
    {title: 'Jobs', img: jobs, path: 'jobs'},
    {title: 'Items', img: assets, path: 'company-items'},
    {title: 'Inclusion Guide', img: productIncusionGuide, path: 'package'},
    {title: 'Colour Scheme', img: colourSchema, path: '/'},
    {title: 'Design Range', img: reportTemplate, path: 'design-ranges'},
    {title: 'Attributes', img: attributes, path: 'company-attributes'},
]

export const companyTableItemsCp = [
    {title: 'Name', value: 'name'},
    {title: 'Vendor', value: 'vendor'},
    {title: 'Category', value: 'category'},
    {title: 'SKU', value: 'sku'},
    {title: 'Image', value: null},
    {title: 'Resource', value: null},
    // {title: 'Deactivate', value: null},
    {title: 'Actions', value: null},
    {title: 'Actions', value: 'fa-bars'}
]
export const companyTableItems = [
    {title: 'Name', value: 'name'},
    {title: 'Vendor', value: 'vendor'},
    {title: 'Category', value: 'category'},
    {title: 'SKU', value: 'sku'},
    {title: 'Image', value: null},
    {title: 'Resource', value: null},
    {title: 'Actions', value: null},
    {title: 'Actions', value: 'show_button_remove'}
]

export const AssetsRequestsItems = [
    {title: 'Name', value: 'name'},
    {title: 'Vendor', value: 'vendor'},
    {title: 'Category', value: 'category'},
    {title: 'Image', value: null},
    {title: 'Last Update', value: 'lastUpdated'},
    {title: 'Status', value: 'status'},
    {title: 'Actions', value: null},
    {title: 'Actions', value: 'show_button_remove'}
]

export const AssetsSARequestsItems = [
    {title: 'Name', value: 'name'},
    {title: 'Company', value: 'company'},
    {title: 'Image', value: null},
    {title: 'Last Update', value: 'lastUpdated'},
    {title: 'Status', value: 'status'},
    {title: 'Actions', value: null},
    {title: 'Actions', value: 'show_button_remove'}
]

export const jobMarkup = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'Version', value: null},
    {title: 'Actions', value: null}
]

export const designRange = [
    {title: 'No.', value: null},
    {title: 'Name', value: 'name'},
    {title: 'In Use', value: 'jobs'},
    {title: 'Status', value: 'status'},
    {title: 'Active', value: 'active'},
    {title: 'Actions', value: null}
]

export const ExpandRectangle = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="9" viewBox="0 0 21 9">
    <rect width="21" height="9" rx="1"/>
</svg>

export const ReducedRectangle = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="3" viewBox="0 0 21 3">
    <rect width="21" height="3" rx="1"/>
</svg>

export const reportTemplateStatus = {
    draft: 'Draft',
    published: 'Published',
    deactivated: 'Deactivated'
};
export const CrossDeleteAttributeValue = <svg xmlns="http://www.w3.org/2000/svg" width="9"
                                              height="8" viewBox="0 0 9 8" fill="none">
    <path
        d="M1.66027 0.410771L4.3996 3.1501L7.10329 0.446409L7.96935 1.31247L5.26566 4.01616L8.02423 6.77473L7.05436 7.7446L4.29579 4.98603L1.60539 7.67643L0.739325 6.81037L3.42973 4.11997L0.690401 1.38064L1.66027 0.410771Z"
    />
</svg>

export const ResetIcon = <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
<path d="M0.252101 4.49153C0.0840336 4.49153 0 4.32203 0 4.23729V0.254237C0 0.0847458 0.0840336 0 0.252101 0H1.17647C1.34454 0 1.42857 0.0847458 1.42857 0.254237V1.77966C2.35294 0.762712 3.61345 0.169492 5.12605 0.169492C7.81513 0.169492 10 2.37288 10 5.08475C10 7.79661 7.81513 10 5.12605 10C3.86555 10 2.68908 9.49152 1.84874 8.72881C1.68067 8.64407 1.68067 8.47458 1.76471 8.38983L2.43698 7.71186C2.52101 7.62712 2.68908 7.62712 2.77311 7.71186C3.36134 8.22034 4.20168 8.55932 5.04202 8.55932C6.97479 8.55932 8.4874 7.0339 8.4874 5.08475C8.4874 3.13559 6.97479 1.61017 5.04202 1.61017C3.86555 1.61017 2.85714 2.20339 2.26891 3.05085H4.20168C4.36975 3.05085 4.45378 3.13559 4.45378 3.30508V4.23729C4.45378 4.40678 4.36975 4.49153 4.20168 4.49153H0.252101Z"/>
</svg>

export const flagIcon = <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
    <path d="M0 12V1.125C0 0.503672 0.503672 0 1.125 0H7.875C8.49633 0 9 0.503672 9 1.125V12L4.5 9.375L0 12Z"/>
</svg>

export const Eye_circle = <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18">
    <circle cx="9.6582" cy="9" r="9"/>
    <path d="M14.6984 8.99952C14.6984 9.07152 14.6984 9.14352 14.6264 9.21552C13.6904 11.0155 11.8904 12.2395 9.87439 12.2395C7.85839 12.2395 5.98639 11.0155 5.05039 9.21552C4.97839 9.14352 4.97839 9.07152 4.97839 8.99952C4.97839 8.92752 4.97839 8.85552 5.05039 8.78352C5.98639 6.98352 7.78639 5.75952 9.87439 5.75952C11.9624 5.75952 13.7624 6.98352 14.6984 8.78352C14.6984 8.85552 14.6984 8.92752 14.6984 8.99952ZM9.87439 11.4475C11.2424 11.4475 12.3224 10.3675 12.3224 8.99952C12.3224 7.63152 11.2424 6.55152 9.87439 6.55152C8.50639 6.55152 7.42639 7.63152 7.42639 8.99952C7.42639 10.3675 8.50639 11.4475 9.87439 11.4475ZM9.87439 7.41552C10.7384 7.41552 11.4584 8.13552 11.4584 8.99952C11.4584 9.86352 10.7384 10.5835 9.87439 10.5835C9.01039 10.5835 8.29039 9.86352 8.29039 8.99952C8.29039 8.85552 8.29039 8.71152 8.36239 8.56752C8.43439 8.63952 8.57839 8.71152 8.79439 8.71152C9.22639 8.71152 9.58639 8.35152 9.58639 7.91952C9.58639 7.77552 9.51439 7.55952 9.44239 7.41552C9.51439 7.41552 9.65839 7.41552 9.87439 7.41552Z" fill="white"/>
</svg>

export const addItemToCompanyTitles = ['All Assets', 'Imported', 'Available Items', 'Available by Filter', 'New Selection']
export const addItemToCompanyTitlesPig = ['Selected', 'Available Items', 'Available by Filter', 'New Selection']
export const addItemToCompanyTitlesCustomise = ['Currently Selected', 'Available Items', 'Remove From Current Selection', 'New Selection', 'Select All']
export const addItemToCompanyTitlesJob = ['Available Items', 'Available by Filter']

export const selectAll = 'All';
export const unselectAll = 'Unselect All';

export const companyAssetsCreateRequestsStatusItems = [
    {name: 'All Requests', value: null},
    {name: 'Approved', value: 'approved'},
    {name: 'Under Review', value: 'under_review'},
    {name: 'Rejected', value: 'rejected'},
    {name: 'Cancelled', value: 'cancelled'},
];

//region License
export const LicenseBasic = {id: 1, name: 'Basic', value: 'license_basic', identifier: 'license_basic', durationDays: 90};
export const LicensePremium = {id: 2, name: 'Premium', value: 'license_premium', identifier: 'license_premium', durationDays: 30};

export const CompanyLicenseItems = [LicenseBasic, LicensePremium];
//endregion

export const JobsIcon =  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path d="M0.115701 2.50943V0.528302C0.115701 0.264151 0.347107 0 0.578512 0H11.9174C12.1488 0 12.3802 0.264151 12.3802 0.528302V2.50943C12.3802 2.77358 12.1488 3.03774 11.9174 3.03774H0.578512C0.347107 3.03774 0.115701 2.77358 0.115701 2.50943ZM12.2645 11.4906V13.4717C12.2645 13.7358 12.0331 14 11.8017 14H0.462812C0.231407 14 0 13.7358 0 13.4717V11.4906C0.115703 11.2264 0.347107 10.9623 0.578512 10.9623H11.9174C12.0331 10.9623 12.2645 11.2264 12.2645 11.4906ZM14 6.07547V8.0566C14 8.32076 13.7686 8.58491 13.5372 8.58491H2.19835C1.96694 8.58491 1.73554 8.32076 1.73554 8.0566V6.07547C1.73554 5.81132 1.96694 5.54717 2.19835 5.54717H13.5372C13.7686 5.54717 14 5.67925 14 6.07547Z"/>
</svg>

export const AssetsIcon = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
    <path d="M7.17647 8.41481V13.7481C7.17647 13.9852 6.94117 14.2222 6.70588 14.2222H0.47059C0.235296 14.2222 0 13.9852 0 13.7481V8.41481C0 8.17778 0.235296 7.94074 0.47059 7.94074H2.70588V10.6667L3.64706 10.0741L4.58824 10.6667V8.05926H6.82352C6.94117 8.05926 7.17647 8.17778 7.17647 8.41481ZM4.58824 5.80741V0.474074C4.58824 0.237037 4.82352 0 5.05882 0H7.29412V2.72593L8.2353 2.13333L9.17647 2.72593V0H11.4118C11.6471 0 11.8824 0.237037 11.8824 0.474074V5.80741C11.8824 6.04444 11.6471 6.28148 11.4118 6.28148H5.17647C4.70588 6.28148 4.58824 6.04444 4.58824 5.80741ZM16 8.41481V13.7481C16 13.9852 15.7647 14.2222 15.5294 14.2222H9.29412C9.05883 14.2222 8.82353 13.9852 8.82353 13.7481V8.41481C8.82353 8.17778 9.05883 7.94074 9.29412 7.94074H11.5294V10.6667L12.4706 10.0741L13.4118 10.6667V8.05926H15.6471C15.7647 8.05926 16 8.17778 16 8.41481Z"/>
</svg>

export const SettingsIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
    <path d="M15 10.3248C14.5961 11.4188 14.0576 12.5128 13.2498 13.3333C13.1151 13.4701 12.8459 13.6068 12.7112 13.4701L11.3649 12.6496C10.8264 13.1966 10.1532 13.4701 9.48006 13.7436V15.3846C9.48006 15.5214 9.34543 15.6581 9.21079 15.7949C8.13373 16.0684 6.92204 16.0684 5.71034 15.7949C5.57571 15.7949 5.44108 15.6581 5.44108 15.3846V13.7436C4.76791 13.4701 4.09475 13.0598 3.55622 12.6496L2.20989 13.4701C2.07526 13.6068 1.80599 13.4701 1.80599 13.3333C0.998195 12.5128 0.459665 11.4188 0.0557671 10.3248C0.0557671 10.188 0.0557651 9.91453 0.190398 9.91453L1.53673 9.09402C1.40209 8.41026 1.40209 7.58974 1.53673 6.90598L0.190398 6.08547C0.0557651 5.94872 -0.0788656 5.81197 0.0557671 5.67521C0.459665 4.5812 0.998195 3.48718 1.80599 2.66667C1.80599 2.52991 2.07526 2.39316 2.20989 2.52991L3.55622 3.35043C4.09475 2.80342 4.76791 2.52991 5.44108 2.25641V0.615385C5.44108 0.478633 5.57571 0.34188 5.71034 0.205128C6.78741 -0.0683761 7.9991 -0.0683761 9.21079 0.205128C9.34543 0.205128 9.48006 0.34188 9.48006 0.615385V2.25641C10.1532 2.52991 10.8264 2.94017 11.3649 3.35043L12.7112 2.52991C12.8459 2.39316 13.1151 2.52991 13.1151 2.66667C13.9229 3.48718 14.4615 4.5812 14.8654 5.67521C14.8654 5.81197 14.8654 6.08547 14.7307 6.08547L13.3844 6.90598C13.519 7.58974 13.519 8.41026 13.3844 9.09402L14.7307 9.91453C14.8654 10.0513 15 10.188 15 10.3248ZM10.0186 8C10.0186 6.63248 8.94153 5.40171 7.46057 5.40171C5.97961 5.40171 4.90254 6.63248 4.90254 8C4.90254 9.36752 6.11424 10.5983 7.46057 10.5983C8.8069 10.5983 10.0186 9.36752 10.0186 8Z"/>
</svg>

export const InclusionGuidIcon = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path d="M14 9.84375V0.65625C14 0.292578 13.6656 0 13.25 0H3C1.34375 0 0 1.17578 0 2.625V11.375C0 12.8242 1.34375 14 3 14H13.25C13.6656 14 14 13.7074 14 13.3438V12.9062C14 12.7012 13.8906 12.5152 13.7219 12.3949C13.5906 11.9738 13.5906 10.7734 13.7219 10.3523C13.8906 10.2348 14 10.0488 14 9.84375ZM4 3.66406C4 3.57383 4.08437 3.5 4.1875 3.5H10.8125C10.9156 3.5 11 3.57383 11 3.66406V4.21094C11 4.30117 10.9156 4.375 10.8125 4.375H4.1875C4.08437 4.375 4 4.30117 4 4.21094V3.66406ZM4 5.41406C4 5.32383 4.08437 5.25 4.1875 5.25H10.8125C10.9156 5.25 11 5.32383 11 5.41406V5.96094C11 6.05117 10.9156 6.125 10.8125 6.125H4.1875C4.08437 6.125 4 6.05117 4 5.96094V5.41406ZM11.9187 12.25H3C2.44688 12.25 2 11.859 2 11.375C2 10.8938 2.45 10.5 3 10.5H11.9187C11.8594 10.9676 11.8594 11.7824 11.9187 12.25Z"/>
</svg>

export const ColorSchemeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path d="M5.58613 0.137679C2.86809 0.668156 0.677807 2.85296 0.141857 5.56277C-0.869886 10.6761 3.74311 14.4879 7.21859 13.9492C8.34518 13.7742 8.89753 12.4562 8.38073 11.4418C7.74907 10.2003 8.65143 8.7511 10.046 8.7511H12.2253C13.2043 8.7511 13.9973 7.94171 14 6.96552C13.9863 2.65608 10.0651 -0.7346 5.58613 0.137679ZM2.62473 8.7511C2.14073 8.7511 1.74971 8.36007 1.74971 7.87608C1.74971 7.39209 2.14073 7.00107 2.62473 7.00107C3.10872 7.00107 3.49975 7.39209 3.49975 7.87608C3.49975 8.36007 3.10872 8.7511 2.62473 8.7511ZM3.49975 5.25104C3.01575 5.25104 2.62473 4.86002 2.62473 4.37603C2.62473 3.89204 3.01575 3.50101 3.49975 3.50101C3.98375 3.50101 4.37477 3.89204 4.37477 4.37603C4.37477 4.86002 3.98375 5.25104 3.49975 5.25104ZM6.99983 3.50101C6.51584 3.50101 6.12481 3.10999 6.12481 2.626C6.12481 2.14201 6.51584 1.75099 6.99983 1.75099C7.48383 1.75099 7.87485 2.14201 7.87485 2.626C7.87485 3.10999 7.48383 3.50101 6.99983 3.50101ZM10.4999 5.25104C10.0159 5.25104 9.6249 4.86002 9.6249 4.37603C9.6249 3.89204 10.0159 3.50101 10.4999 3.50101C10.9839 3.50101 11.3749 3.89204 11.3749 4.37603C11.3749 4.86002 10.9839 5.25104 10.4999 5.25104Z"/>
</svg>

export const CategoriesIcon = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path d="M12.6875 0H1.3125C0.587617 0 0 0.671562 0 1.5V12.5C0 13.3284 0.587617 14 1.3125 14H12.6875C13.4124 14 14 13.3284 14 12.5V1.5C14 0.671562 13.4124 0 12.6875 0ZM6.125 12H1.75V9H6.125V12ZM6.125 7H1.75V4H6.125V7ZM12.25 12H7.875V9H12.25V12ZM12.25 7H7.875V4H12.25V7Z"/>
</svg>

export const AttributesIcon = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <path d="M13.8096 4.77967L12.7268 3.69702C12.4698 3.44002 12.0542 3.44002 11.7999 3.69702L5.25124 10.2449L2.20522 7.1965C1.94819 6.93951 1.53258 6.93951 1.27829 7.1965L0.192769 8.28189C-0.0642562 8.53889 -0.0642562 8.95445 0.192769 9.21144L4.78641 13.8073C5.04343 14.0642 5.45905 14.0642 5.71334 13.8073L13.8069 5.70922C14.0639 5.44949 14.0639 5.03393 13.8096 4.77967ZM4.94226 7.67768C5.11179 7.84992 5.39069 7.84992 5.56022 7.67768L11.2476 1.98555C11.4171 1.81331 11.4171 1.53718 11.2476 1.36767L10.0117 0.12918C9.84214 -0.0430601 9.56324 -0.0430601 9.39371 0.12918L5.25124 4.27115L3.73643 2.7538C3.56691 2.58156 3.28801 2.58156 3.11848 2.7538L1.87984 3.99229C1.71031 4.16453 1.71031 4.44066 1.87984 4.61016L4.94226 7.67768Z"/>
</svg>

export const UsersIcon = <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
    <path d="M12.25 11.55V12.7167C12.25 13.4167 11.6667 14 10.9667 14H1.28333C0.583333 14 0 13.4167 0 12.7167V11.55C0 9.56667 1.63333 7.93333 3.61667 7.93333H4.08333C4.78333 8.16667 5.36667 8.28333 6.18333 8.28333C7 8.28333 7.46667 8.16667 8.16667 7.93333H8.63333C10.6167 7.93333 12.25 9.56667 12.25 11.55ZM2.68333 3.5C2.68333 1.51667 4.2 0 6.18333 0C8.16667 0 9.68333 1.51667 9.68333 3.5C9.68333 5.48333 8.16667 7 6.18333 7C4.2 7 2.68333 5.48333 2.68333 3.5Z"/>
</svg>

export const companyStatisticsItems = [
    {name: 'Assets', value: 'assetsTotal', icon: AssetsIcon, color: '#1AB394'},
    {name: 'Attributes', value: 'attributesTotal', icon: AttributesIcon, color: '#53677A'},
    {name: 'Categories', value: 'categoriesTotal', icon: CategoriesIcon, color: '#1090C0'},
    {name: 'Colour Scheme', value: 'colourSchemesTotal', icon: ColorSchemeIcon, color: '#05BAE5'},
    {name: 'Items', value: 'itemsTotal', icon: AssetsIcon, color: '#1AB394'},
    {name: 'InProgress Jobs', value: 'jobsInProgress', icon: JobsIcon, color: '#FFBB33'},
    {name: 'New Jobs', value: 'jobsNew', icon: JobsIcon, color: '#FFBB33'},
    {name: 'Total Jobs', value: 'jobsTotal', icon: JobsIcon, color: '#FFBB33'},
    {name: 'Inclusion Guide', value: 'productInclusionGuidesTotal', icon: InclusionGuidIcon, color: '#C16CC2'},
    {name: 'Users', value: 'usersTotal', icon: UsersIcon, color: '#FF4444'},
    {name: 'Deactivated Items', value: 'itemsDeactivatedTotal', icon: AssetsIcon, color: '#1AB394'},
]

export const timezoneAustralia = 'Australia/Sydney';

export const COMPANY_ITEMS_AVAILABLE_DEACTIVATED = [
    {
        name: 'All Items',
        status: undefined
    },
    {
        name: 'Available Items',
        status: true
    },
    {
        name: 'Deactivated Items',
        status: false
    }
]

export const four_rectangles_group = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18"
                                          fill="none">
    <rect x="0.5" y="0.958984" width="17" height="3.00001" rx="1"/>
    <rect x="0.5" y="5.62549" width="17" height="3.00001" rx="1"/>
    <rect x="0.5" y="10.2925" width="17" height="3.00001" rx="1"/>
    <rect x="0.5" y="14.959" width="17" height="3.00001" rx="1"/>
</svg>;

export const two_rectangles_group = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 18"
                                         fill="none">
    <rect y="0.958984" width="17" height="7.65" rx="1"/>
    <rect y="10.3091" width="17" height="7.65" rx="1"/>
</svg>;

export const four_square_rectangles_group = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                 viewBox="0 0 18 18" fill="none">
    <rect x="9.77246" y="0.958984" width="7.72727" height="7.65" rx="1"/>
    <rect x="9.77246" y="10.3091" width="7.72727" height="7.65" rx="1"/>
    <rect x="0.5" y="0.958984" width="7.72727" height="7.65" rx="1"/>
    <rect x="0.5" y="10.3091" width="7.72727" height="7.65" rx="1"/>
</svg>;

export const COMPANY_ITEMS_VIEW_MODES = [
    {
        name: four_rectangles_group,
        status: false,
        tooltip: 'Compact View',
        placement: 'top',
        marginRight: 0,
        marginLeft: 0,
        top: 9,
    },
    {
        name: two_rectangles_group,
        status: true,
        tooltip: 'Normal View',
        placement: 'top',
        marginRight: 0,
        marginLeft: 0,
        top: 9,
    },
    {
        name: four_square_rectangles_group,
        status: 'disabled',
        tooltip: 'Grid View',
        placement: 'top',
        marginRight: 0,
        marginLeft: 0,
        top: 9,
    }
]

export const Design_Range_statuses = [
    {
        name: 'All Ranges',
        value: undefined
    },
    {
        name: 'Available',
        value: 'Active'
    },
    {
        name: 'Archived',
        value: 'Archived'
    }
]

export const DESIGN_STATUSES = {
    Deactivated: 'Deactivated',
    Archived: 'Archived',
    Active: 'Active',
}
