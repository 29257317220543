export const ADD_REPORT_TEMPLATE_START = 'ADD_REPORT_TEMPLATE_START';
export const ADD_REPORT_TEMPLATE_FAILED = 'ADD_REPORT_TEMPLATE_FAILED';

export const EDIT_REPORT_TEMPLATE_START = 'EDIT_REPORT_TEMPLATE_START';
export const EDIT_REPORT_TEMPLATE_FINISHED = 'EDIT_REPORT_TEMPLATE_FINISHED';

export const REMOVE_REPORT_TEMPLATE_START = 'REMOVE_REPORT_TEMPLATE_START';
export const REMOVE_REPORT_TEMPLATE_FINISHED = 'REMOVE_REPORT_TEMPLATE_FINISHED';

export const GET_REPORT_TEMPLATES_START = 'GET_REPORT_TEMPLATES_START';
export const GET_REPORT_TEMPLATES_FAILED = 'GET_REPORT_TEMPLATES_FAILED';
export const SET_REPORT_TEMPLATES = 'SET_REPORT_TEMPLATES';

export const GET_REPORT_TEMPLATE_BY_ID_START = 'GET_REPORT_TEMPLATE_BY_ID_START';
export const GET_REPORT_TEMPLATE_BY_ID_FAILED = 'GET_REPORT_TEMPLATE_BY_ID_FAILED';
export const SET_REPORT_TEMPLATE_BY_ID = 'SET_REPORT_TEMPLATE_BY_ID';

export const ADD_REPORT_TEMPLATE_HEADER_START = 'ADD_REPORT_TEMPLATE_HEADER_START';
export const ADD_REPORT_TEMPLATE_HEADER_FINISHED = 'ADD_REPORT_TEMPLATE_HEADER_FINISHED';

export const EDIT_REPORT_TEMPLATE_HEADER = 'EDIT_REPORT_TEMPLATE_HEADER';
export const EDIT_REPORT_TEMPLATE_HEADER_START = 'EDIT_REPORT_TEMPLATE_HEADER_START';
export const EDIT_REPORT_TEMPLATE_HEADER_FINISHED = 'EDIT_REPORT_TEMPLATE_HEADER';

export const REMOVE_REPORT_TEMPLATE_HEADER_START = 'REMOVE_REPORT_TEMPLATE_HEADER_START';
export const REMOVE_REPORT_TEMPLATE_HEADER_FINISHED = 'REMOVE_REPORT_TEMPLATE_HEADER_FINISHED';

export const GET_PLACEHOLDERS_START = 'GET_PLACEHOLDERS_START';
export const GET_PLACEHOLDERS_FAILED = 'GET_PLACEHOLDERS_FAILED';
export const SET_PLACEHOLDERS = 'SET_PLACEHOLDERS';

export const ADD_PLACEHOLDER_START = 'ADD_PLACEHOLDER_START';
export const ADD_PLACEHOLDER_FAILED = 'ADD_PLACEHOLDER_FAILED';
export const ADD_PLACEHOLDER_FINISHED = 'ADD_PLACEHOLDER_FINISHED';

export const EDIT_PLACEHOLDER_START = 'EDIT_PLACEHOLDER_START';
export const EDIT_PLACEHOLDER_FINISHED = 'EDIT_PLACEHOLDER_FINISHED';

export const REMOVE_PLACEHOLDER_START = 'REMOVE_PLACEHOLDER_START';
export const REMOVE_PLACEHOLDER_FINISHED = 'REMOVE_PLACEHOLDER_FINISHED';

export const ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_START = 'ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_START';
export const ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FINISHED = 'ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FINISHED';
export const ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FAILED = 'ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FAILED';

export const REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_START = 'REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_START';
export const REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_FINISHED = 'REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_FINISHED';

export const REMOVE_SELECTION_TYPE_FROM_REPORT_TEMPLATE_START = 'REMOVE_SELECTION_TYPE_FROM_REPORT_TEMPLATE_START';
export const REMOVE_SELECTION_TYPE_FROM_REPORT_TEMPLATE_FINISHED = 'REMOVE_SELECTION_TYPE_FROM_REPORT_TEMPLATE_FINISHED';

export const DRAG_AND_DROP_REPORT_TEMPLATE_START = 'DRAG_AND_DROP_REPORT_TEMPLATE_START';
export const DRAG_AND_DROP_REPORT_TEMPLATE_SUCCESS = 'DRAG_AND_DROP_REPORT_TEMPLATE_SUCCESS';
export const DRAG_AND_DROP_REPORT_TEMPLATE_FAILED = 'DRAG_AND_DROP_REPORT_TEMPLATE_FAILED';
export const DRAG_AND_DROP_REPORT_TEMPLATE = 'DRAG_AND_DROP_REPORT_TEMPLATE';

export const GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_START = 'GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_START';
export const GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_FAILED = 'GET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING_FAILED';
export const SET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING = 'SET_REPORT_TEMPLATE_BY_ID_AFTER_DRAGGING';

export const ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_START = 'ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET';
export const ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_FINISHED = 'ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_FINISHED';
export const ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET_FAILED = 'ADD_CUSTOM_ATTRIBUTE_VALUE_TO_ASSET';

export const DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_START = 'DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_START';
export const DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FINISHED = 'DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FINISHED';
export const DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FAILED = 'DELETE_CUSTOM_ATTRIBUTE_FROM_ASSET_FAILED';

export const ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_START = 'ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_START';
export const ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FINISHED = 'ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FINISHED';
export const ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FAILED = 'ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FAILED';

export const DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_START = 'DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_START';
export const DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FINISHED = 'DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FINISHED';
export const DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FAILED = 'DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FAILED';

export const GET_RESELLERS_START = 'GET_RESELLERS_START';
export const GET_RESELLERS_FAILED = 'GET_RESELLERS_FAILED';
export const SET_RESELLERS = 'SET_RESELLERS';

export const ADD_RESELLER_START = 'ADD_RESELLER_START';
export const ADD_RESELLER_FINISHED = 'ADD_RESELLER_FINISHED';
export const ADD_RESELLER_FAILED = 'ADD_RESELLER_FAILED';

export const EDIT_RESELLER_START = 'EDIT_RESELLER_START';
export const EDIT_RESELLER_FINISHED = 'EDIT_RESELLER_FINISHED';
export const EDIT_RESELLER_FAILED = 'EDIT_RESELLER_FAILED';

export const GET_RESELLER_BY_ID_START = 'GET_RESELLER_BY_ID_START';
export const GET_RESELLER_BY_ID_FAILED= 'GET_RESELLER_BY_ID_FAILED';
export const SET_RESELLER = 'SET_RESELLER';

export const DELETE_RESELLER_START = 'DELETE_RESELLER_START';
export const DELETE_RESELLER_FINISHED = 'DELETE_RESELLER_FINISHED';
export const DELETE_RESELLER_FAILED = 'DELETE_RESELLER_FAILED';

export const UPLOAD_RESELLER_IMAGE_START = 'UPLOAD_RESELLER_IMAGE_START';
export const UPLOAD_RESELLER_IMAGE_FINISHED = 'UPLOAD_RESELLER_IMAGE_FINISHED';
export const UPLOAD_RESELLER_IMAGE_FAILED = 'UPLOAD_RESELLER_IMAGE_FAILED';

export const GET_RESELLER_USERS_START = 'GET_RESELLER_USERS_START';
export const SET_RESELLER_USERS = 'SET_RESELLER_USERS';
export const GET_RESELLER_USERS_FAILED = 'GET_RESELLER_USERS_FAILED';

export const GET_RESELLER_USERS_RESELLER_PORTAL_START = 'GET_RESELLER_USERS_RESELLER_PORTAL_START';
export const SET_RESELLER_USERS_RESELLER_PORTAL = 'SET_RESELLER_USERS_RESELLER_PORTAL';
export const GET_RESELLER_USERS_RESELLER_PORTAL_FAILED = 'GET_RESELLER_USERS_RESELLER_PORTAL_FAILED';

export const ADD_USER_TO_RESELLER_START = 'ADD_USER_TO_RESELLER_START';
export const ADD_USER_TO_RESELLER_FINISHED = 'ADD_USER_TO_RESELLER_FINISHED';
export const ADD_USER_TO_RESELLER_FAILED = 'ADD_USER_TO_RESELLER_FAILED';

export const DELETE_USER_FROM_RESELLER_START = 'DELETE_USER_FROM_RESELLER_START';
export const DELETE_USER_FROM_RESELLER_FINISHED = 'DELETE_USER_FROM_RESELLER_FINISHED';
export const DELETE_USER_FROM_RESELLER_FAILED= 'DELETE_USER_FROM_RESELLER_FAILED';

export const ADD_RESELLER_CHILD_COMPANY_START = 'ADD_RESELLER_CHILD_COMPANY_START';
export const ADD_RESELLER_CHILD_COMPANY_FINISHED = 'ADD_RESELLER_CHILD_COMPANY_FINISHED';
export const ADD_RESELLER_CHILD_COMPANY_FAILED = 'ADD_RESELLER_CHILD_COMPANY_FAILED';

export const GET_RESELLER_CHILD_COMPANIES_START = 'GET_RESELLER_CHILD_COMPANIES_START';
export const GET_RESELLER_CHILD_COMPANIES_FAILED = 'GET_RESELLER_CHILD_COMPANIES_FAILED';
export const SET_RESELLER_CHILD_COMPANIES = 'SET_RESELLER_CHILD_COMPANIES';

export const EDIT_COMPANY_START = 'EDIT_COMPANY_START';
export const EDIT_COMPANY_FINISHED = 'EDIT_COMPANY_FINISHED';
export const EDIT_COMPANY_FAILED = 'EDIT_COMPANY_FAILED';

export const DELETE_COMPANY_START = 'DELETE_COMPANY_START';
export const DELETE_COMPANY_FINISHED = 'DELETE_COMPANY_FINISHED';
export const DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED';

export const GET_RESELLER_CHILD_COMPANY_BY_ID_START = 'GET_RESELLER_CHILD_COMPANY_BY_ID_START';
export const GET_RESELLER_CHILD_COMPANY_BY_ID_FAILED = 'GET_RESELLER_CHILD_COMPANY_BY_ID_FAILED';
export const SET_RESELLER_CHILD_COMPANY_BY_ID = 'SET_RESELLER_CHILD_COMPANY_BY_ID';

export const DELETE_ITEM_FROM_RESELLER_ROASTER_START = 'DELETE_ITEM_FROM_RESELLER_ROASTER_START';
export const DELETE_ITEM_FROM_RESELLER_ROASTER_FINISHED = 'DELETE_ITEM_FROM_RESELLER_ROASTER_FINISHED';
export const DELETE_ITEM_FROM_RESELLER_ROASTER_FAILED = 'DELETE_ITEM_FROM_RESELLER_ROASTER_FAILED';

export const DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_START = 'DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_START';
export const DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FINISHED = 'DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FINISHED';
export const DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FAILED = 'DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FAILED';

export const ADD_USER_TO_RESELLER_CHILD_COMPANY_START = 'ADD_USER_TO_RESELLER_COMPANY_START';
export const ADD_USER_TO_RESELLER_CHILD_COMPANY_FINISHED = 'ADD_USER_TO_RESELLER_CHILD_COMPANY_FINISHED';
export const ADD_USER_TO_RESELLER_CHILD_COMPANY_FAILED = 'ADD_USER_TO_RESELLER_CHILD_COMPANY_FAILED';

export const DELETE_USER_TO_RESELLER_CHILD_COMPANY_START = 'DELETE_USER_TO_RESELLER_CHILD_COMPANY_START';
export const DELETE_USER_TO_RESELLER_CHILD_COMPANY_FINISHED = 'DELETE_USER_TO_RESELLER_CHILD_COMPANY_FINISHED';
export const DELETE_USER_TO_RESELLER_CHILD_COMPANY_FAILED = 'DELETE_USER_TO_RESELLER_CHILD_COMPANY_FAILED';

export const GET_USERS_OF_RESELLER_CHILD_COMPANY_START = 'GET_USERS_OF_RESELLER_COMPANY_START';
export const GET_USERS_OF_RESELLER_CHILD_COMPANY_FAILED= 'GET_USERS_OF_RESELLER_COMPANY_FAILED';
export const SET_USERS_OF_RESELLER_CHILD_COMPANY = 'SET_USERS_OF_RESELLER_CHILD_COMPANY';

export const GET_RESELLER_CHILD_COMPANY_JOBS_START = 'GET_RESELLER_CHILD_COMPANY_JOBS_START';
export const GET_RESELLER_CHILD_COMPANY_JOBS_FAILED = 'GET_RESELLER_CHILD_COMPANY_JOBS_FAILED';
export const SET_RESELLER_CHILD_COMPANY_JOBS = 'SET_RESELLER_CHILD_COMPANY_JOBS';

export const GET_COMPANIES_FOR_RESELLER_CREATION_START = 'GET_COMPANIES_FOR_RESELLER_CREATION_START';
export const GET_COMPANIES_FOR_RESELLER_CREATION_FAILED= 'GET_COMPANIES_FOR_RESELLER_CREATION_FAILED';
export const SET_COMPANIES_FOR_RESELLER_CREATION = 'SET_COMPANIES_FOR_RESELLER_CREATION';

export const DELETE_RESELLER_CHILD_COMPANY_START = 'DELETE_RESELLER_CHILD_COMPANY_START';
export const DELETE_RESELLER_CHILD_COMPANY_FINISHED = 'DELETE_RESELLER_CHILD_COMPANY_FINISHED';
export const DELETE_RESELLER_CHILD_COMPANY_FAILED = 'DELETE_RESELLER_CHILD_COMPANY_FAILED';

export const ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_START = 'ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_START';
export const ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FINISHED = 'ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FINISHED';
export const ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FAILED = 'ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FAILED';

export const EDIT_RESELLER_COMPANY_ITEM_START = 'EDIT_RESELLER_COMPANY_ITEM_START';
export const EDIT_RESELLER_COMPANY_ITEM_FINISHED = 'EDIT_RESELLER_COMPANY_ITEM_FINISHED';
export const EDIT_RESELLER_COMPANY_ITEM_FAILED = 'EDIT_RESELLER_COMPANY_ITEM_FAILED';

export const DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_START = 'DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_START';
export const DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FINISHED = 'DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FINISHED';
export const DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FAILED = 'DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FAILED';

export const ASSIGN_JOB_TO_PARENT_COMPANY_START = 'ASSIGN_JOB_TO_PARENT_COMPANY_START';
export const ASSIGN_JOB_TO_PARENT_COMPANY_FINISHED = 'ASSIGN_JOB_TO_PARENT_COMPANY_FINISHED';
export const ASSIGN_JOB_TO_PARENT_COMPANY_FAILED = 'ASSIGN_JOB_TO_PARENT_COMPANY_FAILED';

export const RETURN_JOB_TO_CHILD_START = 'RETURN_JOB_TO_CHILD_START';
export const RETURN_JOB_TO_CHILD_FINISHED = 'RETURN_JOB_TO_CHILD_FINISHED';
export const RETURN_JOB_TO_CHILD_FAILED = 'RETURN_JOB_TO_CHILD_FAILED';

export const CREATE_VARIATION_REPORT_START = 'CREATE_VARIATION_REPORT_START';
export const CREATE_VARIATION_REPORT_FINISHED = 'CREATE_VARIATION_REPORT_FINISHED';
export const CREATE_VARIATION_REPORT_FAILED = 'CREATE_VARIATION_REPORT_FAILED';

export const ADD_VARIATION_REPORT_RULE_START = 'ADD_VARIATION_REPORT_RULE_START';
export const ADD_VARIATION_REPORT_RULE_FINISHED = 'ADD_VARIATION_REPORT_RULE_FINISHED';
export const ADD_VARIATION_REPORT_RULE_FAILED = 'ADD_VARIATION_REPORT_RULE_FAILED';

export const GET_VARIATION_REPORTS_START = 'GET_VARIATION_REPORTS_START';
export const GET_VARIATION_REPORTS_FAILED = 'GET_VARIATION_REPORTS_FAILED';
export const SET_VARIATION_REPORTS= 'SET_VARIATION_REPORTS';

export const EDIT_VARIATION_REPORT_START = 'EDIT_VARIATION_REPORT_START';
export const EDIT_VARIATION_REPORT_FINISHED = 'EDIT_VARIATION_REPORT_FINISHED';
export const EDIT_VARIATION_REPORT_FAILED = 'EDIT_VARIATION_REPORT_FAILED';

export const EDIT_VARIATION_REPORT_RULE_START = 'EDIT_VARIATION_REPORT_RULE_START';
export const EDIT_VARIATION_REPORT_RULE_FINISHED = 'EDIT_VARIATION_REPORT_RULE_FINISHED';
export const EDIT_VARIATION_REPORT_RULE_FAILED = 'EDIT_VARIATION_REPORT_RULE_FAILED';

export const DELETE_VARIATION_REPORT_START = 'DELETE_VARIATION_REPORT_START';
export const DELETE_VARIATION_REPORT_FINISHED = 'DELETE_VARIATION_REPORT_FINISHED';
export const DELETE_VARIATION_REPORT_FAILED = 'DELETE_VARIATION_REPORT_FAILED';

export const GET_VARIATION_REPORT_HEADERS_START = 'GET_VARIATION_REPORT_HEADERS_START';
export const SET_VARIATION_REPORT_HEADERS = 'SET_VARIATION_REPORT_HEADERS';
export const GET_VARIATION_REPORT_HEADERS_FAILED = 'GET_VARIATION_REPORT_HEADERS_FAILED';

export const GET_TEMPLATE_ATTRIBUTES_START = 'GET_TEMPLATE_ATTRIBUTES_START';
export const GET_TEMPLATE_ATTRIBUTES_FAILED= 'GET_TEMPLATE_ATTRIBUTES_FAILED';
export const SET_TEMPLATE_ATTRIBUTES= 'SET_TEMPLATE_ATTRIBUTES';

export const GET_JOB_VERSIONS_START = 'GET_JOB_VERSIONS_START';
export const GET_JOB_VERSIONS_FAILED = 'GET_JOB_VERSIONS_FAILED';
export const SET_JOB_VERSIONS = 'SET_JOB_VERSIONS';

export const ADD_NOTE_TO_JOB_START = 'ADD_NOTE_TO_JOB_START';
export const ADD_NOTE_TO_JOB_FAILED = 'ADD_NOTE_TO_JOB_FAILED';
export const ADD_NOTE_TO_JOB_FINISHED = 'ADD_NOTE_TO_JOB_FINISHED';

export const EDIT_NOTE_START = 'EDIT_NOTE_START';
export const EDIT_NOTE_FINISHED = 'EDIT_NOTE_FINISHED';
export const EDIT_NOTE_FAILED = 'EDIT_NOTE_FAILED';

export const UPLOAD_NOTE_IMAGE_START = 'UPLOAD_NOTE_IMAGE_START';
export const UPLOAD_NOTE_IMAGE_FINISHED = 'UPLOAD_NOTE_IMAGE_FINISHED';
export const UPLOAD_NOTE_IMAGE_FAILED = 'UPLOAD_NOTE_IMAGE_FAILED';

export const ADD_COMPANY_PACKAGE_START = 'ADD_COMPANY_PACKAGE_START';
export const ADD_COMPANY_PACKAGE_FINISHED = 'ADD_COMPANY_PACKAGE_FINISHED';
export const ADD_COMPANY_PACKAGE_FAILED = 'ADD_COMPANY_PACKAGE_FAILED';

export const DELETE_COMPANY_PACKAGE_START = 'DELETE_COMPANY_PACKAGE_START';
export const DELETE_COMPANY_PACKAGE_FINISHED= 'DELETE_COMPANY_PACKAGE_FINISHED';
export const DELETE_COMPANY_PACKAGE_FAILED = 'DELETE_COMPANY_PACKAGE_FAILED';

export const EDIT_COMPANY_PACKAGE_START = 'EDIT_COMPANY_PACKAGE_START';
export const EDIT_COMPANY_PACKAGE_FINISHED = 'EDIT_COMPANY_PACKAGE_FINISHED';
export const EDIT_COMPANY_PACKAGE_FAILED = 'EDIT_COMPANY_PACKAGE_FAILED';

export const GET_COMPANY_PACKAGE_START = 'GET_COMPANY_PACKAGE_START';
export const SET_COMPANY_PACKAGE = 'SET_COMPANY_PACKAGE';
export const GET_COMPANY_PACKAGE_FAILED = 'GET_COMPANY_PACKAGE_FAILED';

export const GET_COMPANY_PACKAGES_OF_COMPANY_START = 'GET_COMPANY_PACKAGES_OF_COMPANY_START';
export const SET_COMPANY_PACKAGES_OF_COMPANY = 'SET_COMPANY_PACKAGES_OF_COMPANY';
export const GET_COMPANY_PACKAGES_OF_COMPANY_FAILED = 'GET_COMPANY_PACKAGES_OF_COMPANY_FAILED';

export const EDIT_JOB_PACKAGE_START = 'EDIT_JOB_PACKAGE_START';
export const EDIT_JOB_PACKAGE_FINISHED = 'EDIT_JOB_PACKAGE_FINISHED';
export const EDIT_JOB_PACKAGE_FAILED = 'EDIT_JOB_PACKAGE_FAILED';

export const ADD_COMPANY_ITEM_TO_PACKAGE_START = 'ADD_COMPANY_ITEM_TO_PACKAGE_START';
export const ADD_COMPANY_ITEM_TO_PACKAGE_FINISHED = 'ADD_COMPANY_ITEM_TO_PACKAGE_FINISHED';
export const ADD_COMPANY_ITEM_TO_PACKAGE_FAILED = 'ADD_COMPANY_ITEM_TO_PACKAGE_FAILED';

export const DELETE_COMPANY_ITEM_FROM_PACKAGE_START = 'DELETE_COMPANY_ITEM_FROM_PACKAGE_START';
export const DELETE_COMPANY_ITEM_FROM_PACKAGE_FINISHED = 'DELETE_COMPANY_ITEM_FROM_PACKAGE_FINISHED';
export const DELETE_COMPANY_ITEM_FROM_PACKAGE_FAILED = 'DELETE_COMPANY_ITEM_FROM_PACKAGE_FAILED';

export const GET_ITEMS_OF_THE_PACKAGE_START = 'GET_ITEMS_OF_THE_PACKAGE_START';
export const SET_ITEMS_OF_THE_PACKAGE = 'SET_ITEMS_OF_THE_PACKAGE';
export const GET_ITEMS_OF_THE_PACKAGE_FAILED = 'GET_ITEMS_OF_THE_PACKAGE_FAILED';

export const GET_FILTER_FOR_ADD_COMPANY_ITEM_START = 'GET_FILTER_FOR_ADD_COMPANY_ITEM_START';
export const SET_FILTER_FOR_ADD_COMPANY_ITEM = 'SET_FILTER_FOR_ADD_COMPANY_ITEM';
export const GET_FILTER_FOR_ADD_COMPANY_ITEM_FAILED = 'GET_FILTER_FOR_ADD_COMPANY_ITEM_FAILED';

export const SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_START = 'SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_START';
export const SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FINISHED = 'SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FINISHED';
export const SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FAILED = 'SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FAILED';

export const GET_RESELLER_ANALYTICAL_REPORT_START = 'GET_RESELLER_ANALYTICAL_REPORT_START';
export const SET_RESELLER_ANALYTICAL_REPORT = 'SET_RESELLER_ANALYTICAL_REPORT';
export const GET_RESELLER_ANALYTICAL_REPORT_FAILED = 'GET_RESELLER_ANALYTICAL_REPORT_FAILED';

export const UPLOAD_PLACEHOLDER_NOTE_IMAGE_START = 'UPLOAD_PLACEHOLDER_NOTE_IMAGE_START';
export const UPLOAD_PLACEHOLDER_NOTE_IMAGE_FINISHED = 'UPLOAD_PLACEHOLDER_NOTE_IMAGE_FINISHED';
export const UPLOAD_PLACEHOLDER_NOTE_IMAGE_FAILED = 'UPLOAD_PLACEHOLDER_NOTE_IMAGE_FAILED';

export const DELETE_PLACEHOLDER_NOTE_IMAGE_START = 'DELETE_PLACEHOLDER_NOTE_IMAGE_START';
export const DELETE_PLACEHOLDER_NOTE_IMAGE_FINISHED = 'DELETE_PLACEHOLDER_NOTE_IMAGE_FINISHED';
export const DELETE_PLACEHOLDER_NOTE_IMAGE_FAILED = 'DELETE_PLACEHOLDER_NOTE_IMAGE_FAILED';

export const ADD_COMPANY_PACKAGE_VERSION_START = 'ADD_COMPANY_PACKAGE_VERSION_START';
export const ADD_COMPANY_PACKAGE_VERSION_FINISHED = 'ADD_COMPANY_PACKAGE_VERSION_FINISHED';
export const ADD_COMPANY_PACKAGE_VERSION_FAILED = 'ADD_COMPANY_PACKAGE_VERSION_FAILED';

export const GET_PACKAGE_VERSIONS_START = 'GET_PACKAGE_VERSIONS_START';
export const SET_PACKAGE_VERSIONS = 'SET_PACKAGE_VERSIONS';
export const GET_PACKAGE_VERSIONS_FAILED = 'GET_PACKAGE_VERSIONS_FAILED';

export const ADD_COLOR_SCHEME_START = 'ADD_COLOR_SCHEME_START';
export const ADD_COLOR_SCHEME_FINISHED = 'ADD_COLOR_SCHEME_FINISHED';
export const ADD_COLOR_SCHEME_FAILED = 'ADD_COLOR_SCHEME_FAILED';

export const DELETE_COLOR_SCHEME_START = 'DELETE_COLOR_SCHEME_START';
export const DELETE_COLOR_SCHEME_FINISHED = 'DELETE_COLOR_SCHEME_FINISHED';
export const DELETE_COLOR_SCHEME_FAILED = 'DELETE_COLOR_SCHEME_FAILED';

export const EDIT_COLOR_SCHEME_START = 'EDIT_COLOR_SCHEME_START';
export const EDIT_COLOR_SCHEME_FINISHED = 'EDIT_COLOR_SCHEME_FINISHED';
export const EDIT_COLOR_SCHEME_FAILED = 'EDIT_COLOR_SCHEME_FAILED';

export const GET_COLOR_SCHEMES_START = 'GET_COLOR_SCHEMES_START';
export const SET_COLOR_SCHEMES = 'SET_COLOR_SCHEMES';
export const SET_COLOR_SCHEMES_ALL = 'SET_COLOR_SCHEMES_ALL';
export const GET_COLOR_SCHEMES_FAILED = 'GET_COLOR_SCHEMES_FAILED';

export const GET_COLOR_SCHEME_BY_ID_START = 'GET_COLOR_SCHEME_BY_ID_START';
export const SET_COLOR_SCHEME_BY_ID = 'SET_COLOR_SCHEME_BY_ID';
export const GET_COLOR_SCHEME_BY_ID_FAILED = 'GET_COLOR_SCHEME_BY_ID_FAILED';

export const GET_COLOR_SCHEME_ITEMS_START = 'GET_COLOR_SCHEME_ITEMS_START';
export const SET_COLOR_SCHEME_ITEMS = 'SET_COLOR_SCHEME_ITEMS';
export const GET_COLOR_SCHEME_ITEMS_FAILED = 'GET_COLOR_SCHEME_ITEMS_FAILED';

export const ADD_ITEMS_TO_COLOR_SCHEME_START = 'ADD_ITEMS_TO_COLOR_SCHEME_START';
export const ADD_ITEMS_TO_COLOR_SCHEME_FINISHED = 'ADD_ITEMS_TO_COLOR_SCHEME_FINISHED';
export const ADD_ITEMS_TO_COLOR_SCHEME_FAILED = 'ADD_ITEMS_TO_COLOR_SCHEME_FAILED';

export const DELETE_ITEMS_FROM_COLOR_SCHEME_START = 'DELETE_ITEMS_FROM_COLOR_SCHEME_START';
export const DELETE_ITEMS_FROM_COLOR_SCHEME_FINISHED = 'DELETE_ITEMS_FROM_COLOR_SCHEME_FINISHED';
export const DELETE_ITEMS_FROM_COLOR_SCHEME_FAILED = 'DELETE_ITEMS_FROM_COLOR_SCHEME_FAILED';

export const ADD_COLOR_SCHEME_TO_JOB_START = 'ADD_COLOR_SCHEME_TO_JOB_START';
export const ADD_COLOR_SCHEME_TO_JOB_FINISHED = 'ADD_COLOR_SCHEME_TO_JOB_FINISHED';
export const ADD_COLOR_SCHEME_TO_JOB_FAILED = 'ADD_COLOR_SCHEME_TO_JOB_FAILED';

export const ADD_MARKUP_FILE_TO_JOB_START = 'ADD_MARKUP_FILE_TO_JOB_START';
export const ADD_MARKUP_FILE_TO_JOB_FINISHED = 'ADD_MARKUP_FILE_TO_JOB_FINISHED';
export const ADD_MARKUP_FILE_TO_JOB_FAILED = 'ADD_MARKUP_FILE_TO_JOB_FAILED';

export const DELETE_MARKUP_FILE_START = 'DELETE_MARKUP_FILE_START';
export const DELETE_MARKUP_FILE_FINISHED = 'DELETE_MARKUP_FILE_FINISHED';
export const DELETE_MARKUP_FILE_FAILED = 'DELETE_MARKUP_FILE_FAILED';

export const GET_MARKUP_OF_JOB_START = 'GET_MARKUP_OF_JOB_START';
export const SET_MARKUP_OF_JOB = 'SET_MARKUP_OF_JOB';
export const GET_MARKUP_OF_JOB_FAILED = 'GET_MARKUP_OF_JOB_FAILED';

export const ADD_MARK_UP_VERSION_START = 'ADD_MARK_UP_VERSION_START';
export const ADD_MARK_UP_VERSION_FINISHED = 'ADD_MARK_UP_VERSION_FINISHED';
export const ADD_MARK_UP_VERSION_FAILED = 'ADD_MARK_UP_VERSION_FAILED';

export const GET_ASSET_CUSTOM_ATTRIBUTES_START = 'GET_ASSET_CUSTOM_ATTRIBUTES_START';
export const SET_ASSET_CUSTOM_ATTRIBUTES = 'SET_ASSET_CUSTOM_ATTRIBUTES';
export const GET_ASSET_CUSTOM_ATTRIBUTES_FAILED = 'GET_ASSET_CUSTOM_ATTRIBUTES_FAILED';

export const ADD_ITEMS_TO_COMPANY_ROASTER_START = 'ADD_ITEMS_TO_COMPANY_ROASTER_START';
export const ADD_ITEMS_TO_COMPANY_ROASTER_FINISHED = 'ADD_ITEMS_TO_COMPANY_ROASTER_FINISHED';
export const ADD_ITEMS_TO_COMPANY_ROASTER_FAILED = 'ADD_ITEMS_TO_COMPANY_ROASTER_FAILED';

export const ADD_ITEMS_TO_COMPANY_ROASTER_ALL_START = 'ADD_ITEMS_TO_COMPANY_ROASTER_ALL_START';
export const ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FINISHED = 'ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FINISHED';
export const ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FAILED = 'ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FAILED';

export const ADD_ITEMS_TO_PACKAGE_ALL_START = 'ADD_ITEMS_TO_PACKAGE_ALL_START';
export const ADD_ITEMS_TO_PACKAGE_ALL_FINISHED = 'ADD_ITEMS_TO_PACKAGE_ALL_FINISHED';
export const ADD_ITEMS_TO_PACKAGE_ALL_FAILED = 'ADD_ITEMS_TO_PACKAGE_ALL_FAILED';

export const DELETE_PACKAGE_FROM_JOB_START = 'DELETE_PACKAGE_FROM_JOB_START';
export const DELETE_PACKAGE_FROM_JOB_FINISHED = 'DELETE_PACKAGE_FROM_JOB_FINISHED';
export const DELETE_PACKAGE_FROM_JOB_FAILED = 'DELETE_PACKAGE_FROM_JOB_FAILED';

export const ADD_ASSET_TO_RESELLER_ROASTER_START = 'ADD_ASSET_TO_RESELLER_ROASTER_START';
export const ADD_ASSET_TO_RESELLER_ROASTER_FINISHED = 'ADD_ASSET_TO_RESELLER_ROASTER_FINISHED';
export const ADD_ASSET_TO_RESELLER_ROASTER_FAILED = 'ADD_ASSET_TO_RESELLER_ROASTER_FAILED';

export const ADD_ASSET_TO_RESELLER_ROASTER_ALL_START = 'ADD_ASSET_TO_RESELLER_ROASTER_ALL_START';
export const ADD_ASSET_TO_RESELLER_ROASTER_ALL_FINISHED = 'ADD_ASSET_TO_RESELLER_ROASTER_ALL_FINISHED';
export const ADD_ASSET_TO_RESELLER_ROASTER_ALL_FAILED = 'ADD_ASSET_TO_RESELLER_ROASTER_ALL_FAILED';

export const GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_START = 'GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_START';
export const SET_REPORT_TEMPLATES_OF_RESELLER_COMPANY = 'SET_REPORT_TEMPLATES_OF_RESELLER_COMPANY';
export const GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_FAILED = 'GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_FAILED';

export const ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_START = 'ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_START';
export const ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FINISHED = 'ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FINISHED';
export const ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FAILED = 'ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FAILED';

export const DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_START = 'DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_START';
export const DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FINISHED = 'DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FINISHED';
export const DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FAILED = 'DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FAILED';

export const GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_START = 'GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_START';
export const SET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY = 'SET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY';
export const GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_FAILED = 'GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_FAILED';

export const GET_PACKAGES_OF_RESELLER_COMPANY_START = 'GET_PACKAGES_OF_RESELLER_COMPANY_START';
export const SET_PACKAGES_OF_RESELLER_COMPANY = 'SET_PACKAGES_OF_RESELLER_COMPANY';
export const GET_PACKAGES_OF_RESELLER_COMPANY_FAILED = 'GET_PACKAGES_OF_RESELLER_COMPANY_FAILED';

export const ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_START = 'ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_START';
export const ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FINISHED = 'ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FINISHED';
export const ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FAILED = 'ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FAILED';

export const DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_START = 'DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_START';
export const DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FINISHED = 'DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FINISHED';
export const DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FAILED = 'DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FAILED';

export const GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_START = 'GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_START';
export const SET_PACKAGES_OF_RESELLER_CHILD_COMPANY = 'SET_PACKAGES_OF_RESELLER_CHILD_COMPANY';
export const GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_FAILED = 'GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_FAILED';

export const GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_START = 'GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_START';
export const SET_PACKAGE_ITEMS_OF_RESELLER_COMPANY = 'SET_PACKAGE_ITEMS_OF_RESELLER_COMPANY';
export const GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_FAILED = 'GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_FAILED';

export const GET_RESELLER_PACKAGE_VERSIONS_START = 'GET_RESELLER_PACKAGE_VERSIONS_START';
export const SET_RESELLER_PACKAGE_VERSIONS = 'SET_RESELLER_PACKAGE_VERSIONS';
export const GET_RESELLER_PACKAGE_VERSIONS_FAILED = 'GET_RESELLER_PACKAGE_VERSIONS_FAILED';

export const DELETE_CONSULTANT_FROM_JOB_START = 'DELETE_CONSULTANT_FROM_JOB_START';
export const DELETE_CONSULTANT_FROM_JOB_FINISHED = 'DELETE_CONSULTANT_FROM_JOB_FINISHED';
export const DELETE_CONSULTANT_FROM_JOB_FAILED = 'DELETE_CONSULTANT_FROM_JOB_FAILED';

export const DELETE_COMPANY_ITEM_IMAGE_START = 'DELETE_COMPANY_ITEM_IMAGE_START';
export const DELETE_COMPANY_ITEM_IMAGE_FINISHED = 'DELETE_COMPANY_ITEM_IMAGE_FINISHED';
export const DELETE_COMPANY_ITEM_IMAGE_FAILED = 'DELETE_COMPANY_ITEM_IMAGE_FAILED';

export const GET_CATEGORY_CUSTOM_ATTRIBUTE_START = 'GET_CATEGORY_CUSTOM_ATTRIBUTE_START';
export const SET_CATEGORY_CUSTOM_ATTRIBUTE = 'SET_CATEGORY_CUSTOM_ATTRIBUTE';
export const GET_CATEGORY_CUSTOM_ATTRIBUTE_FAILED = 'GET_CATEGORY_CUSTOM_ATTRIBUTE_FAILED';

export const DELETE_PLACEHOLDER_CATEGORY_START = 'DELETE_PLACEHOLDER_CATEGORY_START';
export const DELETE_PLACEHOLDER_CATEGORY_FINISHED = 'DELETE_PLACEHOLDER_CATEGORY_FINISHED';
export const DELETE_PLACEHOLDER_CATEGORY_FAILED = 'DELETE_PLACEHOLDER_CATEGORY_FAILED';

export const ADD_CATEGORIES_TO_PLACEHOLDER_START = 'ADD_CATEGORIES_TO_PLACEHOLDER_START';
export const ADD_CATEGORIES_TO_PLACEHOLDER_FINISHED = 'ADD_CATEGORIES_TO_PLACEHOLDER_FINISHED';
export const ADD_CATEGORIES_TO_PLACEHOLDER_FAILED = 'ADD_CATEGORIES_TO_PLACEHOLDER_FAILED';

export const ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_START = 'ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_START';
export const ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED = 'ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED';
export const ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED = 'ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED';

export const DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_START = 'DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_START';
export const DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED = 'DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED';
export const DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED = 'DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED';

export const ADD_ITEM_TO_VIRTUAL_BOARD_START = 'ADD_ITEM_TO_VIRTUAL_BOARD_START';
export const ADD_ITEM_TO_VIRTUAL_BOARD_FINISHED = 'ADD_ITEM_TO_VIRTUAL_BOARD_FINISHED';
export const ADD_ITEM_TO_VIRTUAL_BOARD_FAILED = 'ADD_ITEM_TO_VIRTUAL_BOARD_FAILED';

export const DELETE_VIRTUAL_BOARD_ITEM_START = 'DELETE_VIRTUAL_BOARD_ITEM_START';
export const DELETE_VIRTUAL_BOARD_ITEM_FINISHED = 'DELETE_VIRTUAL_BOARD_ITEM_FINISHED';
export const DELETE_VIRTUAL_BOARD_ITEM_FAILED = 'DELETE_VIRTUAL_BOARD_ITEM_FAILED';

export const GET_VIRTUAL_BOARD_ITEMS_START = 'GET_VIRTUAL_BOARD_ITEMS_START';
export const SET_VIRTUAL_BOARD_ITEMS = 'SET_VIRTUAL_BOARD_ITEMS';
export const GET_VIRTUAL_BOARD_ITEMS_FAILED = 'GET_VIRTUAL_BOARD_ITEMS_FAILED';

export const ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_START = 'ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_START';
export const ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FINISHED = 'ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FINISHED';
export const ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FAILED = 'ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FAILED';

export const DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_START = 'DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_START';
export const DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_FINISHED = 'DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_FINISHED';
export const DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_FAILED = 'DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_FAILED';

export const DELETE_COLOR_SCHEME_FROM_JOB_START = 'DELETE_COLOR_SCHEME_FROM_JOB_START';
export const DELETE_COLOR_SCHEME_FROM_JOB_FINISHED = 'DELETE_COLOR_SCHEME_FROM_JOB_FINISHED';
export const DELETE_COLOR_SCHEME_FROM_JOB_FAILED = 'DELETE_COLOR_SCHEME_FROM_JOB_FAILED';

export const ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_START = 'ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_START';
export const ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FINISHED = 'ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FINISHED';
export const ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FAILED = 'ADD_CUSTOM_ATTRIBUTES_TO_PLACEHOLDER_NOTE_FAILED';

export const DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_START = 'DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_START';
export const DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FINISHED = 'DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FINISHED';
export const DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FAILED = 'DELETE_PLACEHOLDER_NOTE_CUSTOM_ATTRIBUTE_FAILED';

export const DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START = 'DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START';
export const DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED = 'DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED';
export const DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED = 'DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED';

export const EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START = 'EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START';
export const EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED = 'EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED';
export const EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED = 'EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED';

export const EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_START = 'EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_START';
export const EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FINISHED = 'EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FINISHED';
export const EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FAILED = 'EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FAILED';

export const GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_START = 'GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_START';
export const GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_FINISHED = 'GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_FINISHED';
export const GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_FAILED = 'GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_FAILED';

export const DELETE_JOB_NOTE_IMAGE_START = 'DELETE_JOB_NOTE_IMAGE_START';
export const DELETE_JOB_NOTE_IMAGE_FINISHED = 'DELETE_JOB_NOTE_IMAGE_FINISHED';
export const DELETE_JOB_NOTE_IMAGE_FAILED = 'DELETE_JOB_NOTE_IMAGE_FAILED';

export const EXPORT_ITEMS_TO_EXCEL_START = 'EXPORT_ITEMS_TO_EXCEL_START';
export const SET_EXPORT_ITEMS_URL = 'SET_EXPORT_ITEMS_URL';
export const EXPORT_ITEMS_TO_EXCEL_FAILED = 'EXPORT_ITEMS_TO_EXCEL_FAILED';

export const GET_ASSETS_START = 'GET_ASSETS_START';
export const SET_ASSETS = 'SET_ASSETS';
export const GET_ASSETS_FAILED = 'GET_ASSETS_FAILED';
export const SET_ASSETS_PAGE_ITEMS_EMPTY = 'SET_ASSETS_PAGE_ITEMS_EMPTY';

export const GET_COMPANY_ITEMS_START = 'GET_COMPANY_ITEMS_START';
export const SET_COMPANY_ITEMS = 'SET_COMPANY_ITEMS';
export const GET_COMPANY_ITEMS_FAILED = 'GET_COMPANY_ITEMS_FAILED';

export const GET_COMPANY_CATEGORIES_START = 'GET_COMPANY_CATEGORIES_START';
export const SET_COMPANY_CATEGORIES = 'SET_COMPANY_CATEGORIES';
export const GET_COMPANY_CATEGORIES_FAILED = 'GET_COMPANY_CATEGORIES_FAILED';

export const GET_SYSTEM_CATEGORIES_START = 'GET_SYSTEM_CATEGORIES_START';
export const SET_SYSTEM_CATEGORIES = 'SET_SYSTEM_CATEGORIES';
export const GET_SYSTEM_CATEGORIES_FAILED = 'GET_SYSTEM_CATEGORIES_FAILED';

export const GET_VENDORS_START = 'GET_VENDORS_START';
export const SET_VENDORS = 'SET_VENDORS';
export const GET_VENDORS_FAILED = 'GET_VENDORS_FAILED';

export const GET_COMPANY_ITEMS_VENDORS_START = 'GET_COMPANY_ITEMS_VENDORS_START';
export const SET_COMPANY_ITEMS_VENDORS = 'SET_COMPANY_ITEMS_VENDORS';
export const GET_COMPANY_ITEMS_VENDORS_FAILED = 'GET_COMPANY_ITEMS_VENDORS_FAILED';

export const GET_PACKAGE_ITEMS_POST_START = 'GET_PACKAGE_ITEMS_POST_START';
export const SET_PACKAGE_ITEMS_POST = 'SET_PACKAGE_ITEMS_POST';
export const GET_PACKAGE_ITEMS_POST_FAILED = 'GET_PACKAGE_ITEMS_POST_FAILED';

export const GET_CATEGORY_PLACEHOLDER_POST_START = 'GET_CATEGORY_PLACEHOLDER_POST_START';
export const SET_CATEGORY_PLACEHOLDER_POST = 'SET_CATEGORY_PLACEHOLDER_POST';
export const GET_CATEGORY_PLACEHOLDER_POST_FAILED = 'GET_CATEGORY_PLACEHOLDER_POST_FAILED';

export const GET_ITEMS_STATUS_BY_FILTER_START = 'GET_ITEMS_STATUS_BY_FILTER_START';
export const SET_ITEMS_STATUS_BY_FILTER = 'SET_ITEMS_STATUS_BY_FILTER';
export const GET_ITEMS_STATUS_BY_FILTER_FAILED = 'GET_ITEMS_STATUS_BY_FILTER_FAILED';

export const GET_PACKAGE_ITEMS_STATUS_BY_FILTER_START = 'GET_PACKAGE_ITEMS_STATUS_BY_FILTER_START';
export const SET_PACKAGE_ITEMS_STATUS_BY_FILTER = 'SET_PACKAGE_ITEMS_STATUS_BY_FILTER';
export const GET_PACKAGE_ITEMS_STATUS_BY_FILTER_FAILED = 'GET_PACKAGE_ITEMS_STATUS_BY_FILTER_FAILED';

export const REMOVE_ASSET_START = 'REMOVE_ASSET_START';
export const REMOVE_ASSET_END = 'REMOVE_ASSET_FINISHED';
export const REMOVE_ASSET_FAILED = 'REMOVE_ASSET_FAILED';

export const DISCONTINUE_ASSET_START = 'DISCONTINUE_ASSET_START';
export const DISCONTINUE_ASSET_END = 'DISCONTINUE_ASSET_FINISHED';
export const DISCONTINUE_ASSET_FAILED = 'DISCONTINUE_ASSET_FAILED';

export const UPDATE_ASSET_START = 'UPDATE_ASSET_START';
export const UPDATE_ASSET_END = 'UPDATE_ASSET_END';
export const UPDATE_ASSET_FAILED = 'UPDATE_ASSET_FAILED';

export const CREATE_ASSET_START = 'CREATE_ASSET_START';
export const CREATE_ASSET_END = 'CREATE_ASSET_END';
export const CREATE_ASSET_FAILED = 'CREATE_ASSET_FAILED';

export const UPLOAD_ASSET_IMAGE_START = 'UPLOAD_ASSET_IMAGE_START';
export const UPLOAD_ASSET_IMAGE_END = 'UPLOAD_ASSET_IMAGE_END';
export const UPLOAD_ASSET_IMAGE_FAILED = 'UPLOAD_ASSET_IMAGE_FAILED';

export const GET_ATTRIBUTES_START ='GET_ATTRIBUTES_START';
export const SET_ATTRIBUTES ='SET_ATTRIBUTES';
export const GET_ATTRIBUTES_FAILED ='GET_ATTRIBUTES_FAILED';

export const GET_CATEGORY_ATTRIBUTES_START = 'GET_CATEGORY_ATTRIBUTES_START';
export const SET_CATEGORY_ATTRIBUTES = 'SET_CATEGORY_ATTRIBUTES';
export const GET_CATEGORY_ATTRIBUTES_FAILED = 'GET_CATEGORY_ATTRIBUTES_FAILED';

export const REMOVE_ATTRIBUTE_START = 'REMOVE_ATTRIBUTE_START';
export const REMOVE_ATTRIBUTE_END = 'REMOVE_ATTRIBUTE_END';
export const REMOVE_ATTRIBUTE_FAILED = 'REMOVE_ATTRIBUTE_FAILED';

export const EDIT_ATTRIBUTE_START = 'EDIT_ATTRIBUTE_START';
export const EDIT_ATTRIBUTE_END = 'EDIT_ATTRIBUTE_END';
export const EDIT_ATTRIBUTE_FAILED = 'EDIT_ATTRIBUTE_FAILED';

export const CREATE_ATTRIBUTE_START = 'CREATE_ATTRIBUTE_START';
export const CREATE_ATTRIBUTE_END = 'CREATE_ATTRIBUTE_END';
export const CREATE_ATTRIBUTE_FAILED = 'CREATE_ATTRIBUTE_FAILED';

export const GET_COMPANY_ATTRIBUTE_START = 'GET_COMPANY_ATTRIBUTE_START';
export const SET_COMPANY_ATTRIBUTE = 'SET_COMPANY_ATTRIBUTE';
export const GET_COMPANY_ATTRIBUTE_FAILED = 'GET_COMPANY_ATTRIBUTE_FAILED';

export const REMOVE_COMPANY_ATTRIBUTES_START = 'REMOVE_COMPANY_ATTRIBUTES_START';
export const REMOVE_COMPANY_ATTRIBUTES_END = 'REMOVE_COMPANY_ATTRIBUTES_END';
export const REMOVE_COMPANY_ATTRIBUTES_FAILED = 'REMOVE_COMPANY_ATTRIBUTES_FAILED';

export const EDIT_COMPANY_ATTRIBUTE_START = 'EDIT_COMPANY_ATTRIBUTE_START';
export const EDIT_COMPANY_ATTRIBUTE_END = 'EDIT_COMPANY_ATTRIBUTE_END';
export const EDIT_COMPANY_ATTRIBUTE_FAILED = 'EDIT_COMPANY_ATTRIBUTE_FAILED';

export const CREATE_COMPANY_ATTRIBUTE_START = 'CREATE_COMPANY_ATTRIBUTE_START';
export const CREATE_COMPANY_ATTRIBUTE_END = 'CREATE_COMPANY_ATTRIBUTE_END';
export const CREATE_COMPANY_ATTRIBUTE_FAILED = 'CREATE_COMPANY_ATTRIBUTE_FAILED';

export const EDIT_ATTRIBUTE_OF_ASSET_START = 'EDIT_ATTRIBUTE_OF_ASSET_START';
export const EDIT_ATTRIBUTE_OF_ASSET_END = 'EDIT_ATTRIBUTE_OF_ASSET_END';
export const EDIT_ATTRIBUTE_OF_ASSET_FAILED = 'EDIT_ATTRIBUTE_OF_ASSET_FAILED';

export const  EDIT_ATTRIBUTE_OF_COMPANY_ITEM_START ='EDIT_ATTRIBUTE_OF_COMPANY_ITEM_START';
export const  EDIT_ATTRIBUTE_OF_COMPANY_ITEM_END ='EDIT_ATTRIBUTE_OF_COMPANY_ITEM_END';
export const  EDIT_ATTRIBUTE_OF_COMPANY_ITEM_FAILED ='EDIT_ATTRIBUTE_OF_COMPANY_ITEM_FAILED';

export const GET_CATEGORIES_START ='GET_CATEGORIES_START';
export const SET_CATEGORIES ='SET_CATEGORIES';
export const GET_CATEGORIES_FAILED ='GET_CATEGORIES_FAILED';

export const REMOVE_CATEGORY_START = 'REMOVE_CATEGORY_START';
export const REMOVE_CATEGORY_END = 'REMOVE_CATEGORY_END';
export const REMOVE_CATEGORY_FAILED = 'REMOVE_CATEGORY_FAILED';

export const EDIT_CATEGORY_START = 'EDIT_CATEGORY_START';
export const EDIT_CATEGORY_END = 'EDIT_CATEGORY_END';
export const EDIT_CATEGORY_FAILED = 'EDIT_CATEGORY_FAILED';

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_END = 'CREATE_CATEGORY_END';
export const CREATE_CATEGORY_FAILED = 'CREATE_CATEGORY_FAILED';

export const GET_COMPANY_JOBS_START = 'GET_COMPANY_JOBS_START';
export const SET_COMPANY_JOBS = 'SET_COMPANY_JOBS';
export const GET_COMPANY_JOBS_FAILD = 'GET_COMPANY_JOBS_FAILD';

export const GET_JOB_START = 'GET_JOB_START';
export const SET_JOB = 'SET_JOB';
export const GET_JOB_FAILED = 'GET_JOB_FAILED';

export const REMOVE_JOB_START = 'REMOVE_JOB_START';
export const REMOVE_JOB_END = 'REMOVE_JOB_END';
export const REMOVE_JOB_FAILED = 'REMOVE_JOB_FAILED';

export const DOWNLOAD_JOB_REPORT_START = 'DOWNLOAD_JOB_REPORT_START';
export const DOWNLOAD_JOB_REPORT_END = 'DOWNLOAD_JOB_REPORT_END';
export const DOWNLOAD_JOB_REPORT_FAILED = 'DOWNLOAD_JOB_REPORT_FAILED';

export const EDIT_JOB_START = 'EDIT_JOB_START';
export const EDIT_JOB_END = 'EDIT_JOB_END';
export const EDIT_JOB_FAILED = 'EDIT_JOB_FAILED';

export const ASSIGN_CONSULTANT_TO_JOB_START = 'ASSIGN_CONSULTANT_TO_JOB_START';
export const ASSIGN_CONSULTANT_TO_JOB_END = 'ASSIGN_CONSULTANT_TO_JOB_END';
export const ASSIGN_CONSULTANT_TO_JOB_FAILED = 'ASSIGN_CONSULTANT_TO_JOB_FAILED';

export const GET_JOB_REPORT_START = 'GET_JOB_REPORT_START';
export const GET_JOB_REPORT_END = 'GET_JOB_REPORT_END';
export const GET_JOB_REPORT_FAILED = 'GET_JOB_REPORT_FAILED';

export const GET_JOB_NOTES_START = 'GET_JOB_NOTES_START';
export const SET_JOB_NOTES = 'SET_JOB_NOTES';
export const GET_JOB_NOTES_FAILED = 'GET_JOB_NOTES_FAILED';

export const DELETE_NOTE_FROM_JOB_START = 'DELETE_NOTE_FROM_JOB_START';
export const DELETE_NOTE_FROM_JOB_END = 'DELETE_NOTE_FROM_JOB_END';
export const DELETE_NOTE_FROM_JOB_FAILED = 'DELETE_NOTE_FROM_JOB_FAILED';

export const GET_COMPANIES_START = 'GET_COMPANIES_START';
export const SET_COMPANIES = 'SET_COMPANIES';
export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';

export const GET_COMPANY_START = 'GET_COMPANY_START';
export const SET_COMPANY = 'SET_COMPANY';
export const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED';

export const REMOVE_COMPANY_START = 'REMOVE_COMPANY_START';
export const REMOVE_COMPANY_END = 'REMOVE_COMPANY_END';
export const REMOVE_COMPANY_FAILED = 'REMOVE_COMPANY_FAILED';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_END = 'CREATE_COMPANY_END';
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';

export const GET_RESELLER_CHILD_COMPANY_ITEM_WITH_START = 'GET_RESELLER_CHILD_COMPANY_ITEM_WITH_START';
export const GET_RESELLER_CHILD_COMPANY_ITEM_WITH_END = 'GET_RESELLER_CHILD_COMPANY_ITEM_WITH_END';
export const GET_RESELLER_CHILD_COMPANY_ITEM_WITH_FAILED = 'GET_RESELLER_CHILD_COMPANY_ITEM_WITH_FAILED';

export const GET_COMPANY_ITEM_BY_ID_START = 'GET_COMPANY_ITEM_BY_ID_START';
export const GET_COMPANY_ITEM_BY_ID_END = 'GET_COMPANY_ITEM_BY_ID_END';
export const GET_COMPANY_ITEM_BY_ID_FAILED = 'GET_COMPANY_ITEM_BY_ID_FAILED';

export const ADD_COMPANY_ITEM_START = 'ADD_COMPANY_ITEM_START';
export const ADD_COMPANY_ITEM_END = 'ADD_COMPANY_ITEM_END';
export const ADD_COMPANY_ITEM_FAILED = 'ADD_COMPANY_ITEM_FAILED';

export const ADD_COMPANY_ITEMS_TO_COMPANY_JOB_START = 'ADD_COMPANY_ITEMS_TO_COMPANY_JOB_START';
export const ADD_COMPANY_ITEMS_TO_COMPANY_JOB_END = 'ADD_COMPANY_ITEMS_TO_COMPANY_JOB_END';
export const ADD_COMPANY_ITEMS_TO_COMPANY_JOB_FAILED = 'ADD_COMPANY_ITEMS_TO_COMPANY_JOB_FAILED';

export const REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_START = 'REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_START';
export const REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_END = 'REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_END';
export const REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_FAILED = 'REMOVE_COMPANY_ITEM_FROM_COMPANY_JOB_FAILED';

export const UPDATE_COMPANY_ITEM_START = 'UPDATE_COMPANY_ITEM_START';
export const UPDATE_COMPANY_ITEM_END = 'UPDATE_COMPANY_ITEM_END';
export const UPDATE_COMPANY_ITEM_FAILED = 'UPDATE_COMPANY_ITEM_FAILED';

export const REMOVE_COMPANY_ITEM_START = 'REMOVE_COMPANY_ITEM_START';
export const REMOVE_COMPANY_ITEM_END = 'REMOVE_COMPANY_ITEM_END';
export const REMOVE_COMPANY_ITEM_FAILED = 'REMOVE_COMPANY_ITEM_FAILED';

export const DISCONTINUE_COMPANY_ITEM_START = 'DISCONTINUE_COMPANY_ITEM_START';
export const DISCONTINUE_COMPANY_ITEM_END = 'DISCONTINUE_COMPANY_ITEM_END';
export const DISCONTINUE_COMPANY_ITEM_FAILED = 'DISCONTINUE_COMPANY_ITEM_FAILED';

export const UPLOAD_COMPANY_ITEM_IMAGE_START = 'UPLOAD_COMPANY_ITEM_IMAGE_START';
export const UPLOAD_COMPANY_ITEM_IMAGE_END = 'UPLOAD_COMPANY_ITEM_IMAGE_END';
export const UPLOAD_COMPANY_ITEM_IMAGE_FAILED = 'UPLOAD_COMPANY_ITEM_IMAGE_FAILED';

export const CREATE_COMPANY_JOB_START = 'CREATE_COMPANY_JOB_START';
export const CREATE_COMPANY_JOB_END = 'CREATE_COMPANY_JOB_END';
export const CREATE_COMPANY_JOB_FAILED = 'CREATE_COMPANY_JOB_FAILED';

export const CREATE_COMPANY_CATEGORY_START = 'CREATE_COMPANY_CATEGORY_START';
export const CREATE_COMPANY_CATEGORY_END = 'CREATE_COMPANY_CATEGORY_END';
export const CREATE_COMPANY_CATEGORY_FAILED = 'CREATE_COMPANY_CATEGORY_FAILED';

export const EDIT_COMPANY_CATEGORY_START = 'EDIT_COMPANY_CATEGORY_START';
export const EDIT_COMPANY_CATEGORY_END = 'EDIT_COMPANY_CATEGORY_END';
export const EDIT_COMPANY_CATEGORY_FAILED = 'EDIT_COMPANY_CATEGORY_FAILED';

export const UPLOAD_COMPANY_IMAGE_START = 'UPLOAD_COMPANY_IMAGE_START';
export const UPLOAD_COMPANY_IMAGE_END = 'UPLOAD_COMPANY_IMAGE_END';
export const UPLOAD_COMPANY_IMAGE_FAILED = 'UPLOAD_COMPANY_IMAGE_FAILED';

export const REQUEST_PASSWORD_CHANGE_START = 'REQUEST_PASSWORD_CHANGE_START';
export const REQUEST_PASSWORD_CHANGE_END = 'REQUEST_PASSWORD_CHANGE_END';
export const REQUEST_PASSWORD_CHANGE_FAILED = 'REQUEST_PASSWORD_CHANGE_FAILED';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_END = 'CHANGE_PASSWORD_END';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const UPDATE_RECOVERED_PASSWORD_START = 'UPDATE_RECOVERED_PASSWORD_START';
export const UPDATE_RECOVERED_PASSWORD_END = 'UPDATE_RECOVERED_PASSWORD_END';
export const UPDATE_RECOVERED_PASSWORD_FAILED = 'UPDATE_RECOVERED_PASSWORD_FAILED';

export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_END = 'LOGIN_USER_END';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const LOGIN_USER_TO_RESELLER_PORTAL_START = 'LOGIN_USER_TO_RESELLER_PORTAL_START';
export const LOGIN_USER_TO_RESELLER_PORTAL_END = 'LOGIN_USER_TO_RESELLER_PORTAL_END';
export const LOGIN_USER_TO_RESELLER_PORTAL_FAILED = 'LOGIN_USER_TO_RESELLER_PORTAL_FAILED';

export const REMOVE_COMPANY_ITEM_PLACEHOLDER_START = 'REMOVE_COMPANY_ITEM_PLACEHOLDER_START';
export const REMOVE_COMPANY_ITEM_PLACEHOLDER_END = 'REMOVE_COMPANY_ITEM_PLACEHOLDER_END';
export const REMOVE_COMPANY_ITEM_PLACEHOLDER_FAILED = 'REMOVE_COMPANY_ITEM_PLACEHOLDER_FAILED';

export const ADD_JOB_PLACEHOLDER_NOTE_START = 'ADD_JOB_PLACEHOLDER_NOTE_START';
export const ADD_JOB_PLACEHOLDER_NOTE_END = 'ADD_JOB_PLACEHOLDER_NOTE_END';
export const ADD_JOB_PLACEHOLDER_NOTE_FAILED = 'ADD_JOB_PLACEHOLDER_NOTE_FAILED';

export const DELETE_JOB_PLACEHOLDER_NOTE_START = 'DELETE_JOB_PLACEHOLDER_NOTE_START';
export const DELETE_JOB_PLACEHOLDER_NOTE_END = 'DELETE_JOB_PLACEHOLDER_NOTE_END';
export const DELETE_JOB_PLACEHOLDER_NOTE_FAILED = 'DELETE_JOB_PLACEHOLDER_NOTE_FAILED';

export const EDIT_JOB_PLACEHOLDER_NOTE_START = 'EDIT_JOB_PLACEHOLDER_NOTE_START';
export const EDIT_JOB_PLACEHOLDER_NOTE_END = 'EDIT_JOB_PLACEHOLDER_NOTE_END';
export const EDIT_JOB_PLACEHOLDER_NOTE_FAILED = 'EDIT_JOB_PLACEHOLDER_NOTE_FAILED';

export const GET_ASSET_VENDORS_START ='GET_ASSET_VENDORS_START';
export const SET_ASSET_VENDORS ='SET_ASSET_VENDORS';
export const GET_ASSET_VENDORS_FAILED ='GET_ASSET_VENDORS_FAILED';

export const GET_COMPANY_VENDORS_START = 'GET_COMPANY_VENDORS_START';
export const SET_COMPANY_VENDORS = 'SET_COMPANY_VENDORS';
export const GET_COMPANY_VENDORS_FAILED = 'GET_COMPANY_VENDORS_FAILED';

export const CREATE_VENDOR_START = 'CREATE_VENDOR_START';
export const CREATE_VENDOR_END = 'CREATE_VENDOR_END';
export const CREATE_VENDOR_FAILED = 'CREATE_VENDOR_FAILED';

export const DELETE_VENDOR_START = 'DELETE_VENDOR_START';
export const DELETE_VENDOR_END = 'DELETE_VENDOR_END';
export const DELETE_VENDOR_FAILED = 'DELETE_VENDOR_FAILED';

export const EDIT_VENDOR_START = 'EDIT_VENDOR_START';
export const EDIT_VENDOR_END = 'EDIT_VENDOR_END';
export const EDIT_VENDOR_FAILED = 'EDIT_VENDOR_FAILED';

export const ADD_USER_TO_COMPANY_START = 'ADD_USER_TO_COMPANY_START';
export const ADD_USER_TO_COMPANY_END = 'ADD_USER_TO_COMPANY_END';
export const ADD_USER_TO_COMPANY_FAILED = 'ADD_USER_TO_COMPANY_FAILED';

export const REMOVE_USER_FROM_COMPANY_START = 'REMOVE_USER_FROM_COMPANY_START';
export const REMOVE_USER_FROM_COMPANY_END = 'REMOVE_USER_FROM_COMPANY_END';
export const REMOVE_USER_FROM_COMPANY_FAILED = 'REMOVE_USER_FROM_COMPANY_FAILED';

export const GET_COMPANY_USERS_START = 'GET_COMPANY_USERS_START';
export const SET_COMPANY_USERS = 'SET_COMPANY_USERS';
export const GET_COMPANY_USERS_FAILED = 'GET_COMPANY_USERS_FAILED';

export const GET_ME_START = 'GET_ME_START';
export const GET_ME_END = 'GET_ME_END';
export const GET_ME_FAILED = 'GET_ME_FAILED';

export const GET_COMPANY_ITEMS_EXPORT_START = 'GET_COMPANY_ITEMS_EXPORT_START';
export const SET_COMPANY_ITEMS_EXPORT = 'SET_COMPANY_ITEMS_EXPORT';
export const GET_COMPANY_ITEMS_EXPORT_FAILED = 'GET_COMPANY_ITEMS_EXPORT_FAILED';

export const GET_COMPANY_JOBS_ITEMS_START = 'GET_COMPANY_JOBS_ITEMS_START';
export const SET_COMPANY_JOBS_ITEMS = 'SET_COMPANY_JOBS_ITEMS';
export const GET_COMPANY_JOBS_ITEMS_FAILED = 'GET_COMPANY_JOBS_ITEMS_FAILED';

export const GET_COMPANY_CATEGORIES_ITEMS_START = 'GET_COMPANY_CATEGORIES_ITEMS_START';
export const SET_COMPANY_CATEGORIES_ITEMS = 'SET_COMPANY_CATEGORIES_ITEMS';
export const GET_COMPANY_CATEGORIES_ITEMS_FAILED = 'GET_COMPANY_CATEGORIES_ITEMS_FAILED';

export const REMOVE_COMPANY_CATEGORY_START = 'REMOVE_COMPANY_CATEGORY_START';
export const REMOVE_COMPANY_CATEGORY_END = 'REMOVE_COMPANY_CATEGORY_END';
export const REMOVE_COMPANY_CATEGORY_FAILED = 'REMOVE_COMPANY_CATEGORY_FAILED';

export const CHECK_USER_STATUS_START = 'CHECK_USER_STATUS_START';
export const CHECK_USER_STATUS_END = 'CHECK_USER_STATUS_END';
export const CHECK_USER_STATUS_FAILED = 'CHECK_USER_STATUS_FAILED';

export const USER_REGISTRATION_START = 'USER_REGISTRATION_START';
export const USER_REGISTRATION_END = 'USER_REGISTRATION_END';
export const USER_REGISTRATION_FAILED = 'USER_REGISTRATION_FAILED';

export const USER_VERIFICATION_START = 'USER_VERIFICATION_START';
export const USER_VERIFICATION_END = 'USER_VERIFICATION_END';
export const USER_VERIFICATION_FAILED = 'USER_VERIFICATION_FAILED';

export const CREATE_NEW_COMPANY_START = 'CREATE_NEW_COMPANY_START';
export const CREATE_NEW_COMPANY_END = 'CREATE_NEW_COMPANY_END';
export const CREATE_NEW_COMPANY_FAILED = 'CREATE_NEW_COMPANY_FAILED';

export const ACTIVATE_COMPANY_START = 'ACTIVATE_COMPANY_START';
export const ACTIVATE_COMPANY_FINISHED = 'ACTIVATE_COMPANY_FINISHED';
export const ACTIVATE_COMPANY_FAILED = 'ACTIVATE_COMPANY_FAILED';

export const DEACTIVATE_COMPANY_START = 'DEACTIVATE_COMPANY_START';
export const DEACTIVATE_COMPANY_FINISHED = 'DEACTIVATE_COMPANY_FINISHED';
export const DEACTIVATE_COMPANY_FAILED = 'DEACTIVATE_COMPANY_FAILED';

export const SET_ENTER_CODE_MODE = 'SET_ENTER_CODE_MODE';

export const RESEND_VERIFICATION_CODE_START = 'RESEND_VERIFICATION_CODE_START';
export const RESEND_VERIFICATION_CODE_END = 'RESEND_VERIFICATION_CODE_END';
export const RESEND_VERIFICATION_CODE_FAILED = 'RESEND_VERIFICATION_CODE_FAILED';

export const REQUEST_CREATE_NEW_COMPANY_START = 'REQUEST_CREATE_NEW_COMPANY_START';
export const REQUEST_CREATE_NEW_COMPANY_END = 'REQUEST_CREATE_NEW_COMPANY_END';
export const REQUEST_CREATE_NEW_COMPANY_FAILED = 'REQUEST_CREATE_NEW_COMPANY_FAILED';

export const GET_USERS_JOIN_REQUESTS_START = 'GET_USERS_JOIN_REQUESTS_START';
export const SET_USERS_JOIN_REQUESTS = 'SET_USERS_JOIN_REQUESTS';
export const GET_USERS_JOIN_REQUESTS_FAILED = 'GET_USERS_JOIN_REQUESTS_FAILED';

export const APPROVE_USER_JOIN_REQUEST_START = 'APPROVE_USER_JOIN_REQUEST_START';
export const APPROVE_USER_JOIN_REQUEST_FAILED = 'APPROVE_USER_JOIN_REQUEST_FAILED';
export const APPROVE_USER_JOIN_REQUEST_FINISHED= 'APPROVE_USER_JOIN_REQUEST_FINISHED';

export const REJECT_USER_JOIN_REQUEST_START = 'REJECT_USER_JOIN_REQUEST_START';
export const REJECT_USER_JOIN_REQUEST_FAILED = 'REJECT_USER_JOIN_REQUEST_FAILED';
export const REJECT_USER_JOIN_REQUEST_FINISHED= 'REJECT_USER_JOIN_REQUEST_FINISHED';

export const GET_COMPANY_CREATE_REQUESTS_START = 'GET_COMPANY_CREATE_REQUESTS_START';
export const SET_COMPANY_CREATE_REQUESTS = 'SET_COMPANY_CREATE_REQUESTS';
export const GET_COMPANY_CREATE_REQUESTS_FAILED = 'GET_COMPANY_JOIN_REQUESTS_START';

export const APPROVE_CREATE_COMPANY_REQUEST_START = 'APPROVE_CREATE_COMPANY_REQUEST_START';
export const APPROVE_CREATE_COMPANY_REQUEST_FINISHED = 'APPROVE_CREATE_COMPANY_REQUEST_FINISHED';
export const APPROVE_CREATE_COMPANY_REQUEST_FAILED = 'APPROVE_CREATE_COMPANY_REQUEST_FAILED';

export const REJECT_CREATE_COMPANY_REQUEST_START = 'REJECT_CREATE_COMPANY_REQUEST_START';
export const REJECT_CREATE_COMPANY_REQUEST_FINISHED = 'REJECT_CREATE_COMPANY_REQUEST_FINISHED';
export const REJECT_CREATE_COMPANY_REQUEST_FAILED = 'REJECT_CREATE_COMPANY_REQUEST_FAILED';

export const ACTIVATE_COMPANY_JOIN_REQUEST_START = 'ACTIVATE_COMPANY_JOIN_REQUEST_START';
export const ACTIVATE_COMPANY_JOIN_REQUEST_END = 'ACTIVATE_COMPANY_JOIN_REQUEST_END';
export const ACTIVATE_COMPANY_JOIN_REQUEST_FALSE = 'ACTIVATE_COMPANY_JOIN_REQUEST_FALSE';

export const EDIT_COMPANY_REQUEST_START = 'EDIT_COMPANY_REQUEST_START';
export const EDIT_COMPANY_REQUEST_FINISHED = 'EDIT_COMPANY_REQUEST_FINISHED';
export const EDIT_COMPANY_REQUEST_FAILED = 'EDIT_COMPANY_REQUEST_FAILED';

export const SET_COMPANY_REQUEST_FOR_EDIT = 'SET_COMPANY_REQUEST_FOR_EDIT';

export const BAN_COMPANY_CREATE_REQUEST_START = 'BAN_COMPANY_CREATE_REQUEST_START';
export const BAN_COMPANY_CREATE_REQUEST_FINISHED = 'BAN_COMPANY_CREATE_REQUEST_FINISHED';
export const BAN_COMPANY_CREATE_REQUEST_FAILED = 'BAN_COMPANY_CREATE_REQUEST_FAILED';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';

export const UPLOAD_COMPANY_REQUEST_IMAGE_START = 'UPLOAD_COMPANY_REQUEST_IMAGE_START';
export const UPLOAD_COMPANY_REQUEST_IMAGE_END = 'UPLOAD_COMPANY_REQUEST_IMAGE_END';
export const UPLOAD_COMPANY_REQUEST_IMAGE_FAILED = 'UPLOAD_COMPANY_REQUEST_IMAGE_FAILED';

export const SET_USER_ID = 'SET_USER_ID';

export const SET_COMPANY_CREATE_REQUEST_NAME = 'SET_COMPANY_CREATE_REQUEST_NAME';

export const BAN_USER_JOIN_REQUEST_FAILED_START = 'BAN_USER_JOIN_REQUEST_FAILED_START';
export const BAN_USER_JOIN_REQUEST_FAILED_END = 'BAN_USER_JOIN_REQUEST_FAILED_END';
export const BAN_USER_JOIN_REQUEST_FAILED_FAILED = 'BAN_USER_JOIN_REQUEST_FAILED_FAILED';

export const SET_CHECK_USER_DATA = 'SET_CHECK_USER_DATA';

export const UNBAN_COMPANY_CREATE_REQUEST_START = 'UNBAN_COMPANY_CREATE_REQUEST_START';
export const UNBAN_COMPANY_CREATE_REQUEST_FINISHED = 'UNBAN_COMPANY_CREATE_REQUEST_FINISHED';
export const UNBAN_COMPANY_CREATE_REQUEST_FAILED = 'UNBAN_COMPANY_CREATE_REQUEST_FAILED';

export const UNBAN_COMPANY_JOIN_REQUEST_START = 'UNBAN_COMPANY_JOIN_REQUEST_START';
export const UNBAN_COMPANY_JOIN_REQUEST_FINISHED = 'UNBAN_COMPANY_JOIN_REQUEST_FINISHED';
export const UNBAN_COMPANY_JOIN_REQUEST_FAILED = 'UNBAN_COMPANY_JOIN_REQUEST_FAILED';

export const SET_MODAL_TEXT = 'SET_MODAL_TEXT';

export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_FAILED = 'UPDATE_USER_INFO_FAILED';
export const UPDATE_USER_INFO_FINISHED = 'UPDATE_USER_INFO_FINISHED';

export const DELETE_COMPANY_IMAGE_START = 'DELETE_COMPANY_IMAGE_START';
export const DELETE_COMPANY_IMAGE_END = 'DELETE_COMPANY_IMAGE_END';
export const DELETE_COMPANY_IMAGE_FAILED = 'DELETE_COMPANY_IMAGE_FAILED';

export const CONTACT_US_START = 'CONTACT_US_START';
export const CONTACT_US_FINISHED = 'CONTACT_US_FINISHED';
export const CONTACT_US_FAILED = 'CONTACT_US_FAILED';

export const TOGGLE_CONTACT_US_MODAL = 'TOGGLE_CONTACT_US_MODAL';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const CHECK_RECAPTCHA_START = "CHECK_RECAPTCHA_START";
export const CHECK_RECAPTCHA_END = "CHECK_RECAPTCHA_END";
export const CHECK_RECAPTCHA_FAILED = "CHECK_RECAPTCHA_FAILED";

export const GET_COMPANY_STATUS_START = "GET_COMPANY_STATUS_START";
export const GET_COMPANY_STATUS_END = "GET_COMPANY_STATUS_END";
export const GET_COMPANY_STATUS_FAILED = "GET_COMPANY_STATUS_FAILED";

export const CREATE_COMPANY_JOIN_REQUEST_START = "CREATE_COMPANY_JOIN_REQUEST_START";
export const CREATE_COMPANY_JOIN_REQUEST_END = "CREATE_COMPANY_JOIN_REQUEST_END";
export const CREATE_COMPANY_JOIN_REQUEST_FAILED = "CREATE_COMPANY_JOIN_REQUEST_FAILED";

export const DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_START = 'DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_START';
export const DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_END = 'DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_END';
export const DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_FAILED = 'DELETE_COMPANIES_CREATE_REQUESTS_IMAGE_FAILED';

export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_FINISHED = 'EDIT_USER_FINISHED';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';

export const CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_START = 'CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_START';
export const CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FINISHED = 'CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FINISHED';
export const CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FAILED = 'CHANGE_USER_PASSWORD_BY_SUPER_ADMIN_FAILED';

export const CHANGE_USER_ROLE_BY_SUPER_ADMIN_START = 'CHANGE_USER_ROLE_BY_SUPER_ADMIN_START';
export const CHANGE_USER_ROLE_BY_SUPER_ADMIN_FINISHED = 'CHANGE_USER_ROLE_BY_SUPER_ADMIN_FINISHED';
export const CHANGE_USER_ROLE_BY_SUPER_ADMIN_FAILED = 'CHANGE_USER_ROLE_BY_SUPER_ADMIN_FAILED';

export const ON_SET_USER_FOR_EDIT = 'ON_SET_USER_FOR_EDIT';

export const ON_SET_COMPANY_FOR_EDIT = 'ON_SET_COMPANY_FOR_EDIT';

export const ON_SET_JOB_FOR_EDIT = 'ON_SET_JOB_FOR_EDIT';
export const ON_SET_REPORT_TEMPLATE_FOR_EDIT = 'ON_SET_REPORT_TEMPLATE_FOR_EDIT';
export const ON_SET_REPORT_TEMPLATE_FOR_COPY = 'ON_SET_REPORT_TEMPLATE_FOR_COPY';

export const SET_CREATE_EDIT_ITEM = 'SET_CREATE_EDIT_ITEM';

export const SET_ASSIGN_JOB_INFO = 'SET_ASSIGN_JOB_INFO';

export const SET_MODAL_TEXT_INSIDE = 'SET_MODAL_TEXT_INSIDE';

export const SET_REPORT_TEMPLATES_CR_FIL_DATA = 'SET_REPORT_TEMPLATES_CR_FIL_DATA';

export const SET_CREATE_EDIT_PLACEHOLDERS = 'SET_CREATE_EDIT_PLACEHOLDERS';

export const GET_ANALYTICS_COMPANY_ITEMS_START = 'GET_ANALYTICS_COMPANY_ITEMS_START';
export const GET_ANALYTICS_COMPANY_ITEMS_END = 'GET_ANALYTICS_COMPANY_ITEMS_END';
export const GET_ANALYTICS_COMPANY_ITEMS_FAILED = 'GET_ANALYTICS_COMPANY_ITEMS_FAILED';

export const GET_COMPANY_ASSETS_CREATE_REQUESTS_START = 'GET_COMPANY_ASSETS_CREATE_REQUESTS_START';
export const GET_COMPANY_ASSETS_CREATE_REQUESTS_END = 'GET_COMPANY_ASSETS_CREATE_REQUESTS_END';
export const GET_COMPANY_ASSETS_CREATE_REQUESTS_FAILED = 'GET_COMPANY_ASSETS_CREATE_REQUESTS_FAILED';

export const CREATE_NEW_ASSET_REQUEST_START = 'CREATE_NEW_ASSET_REQUEST_START';
export const CREATE_NEW_ASSET_REQUEST_END = 'CREATE_NEW_ASSET_REQUEST_END';
export const CREATE_NEW_ASSET_REQUEST_FAILED = 'CREATE_NEW_ASSET_REQUEST_FAILED';

export const UPLOAD_NEW_ASSET_REQUEST_IMAGE_START = 'UPLOAD_NEW_ASSET_REQUEST_IMAGE_START';
export const UPLOAD_NEW_ASSET_REQUEST_IMAGE_END = 'UPLOAD_NEW_ASSET_REQUEST_IMAGE_END';
export const UPLOAD_NEW_ASSET_REQUEST_IMAGE_FAILED = 'UPLOAD_NEW_ASSET_REQUEST_IMAGE_FAILED';

export const UPDATE_NEW_ASSET_REQUEST_IMAGE_START = 'UPDATE_NEW_ASSET_REQUEST_IMAGE_START';
export const UPDATE_NEW_ASSET_REQUEST_IMAGE_END = 'UPDATE_NEW_ASSET_REQUEST_IMAGE_END';
export const UPDATE_NEW_ASSET_REQUEST_IMAGE_FAILED = 'UPDATE_NEW_ASSET_REQUEST_IMAGE_FAILED';

export const DELETE_COMPANY_ASSETS_CREATE_REQUEST_START = 'DELETE_COMPANY_ASSETS_CREATE_REQUEST_START';
export const DELETE_COMPANY_ASSETS_CREATE_REQUEST_END = 'DELETE_COMPANY_ASSETS_CREATE_REQUEST_END';
export const DELETE_COMPANY_ASSETS_CREATE_REQUEST_FAILED = 'DELETE_COMPANY_ASSETS_CREATE_REQUEST_FAILED';

export const SET_LICENSE_TO_COMPANY_START = 'SET_LICENSE_TO_COMPANY_START';
export const SET_LICENSE_TO_COMPANY_END = 'SET_LICENSE_TO_COMPANY_END';
export const SET_LICENSE_TO_COMPANY_FAILED = 'SET_LICENSE_TO_COMPANY_FAILED';

export const SET_CONTACT_US_IS_OPEN = 'SET_CONTACT_US_IS_OPEN';

export const GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_START = 'GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_START';
export const GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_END = 'GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_END';
export const GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_FAILED = 'GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_FAILED';

export const APPROVE_COMPANY_ASSETS_CREATE_REQUEST_START = 'APPROVE_COMPANY_ASSETS_CREATE_REQUEST_START';
export const APPROVE_COMPANY_ASSETS_CREATE_REQUEST_END = 'APPROVE_COMPANY_ASSETS_CREATE_REQUEST_END';
export const APPROVE_COMPANY_ASSETS_CREATE_REQUEST_FAILED = 'APPROVE_COMPANY_ASSETS_CREATE_REQUEST_FAILED';

export const REJECT_ASSET_CREATE_REQUEST_START = 'REJECT_ASSET_CREATE_REQUEST_START';
export const REJECT_ASSET_CREATE_REQUEST_END = 'REJECT_ASSET_CREATE_REQUEST_END';
export const REJECT_ASSET_CREATE_REQUEST_FAILED = 'REJECT_ASSET_CREATE_REQUEST_FAILED';

export const SET_CHOSEN_ASSET_ID = 'SET_CHOSEN_ASSET_ID';
export const GET_GENERAL_ANALYTICS_COMPANY_START = 'GET_GENERAL_ANALYTICS_COMPANY_START';
export const SET_GENERAL_ANALYTICS_COMPANY = 'SET_GENERAL_ANALYTICS_COMPANY';
export const GET_GENERAL_ANALYTICS_COMPANY_FAILED = 'GET_GENERAL_ANALYTICS_COMPANY_FAILED';

export const GET_USER_NOTIFICATIONS_START = 'GET_USER_NOTIFICATIONS_START';
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';
export const GET_USER_NOTIFICATIONS_FAILED = 'GET_USER_NOTIFICATIONS_FAILED';

export const CHANGE_COMPONENT = 'CHANGE_COMPONENT';
export const SHOW_WARNING_MODAL_REDUX = 'SHOW_WARNING_MODAL_REDUX';

export const SET_GLOBAL_SPINNER = 'SET_GLOBAL_SPINNER';

export const GET_ASSET_START = 'GET_ASSET_START';
export const SET_ASSET = 'SET_ASSET';
export const GET_ASSET_FAILED = 'GET_ASSET_FAILED';

export const GET_INFO_WHERE_USE_ITEM_START = 'GET_INFO_WHERE_USE_ITEM_START';
export const GET_INFO_WHERE_USE_ITEM_END = 'GET_INFO_WHERE_USE_ITEM_END';
export const GET_INFO_WHERE_USE_ITEM_FAILED = 'GET_INFO_WHERE_USE_ITEM_FAILED';

export const ADD_COMPANY_DESIGN_RANGE_START = 'ADD_COMPANY_DESIGN_RANGE_START';
export const ADD_COMPANY_DESIGN_RANGE_END = 'ADD_COMPANY_DESIGN_RANGE_END';
export const ADD_COMPANY_DESIGN_RANGE_FAILED = 'ADD_COMPANY_DESIGN_RANGE_FAILED';

export const DELETE_COMPANY_DESIGN_RANGE_START = 'DELETE_COMPANY_DESIGN_RANGE_START';
export const DELETE_COMPANY_DESIGN_RANGE_END = 'DELETE_COMPANY_DESIGN_RANGE_END';
export const DELETE_COMPANY_DESIGN_RANGE_FAILED = 'DELETE_COMPANY_DESIGN_RANGE_FAILED';

export const EDIT_COMPANY_DESIGN_RANGE_START = 'EDIT_COMPANY_DESIGN_RANGE_START';
export const EDIT_COMPANY_DESIGN_RANGE_END = 'EDIT_COMPANY_DESIGN_RANGE_END';
export const EDIT_COMPANY_DESIGN_RANGE_FAILED = 'EDIT_COMPANY_DESIGN_RANGE_FAILED';

export const GET_DESIGN_RANGE_COMPANY_START = 'GET_DESIGN_RANGE_COMPANY_START';
export const SET_DESIGN_RANGE_COMPANY_END = 'SET_DESIGN_RANGE_COMPANY_END';
export const GET_DESIGN_RANGE_COMPANY_FAILED = 'GET_DESIGN_RANGE_COMPANY_FAILED';

export const GET_DESIGN_RANGE_START = 'GET_DESIGN_RANGE_START';
export const SET_DESIGN_RANGE_END = 'SET_DESIGN_RANGE_END';
export const GET_DESIGN_RANGE_FAILED = 'GET_DESIGN_RANGE_FAILED';

export const ADD_COMPANY_REPORT_TEMPLATES_START = 'ADD_COMPANY_REPORT_TEMPLATES_START';
export const ADD_COMPANY_REPORT_TEMPLATES_END = 'ADD_COMPANY_REPORT_TEMPLATES_END';
export const ADD_COMPANY_REPORT_TEMPLATES_FAILED = 'ADD_COMPANY_REPORT_TEMPLATES_FAILED';

export const GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_START = 'GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_START';
export const SET_REPORT_TEMPLATES_OF_DESIGN_RANGE_END = 'SET_REPORT_TEMPLATES_OF_DESIGN_RANGE_END';
export const GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_FAILED = 'GET_REPORT_TEMPLATES_OF_DESIGN_RANGE_FAILED';

export const GET_SELECTION_TYPE_START = 'GET_SELECTION_TYPE_START';
export const GET_SELECTION_TYPE_ITEMS_CATEGORIES_START = 'GET_SELECTION_TYPE_ITEMS_CATEGORIES_START';
export const GET_SELECTION_TYPE_ITEMS_VENDORS_START = 'GET_SELECTION_TYPE_ITEMS_VENDORS_START';
export const GET_SELECTION_TYPE_ITEMS_ATTRIBUTES_START = 'GET_SELECTION_TYPE_ITEMS_ATTRIBUTES_START';
export const GET_SELECTION_TYPE_ITEMS_CATEGORIES_FAILED = 'GET_SELECTION_TYPE_ITEMS_CATEGORIES_FAILED';
export const GET_SELECTION_TYPE_ITEMS_VENDORS_FAILED = 'GET_SELECTION_TYPE_ITEMS_VENDORS_FAILED';
export const GET_SELECTION_TYPE_ITEMS_ATTRIBUTES_FAILED = 'GET_SELECTION_TYPE_ITEMS_ATTRIBUTES_FAILED';
export const SET_SELECTION_TYPE_ITEMS = 'SET_SELECTION_TYPE_ITEMS';
export const SET_SELECTION_TYPE_ITEMS_CATEGORIES = 'SET_SELECTION_TYPE_ITEMS_CATEGORIES';
export const SET_SELECTION_TYPE_ITEMS_VENDORS = 'SET_SELECTION_TYPE_ITEMS_VENDORS';
export const SET_SELECTION_TYPE_ITEMS_ATTRIBUTES = 'SET_SELECTION_TYPE_ITEMS_ATTRIBUTES';
export const GET_SELECTION_TYPE_FAILED = 'GET_SELECTION_TYPE_FAILED';
