import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    error: false,
    loading: false,
    selectionTypeCategories: [],
    selectionTypeVendors: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SELECTION_TYPE_ITEMS_CATEGORIES_START:
            return {
                ...state,
                selectionTypeCategories: [],
                loading: true
            };
        case actionTypes.GET_SELECTION_TYPE_ITEMS_VENDORS_START:
            return {
                ...state,
                selectionTypeVendors: [],
                loading: true
            };
        case actionTypes.SET_SELECTION_TYPE_ITEMS_CATEGORIES:
            return {
                ...state,
                selectionTypeCategories: action.selectionTypeCategories,
                error: false,
                loading: false
            };
        case actionTypes.SET_SELECTION_TYPE_ITEMS_VENDORS:
            return {
                ...state,
                selectionTypeVendors: action.selectionTypeVendors,
                error: false,
                loading: false
            };
        case actionTypes.GET_SELECTION_TYPE_ITEMS_CATEGORIES_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            };
        case actionTypes.GET_SELECTION_TYPE_ITEMS_VENDORS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
