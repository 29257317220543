import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    error: false,
    loading: false,
    exportItemsUrl: '',
    companyItemsStatusByFilter: null,
    companyItemsExport: new Array(0),
    companyJobsItems: new Array(0),
    companyItems: new Array(0),
    categories: new Array(0),
    categoriesTotalItems: 0,
    analyticsCompanyData: null,
    companyAssetsCreateRequestsItems: new Array(0),
    companyAssetsCreateRequestsItemsCount: 0,
    companyAssetsCreateRequestsSAItems: new Array(0),
    companyAssetsCreateRequestsSAItemsCount: 0,
    companyJobsItemsCount: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FINISHED:
            return  {
                ...state,
                loading: false
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTE_VALUE_TO_COMPANY_ITEM_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FINISHED:
            return  {
                ...state,
                loading: false,
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_COMPANY_ITEM_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_CUSTOM_ATTRIBUTES_TO_RESELLER_ITEM_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_RESELLER_COMPANY_ITEM_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.EDIT_RESELLER_COMPANY_ITEM_FINISHED:
            return  {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_RESELLER_COMPANY_ITEM_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FINISHED:
            return  {
                ...state,
                loading: false,
            };
        case actionTypes.DELETE_CUSTOM_ATTRIBUTE_FROM_RESELLER_ITEM_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_FINISHED:
            return  {
                ...state,
                loading: false,
            };
        case actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_ALL_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FINISHED:
            return  {
                ...state,
                loading: false,
            };
        case actionTypes.ADD_ITEMS_TO_COMPANY_ROASTER_ALL_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EXPORT_ITEMS_TO_EXCEL_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_EXPORT_ITEMS_URL:
            return  {
                ...state,
                loading: false,
                error: false,
                exportItemsUrl: action.exportItemsUrl
            };
        case actionTypes.EXPORT_ITEMS_TO_EXCEL_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_ITEMS_STATUS_BY_FILTER_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_ITEMS_STATUS_BY_FILTER:
            return  {
                ...state,
                loading: false,
                error: false,
                companyItemsStatusByFilter: action.companyItemsStatus
            };
        case actionTypes.GET_ITEMS_STATUS_BY_FILTER_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_ITEMS_EXPORT_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_ITEMS_EXPORT:
            return  {
                ...state,
                loading: false,
                error: false,
                companyItemsExport: action.companyItemsExport
            };
        case actionTypes.GET_COMPANY_ITEMS_EXPORT_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_JOBS_ITEMS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_JOBS_ITEMS:
            return  {
                ...state,
                loading: false,
                error: false,
                companyJobsItems: action.companyJobsItems,
                companyJobsItemsCount: action.companyJobsItemsCount
            };
        case actionTypes.GET_COMPANY_JOBS_ITEMS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_CATEGORIES_ITEMS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_CATEGORIES_ITEMS:
            return  {
                ...state,
                loading: false,
                error: false,
                categories: action.categories,
                categoriesTotalItems: action.categoriesTotalItems
            };
        case actionTypes.GET_COMPANY_CATEGORIES_ITEMS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_ITEMS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_COMPANY_ITEMS:
            return {
                ...state,
                loading: false,
                error: false,
                companyItems: action.companyItems
            };
        case actionTypes.GET_COMPANY_ITEMS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_ANALYTICS_COMPANY_ITEMS_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.GET_ANALYTICS_COMPANY_ITEMS_END:
            return  {
                ...state,
                loading: false,
                error: false,
                analyticsCompanyData: action.analyticsCompanyData
            };
        case actionTypes.GET_ANALYTICS_COMPANY_ITEMS_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_ASSETS_CREATE_REQUESTS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_COMPANY_ASSETS_CREATE_REQUESTS_END:
            return {
                ...state,
                loading: false,
                error: false,
                companyAssetsCreateRequestsItems: action.companyAssetsCreateRequestsItems,
                companyAssetsCreateRequestsItemsCount: action.companyAssetsCreateRequestsItemsCount
            };
        case actionTypes.GET_COMPANY_ASSETS_CREATE_REQUESTS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_COMPANY_ASSETS_CREATE_REQUEST_START:
            return  {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_COMPANY_ASSETS_CREATE_REQUEST_END:
            return  {
                ...state,
                loading: false,
            };
        case actionTypes.DELETE_COMPANY_ASSETS_CREATE_REQUEST_FAILED:
            return  {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_END:
            return {
                ...state,
                loading: false,
                error: false,
                companyAssetsCreateRequestsSAItems: action.companyAssetsCreateRequestsSAItems,
                companyAssetsCreateRequestsSAItemsCount: action.companyAssetsCreateRequestsSAItemsCount
            };
        case actionTypes.GET_COMPANY_ASSETS_CREATE_SA_REQUESTS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.APPROVE_COMPANY_ASSETS_CREATE_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.APPROVE_COMPANY_ASSETS_CREATE_REQUEST_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.APPROVE_COMPANY_ASSETS_CREATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.REJECT_ASSET_CREATE_REQUEST_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.REJECT_ASSET_CREATE_REQUEST_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.REJECT_ASSET_CREATE_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_INFO_WHERE_USE_ITEM_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_INFO_WHERE_USE_ITEM_END:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.GET_INFO_WHERE_USE_ITEM_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default reducer;
