import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {Provider} from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { createStore, applyMiddleware, compose, combineReducers} from "redux";
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form'

import reportTemplateReducer from "./Store/Reducers/reportTemplates";
import placeholderReducer from './Store/Reducers/placeholders';
import assetReducer from './Store/Reducers/asset';
import resellerReducer from './Store/Reducers/reseller';
import companyReducer from './Store/Reducers/company';
import jobReducer from './Store/Reducers/job';
import packageReducer from './Store/Reducers/packages';
import colorSchemeReducer from './Store/Reducers/colorScheme';
import companyItemReducer from './Store/Reducers/companyItem';
import attributesReducer from './Store/Reducers/attributes';
import categoriesReducer from './Store/Reducers/categories';
import vendorsReducer from './Store/Reducers/vendors';
import usersReducer from './Store/Reducers/users';
import authReducer from './Store/Reducers/auth';
import designRangeReducer from './Store/Reducers/designRange';
import selectionTypeReducer from './Store/Reducers/selectionType';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    reportTemplate: reportTemplateReducer,
    placeholder: placeholderReducer,
    asset: assetReducer,
    reseller: resellerReducer,
    company: companyReducer,
    job: jobReducer,
    packages: packageReducer,
    colorScheme: colorSchemeReducer,
    companyItem: companyItemReducer,
    attributes: attributesReducer,
    categories: categoriesReducer,
    vendors: vendorsReducer,
    users: usersReducer,
    auth: authReducer,
    designRange: designRangeReducer,
    selectionType: selectionTypeReducer,
    form: formReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App store={store}/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
