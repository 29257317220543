import React from 'react';

const Login = React.lazy(() => import('./Login/Login'));
const TermsOfService = React.lazy(() => import('./Login/Login'));
const PrivacyPolicy = React.lazy(() => import('./Login/Login'));
const RejectedCreateCompany = React.lazy(() => import('./Login/SetUpCompany'));
const WaitingAdmionReview = React.lazy(() => import('./Login/SetUpCompany'));
const SucceedRequest = React.lazy(() => import('./Login/SetUpCompany'));
const CreateCompany = React.lazy(() => import('./Login/SetUpCompany'));
const RequestCompany = React.lazy(() => import('./Login/SetUpCompany'));
const Verification = React.lazy(() => import('./Login/SetUpCompany'));
const GetStarted = React.lazy(() => import('./Login/Login'));
const Registration = React.lazy(() => import('./SignUp'));
const ForgotPassword = React.lazy(() => import('./Login/Login'));
const RecoverPassword = React.lazy(() => import('./Login/Login'));
const DashboardContainer = React.lazy(() => import('./DashboardContainer/DashboardContainer'));
const UserBanned = React.lazy(() => import('./Login/SetUpCompany'));
const CompanyDeactivated = React.lazy(() => import('./Login/SetUpCompany'));
const CompanyJoinRequestRejected = React.lazy(() => import('./Login/SetUpCompany'));
const UserJoinBanned = React.lazy(() => import('./Login/SetUpCompany'));
const CompanyExpiredAdmin = React.lazy(() => import('./Login/SetUpCompany'));
const CompanyExpiredUser = React.lazy(() => import('./Login/SetUpCompany'));
const ContactSalesTeam = React.lazy(() => import('./Login/ContactUs'));

const loginRoutes = [
    {path: "/contact-support-team", component: ContactSalesTeam, name: "Contact Support Team" },
    {path: "/company-expired-admin", component: CompanyExpiredAdmin,  name: 'Company Expired Admin'},
    {path: "/company-expired-user", component: CompanyExpiredUser,  name: 'Company Expired User'},
    {path: "/user-join-banned", component: UserJoinBanned,  name: 'User Join Banned'},
    {path: "/reject-join-request", component: CompanyJoinRequestRejected,  name: 'Company Join Request Rejected'},
    {path: "/company-deactivated", component: CompanyDeactivated,  name: 'Company Deactivated'},
    {path: "/user-banned", component: UserBanned,  name: 'User banned'},
    {path: "/terms-service", component: TermsOfService,  name: 'Terms of service'},
    {path: "/privacy-policy", component: PrivacyPolicy, name: 'Privacy policy'},
    {path: "/rejected-create-company", component: RejectedCreateCompany, name: 'Rejected create company request'},
    {path: "/waiting-admin-review", component: WaitingAdmionReview, name: 'Waiting admin review'},
    {path: "/successed-request", component: SucceedRequest, name: 'Succed create company request'},
    {path: "/create-company", component:CreateCompany, name: 'Create company request'},
    {path: "/request-company", component: RequestCompany, name: 'Request company'},
    {path: "/verification", component: Verification, name: 'User verification'},
    {path: "/get-started", component: GetStarted, name: 'Get started'},
    {path: "/registration", component: Registration, name: 'Registration'},
    {path: "/forgot-password", component: ForgotPassword, name: 'Forgot password'},
    {path: "/recovery", component: RecoverPassword, name: 'Recover password'},
    {path: "/login", component: Login, name: 'Login'},
    {path: "/", component: DashboardContainer, name: 'Dashboard container' }
]

export default loginRoutes;