export class Job {
    constructor(name, address, description, companyId, consultantId, status, jobId, packageId) {
        this.name = name ? name : '';
        this.address = address ? address : '';
        this.description = description ? description : 'description';
        this.companyId = companyId;
        this.consultantId = consultantId;
        this.status = status ? status : 'JOB_STATUS_NEW';
        this.jobId = jobId ? jobId : '';
        this.packageId = packageId;
    }
}
