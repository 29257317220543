import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';
import generalUtils from "../../Utils/GeneralUtils";

export const getPlaceholdersStart = () => {
    return {
        type: actionTypes.GET_PLACEHOLDERS_START
    }
};

export const getPlaceholdersFailed = () => {
    return {
        type: actionTypes.GET_PLACEHOLDERS_FAILED
    }
};

export const setPlaceholders = (placeholders) => {
    return {
        type: actionTypes.SET_PLACEHOLDERS,
        placeholders: placeholders
    }
};

export const getPlaceholders = (companyId, searchWord, callback, limit, offset, sort) => {
    return dispatch => {
        dispatch(getPlaceholdersStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceHolders${sortItems}`, {
            params: {
              ...(limit ? {limit} : {limit: '1000'}),
                ...(offset ? {offset} : {offset: '0'}),
                ...(searchWord ? {q: searchWord} : {}),
            }
        })
            .then(response => {
                if (callback) {
                    callback(response);
                }
                dispatch(setPlaceholders(response.data))
            })
            .catch(error => {
                dispatch(getPlaceholdersFailed())
            })
    }
};

export const addPlaceholderStart = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_START
    }
};

export const addPlaceholderFinished = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_FINISHED
    }
};

export const addPlaceholderFailed = (errorText) => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_FAILED,
        errorText: errorText
    }
};

export const addPlaceholder = (companyId, placeholder, callback, errorCallback) => {
    return dispatch => {
        dispatch(addPlaceholderStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceHolders`, placeholder)
            .then(response => {
                callback(response.data)
            })
            .catch(error => {
                dispatch(addPlaceholderFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const editPlaceholderStart = () => {
    return {
        type: actionTypes.EDIT_PLACEHOLDER_START
    }
};

export const editPlaceholderFinished = () => {
    return {
        type: actionTypes.EDIT_PLACEHOLDER_FINISHED
    }
};

export const editPlaceholder = (companyId, placeholderId, placeholder, callback, errorCallback) => {
    return dispatch => {
        dispatch(editPlaceholderStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceHolders/${placeholderId}`, placeholder)
            .then(response => {
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error)
                dispatch(editPlaceholderFinished())
            })
    }
};

export const removePlaceholderStart = () => {
    return {
        type: actionTypes.REMOVE_PLACEHOLDER_START
    }
};

export const removePlaceholderFinished = () => {
    return {
        type: actionTypes.REMOVE_PLACEHOLDER_FINISHED
    }
};

export const removePlaceholder = (companyId, placeholderId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removePlaceholderStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceHolders/${placeholderId}`)
            .then(response => {
                callback();
            })
            .catch(error => {
                errorCallback(error.response.data.error)
                dispatch(removePlaceholderFinished())
            })
    }
};

export const addPlaceholderToReportTemplateStart = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_START
    }
};

export const addPlaceholderToReportTemplateFinished = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FINISHED
    }
};

export const addPlaceholderToReportTemplateFailed = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_TO_REPORT_TEMPLATE_FAILED
    }
};

export const addPlaceholderToReportTemplate = (companyId, reportTemplateId, headerId, placeholderId, callback, errorCallback) => {
    return dispatch => {
        dispatch(addPlaceholderToReportTemplateStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/headers/${headerId}/cph/${placeholderId}`)
            .then(response => {
                callback();
                dispatch(addPlaceholderToReportTemplateFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addPlaceholderToReportTemplateFailed())
            })
    }
};

export const removePlaceholderFromReportTemplateStart = () => {
    return {
        type: actionTypes.REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_START
    }
};

export const removePlaceholderFromReportTemplateFinished = () => {
    return {
        type: actionTypes.REMOVE_PLACEHOLDER_FROM_REPORT_TEMPLATE_FINISHED
    }
};

export const removePlaceholderFromReportTemplate = (companyId, reportTemplateId, headerId, placeholderId, callback) => {
    return dispatch => {
        dispatch(removePlaceholderFromReportTemplateStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/reportTemplates/${reportTemplateId}/headers/${headerId}/cph/${placeholderId}`)
            .then(response => {
                callback();
                dispatch(removePlaceholderFromReportTemplateFinished())
            })
            .catch(error => {
                dispatch(removePlaceholderFromReportTemplateFinished())
            })
    }
};

export const getCategoryCustomAttributeStart = () => {
    return {
        type: actionTypes.GET_CATEGORY_CUSTOM_ATTRIBUTE_START
    }
};

export const setCategoryCustomAttribute = (attributes) => {
    return {
        type: actionTypes.SET_CATEGORY_CUSTOM_ATTRIBUTE,
        categoryAttributes: attributes
    }
};

export const getCategoryCustomAttributeFailed = () => {
    return {
        type: actionTypes.GET_CATEGORY_CUSTOM_ATTRIBUTE_FAILED
    }
};

export const getCategoryCustomAttributes = (companyId, customAttributeId, categoryId, vendorId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCategoryCustomAttributeStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/itemsCustomAttributes/${customAttributeId}`, {
            params: {
              ...(categoryId ? {categoryId} : {}),
              ...(vendorId ? {vendorId} : {}),
            }
        })
            .then(response => {
                if (callback) {
                    callback(response.data);
                }
                dispatch(setCategoryCustomAttribute(response.data));
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getCategoryCustomAttributeFailed());
            })
    }
};

export const deleteCategoryFromPlaceholderStart = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_CATEGORY_START
    }
};

export const deleteCategoryFromPlaceholderFinished = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_CATEGORY_FINISHED
    }
};

export const deleteCategoryFromPlaceholderFailed = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_CATEGORY_FINISHED
    }
};

export const deleteCategoryFromPlaceholder = (companyId, placeholderId, categoryId, updateSelection, callback, errorCallback) => {
    return dispatch =>{
        dispatch(deleteCategoryFromPlaceholderStart());
        return  axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceholders/${placeholderId}/categories/${categoryId}`, { data: updateSelection})
            // .then(response => {
            //     if (callback) {
            //         callback();
            //         dispatch(deleteCategoryFromPlaceholderFinished())
            //     }
            // })
            // .catch(error => {
            //     errorCallback(error.response.data.error);
            //     dispatch(deleteCategoryFromPlaceholderFailed())
            // })
    }
};

export const addCategoriesToPlaceholderStart = () => {
    return {
        type: actionTypes.ADD_CATEGORIES_TO_PLACEHOLDER_START
    }
};

export const addCategoriesToPlaceholderFinished = () => {
    return {
        type: actionTypes.ADD_CATEGORIES_TO_PLACEHOLDER_FINISHED
    }
};

export const addCategoriesToPlaceholderFailed = () => {
    return {
        type: actionTypes.ADD_CATEGORIES_TO_PLACEHOLDER_FAILED
    }
};

export const addCategoriesToPlaceholder = (companyId, placeholderId, categories, callback, errorCallback) => {
    return dispatch =>{
        dispatch(addCategoriesToPlaceholderStart());
        return axios.post(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceholders/${placeholderId}/categories`, categories)
            // .then(response => {
            //     if (callback) {
            //         callback();
            //         dispatch(addCategoriesToPlaceholderFinished())
            //     }
            // })
            // .catch(error => {
            //     errorCallback(error.response.data.error);
            //     dispatch(addCategoriesToPlaceholderFailed())
            // })
    }
};

export const addPlaceholderCategoryAttributeStart = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_START
    }
};

export const addPlaceholderCategoryAttributeFinished = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED
    }
};

export const addPlaceholderCategoryAttributeFailed = () => {
    return {
        type: actionTypes.ADD_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED
    }
};

export const addPlaceholderCategoryAttribute = (companyId, placeholderId, categoryId, attributes, callback, errorCallback) => {
    return dispatch => {
        dispatch(addPlaceholderCategoryAttributeStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceholders/${placeholderId}/categories/${categoryId}/application`, attributes)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addPlaceholderCategoryAttributeFinished())
            })
            .catch(error => {
                dispatch(addPlaceholderCategoryAttributeFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const deletePlaceholderCategoryAttributeStart = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_START
    }
};

export const deletePlaceholderCategoryAttributeFinished = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FINISHED
    }
};

export const deletePlaceholderCategoryAttributeFailed = () => {
    return {
        type: actionTypes.DELETE_PLACEHOLDER_CATEGORY_ATTRIBUTE_FAILED
    }
};

export const deletePlaceholderCategoryAttribute = (companyId, placeholderId, categoryId, attribute, callback, errorCallback) => {
    return dispatch => {
        dispatch(deletePlaceholderCategoryAttributeStart());
        return axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceholders/${placeholderId}/categories/${categoryId}/application`, {
            params: {
              ...({value: attribute})
            }
        })
            // .then(response => {
            //     if (callback) {
            //         callback()
            //     }
            //     dispatch(deletePlaceholderCategoryAttributeFinished())
            // })
            // .catch(error => {
            //     dispatch(deletePlaceholderCategoryAttributeFailed());
            //     errorCallback(error.response.data.error);
            // })
    }
};

export const getCategoryPlaceholderStatusInJobsStart = () => {
    return {
        type: actionTypes.GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_START
    }
}

export const getCategoryPlaceholderStatusInJobsFinished = () => {
    return {
        type: actionTypes.GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_FINISHED
    }
}

export const getCategoryPlaceholderStatusInJobsFailed = () => {
    return {
        type: actionTypes.GET_CATEGORY_PLACEHOLDER_STATUS_IN_JOBS_FAILED
    }
}

export const getCategoryPlaceholderStatusInJobs = (companyId, placeholderId, categoryId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCategoryPlaceholderStatusInJobsStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/categoryPlaceholders/${placeholderId}/categories/${categoryId}/statusInJobs`)
            .then(response => {
                dispatch(getCategoryPlaceholderStatusInJobsFinished());
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getCategoryPlaceholderStatusInJobsFailed());
                errorCallback(error.response.data.error);
            })
    }
}

export const getCategoryPlaceholdersPostStart = () => {
    return {
        type: actionTypes.GET_CATEGORY_PLACEHOLDER_POST_START
    }
}

export const setCategoryPlaceholdersPost = (freePlaceholders) => {
    return {
        type: actionTypes.SET_CATEGORY_PLACEHOLDER_POST,
        freePlaceholders: freePlaceholders
    }
}

export const getCategoryPlaceholdersPostFailed = () => {
    return {
        type: actionTypes.GET_CATEGORY_PLACEHOLDER_POST_FAILED
    }
}

export const getCategoryPlaceholdersPost = (companyId, categories, attributes, callback, errorCallback, limit) => {
    return dispatch => {
        dispatch(getCategoryPlaceholdersPostStart());
        let q = '';
        for (let category of categories) {
            q += '&categoryIds[]=' + category.id
        }
        axios.post(Constants.SERVER_URL + '/companies/' + companyId + '/categoryPlaceholders/get/?limit=1000' + q, attributes)
            .then(response => {
                dispatch(setCategoryPlaceholdersPost(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getCategoryPlaceholdersPostFailed());
            })
    }
}

export const removeCompanyItemPlaceholderStart = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_PLACEHOLDER_START
    }
}

export const removeCompanyItemPlaceholderEnd = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_PLACEHOLDER_END
    }
}

export const removeCompanyItemPlaceholderFailed = () => {
    return {
        type: actionTypes.REMOVE_COMPANY_ITEM_PLACEHOLDER_FAILED
    }
}

export const removeCompanyItemSelectionType = (companyId, jobId, selectionTypeId, itemId, callback, callbackError) => {
    return dispatch => {
        dispatch(removeCompanyItemPlaceholderStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/items/${itemId}/selectionTypes/${selectionTypeId}`)
        .then(response => {
            dispatch(removeCompanyItemPlaceholderEnd());
            callback(response.data)
        })
        .catch(error => {
            console.log(error)
            dispatch(removeCompanyItemPlaceholderFailed());
            callbackError(error);
        })

    }
}

export const addJobPlaceholderNoteStart = () => {
    return {
        type: actionTypes.ADD_JOB_PLACEHOLDER_NOTE_START
    }
}

export const addJobPlaceholderNoteEnd = () => {
    return {
        type: actionTypes.ADD_JOB_PLACEHOLDER_NOTE_END
    }
}

export const addJobPlaceholderNoteFailed = () => {
    return {
        type: actionTypes.ADD_JOB_PLACEHOLDER_NOTE_FAILED
    }
}

export const addJobSelectionTypeNote = (companyId, jobId, selectionTypeId, note, callback, callbackError) => {
    return dispatch => {
        dispatch(addJobPlaceholderNoteStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/selectionTypes/${selectionTypeId}/notes`,
        note)
        .then(response => {
            dispatch(addJobPlaceholderNoteEnd());
            callback(response.data)
        })
        .catch(error => {
            console.log(error)
            dispatch(addJobPlaceholderNoteFailed());
            callbackError(error);
        })
    }

}

export const deleteJobPlaceholderNoteStart = () => {
    return {
        type: actionTypes.DELETE_JOB_PLACEHOLDER_NOTE_START
    }
}

export const deleteJobPlaceholderNoteEnd = () => {
    return {
        type: actionTypes.DELETE_JOB_PLACEHOLDER_NOTE_END
    }
}

export const deleteJobPlaceholderNoteFailed = () => {
    return {
        type: actionTypes.DELETE_JOB_PLACEHOLDER_NOTE_FAILED
    }
}

export const deleteJobSelectionTypeNote = (companyId, jobId, selectionTypeId, noteId, callback, callbackError) => {
    return dispatch => {
        dispatch(deleteJobPlaceholderNoteStart())
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/selectionTypes/${selectionTypeId}/notes/${noteId}`
        )
        .then(response => {
            dispatch(deleteJobPlaceholderNoteEnd());
            callback(response.data)
        })
        .catch(error => {
            console.log(error)
            dispatch(deleteJobPlaceholderNoteFailed());
            callbackError(error);
        })
    }

}

export const editJobPlaceholderNoteStart = () => {
    return {
        type: actionTypes.EDIT_JOB_PLACEHOLDER_NOTE_START
    }
}

export const editJobPlaceholderNoteEnd = () => {
    return {
        type: actionTypes.EDIT_JOB_PLACEHOLDER_NOTE_END
    }
}

export const editJobPlaceholderNoteFailed = () => {
    return {
        type: actionTypes.EDIT_JOB_PLACEHOLDER_NOTE_FAILED
    }
}

export const editJobSelectionTypeNote = (companyId, jobId, selectionTypeId, note, noteId, callback, callbackError) => {
    return dispatch => {
        dispatch(editJobPlaceholderNoteStart())
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/selectionTypes/${selectionTypeId}/notes/${noteId}`,
        note)
        .then(response => {
            dispatch(editJobPlaceholderNoteEnd());
            callback(response.data)
        })
        .catch(error => {
            console.log(error)
            dispatch(editJobPlaceholderNoteFailed());
        })
    }

}

export const setCreateEditPlaceholders = (createEditPlaceholder) => {
    return {
        type: actionTypes.SET_CREATE_EDIT_PLACEHOLDERS,
        createEditPlaceholder
    }
};

export const getPlaceholdersSuperAdmin = (searchWord, callback, limit, offset, sort) => {
    return dispatch => {
        dispatch(getPlaceholdersStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/categoryPlaceHolders${sortItems}`, {
            params: {
                ...(limit ? {limit} : {limit: '1000'}),
                ...(offset ? {offset} : {offset: '0'}),
                ...(searchWord ? {q: searchWord} : {}),
            }
        })
            .then(response => {
                if (callback) {
                    callback(response);
                }
                dispatch(setPlaceholders(response.data))
            })
            .catch(error => {
                dispatch(getPlaceholdersFailed())
            })
    }
};

export const removePlaceholderFromReportTemplateSuperAdmin = (reportTemplateId, headerId, placeholderId, callback) => {
    return dispatch => {
        dispatch(removePlaceholderFromReportTemplateStart());
        axios.delete(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}/headers/${headerId}/cph/${placeholderId}`)
            .then(response => {
                callback();
                dispatch(removePlaceholderFromReportTemplateFinished())
            })
            .catch(error => {
                dispatch(removePlaceholderFromReportTemplateFinished())
            })
    }
};

export const addPlaceholderToReportTemplateSuperAdmin = (reportTemplateId, headerId, placeholderId, callback, errorCallback) => {
    return dispatch => {
        dispatch(addPlaceholderToReportTemplateStart());
        axios.post(`${Constants.SERVER_URL}/reportTemplates/${reportTemplateId}/headers/${headerId}/cph/${placeholderId}`)
            .then(response => {
                callback();
                dispatch(addPlaceholderToReportTemplateFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addPlaceholderToReportTemplateFailed())
            })
    }
};

export const removePlaceholderSuperAdmin = (placeholderId, callback, errorCallback) => {
    return dispatch => {
        dispatch(removePlaceholderStart());
        axios.delete(`${Constants.SERVER_URL}/categoryPlaceHolders/${placeholderId}`)
            .then(response => {
                callback();
            })
            .catch(error => {
                errorCallback(error.response.data.error)
                dispatch(removePlaceholderFinished())
            })
    }
};

export const addPlaceholderSuperAdmin = (placeholder, callback, errorCallback) => {
    return dispatch => {
        dispatch(addPlaceholderStart());
        axios.post(`${Constants.SERVER_URL}/categoryPlaceHolders`, placeholder)
            .then(response => {
                callback()
            })
            .catch(error => {
                dispatch(addPlaceholderFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const editPlaceholderSuperAdmin = (placeholderId, placeholder, callback, errorCallback) => {
    return dispatch => {
        dispatch(editPlaceholderStart());
        axios.patch(`${Constants.SERVER_URL}/categoryPlaceHolders/${placeholderId}`, placeholder)
            .then(response => {
                callback()
            })
            .catch(error => {
                errorCallback(error.response.data.error)
                dispatch(editPlaceholderFinished())
            })
    }
};

export const deleteCategoryFromPlaceholderSuperAdmin = (companyId, placeholderId, categoryId, updateSelection, callback, errorCallback) => {
    return dispatch =>{
        dispatch(deleteCategoryFromPlaceholderStart());
        return  axios.delete(`${Constants.SERVER_URL}/categoryPlaceholders/${placeholderId}/categories/${categoryId}`, { data: updateSelection})
    }
};

export const addCategoriesToPlaceholderSuperAdmin = (placeholderId, categories, callback, errorCallback) => {
    return dispatch =>{
        dispatch(addCategoriesToPlaceholderStart());
        return axios.post(`${Constants.SERVER_URL}/categoryPlaceholders/${placeholderId}/categories`, categories)
    }
};

export const addPlaceholderCategoryAttributeSuperAdmin = (placeholderId, categoryId, attributes, callback, errorCallback) => {
    return dispatch => {
        dispatch(addPlaceholderCategoryAttributeStart());
        axios.post(`${Constants.SERVER_URL}/categoryPlaceholders/${placeholderId}/categories/${categoryId}/application`, attributes)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addPlaceholderCategoryAttributeFinished())
            })
            .catch(error => {
                dispatch(addPlaceholderCategoryAttributeFailed());
                errorCallback(error.response.data.error);
            })
    }
};

export const deletePlaceholderCategoryAttributeSuperAdmin = (placeholderId, categoryId, attribute, callback, errorCallback) => {
    return dispatch => {
        dispatch(deletePlaceholderCategoryAttributeStart());
        return axios.delete(`${Constants.SERVER_URL}/categoryPlaceholders/${placeholderId}/categories/${categoryId}/application`, {
            params: {
                ...({value: attribute})
            }
        })
    }
};

export const getCategoryPlaceholderStatusInJobsSuperAdmin = (placeholderId, categoryId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCategoryPlaceholderStatusInJobsStart())
        axios.delete(`${Constants.SERVER_URL}/categoryPlaceholders/${placeholderId}/categories/${categoryId}`)
            .then(response => {
                dispatch(getCategoryPlaceholderStatusInJobsFinished());
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getCategoryPlaceholderStatusInJobsFailed());
                errorCallback(error.response.data.error);
            })
    }
}
