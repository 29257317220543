import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';

export const getAssetVendorsStart = () => {
    return {
        type: actionTypes.GET_ASSET_VENDORS_START
    }
}

export const setAssetVendors = ({rows, count}) => {
    return {
        type: actionTypes.SET_ASSET_VENDORS,
        assetVendors: rows,
        assetVendorsTotalItems: count
    }
}

export const getAssetVendorsFailed = () => {
    return {
        type: actionTypes.GET_ASSET_VENDORS_FAILED
    }
}

export const getAssetVendors = (categoryId, searchWord, attributeQuery, callback, page, size, sort) => {
    return dispatch => {
        dispatch(getAssetVendorsStart())
        let sortItems = '';

        if (sort && sort.length > 0) {
            sortItems = '?'

            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/vendors${sortItems}`, {
            params: {
                ...(categoryId ? {categoryId} : {}),
                ...(searchWord ? {q: searchWord} : {}),
                ...(attributeQuery ? attributeQuery : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        })
        .then(response => {
            dispatch(setAssetVendors(response.data))
            if (callback) {
                callback(response)
            }
        }).catch(error => {
            console.log(error)
            dispatch(getAssetVendorsFailed())
        })
    }
}

export const getCompanyVendorsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_VENDORS_START
    }
}

export const setCompanyVendors = (companyVendors) => {
    return {
        type: actionTypes.SET_COMPANY_VENDORS,
        companyVendors: companyVendors
    }
}

export const getCompanyVendorsFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_VENDORS_FAILED
    }
}


export const getCompanyVendors = (companyId, categoryId, attributeQuery, callback, limit) => {
    return dispatch => {
        dispatch(getCompanyVendorsStart())
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/items/vendors`,  {
            params: {
              ...(limit ? {limit} : {limit: '1000'}),
              ...(categoryId ? {categoryId} : {}),
              ...(attributeQuery ? attributeQuery : {}),
            }
        })
        .then(response =>  {
            response.data.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));
            response.data.unshift({
                name: 'All Vendors',
                id: null
            });
            dispatch(setCompanyVendors(response.data))
            callback(response)
        })
        .catch(error => {
        console.log(error)
        dispatch(getCompanyVendorsFailed())
    })
    }

}

export const createVendorStart = () => {
    return {
        type: actionTypes.CREATE_VENDOR_START
    }
}

export const createVendorEnd = () => {
    return {
        type: actionTypes.CREATE_VENDOR_END
    }
}

export const createVendorFailed = () => {
    return {
        type: actionTypes.CREATE_VENDOR_FAILED
    }
}

export const createVendor = (name, callback, errorCallback) => {
    return dispatch => {
        dispatch(createVendorStart)
        axios.post(`${Constants.SERVER_URL}/vendors`, {name})
        .then(response => {
            dispatch(createVendorEnd())
            callback(response)
        }).catch(error => {
            console.log(error)
            errorCallback(error)
            dispatch(createVendorFailed())
        })
    }
}

export const deleteVendorStart = () => {
    return {
        type: actionTypes.DELETE_VENDOR_START
    }
}

export const deleteVendorEnd = () => {
    return {
        type: actionTypes.DELETE_VENDOR_END
    }
}

export const deleteVendorFailed = () => {
    return {
        type: actionTypes.DELETE_VENDOR_FAILED
    }
}

export const deleteVendor = (vendorId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteVendorStart())
        axios.delete(`${Constants.SERVER_URL}/vendors/${vendorId}`)
        .then(response => {
            dispatch(deleteVendorEnd())
            callback(response)
        }).catch(error => {
            console.error(error)
            if (errorCallback) {
                errorCallback(error)
            }
            dispatch(deleteVendorFailed())
        })
    }
    
}

export const editVendorStart = () => {
    return {
        type: actionTypes.EDIT_VENDOR_START
    }
}

export const editVendorEnd = () => {
    return {
        type: actionTypes.EDIT_VENDOR_END
    }
}

export const editVendorFailed = () => {
    return {
        type: actionTypes.EDIT_VENDOR_FAILED
    }
}

export const editVendor = (vendorId, name, callback, errorCallback) => {
    return dispatch => {
        dispatch(editVendorStart())
        axios.patch(`${Constants.SERVER_URL}/vendors/${vendorId}`, {name})
        .then(response => {
            dispatch(editVendorEnd())
            callback(response)
        }).catch(error => {
            console.error(error)
            errorCallback(error)
            dispatch(editVendorFailed())
        })
    }
   
}
