import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';
import UserUtils from "./../../Utils/UserUtils";

export const addColorSchemeStart = () => {
    return {
        type: actionTypes.ADD_COLOR_SCHEME_START
    }
};

export const addColorSchemeFailed = () => {
    return {
        type: actionTypes.ADD_COLOR_SCHEME_FAILED
    }
};

export const addColorSchemeFinished = () => {
    return {
        type: actionTypes.ADD_COLOR_SCHEME_FINISHED
    }
};

export const addColorScheme = (companyId, colorScheme, callback, errorCallback) => {
    return dispatch => {
        dispatch(addColorSchemeStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/schemes`, colorScheme)
            .then(response => {
                dispatch(addColorSchemeFinished());
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(error => {
                console.log(error);
                errorCallback(error.response.data.error);
                dispatch(addColorSchemeFailed())
            })
    }
};

export const deleteColorSchemeStart = () => {
    return {
        type: actionTypes.DELETE_COLOR_SCHEME_START
    }
};

export const deleteColorSchemeFailed = () => {
    return {
        type: actionTypes.DELETE_COLOR_SCHEME_FAILED
    }
};

export const deleteColorSchemeFinished = () => {
    return {
        type: actionTypes.DELETE_COLOR_SCHEME_FINISHED
    }
};

export const deleteColorScheme = (companyId, colorSchemeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteColorSchemeStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${colorSchemeId}`)
            .then(response => {
                dispatch(deleteColorSchemeFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                console.log(error);
                errorCallback(error.response.data.error);
                dispatch(deleteColorSchemeFailed())
            })
    }
};

export const editColorSchemeStart = () => {
    return {
        type: actionTypes.EDIT_COLOR_SCHEME_START
    }
};

export const editColorSchemeFailed = () => {
    return {
        type: actionTypes.EDIT_COLOR_SCHEME_FAILED
    }
};

export const editColorSchemeFinished = () => {
    return {
        type: actionTypes.EDIT_COLOR_SCHEME_FINISHED
    }
};

export const editColorScheme = (companyId, colorSchemeId, colorScheme, callback, errorCallback) => {
    return dispatch => {
        dispatch(editColorSchemeStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${colorSchemeId}`, colorScheme)
            .then(response => {
                dispatch(editColorSchemeFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                console.log(error);
                errorCallback(error.response.data.error);
                dispatch(editColorSchemeFailed())
            })
    }
};

export const getColorSchemesStart = () => {
    return {
        type: actionTypes.GET_COLOR_SCHEMES_START
    }
};

export const getColorSchemesFailed = () => {
    return {
        type: actionTypes.GET_COLOR_SCHEMES_FAILED
    }
};

export const setColorSchemes = (colorSchemes) => {
    return {
        type: actionTypes.SET_COLOR_SCHEMES,
        colorSchemes: colorSchemes
    }
};

export const setColorSchemesAll = (colorSchemes) => {
    return {
        type: actionTypes.SET_COLOR_SCHEMES_ALL,
        colorSchemes: colorSchemes.rows,
        colorSchemesTotalItems: colorSchemes.count
    }
};

export const getColorSchemes = (companyId, q, packageId, packageVersionId, callback, errorCallback, sort) => {
    return dispatch => {
        dispatch(getColorSchemesStart());
        let sortItems = '';
        if (sort && sort.length > 0) {
            sortItems = '?'
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/schemes${sortItems}`, {
            params: {
              ...(packageId && packageVersionId ? {packageId} : {}),
              ...(packageId && packageVersionId ? {packageVersionId} : {}),
              ...(q? {q} : {}),
            }
        })
            .then(response => {
                dispatch(setColorSchemes(response.data));
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getColorSchemesFailed())
            })
    }
};

export const getColorSchemesAll = (companyId, q, packageId, packageVersionId, callback, errorCallback, sort, limit, offset) => {
    return dispatch => {
        dispatch(getColorSchemesStart());
        let sortItems = '';
        if (sort && sort.length > 0) {
            sortItems = '?'
            for (const sortItem of sort) {
                sortItems += '&sort[]=' + sortItem
            }
        }
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/schemesAll${sortItems}`, {
            params: {
                ...(packageId && packageVersionId ? {packageId} : {}),
                ...(packageId && packageVersionId ? {packageVersionId} : {}),
                ...(q? {q} : {}),
                ...({limit: limit}),
                ...({offset: offset}),
            }
        })
            .then(response => {
                dispatch(setColorSchemesAll(response.data));
                if (callback) {
                    callback(response.data.rows);
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getColorSchemesFailed())
            })
    }
};

export const getColorSchemeByIdStart = () => {
    return {
        type: actionTypes.GET_COLOR_SCHEME_BY_ID_START
    }
};

export const getColorSchemeByIdFailed = () => {
    return {
        type: actionTypes.GET_COLOR_SCHEME_BY_ID_FAILED
    }
};

export const setColorSchemeById = (colorScheme) => {
    return {
        type: actionTypes.SET_COLOR_SCHEME_BY_ID,
        colorScheme: colorScheme
    }
};

export const getColorSchemeById = (companyId, colorSchemeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getColorSchemeByIdStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${colorSchemeId}`)
            .then(response => {
                dispatch(setColorSchemeById(response.data));
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getColorSchemeByIdFailed())
            })
    }
};

export const addItemsToColorSchemeStart = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COLOR_SCHEME_START
    }
};

export const addItemsToColorSchemeFinished = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COLOR_SCHEME_FINISHED
    }
};

export const addItemsToColorSchemeFailed = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_COLOR_SCHEME_FAILED,
    }
};

export const addItemsToColorScheme = (companyId, colorSchemeId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(addItemsToColorSchemeStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${colorSchemeId}/items`, items)
            .then(response => {
                dispatch(addItemsToColorSchemeFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addItemsToColorSchemeFailed())
            })
    }
};

export const deleteItemsFromColorSchemeStart = () => {
    return {
        type: actionTypes.DELETE_ITEMS_FROM_COLOR_SCHEME_START
    }
};

export const deleteItemsFromColorSchemeFinished = () => {
    return {
        type: actionTypes.DELETE_ITEMS_FROM_COLOR_SCHEME_FINISHED
    }
};

export const deleteItemsFromColorSchemeFailed = () => {
    return {
        type: actionTypes.DELETE_ITEMS_FROM_COLOR_SCHEME_FAILED,
    }
};

export const deleteItemsFromColorScheme = (companyId, colorSchemeId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteItemsFromColorSchemeStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${colorSchemeId}/items`, {headers: {
            Authorization: UserUtils.getToken()
        },
        data: items
        })
            .then(response => {
                dispatch(deleteItemsFromColorSchemeFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteItemsFromColorSchemeFailed())
            })
    }
};

export const getColorSchemeItemsStart = () => {
    return {
        type: actionTypes.GET_COLOR_SCHEME_ITEMS_START
    }
};

export const setColorSchemeItems = (colorSchemeItems) => {
    return {
        type: actionTypes.SET_COLOR_SCHEME_ITEMS,
        colorSchemeItems: colorSchemeItems
    }
};

export const getColorSchemeItemsFailed = () => {
    return {
        type: actionTypes.GET_COLOR_SCHEME_ITEMS_FAILED,
    }
};

export const getColorSchemeItems = (companyId, colorSchemeId, categoryId, searchWord, callback, errorCallback, limit) => {
    return dispatch => {
        dispatch(getColorSchemeItemsStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${colorSchemeId}/items`, {
            params: {
              ...(limit ? {limit} : {limit: '3000'}),
              ...(searchWord ? {q: searchWord} : {}),
              ...(categoryId ? {categoryId} : {}),
            }
        })
            .then(response => {
                dispatch(setColorSchemeItems(response.data.rows));
                if (callback) {
                    callback(response.data);
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getColorSchemeItemsFailed())
            })
    }
};

export const addColorSchemeToJobStart = () => {
    return {
        type: actionTypes.ADD_COLOR_SCHEME_TO_JOB_START
    }
};

export const addColorSchemeToJobFinished = () => {
    return {
        type: actionTypes.ADD_COLOR_SCHEME_TO_JOB_FINISHED,
    }
};

export const addColorSchemeToJobFailed = () => {
    return {
        type: actionTypes.ADD_COLOR_SCHEME_TO_JOB_FAILED,
    }
};

export const addColorSchemeToJob = (companyId, jobId, scheme, callback, errorCallback) => {
    return dispatch => {
        dispatch(addColorSchemeToJobStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/scheme`, scheme)
            .then(response => {
                dispatch(addColorSchemeToJobFinished());
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addColorSchemeToJobFailed())
            })
    }
};

export const addItemToVirtualBoardStart = () => {
    return {
        type: actionTypes.ADD_ITEM_TO_VIRTUAL_BOARD_START
    }
};

export const addItemToVirtualBoardFinished = () => {
    return {
        type: actionTypes.ADD_ITEM_TO_VIRTUAL_BOARD_FINISHED
    }
};

export const addItemToVirtualBoardFailed = () => {
    return {
        type: actionTypes.ADD_ITEM_TO_VIRTUAL_BOARD_FAILED
    }
};

export const addItemToVirtualBoard = (companyId, schemeId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(addItemToVirtualBoardStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${schemeId}/board/items`, items)
            .then(response => {
                dispatch(addItemToVirtualBoardFinished());
                if (callback){
                    callback()
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addItemToVirtualBoardFailed())
            })
    }
};

export const deleteItemFromVirtualBoardStart = () => {
    return {
        type: actionTypes.DELETE_VIRTUAL_BOARD_ITEM_START
    }
};

export const deleteItemFromVirtualBoardFinished = () => {
    return {
        type: actionTypes.DELETE_VIRTUAL_BOARD_ITEM_FINISHED
    }
};

export const deleteItemFromVirtualBoardFailed = () => {
    return {
        type: actionTypes.DELETE_VIRTUAL_BOARD_ITEM_FAILED
    }
};

export const deleteItemFromVirtualBoard = (companyId, schemeId, itemId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteItemFromVirtualBoardStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${schemeId}/board/items/${itemId}`)
            .then(response => {
                dispatch(deleteItemFromVirtualBoardFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                dispatch(deleteItemFromVirtualBoardFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const getVirtualColorBoardItemsStart = () => {
    return {
        type: actionTypes.GET_VIRTUAL_BOARD_ITEMS_START
    }
};

export const setVirtualColorBoardItems = (virtualBoardItems) => {
    return {
        type: actionTypes.SET_VIRTUAL_BOARD_ITEMS,
        virtualBoardItems: virtualBoardItems
    }
};

export const getVirtualColorBoardItemsFailed = () => {
    return {
        type: actionTypes.GET_VIRTUAL_BOARD_ITEMS_FAILED
    }
};

export const getVirtualColorBoardItems = (companyId, schemeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getVirtualColorBoardItemsStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${schemeId}/board/items`)
            .then(response => {
                response.data.sort((a,b) => (a.order > b.order) ? 1 : (b.order > a.order ? -1 : 0));
                dispatch(setVirtualColorBoardItems(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getVirtualColorBoardItemsFailed());
                errorCallback(error.response.data.error)
            })
    }
};

export const addBuilderImageToVirtualColorBoardStart = () => {
    return {
        type: actionTypes.ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_START
    }
};

export const addBuilderImageToVirtualColorBoardFinished = () => {
    return {
        type: actionTypes.ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FINISHED
    }
};

export const addBuilderImageToVirtualColorBoardFailed = () => {
    return {
        type: actionTypes.ADD_BUILDER_IMAGE_TO_VIRTUAL_BOARD_FAILED
    }
};

export const addBuilderImageToVirtualColorBoard = (companyId, schemeId, image, callback, errorCallback) => {
    return dispatch => {
        dispatch(addBuilderImageToVirtualColorBoardStart());
        const formData = new FormData();
        let extension = image.name.split('.')[1];
        formData.append('image', image);
        const config = {
            headers: {
                'ss-image-extension': extension,
            }
        };
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${schemeId}/builder/image`, formData, config)
            .then(response => {
                if (callback) {
                    callback ()
                }
                dispatch(addBuilderImageToVirtualColorBoardFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addBuilderImageToVirtualColorBoardFailed())
            })
    }
};

export const deleteColorSchemeBuilderImageStart = () => {
    return {
        type: actionTypes.DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_START
    }
};

export const deleteColorSchemeBuilderImageFinished = () => {
    return {
        type: actionTypes.DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_FINISHED
    }
};

export const deleteColorSchemeBuilderImageFailed = () => {
    return {
        type: actionTypes.DELETE_BUILDER_IMAGE_FROM_VIRTUAL_BOARD_FAILED
    }
};

export const deleteColorSchemeBuilderImage = (companyId, schemeId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteColorSchemeBuilderImageStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${schemeId}/builder/image`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deleteColorSchemeBuilderImageFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteColorSchemeBuilderImageFailed())
            })
    }
};

export const deleteColorSchemeFromJobStart = () => {
    return {
        type: actionTypes.DELETE_VIRTUAL_BOARD_ITEM_START
    }
};

export const deleteColorSchemeFromJobFinished = () => {
    return {
        type: actionTypes.DELETE_VIRTUAL_BOARD_ITEM_FINISHED
    }
};

export const deleteColorSchemeFromJobFailed = () => {
    return {
        type: actionTypes.DELETE_VIRTUAL_BOARD_ITEM_FAILED
    }
};

export const deleteColorSchemeFromJob = (companyId, jobId, scheme, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteColorSchemeFromJobStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/scheme`, { data: scheme})
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deleteColorSchemeFromJobFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteColorSchemeFromJobFailed())
            })
    }
};


export const editColorSchemeItemCustomAttributesStart = () => {
    return {
        type: actionTypes.EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_START
    }
};

export const editColorSchemeItemCustomAttributesFinished = () => {
    return {
        type: actionTypes.EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FINISHED
    }
};

export const editColorSchemeItemCustomAttributesFailed = () => {
    return {
        type: actionTypes.EDIT_COLOR_SCHEME_ITEM_CUSTOM_ATTRIBUTES_FAILED
    }
};

export const editColorSchemeItemCustomAttributes = (companyId, schemeId, companyItemId, attributes, callback, errorCallback) => {
    return dispatch => {
        dispatch(editColorSchemeItemCustomAttributesStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/schemes/${schemeId}/items/${companyItemId}/customAttributes`, attributes)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(editColorSchemeItemCustomAttributesFinished())
            })
            .catch(error => {
                console.log(error)
                errorCallback(error.response.data.error);
                dispatch(editColorSchemeItemCustomAttributesFailed())
            })
    }
};
