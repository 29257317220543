import * as actionTypes from '../Actions/actionTypes';

const initialState = {
    loading: false,
    error: false,
    resellers: [],
    reseller: null,
    resellerChildCompany: null,
    total: 0,
    resellerUsers: [],
    resellerPortalResellerUsers: [],
    resellerChildCompanies: [],
    resellerChildCompanyUsers: [],
    resellerChildCompanyJobs: [],
    reportTemplates: [],
    childCompanyReportTemplates: [],
    packages: [],
    childCompanyPackages: [],
    packageItems: [],
    packageVersions: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_RESELLERS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_RESELLERS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_RESELLERS:
            return {
                ...state,
                resellers: action.resellers,
                total: action.total,
                loading: false,
                error: false
            };
        case actionTypes.GET_RESELLER_BY_ID_START:
            return {
                ...state,
                loading: true,
                reseller: null,
            };
        case actionTypes.GET_RESELLER_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_RESELLER:
            return {
                ...state,
                reseller: action.reseller,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_RESELLER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_RESELLER_FINISHED:
            return {
                ...state,
                error: false,
                loading: false,
            };
        case actionTypes.DELETE_RESELLER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_RESELLER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_RESELLER_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_RESELLER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPLOAD_RESELLER_IMAGE_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.UPLOAD_RESELLER_IMAGE_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.UPLOAD_RESELLER_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.EDIT_RESELLER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.EDIT_RESELLER_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.EDIT_RESELLER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_RESELLER_USERS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_RESELLER_USERS:
            return {
                ...state,
                resellerUsers: action.resellerUsers,
                total: action.total,
                loading: false,
                error: false
            };
        case actionTypes.GET_RESELLER_USERS_RESELLER_PORTAL_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_RESELLER_USERS_RESELLER_PORTAL_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_RESELLER_USERS_RESELLER_PORTAL:
            return {
                ...state,
                resellerPortalResellerUsers: action.resellerPortalResellerUsers,
                total: action.total,
                loading: false,
                error: false
            };
        case actionTypes.GET_RESELLER_USERS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_USER_TO_RESELLER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_USER_TO_RESELLER_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_USER_TO_RESELLER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_USER_FROM_RESELLER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_USER_FROM_RESELLER_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_USER_FROM_RESELLER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANIES_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANIES_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_RESELLER_CHILD_COMPANIES:
            return {
                ...state,
                resellerChildCompanies: action.resellerChildCompanies,
                total: action.total,
                loading: false,
                error: true
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANY_BY_ID_START:
            return {
                ...state,
                loading: true,
                resellerChildCompany: null,
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANY_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_RESELLER_CHILD_COMPANY_BY_ID:
            return {
                ...state,
                resellerChildCompany: action.resellerChildCompany,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_ITEM_FROM_RESELLER_ROASTER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_ITEM_FROM_RESELLER_ROASTER_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_ITEM_FROM_RESELLER_ROASTER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_ITEM_FROM_RESELLER_CHILD_COMPANY_ROASTER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_USER_TO_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_USER_TO_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_USER_TO_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_USER_TO_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_USER_TO_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_USER_TO_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_USERS_OF_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_USERS_OF_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_USERS_OF_RESELLER_CHILD_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                resellerChildCompanyUsers: action.resellerChildCompanyUsers,
                total: action.total
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANY_JOBS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_RESELLER_CHILD_COMPANY_JOBS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.SET_RESELLER_CHILD_COMPANY_JOBS:
            return {
                ...state,
                loading: false,
                error: false,
                resellerChildCompanyJobs: action.resellerChildCompanyJobs,
                total: action.total
            };
        case actionTypes.DELETE_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_ALL_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_ALL_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_ASSET_TO_RESELLER_ROASTER_ALL_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_REPORT_TEMPLATES_OF_RESELLER_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                reportTemplates: action.reportTemplates
            };
        case actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_REPORT_TEMPLATE_TO_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_REPORT_TEMPLATE_OF_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
                childCompanyReportTemplates: []
            };
        case actionTypes.SET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                childCompanyReportTemplates: action.childCompanyReportTemplates
            };
        case actionTypes.GET_REPORT_TEMPLATES_OF_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_PACKAGES_OF_RESELLER_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.SET_PACKAGES_OF_RESELLER_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                packages: action.packages
            };
        case actionTypes.GET_PACKAGES_OF_RESELLER_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.ADD_PACKAGE_TO_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FINISHED:
            return {
                ...state,
                loading: false,
                error: false
            };
        case actionTypes.DELETE_PACKAGE_OF_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_START:
            return {
                ...state,
                loading: true,
                childCompanyReportTemplates: []
            };
        case actionTypes.SET_PACKAGES_OF_RESELLER_CHILD_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                childCompanyPackages: action.childCompanyPackages
            };
        case actionTypes.GET_PACKAGES_OF_RESELLER_CHILD_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_START:
            return {
                ...state,
                loading: true,
                packageItems: []
            };
        case actionTypes.SET_PACKAGE_ITEMS_OF_RESELLER_COMPANY:
            return {
                ...state,
                loading: false,
                error: false,
                packageItems: action.packageItems
            };
        case actionTypes.GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.GET_RESELLER_PACKAGE_VERSIONS_START:
            return {
                ...state,
                loading: true,
                packageVersions: []
            };
        case actionTypes.SET_RESELLER_PACKAGE_VERSIONS:
            return {
                ...state,
                loading: false,
                error: false,
                packageVersions: action.packageVersions
            };
        case actionTypes.GET_RESELLER_PACKAGE_VERSIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        default:
            return state;
    }
};

export default reducer
