import * as actionTypes from './actionTypes';
import * as Constants from 'Utils/Constants';
import axios from 'axios';
import UserUtils from "./../../Utils/UserUtils";
import generalUtils from "../../Utils/GeneralUtils";

export const addCompanyPackageStart = () => {
    return {
        type: actionTypes.ADD_COMPANY_PACKAGE_START
    }
};

export const addCompanyPackageFinished = () => {
    return {
        type: actionTypes.ADD_COMPANY_PACKAGE_FINISHED
    }
};

export const addCompanyPackageFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_PACKAGE_FAILED
    }
};

export const addCompanyPackage = (companyId, companyPackage, callback, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyPackageStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/packages`, companyPackage)
            .then(response => {
                dispatch(addCompanyPackageFinished());
                if (callback){
                    callback(response.data);
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addCompanyPackageFailed());
            })
    }
};

export const deleteCompanyPackageStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_PACKAGE_START
    }
};

export const deleteCompanyPackageFinished = () => {
    return {
        type: actionTypes.DELETE_COMPANY_PACKAGE_FINISHED
    }
};

export const deleteCompanyPackageFailed = () => {
    return {
        type: actionTypes.DELETE_COMPANY_PACKAGE_FAILED
    }
};

export const deleteCompanyPackage = (companyId, packageId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCompanyPackageStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}`)
            .then(response => {
                dispatch(deleteCompanyPackageFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteCompanyPackageFailed())
            })
    }
};

export const editCompanyPackageStart = () => {
    return {
        type: actionTypes.EDIT_COMPANY_PACKAGE_START
    }
};

export const editCompanyPackageFinished = () => {
    return {
        type: actionTypes.EDIT_COMPANY_PACKAGE_FINISHED
    }
};

export const editCompanyPackageFailed = () => {
    return {
        type: actionTypes.EDIT_COMPANY_PACKAGE_FAILED
    }
};

export const editCompanyPackage = (companyId, packageId, companyPackage, callback, errorCallback) => {
    return dispatch => {
        dispatch(editCompanyPackageStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}`, companyPackage)
            .then(response => {
                dispatch(editCompanyPackageFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(editCompanyPackageFailed())
            })
    }
};

export const getCompanyPackageStart = () => {
    return {
        type: actionTypes.GET_COMPANY_PACKAGE_START
    }
};

export const setCompanyPackage = (companyPackage) => {
    return {
        type: actionTypes.SET_COMPANY_PACKAGE,
        companyPackage: companyPackage
    }
};

export const getCompanyPackageFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_PACKAGE_FAILED
    }
};

export const getCompanyPackage = (companyId, packageId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyPackageStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}`)
            .then(response => {
                dispatch(setCompanyPackage(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getCompanyPackageFailed())
            })
    }
};

export const getCompanyPackagesOfCompanyStart = () => {
    return {
        type: actionTypes.GET_COMPANY_PACKAGES_OF_COMPANY_START
    }
};

export const setCompanyPackagesOfCompany = (companyPackages) => {
    return {
        type: actionTypes.SET_COMPANY_PACKAGES_OF_COMPANY,
        companyPackages: companyPackages
    }
};

export const getCompanyPackagesOfCompanyFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_PACKAGES_OF_COMPANY_FAILED
    }
};

export const getCompanyPackages = (companyId, q, callback, errorCallback, sort) => {
    return dispatch => {
        dispatch(getCompanyPackagesOfCompanyStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/packages${sortItems}`, {
            params: {
                ...(q? {q} : {}),
            }
        })
            .then(response => {
                dispatch(setCompanyPackagesOfCompany(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getCompanyPackagesOfCompanyFailed())
            })
    }
};

export const getCompanyPackagesAll = (page, size, companyId, searchWord, callback, errorCallback, sort) => {
    return dispatch => {
        dispatch(getCompanyPackagesOfCompanyStart());

        let sortItems = generalUtils.buildSortUrlParams(sort);

        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/packagesAll${sortItems}`, {
            params: {
                ...(searchWord ? {q: searchWord} : {}),
                ...({limit: size}),
                ...({offset: (page - 1) * size}),
            }
        })
            .then(response => {
                dispatch(setCompanyPackagesOfCompany(response.data));
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getCompanyPackagesOfCompanyFailed())
            })
    }
};

export const editJobPackageStart = () => {
    return {
        type: actionTypes.EDIT_JOB_PACKAGE_START
    }
};

export const editJobPackageFinished = () => {
    return {
        type: actionTypes.EDIT_JOB_PACKAGE_FINISHED,
    }
};

export const editJobPackageFailed = () => {
    return {
        type: actionTypes.EDIT_JOB_PACKAGE_FAILED
    }
};

export const editJobPackage = (companyId, jobId, companyPackage, callback, errorCallback) => {
    return dispatch => {
        dispatch(editJobPackageStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/package`, companyPackage)
            .then(response => {
                dispatch(editJobPackageFinished());
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                const errorData = error.response ? error.response.data.error : error;
                console.error(errorData)
                if(errorCallback) {
                    errorCallback(errorData)
                }
                dispatch(editJobPackageFailed())
            })
    }
};

export const addCompanyItemToPackageStart = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEM_TO_PACKAGE_START
    }
};

export const addCompanyItemToPackageFinished = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEM_TO_PACKAGE_FINISHED
    }
};

export const addCompanyItemToPackageFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_ITEM_TO_PACKAGE_FAILED
    }
};

export const addCompanyItemToPackage = (companyId, companyPackageId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyItemToPackageStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/packages/${companyPackageId}/items`, items)
            .then(response => {
                dispatch(addCompanyItemToPackageFinished());
                if (callback) {
                    callback(response)
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addCompanyItemToPackageFailed())
            })
    }
};

export const deleteCompanyItemFromPackageStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ITEM_FROM_PACKAGE_START
    }
};

export const deleteCompanyItemFromPackageFinished = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ITEM_FROM_PACKAGE_FINISHED
    }
};

export const deleteCompanyItemFromPackageFailed = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ITEM_FROM_PACKAGE_FAILED
    }
};

export const deleteCompanyItemFromPackage = (companyId, packageId, items, callback, errorCallback) => {
    return dispatch => {
        dispatch(deleteCompanyItemFromPackageStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/items`, {headers: {
                Authorization: UserUtils.getToken()
            },
            data: items
            })
            .then(response => {
                dispatch(deleteCompanyItemFromPackageFinished());
                if (callback) {
                    callback(response)
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deleteCompanyItemFromPackageFailed())
            })
    }
};

export const getItemsOfThePackageStart = () => {
    return {
        type: actionTypes.GET_ITEMS_OF_THE_PACKAGE_START
    }
};

export const setCompanyItemFromPackage = (packageItems) => {
    return {
        type: actionTypes.SET_ITEMS_OF_THE_PACKAGE,
        packageItems: packageItems
    }
};

export const getItemsOfThePackageFailed = () => {
    return {
        type: actionTypes.GET_COMPANY_PACKAGES_OF_COMPANY_FAILED
    }
};

export const getItemsOfThePackage = (currentUser, page, itemsPerPage, companyId, packageId, categories, searchWord, versionId, vendorId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getItemsOfThePackageStart());
        let q = '?limit=' + itemsPerPage +'&offset=' + page*itemsPerPage;
        if (searchWord) {
            q += '&q=' + searchWord
        }
        for (let category of categories) {
            q += '&categoryIds[]=' + category.id;
            if (category.application && category.application.length > 0 && currentUser.currentCompany.applicationAttribute) {
                q += '&attrs[]={"id":' + currentUser.currentCompany.applicationAttribute.id
                    + ', "categoryId":' + category.id
                    + ', "values":[';
                let index = 0;
                for (let app of category.application) {
                    q += '"' + app + (index === category.application.length - 1 ? '"' : '",')
                    index++
                }
                q += ']}';
            }
        }
        if (versionId) {
            q += '&versionId=' + versionId
        }
        if (vendorId) {
            q += '&vendorId=' + vendorId
        }
        q = encodeURI(q);
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/items` + q)
            .then(response => {
                dispatch(setCompanyItemFromPackage(response.data.rows));
                if (callback) {
                    callback(response.data, response)
                }
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(getItemsOfThePackageFailed())
            })
    }
};

export const getFiltersForCompanyPackageStart = () => {
    return {
        type: actionTypes.GET_FILTER_FOR_ADD_COMPANY_ITEM_START
    }
};

export const getFiltersForCompanyPackageFailed = () => {
    return {
        type: actionTypes.GET_FILTER_FOR_ADD_COMPANY_ITEM_FAILED
    }
};

export const setFiltersForCompanyPackage = (filters) => {
    return {
        type: actionTypes.SET_FILTER_FOR_ADD_COMPANY_ITEM,
        packageFilters: filters
    }
};

export const getFiltersForCompanyPackage = (companyId, vendorId, categories, searchWord, attributeQuery, callback, errorCallback) => {
    return dispatch => {
        dispatch(getFiltersForCompanyPackageStart());
        let q = '';
        if (vendorId) {
            q += '&vendorId=' + vendorId
        }
        if (categories && categories.length > 0) {
            for (const category of categories) {
                q += '&categoryIds[]=' + category.id
            }
        }
        if (searchWord) {
            q+= '&q=' + searchWord
        }
        if (q) {
            q = q.replace('&', '?')
        }
        axios.post(Constants.SERVER_URL + '/companies/' + companyId + '/itemsCustomAttributes/get' + q, attributeQuery && attributeQuery.length !== 0 ? {
            attrs: attributeQuery
        } : null)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(getFiltersForCompanyPackageFailed())
            })
    }
};

export const setCompanyItemLevelAttributeStart = () => {
    return {
        type: actionTypes.SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_START
    }
};

export const setCompanyItemLevelAttributeFinished = () => {
    return {
        type: actionTypes.SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FINISHED
    }
};

export const setCompanyItemLevelAttributeFailed = () => {
    return {
        type: actionTypes.SET_COMPANY_ITEM_LEVEL_ATTRIBUTE_FAILED
    }
};

export const setCompanyItemLevelAttribute = (companyId, jobId, itemId, level, callback, errorCallback) => {
    return dispatch => {
        dispatch(setCompanyItemLevelAttributeStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/items/${itemId}/level`, level)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(setCompanyItemLevelAttributeFinished())
            })
            .catch(error => {
                errorCallback(error);
                dispatch(setCompanyItemLevelAttributeFailed)
            })
    }
};

export const addCompanyPackageVersionStart = () => {
    return {
        type: actionTypes.ADD_COMPANY_PACKAGE_VERSION_START
    }
};

export const addCompanyPackageVersionFinished = () => {
    return {
        type: actionTypes.ADD_COMPANY_PACKAGE_VERSION_FINISHED
    }
};

export const addCompanyPackageVersionFailed = () => {
    return {
        type: actionTypes.ADD_COMPANY_PACKAGE_VERSION_FAILED
    }
};

export const addCompanyPackageVersion = (companyId, packageId, callback, errorCallback) => {
    return dispatch => {
        dispatch(addCompanyPackageVersionStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/versions`)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addCompanyPackageVersionFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addCompanyPackageVersionFailed())
            })
    }
};

export const getCompanyPackageVersionStart = () => {
    return {
        type: actionTypes.GET_PACKAGE_VERSIONS_START
    }
};

export const setCompanyPackageVersion = (packageVersions) => {
    return {
        type: actionTypes.SET_PACKAGE_VERSIONS,
        packageVersions: packageVersions
    }
};

export const getCompanyPackageVersionFailed = () => {
    return {
        type: actionTypes.GET_PACKAGE_VERSIONS_FAILED
    }
};

export const getCompanyPackageVersion = (companyId, packageId, callback, errorCallback) => {
    return dispatch => {
        dispatch(getCompanyPackageVersionStart());
        axios.get(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/versions`)
            .then(response => {
                if (callback) {
                    callback(response.data)
                }
                dispatch(setCompanyPackageVersion(response.data))
            })
            .catch(error => {
                errorCallback(error.response && error.response.data.error);
                dispatch(getCompanyPackageVersionFailed())
            })
    }
};


export const addAllItemsToPackageStart = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_PACKAGE_ALL_START
    }
};

export const addAllItemsToPackageFinished = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_PACKAGE_ALL_FINISHED
    }
};

export const addAllItemsToPackageFailed = () => {
    return {
        type: actionTypes.ADD_ITEMS_TO_PACKAGE_ALL_FAILED
    }
};

export const addAllItemsToPackage = (companyId, packageId, filter, callback, errorCallback) => {
    return dispatch => {
        dispatch(addAllItemsToPackageStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/items/filtered`, filter)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(addAllItemsToPackageFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(addAllItemsToPackageFailed())
            })
    }
};

export const deletePackageFromJoStart = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_FROM_JOB_START
    }
};

export const deletePackageFromJobFinished = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_FROM_JOB_FINISHED
    }
};

export const deletePackageFromJobFailed = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_FROM_JOB_FAILED
    }
};

export const deletePackageFromJob = (companyId, jobId, callback, errorCallback) => {
    return dispatch => {
        dispatch(deletePackageFromJoStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/jobs/${jobId}/package`)
            .then(
                () => {
                    if (callback) {
                        callback()
                    }
                }
            ).catch(
            error => {
                const errorData = error.response ? error.response.data.error : error;
                console.error(errorData)
                errorCallback(errorData)
            }
        )
    }
};

export const editPackageItemCustomAttributesStart = () => {
    return {
        type: actionTypes.EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START
    }
};

export const editPackageItemCustomAttributesFinished = () => {
    return {
        type: actionTypes.EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED
    }
};

export const editPackageItemCustomAttributesFailed = () => {
    return {
        type: actionTypes.EDIT_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED
    }
};

export const addPackageItemCustomAttributes = (companyId, packageId, companyItemId, attributes, callback, errorCallback) => {
    return dispatch => {
        dispatch(editPackageItemCustomAttributesStart());
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/items/${companyItemId}/customAttributes`, attributes)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(editPackageItemCustomAttributesFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(editPackageItemCustomAttributesFailed())
            })
    }
};

export const editPackageItemCustomAttributes = (companyId, packageId, companyItemId, attributes, callback, errorCallback) => {
    return dispatch => {
        dispatch(editPackageItemCustomAttributesStart());
        axios.patch(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/items/${companyItemId}/customAttributes`, attributes)
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(editPackageItemCustomAttributesFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(editPackageItemCustomAttributesFailed())
            })
    }
};

export const deletePackageItemCustomAttributeStart = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_START
    }
};

export const deletePackageItemCustomAttributeFinished = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FINISHED
    }
};

export const deletePackageItemCustomAttributeFailed = () => {
    return {
        type: actionTypes.DELETE_PACKAGE_ITEM_CUSTOM_ATTRIBUTES_FAILED
    }
};

export const deletePackageItemCustomAttribute = (companyId, packageId, itemId, attributesForDelete, callback, errorCallback) => {
    return dispatch => {
        dispatch(deletePackageItemCustomAttributeStart());
        axios.delete(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/items/${itemId}/customAttributes`,  {data: attributesForDelete})
            .then(response => {
                if (callback) {
                    callback()
                }
                dispatch(deletePackageItemCustomAttributeFinished())
            })
            .catch(error => {
                errorCallback(error.response.data.error);
                dispatch(deletePackageItemCustomAttributeFailed())
            })
    }
};

export const getPackageItemsPostStart = () => {
    return {
        type: actionTypes.GET_PACKAGE_ITEMS_POST_START
    }
};

export const setPackageItemsPost = () => {
    return {
        type: actionTypes.SET_PACKAGE_ITEMS_POST
    }
};

export const getPackageItemsPostFailed = () => {
    return {
        type: actionTypes.GET_PACKAGE_ITEMS_POST_FAILED
    }
};

export const getPackageItemsPost = (page, itemsPerPage, companyId, packageId, categories, searchWord, versionId,
                                    vendorId, filterAttributes, callback, errorCallback, isSupported, itemIdToShowFirst, selectionTypeId) => {
    return dispatch => {
        dispatch(getPackageItemsPostStart());
        let q = '?limit=' + itemsPerPage +'&offset=' + page*itemsPerPage;
        if (searchWord) {
            q += '&q=' + searchWord
        }
        if (versionId) {
            q += '&versionId=' + versionId
        }
        if (vendorId) {
            q += '&vendorId=' + vendorId
        }
        if (isSupported) {
            q += '&isSupported=' + isSupported
        }
        if (itemIdToShowFirst) {
            q += '&itemIdToShowFirst=' + itemIdToShowFirst
        }
        if (selectionTypeId) {
            q += '&selectionTypeId=' + selectionTypeId
        }
        for (let category of categories) {
            q += '&categoryIds[]=' + category.id;
        }
        axios.post(Constants.SERVER_URL + '/companies/' + companyId + '/packages/' + packageId + '/items/get' + q, {
            attrs: filterAttributes
        })
            .then(response => {
                if (callback) {
                    callback(response.data, parseInt(response.headers['count-items'], 10))
                }
                dispatch(setPackageItemsPost())
            })
            .catch(error => {
                dispatch(getPackageItemsPostFailed())
            })
    }
};

export const getPackageItemsStatusByFilterStart = () => {
    return {
        type: actionTypes.GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_START
    }
}

export const setPackageItemsStatusByFilter = (status) => {
    return {
        packageItemsStatus: status.total === status.added,
        type: actionTypes.SET_PACKAGE_ITEMS_STATUS_BY_FILTER
    }
}

export const getPackageItemsStatusByFilterFailed = () => {
    return {
        type: actionTypes.GET_PACKAGE_ITEMS_OF_RESELLER_COMPANY_FAILED
    }
}

export const getPackageItemsStatusByFilter = (companyId, packageId, filter, callback, errorCallback) => {
    return dispatch => {
        dispatch(getPackageItemsStatusByFilterStart())
        axios.post(`${Constants.SERVER_URL}/companies/${companyId}/packages/${packageId}/items/filtered/status/get`,
            {
                filter: filter
            })
            .then(response => {
                dispatch(setPackageItemsStatusByFilter(response.data))
                if (callback) {
                    callback(response.data)
                }
            })
            .catch(error => {
                dispatch(getPackageItemsStatusByFilterFailed())
                errorCallback(error.response.data.error)
            })
    }
}
